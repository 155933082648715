import api from "../../api";

export const getInfoCabecalho = async (enfermeiroID, tarefaID) => {
  const res = await api.post(
    "/backoffice/buscarCabecalho",
    {
      token: "gecro",
      idTarefa: tarefaID,
      idEnfermeiro: enfermeiroID,
    }
  );

  return res.data;
};

export const getFotoPaciente = async (pacienteID) => {
  let foto_paciente;
  try {
    await api
      .post("/backoffice/buscarFotoPaciente", {
        token: "gecro",
        paciente: pacienteID,
      })
      .then((response) => {
        const type = response.data.filename.split(".")[1];
        foto_paciente = `data:image/${type};base64,${response.data.file}`;
      });

    return foto_paciente;
  } catch (error) {
    return null;
  }
};

export const completarAdesao = async (paciente, tarefa) => {
  const res = await api.post(
    "/backoffice/finalizarAdesao",
    {
      token: "gecro",
      paciente: paciente,
      tarefa: tarefa,
    }
  );

  return res.data;
};

export const cancelarAdesao = async (enfermeiroID, tarefa, motivo) => {
  const res = await api.post(
    "/backoffice/cancelarTarefa",
    {
      token: "gecro",
      idEnfermeiro: enfermeiroID,
      idTarefa: tarefa,
      motivo,
    }
  );

  return res.data;
};
