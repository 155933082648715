import api from "api";

export const buscarCuidadores = async (body) => {
  const res = await api.post(
    "/backoffice/buscarRedeApoio",
    body
  );
  return res.data;
};

export const salvarObservacoes = async (body) => {
  const res = await api.post(
    "/backoffice/salvarObservacoes",
    body
  );
  return res.data;
};

export const finalizarTarefa = async (pacienteID, tarefa) => {
  const res = await api.post(
    "/backoffice/finalizarTarefa",
    {
      token: "gecro",
      paciente: pacienteID,
      tarefa: tarefa,
    }
  );
  return res.data;
};
