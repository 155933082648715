
import { useEffect, useRef } from 'react'

export function useInterval(callback, delay = 5000) {
  const savedCallback = useRef(callback)
  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}
