import React, { useEffect } from "react";
import * as actions from './action'
import "./style.scss";
import { Typography, Tag, Popconfirm, message, Icon, Avatar } from "antd";
import { connect } from "react-redux";
import { CRITICIDADE, TIPO_TAREFA } from "../../Misc/constants";
import { logOut } from "../Login/action";
import UrgencyPopupBadge from "Components/UrgencyPopup";
import { useLocation } from "react-router-dom";

const { Text } = Typography;
const HeaderBackoffice = (props) => {
  const confirm = (e) => {
    message.info("Você não está mais autenticado.");
    props.logOut();
  };

  const getFoto = async () => await props.getFotoPaciente(props.paciente)

  const location = useLocation();

  useEffect(() => {
    if (props.paciente) getFoto()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente])

  return (
    <>
      <div className="marginCabecalho">
        {location.pathname !== '/' && props.paciente && props.foto_perfil !== undefined && (
          <Avatar
            size={48}
            icon="user"
            style={{ marginRight: 10 }}
            src={props.foto_perfil}
          />
        )}
        <Text className="tituloCabecalho">{props.tituloPricipal}</Text>

        {props.criticidade !== null && (
          <Tag
            className="margin"
            style={{
              color: CRITICIDADE[props.criticidade].color,
              fontWeight: "normal",
              fontSize: "12px",
            }}
            color={CRITICIDADE[props.criticidade].background}
          >
            {CRITICIDADE[props.criticidade].descricao}
          </Tag>
        )}
        {props.tipoTarefa !== null && (
          <Tag
            className="margin"
            style={{
              fontWeight: "normal",
              fontSize: "14px",
            }}
            color={TIPO_TAREFA[props.tipoTarefa].background}
          >
            {TIPO_TAREFA[props.tipoTarefa].descricao}
          </Tag>
        )}
      </div>
      <div style={{ display: "flex", alignItems: 'center' }}>
        <UrgencyPopupBadge />
        <Text>
          <Popconfirm
            title="Você deseja sair do sistema?"
            onConfirm={confirm}
            okText="Sim"
            cancelText="Não"
            placement="bottomRight"
          >
            <span className="enfermeiroCabecalho">
              {props.user.nome} <Icon type="caret-down" />
            </span>
          </Popconfirm>
        </Text>
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  tituloPricipal: store.header.tituloPrincipal,
  criticidade: store.header.criticidade,
  tipoTarefa: store.header.tipoTarefa,
  user: store.login.user,
  foto_perfil: store.header.foto_perfil,
  paciente: store.adesao.paciente,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  getFotoPaciente: (pacienteID) => dispatch(actions.getFotoPaciente(pacienteID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBackoffice);
