/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Select, Button, Row, Col, Tooltip, Divider } from "antd";
import * as actions from "../action";
import * as actionsEnfermeiros from "Pages/PainelAtividades/action";
import BasicModal from "Components/BasicModal";
import AtendentesAfastamentoTabela from "./AtendentesAfastamentoTabela";
import { maskDate, isValidDate } from "Misc/utils";
import { DATE_FORMAT, SCROLL_CONFIG } from "Misc/constants";
import moment from "moment";

const { Option } = Select;

const AtendentesActionModal = ({
  matricula,
  modalType,
  handleCloseActionModal,
  buscarEnfermeiros,
  enfermeiros,
  loadingEnfermeiros,
  motivos,
  loadingMotivos,
  buscarOpcoesAfastamento,
  buscarOpcoesDesligamento,
  registrarDesligamento,
  registrarAfastamento,
  filtro,
  loadingSave,
  ...props
}) => {
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    getFieldValue,
    resetFields,
  } = props.form;

  useEffect(() => {
    if (modalType !== null) {
      buscarEnfermeiros({ matricula: matricula });
    }

    switch (modalType) {
      case "afastar":
        buscarOpcoesAfastamento();
        break;

      default:
        break;
    }
  }, [modalType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, async (err, values) => {
      if (!err) {
        switch (modalType) {
          case "afastar":
            const afastamento = await registrarAfastamento(
              JSON.stringify({ matricula: matricula, ...values }),
              filtro,
              true
            );
            if (afastamento) {
              resetFields();
            }
            break;
          case "desligar":
            registrarDesligamento(
              JSON.stringify({ matricula: matricula, ...values }),
              filtro
            );
            break;
          default:
            break;
        }
      }
    });
  };

  const titleModal = {
    afastar: "Afastamentos",
    desligar: "Desligar",
  };

  const widthModal = {
    afastar: "55vw",
    desligar: "40vw",
  };

  const compareDates = (rule, value, callback) => {
    const inicio = moment(getFieldValue("dataInicio"), "DD/MM/YYYY");
    const fim = moment(value, "DD/MM/YYYY");

    if (fim.isBefore(inicio) && value.length >= 10) {
      callback(true);
    } else if (
      fim.isSameOrBefore(moment("1900", "YYYY"), "year") &&
      value.length >= 10
    ) {
      callback(true);
    } else {
      callback();
    }
  };

  const isRequired = true;

  const formLayout = {
    labelCol: {
      xs: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 14 },
    },
  };

  const desligaLayout = {
    labelCol: {
      xs: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 10 },
    },
  };

  const dateLayout = {
    labelCol: {
      xs: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 11 },
    },
  };

  const dateAteLayout = {
    labelCol: {
      xs: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 11 },
    },
  };

  const footerLayout = {
    labelCol: {
      xs: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 19 },
    },
  };

  return (
    <BasicModal
      title={titleModal[modalType]}
      visible={Boolean(modalType)}
      onClose={handleCloseActionModal}
      destroyOnClose
      width={widthModal[modalType]}
      bodyStyle={{}}
      style={{ minWidth: "400px" }}
      footer={
        modalType === "desligar"
          ? [
              <Button
                className="secondary-btn"
                key="back"
                onClick={handleCloseActionModal}
                disabled={loadingSave}
              >
                Cancelar
              </Button>,
              <Button
                className="primary-btn"
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={loadingSave}
              >
                Confirmar
              </Button>,
            ]
          : []
      }
    >
      <div className="action-modal-atendentes">
        <Form layout="horizontal" hideRequiredMark {...formLayout}>
          {modalType === "afastar" && (
            <Form.Item label="Motivo">
              {getFieldDecorator("motivo", {
                rules: [
                  { required: isRequired, message: "Selecione uma opção." },
                ],
              })(
                <Select
                  loading={loadingMotivos}
                  allowClear
                  disabled={loadingSave}
                >
                  {motivos.map((motivo) => (
                    <Option key={motivo.id} value={motivo.id}>
                      {motivo.nome}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}

          {modalType === "desligar" && (
            <Form.Item label="Data" {...desligaLayout}>
              {getFieldDecorator("dataDesligamento", {
                rules: [
                  {
                    required: isRequired,
                    message: "Campo obrigatório.",
                  },
                  {
                    pattern: DATE_FORMAT,
                    validator: isValidDate,
                    message: "Insira uma data válida.",
                  },
                ],
                getValueFromEvent: (e) => maskDate(e.target.value),
              })(<Input maxLength={10} allowClear disabled={loadingSave} />)}
            </Form.Item>
          )}

          {modalType === "afastar" && (
            <Row>
              <Col span={12}>
                <Form.Item label="De" {...dateLayout}>
                  {getFieldDecorator("dataInicio", {
                    rules: [
                      {
                        required: isRequired,
                        message: "Campo obrigatório.",
                      },
                      {
                        pattern: DATE_FORMAT,
                        validator: isValidDate,
                        message: "Insira uma data válida.",
                      },
                    ],
                    getValueFromEvent: (e) => maskDate(e.target.value),
                  })(
                    <Input maxLength={10} allowClear disabled={loadingSave} />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Até" {...dateAteLayout}>
                  {getFieldDecorator("dataFim", {
                    rules: [
                      {
                        required: isRequired,
                        message: "Campo obrigatório.",
                      },
                      {
                        pattern: DATE_FORMAT,
                        message: "Insira uma data válida.",
                      },
                      {
                        validator: compareDates,
                        message: "Insira uma data válida.",
                      },
                    ],
                    getValueFromEvent: (e) => maskDate(e.target.value),
                  })(
                    <Input maxLength={10} allowClear disabled={loadingSave} />
                  )}
                </Form.Item>
              </Col>
            </Row>
          )}

          <Form.Item label="Substituto">
            {getFieldDecorator("substituto", {
              rules: [
                { required: isRequired, message: "Selecione uma opção." },
              ],
            })(
              <Select
                loading={loadingEnfermeiros}
                allowClear
                disabled={loadingSave}
              >
                {enfermeiros.map((enfermeiro) => (
                  <Option
                    key={enfermeiro.matricula}
                    value={enfermeiro.matricula}
                    disabled={
                      enfermeiro.afastado || matricula === enfermeiro.matricula
                    }
                  >
                    <Tooltip
                      title={`${enfermeiro.nome} (${
                        enfermeiro.afastado
                          ? "afastado"
                          : matricula === enfermeiro.matricula
                          ? "selecionado"
                          : enfermeiro.atendimentos
                      })`}
                    >
                      {enfermeiro.nome} (
                      {enfermeiro.afastado
                        ? "afastado"
                        : matricula === enfermeiro.matricula
                        ? "selecionado"
                        : enfermeiro.atendimentos}
                      )
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>

          {modalType === "afastar" && (
            <>
              <Form.Item style={{ margin: 0, padding: 0 }} {...footerLayout}>
                <div className="afastar-footer">
                  <Button
                    className="secondary-btn"
                    key="back"
                    onClick={handleCloseActionModal}
                    disabled={loadingSave}
                    style={{ minWidth: "110px" }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className="primary-btn"
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                    loading={loadingSave}
                    style={{ minWidth: "110px" }}
                  >
                    Inserir
                  </Button>
                </div>
              </Form.Item>
              <Divider />
            </>
          )}
        </Form>
        {modalType === "afastar" && <AtendentesAfastamentoTabela />}
      </div>
    </BasicModal>
  );
};

const mapStateToProps = (store) => ({
  modalType: store.atendentes.actionModal,
  enfermeiros: store.painelatividades.enfermeiros,
  loadingEnfermeiros: store.painelatividades.loadingModal,
  motivos: store.atendentes.motivos,
  loadingSave: store.atendentes.loadingModalSave,
  loadingMotivos: store.atendentes.loadingMotivos,
  matricula: store.atendentes.matricula,
  filtro: store.atendentes.filtro,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseActionModal: () => dispatch(actions.closeActionModal()),
  buscarEnfermeiros: (body) => dispatch(actionsEnfermeiros.getNurses(body)),
  buscarOpcoesAfastamento: () => dispatch(actions.buscarOpcoesAfastamento()),
  buscarOpcoesDesligamento: () => dispatch(actions.buscarOpcoesDesligamento()),
  registrarAfastamento: (body, filtro, another) =>
    dispatch(actions.registrarAfastamento(body, filtro, another)),
  registrarDesligamento: (body, filtro) =>
    dispatch(actions.registrarDesligamento(body, filtro)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "modal_action_atendentes",
  })(AtendentesActionModal)
);
