import React from "react";
import { connect } from "react-redux";
import { Rosca, Barra, BarraHorizontal, Linha } from "./CustomCharts";

export const Chart = (props) => {
  const {
    tipo,
    titulo,
    dados,
    ultimaAtualizacao,
    total,
    labels,
    unit,
    tempoMedio,
    esperaMaior,
    frase,
  } = props;

  const OnlyData = (props) => {
    return (
      <div className="onlyDataCharts chart-grid">
        <div className={`tempoMedio ${props.tipo}`}>
          {props.tempoMedio}
          <span>Minutos</span>
        </div>

        <div className={`maiorEspera ${props.tipo}`}>
          <span>
            Maior tempo de <br />
            {props.tipo === 'tme' ? 'espera' : 'atendimento'} único
          </span>
          {props.esperaMaior} Minutos
        </div>
      </div>
    );
  };

  const selectChart = () => {
    switch (tipo) {
      case "rosca":
        return <Rosca dados={dados} total={total} />;
      case "barra_horizontal":
        return <BarraHorizontal dados={dados} total={total} />;
      case "barra":
        return <Barra dados={dados} />;
      case "linha":
        return <Linha dados={dados} labels={labels} unit={unit} />;
      case "tme":
      case "tma":
        return <OnlyData tempoMedio={tempoMedio} esperaMaior={esperaMaior} tipo={tipo} />;
      default:
        return <div>nenhum dado</div>;
    }
  };

  const makeTitle = (titulo) => {
    if (frase.includes('últimos 7 dias')) return titulo.replace('no Período', 'nos últimos 7 dias')
    if (frase.includes('hoje')) return titulo.replace('no Período', 'hoje')
    return titulo
  }

  return (
    <>
      <h3>{makeTitle(titulo)}</h3>
      <div style={{ width: "100%", flex: 1 }}>{selectChart()}</div>
      <span>{ultimaAtualizacao}</span>
    </>
  );
};

const mapStateToProps = (store) => ({
  frase: store.dashboard.frase,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
