import React from "react";
import { Icon, Typography } from "antd";
import "./style.scss";

const { Text } = Typography;

const HeaderSection = ({
  style,
  subtitle = true,
  title = "Acompanhamento Profissional",
}) => (
  <>
    <div className="cadastro-object-title" style={style}>
      <Icon style={{ marginRight: "1vw" }} type="team" />
      <Text className="cadastro-section-title">
        {title}
        <span style={{ marginLeft: "8px" }} className="color-gray">
          (opcional)
        </span>
      </Text>
    </div>
    {subtitle && (
      <Text className="cadastro-section-description">
        O idoso é acompanhado por algum profissional da saúde?{" "}
        <span className="color-gray">(opcional)</span>
      </Text>
    )}
  </>
);

export default HeaderSection;
