import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Typography } from "antd";
import _ from "lodash";
import { buscarCuidadores } from "Pages/Urgencia/action";

const { Text } = Typography;

const CuidadoresFollowup = (props) => {
  const { paciente, lista_cuidadores, contato_emergencial } = props;
  useEffect(() => {
    if (paciente) props.buscarCuidadores(paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paciente]);
  return (
    <>
      <Text className={"cadastro-section-title"} style={{ color: "#0F79B2" }}>
        Cuidadores
      </Text>
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          flexDirection: "column",
          lineHeight: "22px",
        }}
      >
        {lista_cuidadores.map((item, i) => (
          <Contato key={i} item={item} />
        ))}
        {lista_cuidadores.length === 0 && (
          <span>Nenhum cuidador foi informado</span>
        )}
      </div>
      <Text className={"cadastro-section-title"} style={{ color: "#0F79B2" }}>
        Contato para emergências
      </Text>
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          flexDirection: "column",
          lineHeight: "22px",
        }}
      >
        {!_.isEmpty(contato_emergencial) ? (
          <Contato item={contato_emergencial} />
        ) : (
          <span>Nenhum Contato Emergêncial foi informado</span>
        )}
      </div>
    </>
  );
};

const Contato = ({ item }) => (
  <div
    style={{
      display: "flex",
      marginBottom: "8px",
      flexDirection: "column",
    }}
  >
    <span>
      {item.nome}{" "}
      {item.principal && (
        <span>
          - <b>principal</b>
        </span>
      )}
    </span>
    <strong>{item.telefone}</strong>
  </div>
);

const mapStateToProps = (store) => ({
  lista_cuidadores: store.urgencia.lista_cuidadores,
  lista_ausencias: store.ausencia.lista_ausencias,
  paciente: store.adesao.paciente,
  contato_emergencial: store.urgencia.contato_emergencial,
});

const mapDispatchToProps = (dispatch) => ({
  buscarCuidadores: (paciente) => dispatch(buscarCuidadores(paciente)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CuidadoresFollowup);

// export default CuidadoresFollowup;
