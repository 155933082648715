import { sendErrorMsg, sendSuccessMsg } from "Misc/utils";
import api from "../../api";

export const getInfo = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarInfoPessoais",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );
  return res.data;
};

export const getFotoPaciente = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarFotoPaciente",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  ); /*.then(response => {
    return response.data;

    const type = response.data.filename.split('.')[1];
    foto_paciente = `data:image/${type};base64,${response.data.file}`;
  });*/
  return res.data;
};

export const attInfoPessoal = async (tarefaID, infoPessoal) => {
  const res = await api.post(
    "/backoffice/atualizarInfoPessoais",
    { ...infoPessoal, tarefa: tarefaID }
  );

  return res.data;
};

export const reenviarEmailBoasVindas = async (paciente, idPaciente) => {
  try {
    const res = await api.post("/backoffice/reenviarBoasVindas", {
      paciente,
      idPaciente,
    });
    sendSuccessMsg("E-mail reenviado com sucesso.");
    return res.data;
  } catch (error) {
    sendErrorMsg(error);
    return;
  }
};
