import * as types from "./actionType";

const initialState = {
  loading: false,
  unsaved: {
    infoPessoal: false,
    doenca: false,
    profissional: false,
    parametroSaude: false,
    questionario: false,
    redeApoio: false,
    responsavel: false,
    ligacoes: false,
  },
  ocorrencias: [],
  observacao: "",
  grupos: [],
  finishLoading: false,
  observacoesHistorico: [],
  graficosHistorico: [],
  relatorioHistorico: [],
  obsLoading: false,
  reportLoading: false,
  grauParentescoRespondente: { id: null, grauParentesco: null },
  funcionarioACS: null,
  activeTab: "1",
  relatedQuestion: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INIT:
      return {
        ...state,
        loading: true,
        grauParentescoRespondente: { id: null, grauParentesco: null },
        funcionarioACS: null,
      };
    case types.FETCH_OBS_INIT:
      return {
        ...state,
        obsLoading: true,
      };
    case types.FETCH_CHART_INIT:
      return {
        ...state,
        obsLoading: true,
      };
    case types.SAVE_FOLLOW_UP_START:
      return {
        ...state,
        finishLoading: true,
      };
    case types.SAVE_FOLLOW_UP_END:
      return {
        ...state,
        finishLoading: false,
      };
    case types.FETCH_COMPLETE:
      return {
        ...state,
        loading: false,
        unsaved: initialState.unsaved,
      };
    case types.RESET_FIELDS:
      return {
        ...state,
        unsaved: initialState.unsaved,
      };
    case types.MODIFY_FIELDS:
      return {
        ...state,
        unsaved: { ...state.unsaved, ...action.payload },
      };
    case types.FETCH_ROTINA_GERAL:
      return {
        ...state,
        ocorrencias: action.payload.ocorrencias,
        observacao: action.payload.observacao,
        grauParentescoRespondente: action.payload.grauParentescoRespondente,
        funcionarioACS: action.payload.acs,
      };
    case types.FETCH_FOLLOW_UP:
      return {
        ...state,
        grupos: action.payload.grupos,
      };
    case types.FETCH_OBS:
      return {
        ...state,
        observacoesHistorico: action.payload,
        obsLoading: false,
      };
    case types.FETCH_CHART:
      return {
        ...state,
        graficosHistorico: action.payload,
        obsLoading: false,
      };
    case types.FETCH_ERROR:
      return {
        ...state,
        obsLoading: false,
      };
    case types.FETCH__ROTINA_GERAL_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.SEND_REPORT_INIT:
      return {
        ...state,
        reportLoading: true,
      };
    case types.SEND_REPORT_FINISH:
      return {
        ...state,
        reportLoading: false,
      };
    case types.FETCH_REPORT_HISTORY_INIT:
      return {
        ...state,
        reportLoading: true,
      };
    case types.FETCH_REPORT_HISTORY_SUCCESS:
      return {
        ...state,
        relatorioHistorico: action.payload,
        reportLoading: false,
      };
    case types.FETCH_REPORT_HISTORY_ERROR:
      return {
        ...state,
        relatorioHistorico: [],
        reportLoading: false,
      };
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      };
    case types.SET_RELATED_QUESTION:
      return {
        ...state,
        relatedQuestion: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
