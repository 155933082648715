/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import "antd/dist/antd.css";
import { Form, Typography, Radio, Input, Tooltip } from "antd";
import * as actions from "../../action";
import { connect } from "react-redux";
import { useEffect } from "react";

const { Text } = Typography;
const { TextArea } = Input;

const PerguntaQuestionarioCadastro = (props) => {
  const {
    pergunta,
    defaultValue,
    fieldDecorator,
    getFieldDecorator,
    id,
    readOnly,
    ivcf,
    textoDeResposta,
  } = props;
  const [showDerivate, setShowDerivate] = useState(defaultValue);
  const questionRef = useRef(null);

  useEffect(() => {
    props.setRefs(questionRef)
  }, []);

  const showDerivateQuestions = () => {
    return props.derivateQuestions.map((question) => {
      return (
        <div key={`${id}-${question.numero}`}>
          {question.ivcf && (
            <Tooltip placement="right" title="A resposta desta questão afeta a pontuação do IVCF-20.">
              <div className="ivcf-badge">ivcf20</div>
            </Tooltip>
          )}
          <Text className="questionsModal">{question.nome}</Text>
          <Form.Item style={{marginTop: 4}}>
            {getFieldDecorator(
              `questions[${`${fieldDecorator}-${question.numero}`}]`,
              {
                rules: [
                  {
                    required: true,
                    message: "Resposta obrigatória!!",
                  },
                ],
                initialValue: question?.respostaTexto || question.resposta,
              }
            )(
              question?.respAberta === true ? (<TextArea rows={2} cols={100} />) :
              (<Radio.Group buttonStyle="solid" size="large" disabled={readOnly}>
                <Radio.Button value={true}>{question?.respTxt?.afirmativa || "Sim"}</Radio.Button>
                <Radio.Button value={false}>{question?.respTxt?.negativa || "Não"}</Radio.Button>
              </Radio.Group>)
            )}
          </Form.Item>
        </div>
      );
    });
  };

  return (
    <div className={`flex-container item-pergunta`} key={id} questionid={id} ref={questionRef}>
      <div className={+id === +props.relatedQuestion ? 'active' : ''}>
        {ivcf && (
           <Tooltip placement="right" title="A resposta desta questão afeta a pontuação do IVCF-20." overlayStyle={{maxWidth: '400px'}}>
           <div className="ivcf-badge">ivcf20</div>
         </Tooltip>
        )}
        <Text className="questionsModal">{pergunta}</Text>
        <Form.Item style={{marginTop: 4}}>
          {getFieldDecorator(`questions[${fieldDecorator}]`, {
            rules: [
              {
                required: true,
                message: "Resposta obrigatória!!",
              },
            ],
            initialValue: defaultValue,
          })(
            <Radio.Group
              buttonStyle="solid"
              size="large"
              onChange={(event) => setShowDerivate(event.target.value)}
              disabled={readOnly}
            >
              <Radio.Button value={true}>{textoDeResposta?.afirmativa || "Sim"}</Radio.Button>
              <Radio.Button value={false}>{textoDeResposta?.negativa || "Não"}</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
      </div>
      {showDerivate && showDerivateQuestions()}
    </div>
  );
};

const mapStateToProps = (store) => ({
  questions: store.questionario.questions,
  relatedQuestion: store.rotina.relatedQuestion,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoQuestionario: (pacienteID) =>
    dispatch(actions.getInfoQuestionario(pacienteID)),
  setRefs: (ref) =>
    dispatch(actions.setRefs(ref)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerguntaQuestionarioCadastro);
