import React from "react";
import { Form } from "antd";
import InstructionsFollowUp from "./InstructionsFollowUp";
import QuestionFollowUp from "./QuestionFollowUp";
import CuidadoresFollowup from "./CuidadoresFollowup";

const GroupFollowUp = ({
  form,
  perguntas = [],
  acompanhamento,
}) => {
return (
  <div style={{ padding: "16px 16px 0 16px" }}>
    <InstructionsFollowUp acompanhamento={acompanhamento} />
    <Form>
      {perguntas.map((pergunta, i) => (
        <QuestionFollowUp
          key={`pergunta_${i}`}
          pergunta={pergunta}
          required={true}
          form={form}
        />
      ))}
    
       <div
       style={{
         marginTop: "8px",
         marginBottom: "16px",
         display: "flex",
         flexDirection: "column",
         lineHeight: "22px",
       }}
     >
       <span>
          <strong>{acompanhamento ? '5' : '4'}.</strong> Se a maioria das respostas indicarem alterações de saúde do paciente, questionar se ele deseja que seu contato de emergência seja acionado, ou se ele deseja que o SAMU seja acionado.
        </span>
      </div>
   
    </Form>
    <CuidadoresFollowup />
  </div>
)};

export default GroupFollowUp;
