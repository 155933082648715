export const FETCHING_TASK = "painelatividades.Buscando_tarefa";
export const POLLING_TASK = "painelatividades.Buscando_tarefa_polling";
export const FETCHING_TASK_COMPLETE = "painelatividades.Buscou_tarefa";
export const FETCHING_TASK_ERROR = "painelatividades.Buscou_tarefa_Error";
export const RESET_TASK = "painelatividades.reset_tasks";

export const EXECUTE_TASK = "painelatividades.Executar_tarefa";
export const EXECUTE_TASK_COMPLETE = "painelatividades.Executou_tarefa";
export const EXECUTE_TASK_ERROR = "painelatividades.Buscou_tarefa_Error";

export const FETCH_INIT = "painelatividades.iniciar_busca";
export const FETCH_ERROR = "painelatividades.erro_busca";
export const NURSES_FETCH_COMPLETE =
  "painelatividades.finalizou_busca_enfermeiros";

export const SEND_INFO = "painelatividades.Delegar_tarefa";
export const SEND_INFO_COMPLETE = "painelatividades.Delegou_tarefa";
export const SEND_INFO_ERROR = "painelatividades.Delegou_tarefa_Error";
