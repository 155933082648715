import React from "react";
import "antd/dist/antd.css";
import { Typography, Input, Button, Icon, Form } from "antd";
import { InputPhone } from "Components/FormInputs";
import { connect } from "react-redux";
import { confirmReenvioEmail } from "Misc/utils";

const { Text } = Typography;

const Cuidador = (props) => {
  const {
    formItems,
    add,
    handleSubmit,
    form: { getFieldDecorator, getFieldValue },
    rotina,
    readOnly,
  } = props;

  return (
    <>
      {!rotina && (
        <>
          <div className="cadastro-object-title">
            <Icon style={{ marginRight: "10px" }} type="heart" />
            <Text className="cadastro-section-title">Cuidadores</Text>
          </div>
          <Text className="cadastro-section-description color-gray">
            <span style={{ fontWeight: "bold" }}>Cuidador principal </span> é a
            pessoa que provê o cuidado (alimentação, higiene, medicação, etc.)
            de forma cotidiana e é a mais próxima do idoso.
          </Text>
          <br />
          <Text
            className="cadastro-section-description color-gray"
            style={{ marginBottom: "17px" }}
          >
            <span style={{ fontWeight: "bold" }}>Cuidadores eventuais</span> são
            pessoas que ocasionalmente cuidam do idoso.
          </Text>
        </>
      )}
      <CuidadorObjeto
        cuidadorTipo={rotina ? "Principal" : "Cuidador principal"}
      />

      <Form style={{ width: "100%" }} hideRequiredMark={true}>
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <Form.Item
            style={{
              marginRight: "8px",
            }}
            label="Nome"
            colon={false}
          >
            {getFieldDecorator(`cuidador[nome]`, {
              rules: [
                {
                  required: Boolean(getFieldValue("cuidador[telefone]")),
                  whitespace: true,
                  message: "Insira um nome.",
                },
              ],
              initialValue: props.cuidador && props.cuidador.nome,
            })(
              <Input
                size={"large"}
                placeholder="Nome"
                style={{ marginRight: 8 }}
                maxLength={60}
                allowClear
                disabled={readOnly}
              />
            )}
          </Form.Item>
          <div
            style={{
              marginRight: "8px",
            }}
          >
            <InputPhone
              isRequired={Boolean(getFieldValue("cuidador[nome]"))}
              getFieldDecorator={getFieldDecorator}
              initialValue={props.cuidador && props.cuidador.telefone}
              decorator={`cuidador[telefone]`}
              label="Telefone"
              colon={false}
              placeholder="Ex.:99 9 9999-9999"
              disabled={readOnly}
            />
          </div>
          <Form.Item key={"principal"} label="Email " colon={false}>
            {getFieldDecorator(`cuidador[email]`, {
              rules: [
                {
                  required: false,
                  whitespace: true,
                  message:
                    "Please input passenger's nome or delete this field.",
                },
                {
                  type: "email",
                  message: "Insira um email válido.",
                },
              ],
              initialValue: props.cuidador && props.cuidador.email,
            })(
              <Input
                size={"large"}
                placeholder="Email"
                allowClear
                disabled={readOnly}
              />
            )}
            <Button
              className="buttonFooterBack"
              onClick={() => confirmReenvioEmail(getFieldValue(`cuidador[email]`), props.paciente)}
              style={{ display: "table", width: "100%", marginTop: ".5rem" }}
              disabled={!getFieldValue(`cuidador[email]`) || props.unsaved.redeApoio}
              
            >
              Reenviar e-mail de Boas Vindas
            </Button>
          </Form.Item>
        </div>
      </Form>
      <CuidadorObjeto
        cuidadorTipo={rotina ? "Eventual" : "Cuidador eventual"}
      />
      <Form
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
        hideRequiredMark={true}
      >
        {formItems}
        {!readOnly && (
          <Form.Item>
            <Button onClick={add} className="add-more-button">
              Adicionar mais um
            </Button>
          </Form.Item>
        )}
      </Form>
    </>
  );
};

const CuidadorObjeto = (props) => {
  const { cuidadorTipo } = props;
  return (
    <>
      <div style={{ marginTop: "1vh", width: "100%" }}>
        <Text
          className="cadastro-section-title"
          style={{ marginTop: "1.5vh", fontSize: "14px" }}
        >
          {cuidadorTipo}
          <Text className="cadastro-section-description color-gray">
            {" "}
            (opcional)
          </Text>
        </Text>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  cuidador: store.redeapoio.cuidador,
  secundarios: store.redeapoio.secundarios,
  unsaved: store.rotina.unsaved
});

export default connect(mapStateToProps, null)(Cuidador);
