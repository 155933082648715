import React, { useState, useEffect } from "react";
import {
  Typography,
  Tag,
  Checkbox,
  Collapse,
  Button,
  Popover,
  Radio,
  Form,
  Select,
  Row
} from "antd";
import { connect } from "react-redux";
import CamposParametros from "./CamposParametros";
import FrequenciaMedida from "./FrequenciaMedida";
import * as actions from "../action";
const { Text } = Typography;
const { Option } = Select;

const { Panel } = Collapse;

const ParametroSaude = (props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const { rotina, plano, wearable, readOnly, modelos, idWearableModelo, outliers } = props;
  const [pulseiraSelected, setPulseiraSelected] = useState(idWearableModelo);

  useEffect(() => {
    props.getValoresOutliers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (props.paciente) props.getAcompanhamento(props.paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);

  const verificaRowsExpandidas = () => {
    if (props.parametrosSaude) {
      return (
        props.parametrosSaude.parametros
          // eslint-disable-next-line array-callback-return
          .filter((el) => {
            if (el.selecionado) return el.ordem;
          })
          .map((el) => el.ordem)
      );
    } else {
      return [];
    }
  };

  useEffect(() => {
    setExpandedRowKeys(verificaRowsExpandidas());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parametrosSaude]);
  const buscaSubitens = (id) => {
    // eslint-disable-next-line array-callback-return
    return props.parametrosSaude.parametros.map((parametro) => {
      if (String(parametro.id) === id) {
        return (
          <>
          <div className="parameter-grid">
            {parametro.subitens.map((subitem) => {
              return (
                <>
                  <CamposParametros
                    nome={subitem.nome}
                    label={subitem.label}
                    valores={subitem.valores || subitem.valoresDesejaveis}
                    form={props.form}
                    unidade={subitem.unidade}
                    idSubitem={subitem.id || subitem.idDesejavel}
                    idParametro={String(parametro.id)}
                    isDesejavel={Boolean(subitem.idDesejavel)}
                    codigoOperador={subitem.codigoOperador}
                    readOnly={readOnly}
                    outliers={outliers}
                  />
                </>
              );
            })}
            </div>
            <FrequenciaMedida
              form={props.form}
              idParametro={String(parametro.id)}
              horarios={parametro.horarios}
              periodicidade={parametro.periodicidade}
              readOnly={readOnly}
            />
          </>
        );
      }
    });
  };

  const handleCreate = () => {
    const params = props.parametrosSaude.parametros.map((el) => {
      return el.selecionado;
    });

    const param_saude = {};
    for (var i = 0; i < params.length; i++) {
      param_saude[(i + 1).toString()] = params[i];
    }
    return (
      <>
        {props.form.getFieldDecorator(`saude_checkbox`, {
          initialValue: param_saude,
        })}
      </>
    );
  };

  const handleChange = (array) => {
    if (array.length < expandedRowKeys.length) return setExpandedRowKeys(array)
    
    var diff = array.filter(x => x.toString() !== 'undefined' && !expandedRowKeys.includes(x));

    const obj = { ...props.form.getFieldValue("saude_checkbox") };

    if (obj[diff[0]]) setExpandedRowKeys(array);

  };


  const customExpandIcon = (props, readOnly) => (
    <Checkbox disabled={readOnly} checked={props.isActive} />
  );

  const customHeader = (item, index) => {
    const obj = { ...props.form.getFieldValue("saude_checkbox") };

    const attParametrosSelecionados = (event) => {
      event.stopPropagation();
      const newIndex = index + 1;
      let array = expandedRowKeys;

      Object.keys(obj).forEach((el) => {
        if (+el === +newIndex) {
          obj[el] = !obj[el];
        }
      });

      props.form.setFieldsValue({
        saude_checkbox: obj,
      });

      if (!array.includes(newIndex.toString()) && event.target.checked) {
        array.push(newIndex.toString());
        setExpandedRowKeys(array);
      } else {
        const index = array.indexOf(newIndex.toString() || newIndex);
        if (index > -1) {
          array.splice(index, 1);
        }
        setExpandedRowKeys(array);
      }
    }

    return (
      <>
        <Row>
          <Checkbox
            onClick={(event) => attParametrosSelecionados(event)}
            checked={obj[index + 1]}
            style={{ marginRight: "5px" }}
            disabled={readOnly}
          />
          {item.nome}{" "}
          {
            modelos.find((modelo) => modelo.id === pulseiraSelected)?.parametros.find((param) => param === item.id) && (

          <i style={{ fontSize: "13px", color: "#434343" }}>
            (Parâmetro coletado automaticamente pela pulseira)
          </i>
            )
          }
        </Row>
      </>
    );
  }

  const checkForForceRender = (ordem) => {
    const obj = { ...props.form.getFieldValue("saude_checkbox") };
    return obj && obj[ordem]
  }

  useEffect(() => {
    setPulseiraSelected(idWearableModelo);
  }, [idWearableModelo]);
  
  useEffect(() => {
    const indexError = props.erroParametro?.parametrosSaude?.indexOf(props.erroParametro?.parametrosSaude?.find(el => el !== undefined))
    const newIndex = indexError === 7 ? 5 : indexError
    const newArray = newIndex && [...expandedRowKeys, newIndex.toString()]
    if (newIndex) setExpandedRowKeys(newArray)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.erroParametro])

  return (
    <>
      {!rotina && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="cadastro-object-title">
            <Tag color="orange">NOVO</Tag>
            <Text className="cadastro-section-title">Parâmetros de Saúde</Text>
          </div>
          <Popover
            content={<PopMorb doencas={props.morbidades} />}
            title="Doenças do idoso"
            trigger="click"
            placement="right"
          >
            <Button 
              style={{
                marginLeft: "8px",
                fontSize: "0.9rem",
                padding: 0,
                paddingLeft: "revert",
                paddingRight: "revert",
              }}
              className="buttonFooterBack"
            >
              Ver doenças
            </Button>
          </Popover>
        </div>
      )}
      <div className="spacing-sections">
        {/* Plano e Wearable*/}
        <div className="wearables-plan-container">
          <p className="plano-texto">
            <strong>{plano?.titulo}</strong>
            <span>{plano?.descricao}</span>
          </p>
          {plano?.wearable && (
            <Form style={{ width: "100%" }} hideRequiredMark>
              <Form.Item
                key="idWearableModelo"
                label={
                  !rotina
                    ? "Selecione a pulseira que será utilizada pelo paciente:"
                    : "Pulseira utilizada pelo paciente:"
                }
              >
                {props.form.getFieldDecorator("idWearableModelo", {
                  rules: [{ required: true, message: "Selecione um modelo!" }],
                  initialValue:
                    modelos.length > 0 && idWearableModelo && idWearableModelo,
                })(
                  <Select
                    style={{ width: "375px" }}
                    disabled={readOnly}
                    name="idWearableModelo"
                    onChange={(e) => {
                      props.form.setFieldsValue({ wearable: null });
                      setPulseiraSelected(e);
                    }}
                    loading={modelos.length < 1}
                  >
                    {modelos.map((modelo) => {
                      return <Option value={modelo.id}>{modelo.nome}</Option>;
                    })}
                  </Select>
                )}
              </Form.Item>

              {pulseiraSelected &&
                props.parametrosSaude &&
                pulseiraSelected !== 1 &&
                modelos.find((e) => e.id === pulseiraSelected)?.parametros && (
                  <div className="parametros-automaticos">
                    <p>Parâmetros monitorados automaticamente:</p>
                    <ul>
                      {modelos
                        .find((e) => e.id === pulseiraSelected)
                        .parametros.map((item) => {
                          const parametro =
                            props.parametrosSaude.parametros.find(
                              (e) => e.id === item
                            );
                          return <li>{parametro.nome}</li>;
                        })}
                    </ul>
                  </div>
                )}

              {pulseiraSelected && pulseiraSelected !== 1 && (
                <Form.Item
                  label={
                    <span>
                      <i>Pulseira</i> ativa?
                    </span>
                  }
                  colon={false}
                >
                  {props.form.getFieldDecorator("wearable", {
                    initialValue: wearable,
                    rules: [
                      { required: true, message: "Selecione uma opção!" },
                    ],
                  })(
                    <Radio.Group
                      buttonStyle="solid"
                      size="large"
                      disabled={readOnly}
                    >
                      <Radio.Button value={true}>Sim</Radio.Button>
                      <Radio.Button value={false}>Não</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
              )}
            </Form>
          )}
        </div>

        {!rotina && (
          <Text className="cadastro-section-description">
            Quais parâmetros de saúde serão acompanhados no plano de cuidados?
          </Text>
        )}
        {props.parametrosSaude?.parametros.map((item, index) => {
          return (
          <>
            <Collapse
              activeKey={expandedRowKeys}
              // expandIcon={(props) => customExpandIcon(props, readOnly)}
              // destroyInactivePanel={checkForDestroy(item.id)}
              destroyInactivePanel={props.form.getFieldValue("saude_checkbox") && !props.form.getFieldValue("saude_checkbox")[item.id]}
              expandIconPosition="right"
              onChange={handleChange}
            >
                <Panel
                    // header={item.nome}
                    header={customHeader(item, index)}
                    key={item.ordem}
                    className={item.id > 4 && item.id !== 7 ? "not-showed" : ""}
                    showArrow={item.id > 4 && item.id !== 7 ? false : true}
                    disabled={readOnly}
                    forceRender={checkForForceRender(item.ordem)}
                    // forceRender={true}
                  >
                    {item.id < 5 || item.id === 7
                      ? buscaSubitens(String(item.id))
                      : handleCreate(item.id)}
                  </Panel>
              {/* {props.parametrosSaude.parametros.map((item, index) => {
                return (
                
                );
              }) */}
            </Collapse>
          </>
        )})}
      </div>
    </>
  );
};

const PopMorb = ({ doencas }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {doencas.map((d) => (
      <Text>{d}</Text>
    ))}
  </div>
);

const mapStateToProps = (store) => ({
  parametrosSaude: store.acompanhamento.parametrosSaude,
  morbidades: store.acompanhamento.morbidades,
  plano: store.acompanhamento.plano,
  wearable: store.acompanhamento.wearable,
  idWearableModelo: store.acompanhamento.idWearableModelo,
  modelos: store.acompanhamento.modelos,
  paciente: store.adesao.paciente,
  outliers: store.acompanhamento.outliers
});

const mapDispatchToProps = (dispatch) => ({
  getAcompanhamento: (pacienteID) =>
    dispatch(actions.getAcompanhamento(pacienteID)),
  getValoresOutliers: () => dispatch(actions.getValoresOutliers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametroSaude);
