import * as types from "./actionType";

//TODO: resetar para undefined
const initialState = {
  loading: false,
  user: undefined,
  error: undefined
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOG_IN_INIT:
      return {
        ...state,
        loading: true
      };
    case types.LOG_IN:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case types.LOG_OUT:
      return {
        ...state,
        user: false
      };
    case types.FAIL_LOGIN:
      return {
        ...state,
        user: false,
        loading: false,
        error: action.payload
      };
    case types.LOG_IN_END:
      return {
        ...state,
        loading: false,
        error: undefined
      };
    default:
      return state;
  }
};

export default reducer;
