import React from "react";
import { Typography, Input, Form } from "antd";
import { NUMBER_WITH_COMMA } from "Misc/constants";
import "./style.scss";

const { Text } = Typography;

const CamposParametros = (props) => {
  const {
    isDesejavel,
    nome,
    label,
    valores,
    unidade,
    readOnly,
    codigoOperador,
    outliers,
  } = props;
  const { getFieldDecorator, getFieldValue } = props.form;

  const id = isDesejavel ? "idDesejavel" : "id";
  const value = isDesejavel ? "valoresDesejaveis" : "valores";
  const isBetween = codigoOperador === "between";

  const validateOutliers = (value, unidade) => {
    const findParam = outliers.find(
      (parametro) => +parametro.idParametroSaude === +props.idParametro
    );
    if (value) {
      const newValue = value.replace(',', '.')
      if (+newValue > +findParam.valorMaximo || +newValue < +findParam.valorMinimo) {
        return Promise.reject(
          new Error(`Valor mín. possível de ${findParam.valorMinimo} ${unidade} e o máx. de ${findParam.valorMaximo} ${unidade}`)
        );
      }
    }
  };

  return (
    <div
      className="spacing-sections"
      style={isBetween ? { gridColumn: "1 / -1" } : {}}
    >
      <Text style={{ fontWeight: "bold" }}>{nome || null}</Text>
      {!isBetween ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "flex-start",
          }}
        >
          <Text style={{ lineHeight: "42px" }}>
            <i>{label}</i>
          </Text>
          <Form.Item key="input-parametro-frequencia">
            {getFieldDecorator(
              `parametrosSaude[${props.idParametro}[subitem[${id}:${props.idSubitem}][${value}[0]]]`,
              {
                rules: [
                  {
                    required: !isDesejavel,
                    message: "Insira um valor válido!",
                    pattern: NUMBER_WITH_COMMA,
                  },
                  {
                    validator: async (rules, value) => {
                      if (isDesejavel && +props.idSubitem === 21) {
                        const alarmValue = getFieldValue(
                          `parametrosSaude[${props.idParametro}[subitem[${id}:22][valoresDesejaveis[0]]]`
                        );
                        if (alarmValue) {
                          if (
                            +value.replace(",", ".") >=
                            +alarmValue.replace(",", ".")
                          ) {
                            return Promise.reject(
                              new Error(
                                `Insira um valor menor que ${alarmValue}`
                              )
                            );
                          }
                        }
                      }
                      return validateOutliers(value, unidade);
                    },
                  },
                ],
                initialValue: valores[0],
                normalize: (value) => {
                  return value && value.includes(".")
                    ? value.replace(".", ",")
                    : value;
                },
              }
            )(
              <Input
                className="input-health-params"
                addonAfter={unidade}
                disabled={readOnly}
              />
            )}
          </Form.Item>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "flex-start",
          }}
        >
          <Text style={{ lineHeight: "42px" }}>{label}</Text>
          <Form.Item key="input-parametro-frequencia">
            {getFieldDecorator(
              `parametrosSaude[${props.idParametro}[subitem[${id}:${props.idSubitem}][${value}[0]]]`,
              {
                rules: [
                  {
                    required: !isDesejavel,
                    message: "Insira um valor válido!",
                    pattern: NUMBER_WITH_COMMA,
                  },
                  {
                    validator: async (rules, value) => {
                      return validateOutliers(value, unidade);
                    },
                  },
                ],
                initialValue: valores[0],
                normalize: (value) => {
                  return value && value.includes(".")
                    ? value.replace(".", ",")
                    : value;
                },
              }
            )(
              <Input
                className="input-health-params"
                addonAfter={unidade}
                disabled={readOnly}
              />
            )}
          </Form.Item>
          <Text style={{ lineHeight: "42px" }}> e </Text>
          <Form.Item key="input-parametro-frequencia">
            {getFieldDecorator(
              `parametrosSaude[${props.idParametro}[subitem[${id}:${props.idSubitem}][${value}[1]]]`,
              {
                rules: [
                  {
                    pattern: NUMBER_WITH_COMMA,
                    validator: async (rule, value) => {
                      if (!isDesejavel === false && !value) return;
                      if (!value.match(NUMBER_WITH_COMMA))
                        return Promise.reject(
                          new Error(`Insira um valor válido!`)
                        );
                      const minDesireableValue =
                        getFieldValue(`parametrosSaude`)[props.idParametro]
                          .subitem[`${id}:${props.idSubitem}`][
                          isDesejavel ? "valoresDesejaveis" : "valores"
                        ][0];
                      if (+value <= +minDesireableValue)
                        return Promise.reject(
                          new Error(
                            `Insira um valor maior que ${minDesireableValue}`
                          )
                        );
                        return validateOutliers(value, unidade);
                    },
                  },
                ],
                initialValue: valores[1],
                normalize: (value) => {
                  return value && value.includes(".")
                    ? value.replace(".", ",")
                    : value;
                },
              }
            )(
              <Input
                className="input-health-params"
                addonAfter={unidade}
                disabled={readOnly}
              />
            )}
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default CamposParametros;
