import * as types from "./actionType";
import * as typeRotina from "Pages/Rotina/actionType";
import * as services from "./services";
import { sendErrorMsg, sendMsg } from "Misc/utils";
import { getNurses } from "../../Pages/PainelAtividades/service";

export const getAcompanhamento = (pacienteID) => async (dispatch) => {
  try {
    const responseAcompanhamento = await services.getAcompanhamento(pacienteID);
    await dispatch({
      type: types.FETCHING_ACOMPANHAMENTO_COMPLETE,
      payload: responseAcompanhamento,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_ACOMPANHAMENTO_ERROR });
  }
};

export const getMedicamentoListaPaciente = (pacienteID) => async (dispatch) => {
  try {
    await dispatch({ type: types.FETCHING_LISTA_MEDICAMENTO });

    const responseDosagens = await services.getDosagemMedicamento();
    await dispatch({
      type: types.FETCHING_DOSAGEM_COMPLETE,
      payload: responseDosagens,
    });

    const responseFreq = await services.getFrequenciaMedicamento();
    await dispatch({
      type: types.FETCHING_FREQUENCIA_COMPLETE,
      payload: responseFreq,
    });

    const responseRecomendacao = await services.getRecomendacoesMedicamento();
    await dispatch({
      type: types.FETCHING_RECOMENDACAO_COMPLETE,
      payload: responseRecomendacao,
    });

    const responseEnfermeiros = await getNurses({ paciente: pacienteID });
    await dispatch({
      type: types.FETCHING_ENFERMEIROS_COMPLETE,
      payload: responseEnfermeiros,
    });
    sendMsg(responseEnfermeiros);

    const responseListaMed = await services.getMedicamentoListaPaciente(
      pacienteID
    );
    await dispatch({
      type: types.FETCHING_LISTA_MEDICAMENTO_COMPLETE,
      payload: responseListaMed,
    });

    // const responseAcompanhamento = await services.getAcompanhamento(pacienteID);
    // await dispatch({
    //   type: types.FETCHING_ACOMPANHAMENTO_COMPLETE,
    //   payload: responseAcompanhamento,
    // });

    const responseModelos = await services.getModelos();
    await dispatch({
      type: types.FETCHING_MODELOS_COMPLETE,
      payload: responseModelos.buscarModelosPulseriaResponses,
    })

    const resMorb = await services.getMorbidadesPaciente(pacienteID);

    const morbidades = resMorb.morbidades
      .filter((m) => !!m.resposta)
      .map((m) => m.nome);

    await dispatch({
      type: types.MORBIDADES,
      payload: morbidades,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_LISTA_MEDICAMENTO_ERROR });
  }
};

export const resetMedicamentoDetalhe = () => async (dispatch) => {
  dispatch({ type: types.FETCHING_MEDICAMENTO_DETALHES });
};

export const getMedicamentoDetalhesPaciente =
  (pacienteID, medicamentoID) => async (dispatch) => {
    try {
      dispatch({ type: types.FETCHING_MEDICAMENTO_DETALHES });

      const response = await services.getMedicamentoDetalhesPaciente(
        pacienteID,
        medicamentoID
      );
      await dispatch({
        type: types.FETCHING_MEDICAMENTO_DETALHES_COMPLETE,
        payload: response,
      });
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.FETCHING_MEDICAMENTO_DETALHES_ERROR });
    }
  };

export const getMedicamento = (nome) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCHING_MEDICAMENTO });
    const response = await services.getMedicamentos(nome);
    await dispatch({
      type: types.FETCHING_MEDICAMENTO_COMPLETE,
      payload: response,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_MEDICAMENTO_ERROR });
  }
};

export const changeMedicamento = (medicamento) => async (dispatch) => {
  await dispatch({
    type: types.CHANGE_MEDICAMENTO_COMPLETE,
    payload: [medicamento],
  });
};

export const adicionaMedicamento = (pacienteID, info) => async (dispatch) => {
  try {
    dispatch({ type: types.SEND_MEDICAMENTO });
    const response = await services.adicionaMedicamento(
      pacienteID,
      info.medicamento,
      info.apelido,
      info.quantidade,
      info.unidade,
      info.horarios,
      info.frequencia,
      info.xDias,
      info.diaSemana,
      info.recomendacao,
      info.personalizacao,
      info.inicio,
      info.fim,
      info.idMedicamentoAtual
    );
    await dispatch({
      type: types.SEND_MEDICAMENTO_COMPLETE,
    });

    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.SEND_MEDICAMENTO_ERROR });
  }
};

export const excluiMedicamento =
  (pacienteID, medicamentoId) => async (dispatch) => {
    try {
      dispatch({ type: types.DELETE_MEDICAMENTO });
      const response = await services.excluirMedicamentoPaciente(
        pacienteID,
        medicamentoId
      );
      await dispatch({
        type: types.DELETE_MEDICAMENTO_COMPLETE,
      });

      return response;
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.DELETE_MEDICAMENTO_ERROR });
    }
  };

export const atualizarAcompanhamento = (
  pacienteID,
  parametroSaude,
  ligacaoPeriodica,
  wearable,
  idWearableModelo,
  outrasInformacoes
) => async (dispatch) => {
  try {
    dispatch({ type: types.SEND_ACOMPANHAMENTO });
    const response = await services.atualizarAcompanhamento(
      pacienteID,
      parametroSaude,
      ligacaoPeriodica,
      wearable,
      idWearableModelo,
      outrasInformacoes
    );
    await dispatch({
      type: types.SEND_ACOMPANHAMENTO_COMPLETE,
    });

      return response;
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.SEND_ACOMPANHAMENTO_ERROR });
    }
  };

export const atualizarParametrosSaude = (body) => async (dispatch) => {
  try {
    await services.atualizarParametrosSaude(body);
    await dispatch({
      type: typeRotina.MODIFY_FIELDS,
      payload: { parametroSaude: false },
    });
  } catch (error) {
    // dispatch({ type: types.SEND_ACOMPANHAMENTO_ERROR });
    sendErrorMsg(error);
  }
};

export const atualizarLigacaoPeriodica = (body) => async (dispatch) => {
  try {
    await services.atualizarLigacaoPeriodica(body);
    await dispatch({
      type: typeRotina.MODIFY_FIELDS,
      payload: { ligacoes: false },
    });
  } catch (error) {
    sendErrorMsg(error);
    // dispatch({ type: types.SEND_ACOMPANHAMENTO_ERROR });
  }
};

export const getTarefaNFListaPaciente = (pacienteID) => async (dispatch) => {
  try {
    await dispatch({ type: types.FETCHING_LISTA_TAREFA_NF });

    const responseTNF = await services.getTarefasNF();
    await dispatch({
      type: types.FETCHING_TAREFA_NF_COMPLETE,
      payload: responseTNF,
    });

    const responseFreq = await services.getFrequenciaTarefaNF();
    await dispatch({
      type: types.FETCHING_FREQUENCIA_COMPLETE,
      payload: responseFreq,
    });

    const responseListaTNF = await services.getTarefaNFListaPaciente(
      pacienteID
    );

    await dispatch({
      type: types.FETCHING_LISTA_TAREFA_NF_COMPLETE,
      payload: responseListaTNF,
    });

    // const responseAcompanhamento = await services.getAcompanhamento(pacienteID);
    // await dispatch({
    //   type: types.FETCHING_ACOMPANHAMENTO_COMPLETE,
    //   payload: responseAcompanhamento,
    // });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_LISTA_TAREFA_NF_ERROR });
  }
};

export const resetTarefaNFDetalhe = () => async (dispatch) => {
  dispatch({ type: types.FETCHING_TAREFA_NF_DETALHES });
};

export const getTarefaNFDetalhesPaciente =
  (pacienteID, tarefaID) => async (dispatch) => {
    try {
      dispatch({ type: types.FETCHING_TAREFA_NF_DETALHES });

      const response = await services.getTarefaNFDetalhesPaciente(
        pacienteID,
        tarefaID
      );

      await dispatch({
        type: types.FETCHING_TAREFA_NF_DETALHES_COMPLETE,
        payload: response,
      });
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.FETCHING_TAREFA_NF_DETALHES_ERROR });
    }
  };

export const getTarefasNF = () => async (dispatch) => {
  try {
    dispatch({ type: types.FETCHING_TAREFA_NF });
    const response = await services.getTarefasNF();
    await dispatch({
      type: types.FETCHING_TAREFA_NF_COMPLETE,
      payload: response,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_TAREFA_NF_ERROR });
  }
};

export const changeTarefaNF = (tarefa) => async (dispatch) => {
  await dispatch({
    type: types.CHANGE_TAREFA_NF_COMPLETE,
    payload: [tarefa],
  });
};

export const adicionaTarefaNF = (pacienteID, info) => async (dispatch) => {
  try {
    dispatch({ type: types.SEND_TAREFA_NF });
    const response = await services.adicionaTarefaNF(
      pacienteID,
      info.tipoTarefaID,
      info.idPlanoTarefa,
      info.horarios,
      info.frequencia,
      info.xDias,
      info.diaSemana,
      info.outrosTipoTarefa,
      info.inicio,
      info.fim,
      info.recomendacao
    );
    await dispatch({
      type: types.SEND_TAREFA_NF_COMPLETE,
    });

    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.SEND_TAREFA_NF_ERROR });
  }
};

export const excluiTarefaNF = (pacienteID, tarefaId) => async (dispatch) => {
  try {
    dispatch({ type: types.DELETE_TAREFA_NF });
    const response = await services.excluirTarefaNFPaciente(
      pacienteID,
      tarefaId
    );
    await dispatch({
      type: types.DELETE_TAREFA_NF_COMPLETE,
    });

    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.DELETE_TAREFA_NF_ERROR });
  }
};


export const getValoresOutliers = () => async (dispatch) => {
  try {
    dispatch({ type: types.FETCHING_VALORES_OUTLIERS });
    const response = await services.getValoresOutliers();
    await dispatch({
      type: types.FETCHING_VALORES_OUTLIERS_COMPLETE,
      payload: response,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_VALORES_OUTLIERS_ERROR });
  }
};
