import * as types from "./actionType";

const initialState = {
  tituloPrincipal: "",
  criticidade: null,
  tipoTarefa: null,
  foto_perfil: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_HEADER_COMPLETE:
      return {
        ...state,
        tituloPrincipal: action.payload.nome,
        criticidade: action.payload.criticidade ?? null,
        tipoTarefa: action.payload.tipoTarefa ?? null,
        foto_perfil: action.payload.foto_perfil,
      };

    case types.EDITING_HEADER:
      return {
        ...state,
        tituloPrincipal: null,
        criticidade: null,
        tipoTarefa: null,
        foto_perfil: null,
      };
    case types.FETCHING_PHOTO:
      return {
        ...state,
        foto_perfil: null,
      };

    case types.FETCHING_PHOTO_COMPLETE:
      return {
        ...state,
        foto_perfil: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
