import * as types from "./actionType";
import * as services from "./service";
import {
  sendErrorMsg,
  sendSuccessMsg,
  createPhrase,
  getNomeAtendenteFiltrado,
  getPeriodo,
} from "Misc/utils";
import _ from "lodash";
import { buscarAtendentes } from "Pages/Atendentes/service";
import store from "store";

export const buscarDashboard = (values) => async (dispatch) => {
  try {
    const body = { filtro: values };
    dispatch({ type: types.BUSCAR_DASHBOARD_INIT });
    const response = await services.buscarDashboard(body);
    const periodo = getPeriodo(response.filtro);

    await dispatch({
      type: types.BUSCAR_DASHBOARD_SUCCESS,
      dash: response.dash,
      filtro: response.filtro,
      periodo: periodo,
    });

    // TODO: PEDIR FRASE PARA O RODRIGO

    const frase = createPhrase(response.filtro);

    await dispatch({ type: types.MUDAR_FRASE, frase: frase });

    await dispatch(fecharModal());
  } catch (error) {
    await dispatch({
      type: types.BUSCAR_DASHBOARD_FAIL,
    });
    sendErrorMsg(error);
  }
};
export const buscarTMEeTMA = (values) => async (dispatch) => {
  try {
    const body = { filtro: values };
    dispatch({ type: types.BUSCAR_DASHBOARD_INIT });
    const response = await services.buscarTMEeTMA(body);
    const periodo = getPeriodo(response.filtro);

    await dispatch({
      type: types.BUSCAR_DASHBOARD_SUCCESS,
      dash: response.dash,
      filtro: response.filtro,
      periodo: periodo,
    });

    // TODO: PEDIR FRASE PARA O RODRIGO

    const frase = createPhrase({
      ...response.filtro,
      ...(!response.filtro.dataInicio && {dataInicio: new Date(new Date().setDate(new Date().getDate() - 6)).toLocaleDateString('pt-BR')}),
      ...(!response.filtro.dataFim && {dataFim: new Date().toLocaleDateString('pt-BR')}),
    });

    await dispatch({ type: types.MUDAR_FRASE, frase: frase });

    await dispatch(fecharModal());
  } catch (error) {
    await dispatch({
      type: types.BUSCAR_DASHBOARD_FAIL,
    });
    sendErrorMsg(error);
  }
};

export const buscarDetalhamentoDashboard = (values) => async (dispatch) => {
  try {
    const selectedUbs = store.getState().dashboard.selectedUbs;
    const body = { filtro: values };
    dispatch({ type: types.BUSCAR_DASHBOARD_DETAILS_INIT });
    const response = await services.buscarDetalhamentoDashboard(body);
    buscarOpcoesTransferenciaTarefas(response.filtro, selectedUbs);
    buscarTotalOpcoesPacientesAtendentes(response.filtro, selectedUbs);

    let tarefasPendentes = [];
    let tarefasAndamento = [];
    let tarefasConcluidas = [];

    if (response) {
      response.status.forEach((tarefa) => {
        switch (tarefa.id) {
          case 1:
            tarefasPendentes = tarefa.tarefas;
            break;

          case 2:
            tarefasAndamento = tarefa.tarefas;
            break;

          case 3:
            tarefasConcluidas = tarefa.tarefas;
            break;

          default:
            break;
        }
      });
    }

    await dispatch({
      type: types.BUSCAR_DASHBOARD_DETAILS_SUCCESS,
      dashCompleteDetail: response,
      tarefasPendentes: tarefasPendentes,
      tarefasAndamento: tarefasAndamento,
      tarefasConcluidas: tarefasConcluidas,
      filtro: response.filtro,
    });

    const frase = createPhrase(response.filtro);

    await dispatch({ type: types.MUDAR_FRASE, frase: frase });
  } catch (error) {
    await dispatch({
      type: types.BUSCAR_DASHBOARD_DETAILS_FAIL,
    });
    sendErrorMsg(error);
  }
};

export const abrirModal = () => (dispatch) => {
  dispatch({ type: types.OPEN_FILTER_MODAL });
  dispatch(buscarOpcoes());
};

export const fecharModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_FILTER_MODAL });
};

export const salvarFiltro = (filtro) => (dispatch) => {
  dispatch({ type: types.SALVAR_FILTRO, payload: filtro });
};

export const resetFiltro = () => (dispatch) => {
  dispatch({ type: types.RESET_FILTRO });
};

export const changeBackingValue = (isBacking) => (dispatch) => {
  console.log('value isBacking', isBacking)
  dispatch({ type: types.VOLTAR_DETALHAMENTO, payload: isBacking })
}

export const abrirModalTransferPatients = () => (dispatch) => {
  dispatch({ type: types.OPEN_TRANSFER_PATIENTS_MODAL });
};

export const fecharModalTransferPatients = () => (dispatch) => {
  dispatch({ type: types.CLOSE_TRANSFER_PATIENTS_MODAL });
};

export const abrirModalTransferTasks = () => (dispatch) => {
  dispatch({ type: types.OPEN_TRANSFER_TASKS_MODAL });
};

export const fecharModalTransferTasks = () => (dispatch) => {
  dispatch({ type: types.CLOSE_TRANSFER_TASKS_MODAL });
};

export const buscarOpcoes = () => async (dispatch) => {
  try {
    dispatch({
      type: types.BUSCAR_OPCOES_INIT,
    });

    const assuntos = await services.buscarOpcoesAssunto();
    const status = await services.buscarOpcoesStatus();
    const prioridades = await services.buscarOpcoesPrioridade();
    const ubs = await services.buscarOpcoesUbs();
    const morbidades = await services.buscarOpcoesMorbidades();
    const bairros = await services.buscarOpcoesBairros();

    dispatch({
      type: types.BUSCAR_OPCOES_SUCCESS,
      assuntos: assuntos.opcoes,
      status: status.opcoes,
      prioridades: prioridades.opcoes,
      ubsOption: ubs.opcoesUbs,
      morbidades: morbidades.morbidades,
      bairros: bairros.bairros,
    });
  } catch (error) {
    dispatch({
      type: types.BUSCAR_OPCOES_FAIL,
    });
    sendErrorMsg(error);
  }
};

export const buscarOpcoesTransferenciaTarefas =
  (filtro, selectedUbs) => async (dispatch) => {
    try {
      dispatch({
        type: types.BUSCAR_OPCOES_TRANSFER_TAREFA_INIT,
      });

      const matricula = store.getState().dashboard.atendentes[0]?.matricula
      
      const atendentesTarefas = await services.buscarTarefasAtendentes({
        ...(selectedUbs ? { ubs: selectedUbs } : { matricula }),
      });

      dispatch({
        type: types.BUSCAR_OPCOES_TRANSFER_TAREFA_SUCCESS,
        tasksAtendenteFiltrado: _.filter(atendentesTarefas.tarefas, {
          id: filtro.atendente,
        })[0].tarefas,
        todosAtendentes: atendentesTarefas.tarefas,
      });
    } catch (error) {
      dispatch({
        type: types.BUSCAR_OPCOES_TRANSFER_TAREFA_FAIL,
      });
      sendErrorMsg(error);
    }
  };

export const transferirTarefas = (info) => async (dispatch) => {
  try {
    dispatch({ type: types.TRANSFER_TAREFA_INIT });

    await services.transferirTarefas(info);
    sendSuccessMsg("Transferência realizada com sucesso.");

    dispatch({
      type: types.TRANSFER_TAREFA_SUCCESS,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.TRANSFER_TAREFA_FAIL });
  }
};

export const transferirPacientes = (info) => async (dispatch) => {
  try {
    dispatch({ type: types.TRANSFER_PATIENT_INIT });

    await services.transferirPacientes(info);
    sendSuccessMsg("Transferência realizada com sucesso.");

    dispatch({
      type: types.TRANSFER_PATIENT_SUCCESS,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.TRANSFER_PATIENT_FAIL });
  }
};

export const buscarTotalOpcoesPacientesAtendentes =
  (filtro, selectedUbs) => async (dispatch) => {
    try {
      dispatch({ type: types.BUSCAR_OPCOES_TRANSFER_PATIENT_INIT });

      const matricula = store.getState().dashboard.atendentes[0]?.matricula

      const atendTotalPacientes = await services.buscarTotalPacientesAtendentes(
        {
        ...(selectedUbs ? { ubs: selectedUbs } : { matricula }),
        }
      );
      const pacientesAtendente = await services.buscarPacientesAtendente({
        token: "gecro",
        idEnfermeiro: filtro.atendente,
      });
      const nomeAtend = getNomeAtendenteFiltrado(filtro);
      var myMap = new Map();

      atendTotalPacientes.tarefas.forEach((atendente) => {
        if (atendente.id !== filtro.atendente) {
          var key = atendente.id;
          var array = [];
          pacientesAtendente.pacientes.forEach((paciente, i) => {
            array.push({ id: paciente.id, checked: false });
            myMap.set(key, array);
          });
        }
      });

      dispatch({
        type: types.BUSCAR_OPCOES_TRANSFER_PATIENT_SUCCESS,
        atendTotalPacientes: atendTotalPacientes.tarefas,
        pacientesAtendente: pacientesAtendente.pacientes,
        nomeAtend: nomeAtend,
        map: myMap,
      });
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.BUSCAR_OPCOES_TRANSFER_PATIENT_FAIL });
    }
  };

export const getEnfermeiros = (nome) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_ENFERMEIROS_INIT });

    const atends = await buscarAtendentes({ nome });

    dispatch({
      type: types.BUSCAR_ENFERMEIROS_SUCCESS,
      atendentes: atends.atendentes,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.BUSCAR_ENFERMEIROS_FAIL });
  }
};
export const getBairros = (body) => async (dispatch) => {
  try {
    // dispatch({ type: types.BUSCAR_ENFERMEIROS_INIT });
    dispatch({ type: types.BUSCAR_BAIRROS_INIT });

    // const atends = await getNurses(body);
    const neighborhoods = await services.buscarOpcoesBairros(body);

    dispatch({
      type: types.BUSCAR_BAIRROS_SUCCESS,
      bairros: neighborhoods,
      selectedUbs: body.ubs,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.BUSCAR_BAIRROS_FAIL });
  }
};

export const getPacientePorParteNome = (nome) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_PACIENTES_INIT });

    if (!nome) {
      dispatch({
        type: types.BUSCAR_PACIENTES_SUCCESS,
        pacientes: [],
      });
    } else {
      const pacientes = await services.buscarPacientePorParteNome({ nome });
      dispatch({
        type: types.BUSCAR_PACIENTES_SUCCESS,
        pacientes: pacientes.pacientes,
      });
    }

  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.BUSCAR_PACIENTES_FAIL });
  }
};
