import * as types from "./actionType";
const initialState = {
  idosoFezCadastro: "",
  grauParentesco: "",
  nome: "",
  email: "",
  ncto: "",
  sexo: "",
  peso: "",
  altura: "",
  sozinho: "",
  telefone: "",
  telefoneOpcional: "",
  tech: "",
  cpf: "",
  file: "",
  filename:"",
  endereco: {
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    uf: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_INFO_COMPLETE:
      return {
        ...state,

        idosoFezCadastro: action.payload.idosoFezCadastro,
        grauParentesco: action.payload.grauParentesco,
        nome: action.payload.nome,
        email: action.payload.email,
        ncto: action.payload.ncto,
        sexo: action.payload.sexo,
        peso: action.payload.peso,
        altura: action.payload.altura,
        sozinho: action.payload.sozinho,
        telefone: action.payload.telefone,
        telefoneOpcional: action.payload.telefoneOpcional,
        tech: action.payload.tech,
        cpf: action.payload.cpf,
        endereco: action.payload.endereco
      };
    case types.FETCHING_INFO:
      return {
        ...state,
        idosoFezCadastro: "",
        grauParentesco: "",
        nome: "",
        email: "",
        ncto: "",
        sexo: "",
        peso: "",
        altura: "",
        sozinho: "",
        telefone: "",
        telefoneOpcional: "",
        tech: "",
        cpf: "",
        endereco: {
          cep: "",
          logradouro: "",
          numero: "",
          complemento: "",
          bairro: "",
          cidade: "",
          uf: ""
        }
      };

    case types.EDITED_INFO:
      return {
        ...state,
        ...action.payload
      };

    case types.UPDATED_INFO:
      return {
        ...state,
        ...action.payload
      };

      case types.FETCHING_PHOTO:
      return {
        ...state,
        foto_perfil: {
          foto_paciente: null,
          tipo_foto: null
        }
      };

      case types.FETCHING_PHOTO_COMPLETE:
      return {
        ...state,
        foto_perfil: {
          foto_paciente: action.payload.file,
          tipo_foto: action.payload.filename.split('.')[1]
        }
      };

    default:
      return state;
  }
};

export default reducer;
