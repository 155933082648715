import React from "react";
import { Row, Button, Input, Form, Typography } from "antd";
import Acompanhamento from "Pages/Pacientes/Components/AcompanhamentoGenerico";
import { connect } from "react-redux";
import { showConfirmationTab } from "Misc/utils";
import { SCROLL_CONFIG } from "Misc/constants";
import { salvarObservacoes } from "Pages/Urgencia/action";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";

const { TextArea } = Input;
const { Text } = Typography;

const Instructions = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    unsaved,
    paciente,
    finishLoading,
    lista_cuidadores,
    lista_ausencias,
    salvarObservacoes,
    contato_emergencial,
  } = props;

  const { tarefa, tipo } = useParams();
  const history = useHistory();

  const handleValidate = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const { observacao } = values;

        const req = {
          token: "gecro",
          tarefa: tarefa,
          observacao: observacao,
        };

        showConfirm(req);
      } else {
        console.error("On ERROR", err);
      }
    });
  };

  const showConfirm = (answers) => {
    const auxArray = [];
    Object.keys(unsaved).forEach((item) => {
      if (unsaved[item]) {
        auxArray.push(item);
      }
    });

    if (auxArray.length > 0) {
      return showConfirmationTab(auxArray, () => handleSaveFollowUp(answers));
    } else {
      handleSaveFollowUp(answers);
    }
  };

  const handleSaveFollowUp = async (answers) => {
    const res = await salvarObservacoes(
      JSON.stringify(answers),
      paciente,
      tarefa
    );
    if (res) history.push("/painel");
  };

  return (
    <>
      <Row className="followup-body">
        {tipo !== "7" ? (
          <>
            <div style={{ padding: "16px 16px 0 16px" }}>
              <Text
                className={"cadastro-section-title"}
                style={{ color: "#0F79B2" }}
              >
                Instruções
              </Text>
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "22px",
                }}
              >
                {tipo === "4" && (
                  <>
                    <span>
                      <strong>1.</strong>{" "}
                      <strong style={{ color: "#DD0000" }}>
                        Ligar para o paciente.
                      </strong>
                    </span>
                    <span>
                      <strong>2.</strong> Caso o paciente não atenda, ligar para
                      cuidador ou contato para emergências, na ordem abaixo.
                    </span>
                    <span>
                      <strong>3.</strong> Em último caso, ligar para o{" "}
                      <strong>SAMU (192)</strong>.
                    </span>
                  </>
                )}
                {tipo === "5" && (
                  <>
                    <span>
                      <strong>1.</strong>Verificar as ausências de reporte:
                    </span>

                    <div
                      style={{
                        border: " 1px solid rgba(0, 0, 0, 0.15)",
                        boxSizing: "border-box",
                        borderRadius: "4px",
                        margin: "8px",
                      }}
                    >
                      {lista_ausencias.map((item, i) => (
                        <React.Fragment key={i}>
                          {i > 0 && (
                            <hr style={{ color: "rgba(0, 0, 0, 0.09)" }} />
                          )}
                          <div
                            style={{
                              margin: "16px",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span style={{ flex: 1 }}>{item.texto}</span>
                            <span>{item.data}</span>
                          </div>
                        </React.Fragment>
                      ))}
                      {lista_ausencias.length === 0 && (
                        <span style={{ margin: "16px" }}>
                          {" "}
                          Nenhuma ausência recente.
                        </span>
                      )}
                    </div>

                    <span>
                      <strong>2. Ligar para o paciente.</strong>
                    </span>
                    <span>
                      <strong>3.</strong> Caso o paciente não atenda, ligar para
                      cuidador, seguindo a ordem de prioridade abaixo..
                    </span>
                  </>
                )}
                {tipo === "9" && (
                  <>
                    <span>
                      <strong>1. </strong>
                      <strong>
                        Ligar para o paciente
                      </strong>
                    </span>
                    <span>
                      <strong>2.</strong> Caso o paciente não atenda, ligar para
                      cuidador ou contato de emergência na ordem abaixo.
                    </span>
                    <span>
                      <strong>3.</strong> Informar ao paciente que no dia
                      anterior foram verificadas muitas medições incomuns para
                      os parâmetros de saúde automáticos (frequência cardíaca,
                      temperatura, etc).
                    </span>
                    <span>
                      <strong>4.</strong> Questionar o paciente se está tudo
                      bem, se ele tem usado a sua pulseira, orientá-lo a usar a
                      pulseira de forma adequada sempre que possível. Se o
                      paciente informar que está usando a pulseira, pode ser que
                      ela esteja com algum problema. Orientá-lo a fazer o
                      procedimento de reinício.
                    </span>
                  </>
                )}
              </div>
            </div>
            <div style={{ padding: "16px 16px 0 16px" }}>
              <Text
                className={"cadastro-section-title"}
                style={{ color: "#0F79B2" }}
              >
                Cuidadores
              </Text>
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: "column",
                  lineHeight: "22px",
                }}
              >
                {lista_cuidadores.map((item, i) => (
                  <Contato key={i} item={item} />
                ))}
                {lista_cuidadores.length === 0 && (
                  <span>Nenhum cuidador foi informado</span>
                )}
              </div>
              {(tipo === "4" || tipo === "9") && (
                <>
                  <Text
                    className={"cadastro-section-title"}
                    style={{ color: "#0F79B2" }}
                  >
                    Contato para emergências
                  </Text>
                  <div
                    style={{
                      marginTop: "8px",
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: "22px",
                    }}
                  >
                    {!_.isEmpty(contato_emergencial) ? (
                      <Contato item={contato_emergencial} />
                    ) : (
                      <span>Nenhum Contato Emergêncial foi informado</span>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <Acompanhamento />
        )}
      </Row>

      <Row className={"followup-footer"}>
        <Form>
          <Form.Item label="Observações">
            {getFieldDecorator("observacao", { initialValue: null })(
              <TextArea rows={2} />
            )}
          </Form.Item>
        </Form>
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            onClick={handleValidate}
            className="buttonComplete"
            type="primary"
            loading={finishLoading}
          >
            Finalizar atendimento
          </Button>
        </div>
      </Row>
    </>
  );
};

const Contato = ({ item }) => (
  <div
    style={{
      display: "flex",
      marginBottom: "8px",
      flexDirection: "column",
    }}
  >
    <span>
      {item.nome}{" "}
      {item.principal && (
        <span>
          - <b>principal</b>
        </span>
      )}
    </span>
    <strong>{item.telefone}</strong>
  </div>
);

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
  lista_cuidadores: store.urgencia.lista_cuidadores,
  lista_ausencias: store.ausencia.lista_ausencias,
  finishLoading: store.rotina.finishLoading,
  paciente: store.adesao.paciente,
  contato_emergencial: store.urgencia.contato_emergencial,
});

const mapDispatchToProps = (dispatch) => ({
  salvarObservacoes: (body, pacienteID, tarefa) =>
    dispatch(salvarObservacoes(body, pacienteID, tarefa)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Instructions));
