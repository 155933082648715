import React from "react";
import { Form, Input } from "antd";
import { maskCPF, validaCPF, maskPhone } from "Misc/utils";

export const InputCPF = ({
  label,
  decorator,
  getFieldDecorator,
  isRequired,
  initialValue,
  style,
  disabled,
  layout = {},
  ...props
}) => {
  return (
    <Form.Item label={label} {...layout}>
      {getFieldDecorator(decorator, {
        getValueFromEvent: (e) => maskCPF(e.target.value),
        rules: [
          {
            required: isRequired,
            message: "Insira um cpf válido.",
            validator: validaCPF,
          },
        ],
        initialValue: initialValue,
      })(
        disabled ? (
          <span>{initialValue}</span>
        ) : (
          <Input
            allowClear
            placeholder=""
            maxLength={14}
            size="large"
            style={{ minWidth: "170px", ...style }}
            {...props}
          />
        )
      )}
    </Form.Item>
  );
};

export const InputPhone = ({
  label,
  decorator,
  getFieldDecorator,
  isRequired,
  initialValue,
  colon = true,
  optionalLabel = false,
  allowClear = true,
  message = "Insira um telefone.",
  style,
  ...props
}) => {
  return (
    <Form.Item label={label} colon={colon}>
      {getFieldDecorator(decorator, {
        getValueFromEvent: (e) => maskPhone(e.target.value),
        rules: [
          {
            required: isRequired,
            message: message,
          },
        ],
        initialValue: initialValue,
      })(
        <Input
          allowClear={allowClear}
          placeholder=""
          maxLength={14}
          size="large"
          style={{ minWidth: "170px", ...style }}
          {...props}
        />
      )}
      {optionalLabel && (
        <span className="color-gray" style={{ marginLeft: "8px" }}>
          (opcional)
        </span>
      )}
    </Form.Item>
  );
};
