import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";

export const buscarAusenciaReporte = tarefa => async dispatch => {
  try {
    const req = {
      token: "gecro",
      tarefa: tarefa
    };

    const res = await services.buscarAusenciaReporte(req);

    await dispatch({
      type: types.FETCH_AUSENCIAS,
      payload: res.ausenciasReporte
    });
  } catch (error) {
    sendErrorMsg(error);
    console.error("Falha na requisicao de [buscarAusenciaReporte]", error);
  }
};
