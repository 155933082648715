import React from "react";
import { connect } from "react-redux";
import { Table, Icon, Popover, Divider, Empty } from "antd";
import * as actions from "../action";
import { Link } from "react-router-dom";

const PacientesTabela = ({
  listaPacientes,
  buscarPaciente,
  buscarResponsavel,
  abrirTrocarResponsavelModal,
  abrirModalDesistencia,
  abrirModalPlano,
  activeCollapse
}) => {
  function suspendedClass(situation) {
    return Boolean(situation === "Ativo") ? "" : "inactive-option";
  }
  function inactiveClass(situation) {
    return Boolean(situation === "Inativo") ? "inactive-option" : "";
  }
  const columns = [
    {
      title: "Nome do paciente",
      dataIndex: "nomePaciente",
      key: "nomePaciente",
      render: (i, record) => (
        <span className="name-click">
          <Link to={`/pacientes/${record.id}`}>{record.nomePaciente}</Link>
        </span>
      ),
    },
    {
      title: "CPF",
      dataIndex: "cpf",
      key: "cpf",
    },
    {
      title: "Telefone",
      dataIndex: "telefone",
      key: "telefone",
    },
    {
      title: "Nome do responsável",
      dataIndex: "nomeResponsavel",
      key: "nomeResponsavel",
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      key: "situacao",
    },
    {
      title: "Ações",
      dataIndex: "acoes",
      key: "acoes",
      render: (i, record) => (
        <Popover
          placement="right"
          content={
            <>
              <span
                className={`popover-menu-item ${inactiveClass(
                  record.situacao
                )}`}
                onClick={() => buscarPaciente(record.id)}
              >
                <Icon style={{ color: "#38C1E5" }} type="edit" /> Alterar dados
                paciente
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className={`popover-menu-item ${inactiveClass(
                  record.situacao
                )}`}
                onClick={() => buscarResponsavel(record.id)}
              >
                <Icon style={{ color: "#0F79B2" }} type="edit" /> Alterar dados
                respons.
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className="popover-menu-item"
                onClick={() => abrirTrocarResponsavelModal(record.id)}
              >
                <Icon style={{ color: "#000" }} type="team" /> Trocar
                responsável
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className={`popover-menu-item ${suspendedClass(
                  record.situacao
                )}`}
                onClick={() => abrirModalPlano(record.id, "alterar")}
              >
                <Icon style={{ color: "#1890FF" }} type="file-text" /> Alterar
                modelo
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className={`popover-menu-item ${suspendedClass(
                  record.situacao
                )}`}
                onClick={() => abrirModalDesistencia(record.id, "cancelar")}
              >
                <Icon style={{ color: "#888888" }} type="close-circle" /> Canc.
                Monitoramento
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className={`popover-menu-item ${suspendedClass(
                  record.situacao
                )}`}
                onClick={() => abrirModalDesistencia(record.id, "suspender")}
              >
                <Icon style={{ color: "#333333" }} type="pause-circle" /> Susp.
                Monitoramento
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className="popover-menu-item"
                onClick={() => abrirModalPlano(record.id, "reativar")}
              >
                <Icon style={{ color: "#138183" }} type="play-circle" /> Reativ.
                Monitoramento
              </span>
            </>
          }
        >
          <Icon type="more" className="icon-more" />
        </Popover>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      // scroll={{ y: `${activeCollapse ? '30vh' : '60vh'}` }}
      dataSource={listaPacientes}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Registro não encontrado para o filtro informado"
          />
        ),
      }}
    />
  );
};

const mapStateToProps = (store) => ({
  listaPacientes: store.pacientes.listaPacientes,
});

const mapDispatchToProps = (dispatch) => ({
  buscarPaciente: (id) => dispatch(actions.buscarPaciente(id)),
  buscarResponsavel: (id) => dispatch(actions.buscarResponsavel(id)),
  abrirTrocarResponsavelModal: (id) =>
    dispatch(actions.abrirTrocarResponsavelModal(id)),
  abrirModalDesistencia: (id, type) =>
    dispatch(actions.abrirModalDesistencia(id, type)),
  abrirModalPlano: (id, type) => dispatch(actions.abrirModalPlano(id, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PacientesTabela);
