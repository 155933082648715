import React, { useState } from "react";
import "./style.scss";
import { Button, Select, Input, Divider, Form } from "antd";
import { useHistory } from "react-router-dom";
import * as actions from "./action";
import { maskCPF } from "Misc/utils";
import { PERFIS } from "Misc/constants";
import { connect } from "react-redux";

const FiltroMeusPacientes = ({
  getPatient,
  resetFilterPlan,
  loading,
  user,
  ...props
}) => {
  const { getFieldDecorator } = props.form;
  const InputGroup = Input.Group;
  const { Option } = Select;
  const { Search } = Input;
  const history = useHistory();
  const [seletor, setSeletor] = useState("nome");

  const handleClick = async (value, seletor) => {
    resetFilterPlan();

    const body = { token: "gecro" };
    const nameAttribute = "nome";
    const cpfAttribute = "cpf";
    const attendAttribute = "atendente";

    switch (seletor) {
      case "nome":
        body[nameAttribute] = value;
        break;
      case "cpf":
        body[cpfAttribute] = value;
        break;
      default:
        body[attendAttribute] = user.id;
    }

    const res = await getPatient(JSON.stringify(body));
    if (res) {
      history.push(`/meuspacientes`);
    }
  };

  return (
    <>
      {user?.perfil === PERFIS.ENFERMEIRO_UBS && (
        <>
          <div className="layoutButton">
            <Button
              onClick={handleClick}
              loading={loading}
              type="primary"
              block
              style={{ fontWeight: "bold" }}
            >
              Meus Pacientes
            </Button>
          </div>
          <div className="layoutFiltroPlanoCuidado">
            <Divider style={{ fontWeight: "normal", fontSize: 14 }}>
              {" "}
              ou{" "}
            </Divider>
          </div>
        </>
      )}
      <div>
        <InputGroup compact>
          <Select value={seletor} style={{ width: 85 }} onChange={setSeletor}>
            <Option key="nome" value="nome">
              Nome
            </Option>
            <Option key="cpf" value="cpf">
              CPF
            </Option>
          </Select>
          {seletor === "nome" ? (
            <Search
              loading={loading}
              onSearch={(value) => handleClick(value, seletor)}
              style={{ width: 190 }}
            />
          ) : (
            <Form.Item>
              {getFieldDecorator("cpf", {
                getValueFromEvent: (e) => maskCPF(e.target.value),
              })(
                <Search
                  loading={loading}
                  onSearch={(value) => handleClick(value, seletor)}
                  style={{ width: 190 }}
                  maxLength={14}
                />
              )}
            </Form.Item>
          )}
        </InputGroup>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  loading: store.meuspacientes.loading,
  user: store.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  getPatient: (mock) => dispatch(actions.getPatient(mock)),
  resetFilterPlan: () => dispatch(actions.resetFilterPlan()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "filtro_pacientes_plano_cuidados",
  })(FiltroMeusPacientes)
);
