/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionsHeader from "Components/Header/action";
import * as actionsAdesao from "Pages/Adesao/action";
import * as actions from "./action";
import { useParams, Redirect } from "react-router-dom";
import { Row, Col, Collapse } from "antd";
import { isEnfermeiro } from "Misc/utils";

import PacientesFiltro from "./Components/PacientesFiltro";
import PacientesTabela from "./Components/PacientesTabela";
import OpcoesRotina from "../Rotina/OpcoesRotina";
import Acompanhamento from "./Components/AcompanhamentoGenerico";
import BarraInformacao from "../../Components/BarraInformacao";
import ModalPaciente from "./Components/ModalPaciente";
import ModalResponsavel from "./Components/ModalResponsavel";
import ModalDesistencia from "./Components/ModalDesistencia";
import ModalPlano from "./Components/ModalPlano";

import "./style.scss";

const Atendentes = ({
  changeCabecalhoInfo,
  changePacienteID,
  listaPacientes,
  getInfoCabecalhoByPaciente,
  resetCadastros,
  user,
  history,
}) => {
  const { idPaciente } = useParams();
  const [activeCollapse, setActiveCollapse] = useState(false);
  useEffect(() => {
    if (idPaciente) {
      changePacienteID(idPaciente);
      getInfoCabecalhoByPaciente(user.id, idPaciente);
    } else {
      changeCabecalhoInfo({
        nome: "Pacientes",
      });
      resetCadastros();
    }
  }, [idPaciente]);

  if (idPaciente === undefined && isEnfermeiro(user.perfil)) {
    return <Redirect to="/painel" />;
  }

  return idPaciente === undefined ? (
    <div className="atendentes-body">
      {/* Modais */}
      <ModalPlano />
      <ModalDesistencia />
      <ModalPaciente />
      <ModalResponsavel />
      <section className="atendentes-section atendentes-filtro">
        <Collapse onChange={() => setActiveCollapse((state) => !state)} defaultActiveKey={['1']} >
          <Collapse.Panel
            header="Filtros de busca"
            style={{ width: "700px", textAlign: "center" }}
            showArrow={false}
            key="1"
          >
            <PacientesFiltro />
          </Collapse.Panel>
        </Collapse>
      </section>
      {listaPacientes !== null && (
        <section className="atendentes-section atendentes-table">
          <PacientesTabela activeCollapse={activeCollapse}  />
        </section>
      )}
    </div>
  ) : (
    <>
      <BarraInformacao />
      <Row className={"layout-rotina"}>
        <Col className={"coluna-folloup"}>
          <Acompanhamento />
        </Col>
        <Col className={"coluna-body"}>
          <OpcoesRotina readOnly />
        </Col>
      </Row>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeCabecalhoInfo: (info) =>
    dispatch(actionsHeader.changeCabecalhoInfo(info)),
  changePacienteID: (pacienteId) =>
    dispatch(actionsAdesao.changePacienteID(pacienteId)),
  getInfoCabecalhoByPaciente: (enfermeiroID, paciente) =>
    dispatch(actions.getInfoCabecalhoByPaciente(enfermeiroID, paciente)),
  resetCadastros: () => dispatch(actions.resetCadastros()),
});

const mapStateToProps = (store) => ({
  listaPacientes: store.pacientes.listaPacientes,
  user: store.login.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Atendentes);
