import api from "api";

export const buscarCadastros = async (body) => {
  const res = await api.post(
    "/backoffice/buscarCadastros",
    body
  );

  return res.data;
};

export const getInfoCabecalhoByPaciente = async (enfermeiroID, paciente) => {
  const res = await api.post(
    "/backoffice/buscarCabecalho",
    {
      paciente: paciente,
      idEnfermeiro: enfermeiroID,
    }
  );

  return res.data;
};

export const getFotoPaciente = async (pacienteID) => {
  let foto_perfil;
  try {
    await api
      .post("/backoffice/buscarFotoPaciente", {
        token: "gecro",
        paciente: pacienteID,
      })
      .then((response) => {
        const type = response.data.filename.split(".")[1];
        foto_perfil = `data:image/${type};base64,${response.data.file}`;
      });

    return foto_perfil;
  } catch (error) {
    return null;
  }
};

export const buscarPaciente = async (id) => {
  const res = await api.post(
    "/backoffice/buscarDadosPaciente",
    {
      paciente: id,
    }
  );

  return res.data;
};

export const atualizarPaciente = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarDadosPaciente",
    body
  );

  return res.data;
};

export const buscarResponsavel = async (id) => {
  const res = await api.post(
    "/backoffice/buscarDadosResponsavel",
    {
      paciente: id,
    }
  );

  return res.data;
};

export const atualizarResponsavel = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarDadosResponsavel",
    body
  );

  return res.data;
};

export const trocarResponsavel = async (body) => {
  const res = await api.post(
    "/backoffice/trocarResponsavel",
    body
  );

  return res.data;
};

export const cancelarMonitoramento = async (body) => {
  const res = await api.post(
    "/backoffice/cancelarMonitoramento",
    body
  );

  return res.data;
};

export const suspenderMonitoramento = async (body) => {
  const res = await api.post(
    "/backoffice/suspenderMonitoramento",
    body
  );

  return res.data;
};

export const buscarPlanos = async () => {
  const res = await api.post("/cadastro/buscarPlanos", {
    token: "gecro",
  });

  return res.data;
};

export const atualizarPlano = async (body) => {
  const res = await api.post(
    "/cadastro/atualizarPlano",
    body
  );

  return res.data;
};

export const reativarMonitoramento = async (body) => {
  const res = await api.post(
    "/backoffice/reativarMonitoramento",
    body
  );

  return res.data;
};

export const buscarPlanoPaciente = async (id) => {
  const res = await api.post(
    "/cadastro/buscarPlanoPaciente",
    {
      paciente: id,
    }
  );

  return res.data;
};
