import React from "react";
import { message, Modal, Icon } from "antd";
import moment from "moment";
import _ from "lodash";
import store from "store";
import { PERFIS } from "Misc/constants";
import { reenviarEmailBoasVindas } from "Components/InformacaoPessoal/service";

const { confirm } = Modal;

export const maskCPF = (cpf) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

export const validaCPF = async (rule, cpf) => {
  cpf = cpf.toString().replace(/[^\d]+/g, "");
  if (cpf === "") throw new Error("Something wrong!");
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    throw new Error("Something wrong!");
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) throw new Error("Something wrong!");
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) throw new Error("Something wrong!");
  return true;
};

export const validaCPFUnrequired = async (rule, cpf) => {
  if (cpf === "" || cpf === undefined || cpf === null) return true;

  cpf = cpf.toString().replace(/[^\d]+/g, "");

  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    throw new Error("Something wrong!");
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) throw new Error("Something wrong!");
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) throw new Error("Something wrong!");
  return true;
};

export const futureDate = async (rule, ncto) => {
  // const today = moment();
  if (ncto.length >= 10) {
    const date = moment(ncto, "DD/MM/YYYY");

    if (!date.isValid()) {
      throw new Error("Data é inválida");
    }

    if (moment().isSameOrBefore(date)) {
      throw new Error("Data está no futuro");
    }

    if (date.isSameOrBefore(moment("1900", "YYYY"), "year")) {
      throw new Error("Data anterior a 1900");
    }

    return true;
  } else {
    throw new Error("Quantidade de dígitos na data são inválidos");
  }
};

export const isValidDate = async (rule, dt) => {
  // const today = moment();
  if (dt.length >= 10) {
    const date = moment(dt, "DD/MM/YYYY");

    if (!date.isValid()) {
      throw new Error("Data é inválida");
    }

    if (date.isSameOrBefore(moment("1900", "YYYY"), "year")) {
      throw new Error("Data anterior a 1900");
    }

    return true;
  } else {
    throw new Error("Quantidade de dígitos na data são inválidos");
  }
};

export const isValidDateUnrequired = async (rule, dt) => {
  if (dt === "" || dt === undefined || dt === null) return true;
  // const today = moment();
  if (dt.length >= 10) {
    const date = moment(dt, "DD/MM/YYYY");

    if (!date.isValid()) {
      throw new Error("Data é inválida");
    }

    if (date.isSameOrBefore(moment("1900", "YYYY"), "year")) {
      throw new Error("Data anterior a 1900");
    }

    return true;
  } else {
    throw new Error("Quantidade de dígitos na data são inválidos");
  }
};

export const maskTime = (time) => {
  time = time.toString();
  time = time.replace(/\D/g, ""); //Remove tudo o que não é dígito
  if (time.length < 5) {
    time = time.replace(/(\d{2})(\d)/, "$1:$2");
  }
  return time;
};

export const maskDate = (date) => {
  date = date.toString();
  date = date.replace(/\D/g, ""); //Remove tudo o que não é dígito
  if (date.length < 10) {
    date = date.replace(/(\d{2})(\d)/, "$1/$2");
    date = date.replace(/(\d{2})(\d)/, "$1/$2");
    date = date.replace(/(\d{4})(\d)/, "$1/$2");
  }
  return date;
};

export const maskNumber = (num) => {
  num = num.toString();
  return num.replace(/\D/gi, "");
};

export const maskPhone = (phone) => {
  phone = phone.toString();
  phone = phone.replace(/\D/g, ""); //Remove tudo o que não é dígito
  if (phone.length > 10) {
    phone = phone.replace(/^(\d{2})(\d)(\d)/g, "$1 $2 $3"); //Coloca parênteses em volta dos dois primeiros dígitos
  }
  phone = phone.replace(/^(\d{2})(\d)/g, "$1 $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return phone;
};

export const maskPhoneSymbol = (phone) => {
  phone = phone.toString();
  phone = phone.replace(/\D/g, ""); //Remove tudo o que não é dígito
  if (phone.length > 10) {
    phone = phone.replace(/^(\d{2})(\d)(\d)/g, "($1) $2 $3"); //Coloca parênteses em volta dos dois primeiros dígitos
  }
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return phone;
};

const getMsgIcons = (num) => {
  switch (num) {
    case 1:
      return (
        <Icon type="close-circle" style={{ color: "#f5222d" }} theme="filled" />
      );
    case 2:
      return (
        <Icon
          type="exclamation-circle"
          style={{ color: "#faad14" }}
          theme="filled"
        />
      );
    case 3:
      return (
        <Icon type="info-circle" style={{ color: "#1890ff" }} theme="filled" />
      );
    case 4:
      return (
        <Icon type="check-circle" style={{ color: "#52c41a" }} theme="filled" />
      );
    default:
      return (
        <Icon type="close-circle" style={{ color: "#f5222d" }} theme="filled" />
      );
  }
};

export const sendSuccessMsg = (msg) => {
  return message.success(msg);
};

export const sendErrorMsg = (error) => {
  const msg = error.response;

  return !!msg && !!msg.data.message
    ? message.error(msg.data.message)
    : message.error(error.message);
};

export const sendMsg = (obj, duration = 3) => {
  const msg = obj?.message;
  return !!msg
    ? message.open({
        content: msg,
        icon: getMsgIcons(obj?.icon),
        duration: duration,
      })
    : null;
};

export const returnMessage = (error) => {
  const msg = error.response;

  return !!msg && !!msg.data.message ? msg.data.message : error.message;
};

export const returnCode = (error) => {
  const msg = error.response;

  return !!msg && !!msg.data.error ? msg.data.error : false;
};

export const returnStatus = (error) => {
  const msg = error.response;

  return !!msg && !!msg.status ? msg.status : false;
};

export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    console.error(e);
    return "";
  }
};

export const mapTabToTitle = {
  infoPessoal: "Informações pessoais",
  doenca: "Doenças",
  profissional: "Profissionais",
  parametroSaude: "Parâmetros de saúde",
  tarefasNaoFarmacologicas: "Tarefas não farmacológicas",
  questionario: "Questionário",
  redeApoio: "Rede de apoio",
  responsavel: "Responsável",
  ligacoes: "Ligações periódicas",
};

export const showConfirmationTab = (
  arrayTab,
  okClick = () => console.info("OK"),
  cancelClick = () => console.info("Cancel"),
  confirmQuestion = "Deseja finalizar o atendimento mesmo assim?",
  btnConfirmText = "Sim, finalizar"
) => {
  return confirm({
    title: "Alterações não salvas",
    content: (
      <>
        <p>Existem alterações não salvas nas abas:</p>

        {arrayTab.map((tab, index) => {
          return <p key={index}>- {mapTabToTitle[tab]}</p>;
        })}

        <p>{confirmQuestion}</p>
      </>
    ),
    okText: btnConfirmText,
    cancelText: "Cancelar",
    okType: "default",
    onOk() {
      okClick();
    },
    onCancel() {
      cancelClick();
    },
  });
};

export const confirmReenvioEmail = (
  paciente,
  idPaciente,
  okClick = async (paciente, idPaciente) => await reenviarEmailBoasVindas(paciente, idPaciente),
  // okClick = (paciente, idPaciente) => console.info("OK", paciente, idPaciente),
  // cancelClick = () => console.info("Cancel")
) => {
  return confirm({
    title: "Reenviar e-mail de Boas Vindas?",
    content: "Tem certeza que quer reenviar o e-mail de boas vindas?",
    okText: "Sim, enviar",
    cancelText: "Cancelar",
    okType: "default",
    onOk() {
      okClick(paciente, idPaciente);
    },
  });
};

export const mapSubitem = (newValue, el) =>
  Object.keys(newValue.parametrosSaude[el].subitem).map((sub) => {
    // Separar em id e numero do id , podem ser id e idDesejavel
    const id = sub.split(":");
    // Criar o obj para colocar os subitem
    const obj = {};
    // a chave do id [id ou idDesejavel] vai receber o valor
    obj[id[0]] = id[1];
    // Guardar o campo valores
    const values = newValue.parametrosSaude[el].subitem[sub];
    // const keys = Object.keys(values);
    // Remover os "", false, null e undefined
    // values[keys[0]] = _.compact(values[keys[0]])
    return { ...obj, ...values };
  });

//TODO: a frase tem que vir do Backend
export const createPhrase = (filtro) => {
  const atendentes = store.getState().dashboard.atendentes;

  const inicio = Boolean(filtro?.dataInicio) ? true : false;
  const fim = Boolean(filtro?.dataFim) ? true : false;

  let frase = "";
  if (typeof filtro?.atendente !== "number") {
    if (inicio && fim) {
      if (
        filtro.dataInicio ===
          new Date(
            new Date().setDate(new Date().getDate() - 6)
          ).toLocaleDateString("pt-BR") &&
        filtro.dataFim === new Date().toLocaleDateString("pt-BR")
      ) {
        frase = `últimos 7 dias - (Todos os atendentes)`;
      } else if (filtro.dataInicio === filtro.dataFim) {
        if (filtro.dataInicio === new Date().toLocaleDateString("pt-BR")) {
          frase = "hoje - (Todos os atendentes)";
        } else {
          frase = `${filtro?.dataInicio} - (Todos os atendentes)`;
        }
      } else {
        frase = `(${filtro?.dataInicio} a ${filtro?.dataFim}) - (Todos os atendentes)`;
      }
    } else {
      frase = "últimos 30 dias - (Todos os atendentes)";
    }
  } else {
    const pessoa = _.find(atendentes, function (o) {
      return o.id === filtro.atendente;
    });

    if (inicio && fim) {
      frase = `${pessoa.nome} - (${filtro?.dataInicio} a ${filtro?.dataFim})`;
    } else {
      console.log("últimos 30 dias");
      frase = `${pessoa.nome} - últimos 30 dias`;
    }
  }

  return frase;
};

export const getNomeAtendenteFiltrado = (filtro) => {
  const atendentes = store.getState().dashboard.atendentes;

  const pessoa = _.find(atendentes, function (o) {
    return o.id === filtro.atendente;
  });

  return pessoa;
};

export const getPeriodo = (filtro) => {
  const inicio = Boolean(filtro?.dataInicio) ? true : false;
  const fim = Boolean(filtro?.dataFim) ? true : false;

  let periodo = "";
  if (inicio && fim) {
    if (
      filtro.dataInicio ===
        new Date(
          new Date().setDate(new Date().getDate() - 7)
        ).toLocaleDateString("pt-BR") &&
      filtro.dataFim === new Date().toLocaleDateString("pt-BR")
    ) {
      periodo = `Últimos 7 dias`;
    } else if (filtro.dataInicio === filtro.dataFim) {
      if (filtro.dataInicio === new Date().toLocaleDateString("pt-BR")) {
        periodo = `Hoje`;
      } else {
        periodo = `${filtro?.dataInicio}`;
      }
      periodo = `Hoje`;
    } else {
      periodo = `${filtro?.dataInicio} a ${filtro?.dataFim}`;
    }
  } else {
    periodo = `Últimos 30 dias`;
  }

  return periodo;
};

export const isAdmin = (perfil) =>
  Boolean(perfil === PERFIS.ADMIN_UBS || perfil === PERFIS.ADMIN_VIVER);

export const isEnfermeiro = (perfil) =>
  Boolean(
    perfil === PERFIS.ENFERMEIRO_UBS || perfil === PERFIS.ENFERMEIRO_VIVER
  );

export const isUBS = (perfil) =>
  Boolean(perfil === PERFIS.ENFERMEIRO_UBS || perfil === PERFIS.ADMIN_UBS);
