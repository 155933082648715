import api from "../../api";

export const getRaioXPaciente = async (pacienteId) => {
  const res = await api.post("/backoffice/raio-x", {
    token: "gecro",
    paciente: pacienteId,
  });

  // const resMock = {
  //   data: {
  //     success: false,
  //     error: 0,
  //     ivcfAtual: 18,
  //     riscoIvcfAtual: "alto",
  //     ivcfInicioPeriodo: 18,
  //     riscoIvcfInicioPeriodo: "alto",
  //     tarefasNaoRealizadas: 5,
  //     totalTarefasEsperadas: 3,
  //     diasComAusencias: 1,
  //     respostasDesfavoraveisUltimaRotina: 5,
  //     respostasDesfavoraveisRotinaAnterior: 2,
  //     totalAgudizacoes: 1,
  //     totalAgudizacoesPeriodoAnterior: 0,
  //     agudizacoes: [
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 1,
  //         nomeTipoUrgencia: "Botão do pânico",
  //       },
  //       {
  //         quantidade: 1,
  //         idTipoUrgencia: 2,
  //         nomeTipoUrgencia: "Hiperglicemia",
  //       },
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 3,
  //         nomeTipoUrgencia: "Pico hipertensivo",
  //       },
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 4,
  //         nomeTipoUrgencia: "Oxigenação baixa",
  //       },
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 5,
  //         nomeTipoUrgencia: "Bradicardia",
  //       },
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 6,
  //         nomeTipoUrgencia: "Hipoglicemia",
  //       },
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 7,
  //         nomeTipoUrgencia: "Taquicardia",
  //       },
  //       {
  //         quantidade: 0,
  //         idTipoUrgencia: 8,
  //         nomeTipoUrgencia: "Febre",
  //       },
  //     ],
  //   },
  // };


  return res.data;
};
