import * as types from "./actionsType";

const initialState = {
  informacoes: [],
  outras: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_PLANO_CUIDADO_COMPLETE:
      return {
        ...state,
        informacoes: action.payload.informacoes,
        outras: action.payload.outras
      };
    case types.FETCHING_PLANO_CUIDADO:
      return {
        ...state,
        informacoes: [],
        outras: ""
      };

    default:
      return state;
  }
};

export default reducer;
