import { Typography } from "antd";
import React from "react";
import { connect } from "react-redux";
import Chart from "./Chart";
import NotFound from "./NotFound";

const { Text } = Typography;

export const TempoMedioCharts = (props) => {
  const { charts, buscarLoading } = props;

  return (
    <>
    {charts.length === 0 && buscarLoading === false ? (
      <div className="no-content">
        <NotFound />
        <h3>Não foi encontrado nenhum dado para o filtro informado</h3>
        <Text>Refaça a busca utilizando outros filtros.</Text>
      </div>
    ) : (
      <div className="charts chart-grid">
        {charts.map((chart) => (
          <div className={chart.tipo} key={chart.tipo}>
            <Chart {...chart} />
          </div>
        ))}
      </div>
    )}
  </>
  );
};

const mapStateToProps = (store) => ({
  charts: store.dashboard.dash,
  buscarLoading: store.dashboard.buscarLoading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TempoMedioCharts);
