import React from "react";
import { Avatar, Button, Modal, Typography } from "antd";
import SemaforoRiscoBaixo from "Components/IndiceIvcfCard/Semaforos/SemaforoRiscoBaixo";
import SemaforoRiscoModerado from "Components/IndiceIvcfCard/Semaforos/SemaforoRiscoModerado";
import SemaforoRiscoAlto from "Components/IndiceIvcfCard/Semaforos/SemafotoRiscoAlto";
import "./style.scss";
import { BarLoader } from "react-spinners";

const IndiceIvcfModal = ({ onClose, open, historyIvcf }) => {
  return (
    <Modal
      title={
        <span style={{ color: "#0F79B2", fontWeight: "bold" }}>
          Histórico - Alterações pontuação IVCF-20
        </span>
      }
      destroyOnClose
      bodyStyle={{ height: "calc(90vh - 100px)", overflow: "auto" }}
      centered
      style={{ minWidth: "70vw", overflowY: "hidden" }}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Fechar
        </Button>,
      ]}
    >
      {!historyIvcf && <BarLoader color={"#0F79B2"} width={"100%"} />}
      {historyIvcf && historyIvcf.length <= 0 && (
        <Typography.Title level={4} type="secondary" style={{textAlign: 'center'}}>Ainda não existe histórico de alterações para esse paciente</Typography.Title>
      )}
      {historyIvcf &&
        historyIvcf.length > 0 &&
        historyIvcf.map((item) => (
          <div className="wrapper-history">
            <div
              className={`circle ${
                item.risco.toLowerCase() === "baixo" && "riscoBaixo"
              } ${item.risco.toLowerCase() === "moderado" && "riscoModerado"} ${
                item.risco.toLowerCase() === "alto" && "riscoAlto"
              }`}
            />
            <div className="card-timestamp">{item.dataInicio}</div>
            <div className="card-wrapper">
              <div className="card-container">
                <div className="card-header">
                  <span className="ivcf-20">IVCF-20</span>
                  {item.risco.toLowerCase() === "baixo" && (
                    <SemaforoRiscoBaixo />
                  )}
                  {item.risco.toLowerCase() === "moderado" && (
                    <SemaforoRiscoModerado />
                  )}
                  {item.risco.toLowerCase() === "alto" && <SemaforoRiscoAlto />}
                </div>

                <div className="card-body">
                  <Avatar
                    className={`${
                      item.risco.toLowerCase() === "baixo" && "riscoBaixo"
                    } ${
                      item.risco.toLowerCase() === "moderado" && "riscoModerado"
                    } ${item.risco.toLowerCase() === "alto" && "riscoAlto"}`}
                  >
                    <span style={{ fontWeight: "700" }}>
                      {item.valorIndice}
                    </span>
                  </Avatar>

                  <div
                    className={`classe ${
                      item.risco.toLowerCase() === "baixo" && "riscoBaixo"
                    } ${
                      item.risco.toLowerCase() === "moderado" && "riscoModerado"
                    } ${item.risco.toLowerCase() === "alto" && "riscoAlto"}`}
                  >
                    <span>Risco {item.risco}</span>
                  </div>
                </div>
              </div>
              <div className="card-text">
                <p>O que alterou a pontuação:</p>
                <ul>
                  {item.itens.map((alteracao) => (
                    <li>{alteracao}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
    </Modal>
  );
};

export default IndiceIvcfModal;
