import React from "react";
import { connect } from "react-redux";
import { Table, Icon, Popover, Divider, Empty } from "antd";
import { AfastarIcon, DesligarIcon } from "Misc/icons";
import * as actions from "../action";

const AtendentesTabela = ({
  listaAtendentes,
  openAtendentesModal,
  openActionModal,
  activeCollapse
}) => {
  const columns = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      render: (i, record) => (
        <span
          className="name-click"
          onClick={() => openAtendentesModal("view", record)}
        >
          {record.nome}
        </span>
      ),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Situação",
      dataIndex: "situacao.nome",
      key: "situacao.nome",
    },
    {
      title: "Perfil",
      dataIndex: "perfil.nome",
      key: "perfil.nome",
    },
    {
      title: "UBS",
      dataIndex: "ubs.nome",
      key: "ubs.nome",
    },
    {
      title: "Motivo do afastamento",
      key: "afastamento.motivo",
      render: (i, record) => {
        const afastamento = record?.afastamentos?.[0];
        const desligamento = record?.desligamento?.motivo?.nome;
        const situacao = record?.situacao?.codigo;
        return situacao === "T"
          ? afastamento?.motivo?.nome
          : situacao === "I"
          ? desligamento
          : "-";
      },
    },
    {
      title: "Período do afastamento",
      key: "afastamento.periodo",
      render: (i, record) => {
        const afastamento = record?.afastamentos?.[0];
        const situacao = record?.situacao?.codigo;
        return situacao === "T"
          ? `${afastamento?.dataInicio} a ${afastamento?.dataFim}`
          : "-";
      },
    },
    {
      title: "Ações",
      dataIndex: "acoes",
      key: "acoes",
      render: (i, record) => (
        <Popover
          placement="right"
          content={
            <>
              <span
                className="popover-menu-item"
                onClick={() => openAtendentesModal("edit", record)}
              >
                <Icon style={{ color: "#38C1E5" }} type="edit" /> Alterar
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className="popover-menu-item"
                onClick={() => openActionModal("desligar", record)}
              >
                <Icon component={DesligarIcon} /> Desligar
              </span>
              <Divider style={{ margin: 4 }} />
              <span
                className="popover-menu-item"
                onClick={() => openActionModal("afastar", record)}
              >
                <Icon component={AfastarIcon} /> Afastamentos
              </span>
              {record.situacao.codigo === "N" && (
                <>
                  <Divider style={{ margin: 4 }} />
                  <span
                    className="popover-menu-item"
                    onClick={() => openAtendentesModal("complete", record)}
                  >
                    <Icon type="file-text" style={{ color: "#138183" }} />{" "}
                    Completar cadastro
                  </span>
                </>
              )}
            </>
          }
        >
          <Icon type="more" className="icon-more" />
        </Popover>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      // scroll={{ y: `${activeCollapse ? '32vh' : '55vh'}` }}
      dataSource={listaAtendentes}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Registro não encontrado para o filtro informado"
          />
        ),
      }}
    />
  );
};

const mapStateToProps = (store) => ({
  listaAtendentes: store.atendentes.listaAtendentes,
});

const mapDispatchToProps = (dispatch) => ({
  openAtendentesModal: (modal, record) =>
    dispatch(actions.openAtendentesModal(modal, record)),
  openActionModal: (modal, record) =>
    dispatch(actions.openActionModal(modal, record)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AtendentesTabela);
