import React from 'react'
import '../style.scss'

const SemaforoRiscoBaixo = () => {
  return (
    <>
      <div className="default riscoBaixo" />
      <div className="default" />
      <div className="default" />
    </>
  )
}

export default SemaforoRiscoBaixo
