export const BUSCAR_PACIENTES_INIT = "pacientes.inicio_buscar_pacientes";
export const BUSCAR_PACIENTES_FAIL = "pacientes.falha_buscar_pacientes";
export const BUSCAR_PACIENTES_SUCCESS = "pacientes.sucesso_buscar_pacientes";
export const RESET_PACIENTES = "pacientes.reiniciar_lista_pacientes";
export const SAVE_FILTRO_PACIENTES = "pacientes.salvar_filtro_pacientes";
export const RESET_FILTRO_PACIENTES = "pacientes.resetar_filtro_pacientes";

export const BUSCAR_INIT = "pacientes.inicio_buscar";
export const BUSCAR_FAIL = "pacientes.falha_buscar";
export const BUSCAR_SUCCESS = "pacientes.sucesso_buscar";

export const BUSCAR_CEP_PACIENTE = "pacientes.buscar_cep_paciente";
export const BUSCAR_CEP_PACIENTE_FAIL = "pacientes.buscar_cep_paciente_fail";
export const BUSCAR_CEP_PACIENTE_INIT = "pacientes.buscar_cep_paciente_init";

export const OPEN_MODAL_PACIENTE = "pacientes.abrir_modal_paciente";
export const OPEN_MODAL_RESPONSAVEL = "pacientes.abrir_modal_responsavel";
export const OPEN_NEW_MODAL_RESPONSAVEL =
  "pacientes.abrir_modal_novo_responsavel";
export const CLOSE_MODAL = "pacientes.fechar_modal";

export const INIT_SALVAR = "pacientes.inicando_salvamento_dados";
export const FINISH_SALVAR = "pacientes.finalizando_salvamento_dados";

export const OPEN_MODAL_DESISTENCIA = "pacientes.abrir_modal_desistencia";
export const OPEN_MODAL_PLANO = "pacientes.abrir_modal_plano";
export const CHANGE_PLANO = "pacientes.mudar_plano_paciente";
