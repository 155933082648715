/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "antd/dist/antd.css";
import GrupoPerguntasCadastro from "./GruposPerguntas";
import { Typography, Form, Divider, Button } from "antd";
import { connect } from "react-redux";
import { attQuestionario } from "./service";
import * as actions from "./action";
import { unsavedFields } from "Pages/Rotina/action";
import { sendErrorMsg, sendSuccessMsg } from "Misc/utils";
import { SCROLL_CONFIG } from "Misc/constants";
import _ from "lodash";
import { useParams } from "react-router-dom";

const { Text } = Typography;

const handleChange = (props) => {
  props.unsavedFields({ questionario: true });
};

const QuestionarioCadastro = Form.create({
  name: "questionario",
  onValuesChange: _.debounce(handleChange, 200),
})((props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    rotina,
    title,
    badge_questionario,
    readOnly,
    goToRef,
    activeRef,
    refs,
    setActiveRef,
    relatedQuestion
  } = props;
  const { tipo } = useParams();

  const validateInput = (e) => {
    e.preventDefault();

    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const arrayReturned = [];

        Object.entries(values.questions).forEach(([key, value]) => {
          let json;
          if (typeof value === "string") {
            json = { numero: key, respostaAberta: value };
          } else {
            json = { numero: key, valor: value };
          }

          arrayReturned.push(json);
        });
        const newJSON = {
          token: "gecro",
          respostas: arrayReturned,
          paciente: props.paciente,
        };

        props.editInfo(newJSON, values.questions, false);
        attQuestionario(newJSON).then((res) => {
          props.nextStep(props.step);
        });
      } else {
        sendErrorMsg(new Error("Existem campos obrigatórios não preenchidos"));
      }
    });
  };

  const saveTab = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const arrayReturned = [];
        Object.entries(values.questions).forEach(([key, value]) => {
          let json;
          if (typeof value === "string") {
            json = { numero: key, respostaAberta: value };
          } else {
            json = { numero: key, valor: value };
          }
          arrayReturned.push(json);
        });
        const newJSON = {
          token: "gecro",
          respostas: arrayReturned,
          paciente: props.paciente,
        };

        props.editInfo(newJSON, values.questions).then(() => {sendSuccessMsg("Questionário atualizado com sucesso.")});
      } else {
        sendErrorMsg(new Error("Existem campos obrigatórios não preenchidos"));
      }
    });
  };

  const filterRef = (refsArray) =>
    _.find(
      refsArray,
      (ref) => +ref.current?.getAttribute("questionid") === relatedQuestion
    );

  useEffect(() => {
    setActiveRef(filterRef(refs));
  }, [refs, relatedQuestion]);

  useEffect(() => {
    if (activeRef) {
      setTimeout(() => {
        goToRef(activeRef)
      }, 300);
    };
  }, [activeRef]);

  useEffect(() => {
    if (props.paciente) props.getInfoQuestionario(props.paciente);
  }, [props.paciente]);
  return (
    <div style={!!rotina ? { marginBottom: "100px" } : {}}>
      {!rotina && (
        <>
          {tipo !== "2" && (
            <>
              <Text>Confirme as informações</Text>
              <Divider style={{ background: "rgba(56, 193, 229, 0.4)" }} />
            </>
          )}
        </>
      )}
      {props.grupos &&
        props.grupos.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <GrupoPerguntasCadastro
                perguntas={item.perguntas}
                titulo={item.nome}
                getFieldDecorator={getFieldDecorator}
                readOnly={readOnly}
                {...props}
              />

              {!rotina && (
                <Divider style={{ background: "rgba(56, 193, 229, 0.4)" }} />
              )}
            </React.Fragment>
          );
        })}
      {!rotina ? (
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            className="buttonFooterNext"
            onClick={(e) => validateInput(e)}
            type="primary"
          >
            Próximo
          </Button>
          <Button
            className="buttonFooterBack"
            onClick={() => props.backStep(props.step)}
            type="primary"
            style={
              props.step > 1
                ? { opacity: 1 }
                : { opacity: 0, pointerEvents: "none" }
            }
          >
            Voltar
          </Button>
        </div>
      ) : (
        !readOnly && (
          <div className="footer-save-button">
            <Button
              className="buttonFooterNext"
              onClick={saveTab}
              disabled={!badge_questionario}
              type="primary"
            >
              Salvar alterações
            </Button>
            <span className="affix-title">{title}</span>
          </div>
        )
      )}
    </div>
  );
});

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  step: store.adesao.step,
  grupos: store.questionario.grupos,
  respostas: store.questionario.respostas,
  badge_questionario: store.rotina.unsaved.questionario,
  activeRef: store.questionario.activeRef,
  refs: store.questionario.refs,
  relatedQuestion: store.rotina.relatedQuestion,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoQuestionario: (pacienteID) =>
    dispatch(actions.getInfoQuestionario(pacienteID)),
  editInfo: (req, questions, restCall) => dispatch(actions.editInfo(req, questions, restCall)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
  goToRef: (ref) => dispatch(actions.goToRef(ref)),
  setActiveRef: (ref) => dispatch(actions.setActiveRef(ref)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionarioCadastro);
