export const ENFERMEIRO_ID = "-1";

export const CRITICIDADE = {
  0: { descricao: "ALTA", background: "#DD0000", color: "white" },
  1: { descricao: "MÉDIA", background: "#FFD600", color: "black" },
  2: { descricao: "BAIXA", background: "#2E7D32", color: "white" },
};

export const TIPO_OCORRENCIA = {
  blue: "#1890FF",
  red: "#DD0000",
};

export const LINE_COLORS = ["#673AB7", "#00838F"];

export const BAR_COLORS = ["#673AB7", "#00838F", "#00BCD4", "#E65100"];

export const SLEEP_BAR_COLORS = ['#673AB7', '#5C6BC0', '#42A5F5', '#1DE9B6', '#B2FF59']

export const BAR_COLORS_EXTRA = ["#164aa2", "#e93c6b"];

export const TIPO_TAREFA = {
  1: {
    descricao: "Boas Vindas",
    background: "purple",
    color: "black",
    route: "boasvindas",
    headerDescricao: "Boas Vindas",
    criticidade: 1,
  },
  2: {
    descricao: "Adesão",
    background: "blue",
    color: "black",
    route: "adesao",
    headerDescricao: "Nova Adesão",
    criticidade: 2,
  },
  3: {
    descricao: "Ligação de Rotina",
    background: "pink",
    color: "black",
    route: "ligacaorotina",
    headerDescricao: "Ligação de Rotina",
    criticidade: 1,
  },
  4: {
    descricao: "Urgência médica",
    background: "red",
    color: "black",
    route: "urgenciamedica",
    headerDescricao: "Urgência médica",
    criticidade: 0,
  },
  5: {
    descricao: "Ausência de reporte",
    background: "volcano",
    color: "black",
    route: "ausenciareporte",
    headerDescricao: "Ausência de reporte",
    criticidade: 2,
  },
  7: {
    descricao: "Atendimento solicitado",
    background: "orange",
    color: "black",
    route: "atendimentosolicitado",
    headerDescricao: "Atendimento solicitado",
    criticidade: 1,
  },
  8: {
    descricao: "Reativação",
    background: "green",
    color: "black",
    route: "reativacao",
    headerDescricao: "Reativação",
    criticidade: 1,
  },
  9: {
    descricao: "Medições incomuns",
    background: "gold",
    color: "black",
    route: "medicoesincomuns",
    headerDescricao: "Medições incomuns",
    criticidade: 1,
  },
  10: {
    descricao: "Sintomas inflamatórios",
    background: "gold",
    color: "black",
    route: "sintomasinflamatorios",
    headerDescricao: "Sintomas inflamatórios",
    criticidade: 1,
  },
  11: {
    descricao: "Desvio padrão",
    background: "gold",
    color: "black",
    route: "desviopadrao",
    headerDescricao: "Desvio padrão",
    criticidade: 1,
  }
};

export const DIAS_SEMANA = [
  { valor: "SEG", descricao: "segunda-feira" },
  { valor: "TER", descricao: "terça-feira" },
  { valor: "QUA", descricao: "quarta-feira" },
  { valor: "QUI", descricao: "quinta-feira" },
  { valor: "SEX", descricao: "sexta-feira" },
  { valor: "SAB", descricao: "sábado" },
  { valor: "DOM", descricao: "domingo" },
];

export const PERIODICIDADE = [
  { valor: "D", descricao: "Diário" },
  { valor: "S", descricao: "Semanal" },
  { valor: "Q", descricao: "Quinzenal" },
  { valor: "M", descricao: "Mensal" },
];

export const PERIODICIDADE_LIGACAO = [
  { valor: "D", descricao: "Diário" },
  { valor: "S", descricao: "Semanal" },
  { valor: "Q", descricao: "Quinzenal" },
  { valor: "M", descricao: "Mensal" },
  { valor: "B", descricao: "Bimestral" },
  { valor: "T", descricao: "Trimestral" },
  { valor: "F", descricao: "Quadrimestral" },
];

export const SEXO = {
  M: "Masculino",
  F: "Feminino",
};

export const NUMBER = /^\s*[+-]?\s*(?:\d{1,3}(?:(,?)\d{3})?(?:\1\d{3})*(\.\d*)?|\.\d+)\s*$/g;

export const NUMBER_WITH_COMMA = /^\s*[+-]?\s*(?:\d{1,3}(?:(,?)\d{3})?(?:\1\d{3})*(,\d*)?|\.\d+)\s*$/g;

export const DATE_FORMAT = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;

export const TIME_FORMAT = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm;

export const MEDICAMENTO_FRACAO = /^([1]{1}\/?([2-4])$|^[1-9]\d*$)*$/gim;

export const SCROLL_CONFIG = {
  scroll: { offsetTop: 50, alignWithTop: true, onlyScrollIfNeeded: false },
};

export const STATES = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const PERFIS = {
  ADMIN_UBS: "1",
  ENFERMEIRO_UBS: "2",
  ADMIN_VIVER: "7",
  ENFERMEIRO_VIVER: "8",
};
