import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg, sendMsg, sendSuccessMsg } from "Misc/utils";

export const buscarOpcoesSituacao = () => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_SITUACOES_INIT });

    const response = await services.buscarOpcoesSituacao();

    dispatch({
      type: types.BUSCAR_SITUACOES_SUCCESS,
      payload: response.situacao,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_SITUACOES_FAIL });
    sendErrorMsg(error);
  }
};

export const buscarOpcoesPerfil = () => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_PERFIS_INIT });

    const response = await services.buscarOpcoesPerfil();

    dispatch({
      type: types.BUSCAR_PERFIS_SUCCESS,
      payload: response.perfil,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_PERFIS_FAIL });
    sendErrorMsg(error);
  }
};

export const buscarAtendentes = (body) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_ATENDENTES_INIT });

    const response = await services.buscarAtendentes(body);
    dispatch({
      type: types.BUSCAR_ATENDENTES_SUCCESS,
      payload: response.atendentes,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_ATENDENTES_FAIL });
    sendErrorMsg(error);
  }
};

export const refreshAtendentes = (body) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_ATENDENTES_INIT });
    
    const response = await services.buscarAtendentes(body);
    dispatch({
      type: types.BUSCAR_ATENDENTES_SUCCESS,
      payload: response.atendentes,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_ATENDENTES_FAIL });
    console.error(error);
  }
};

export const saveFiltro = (filtro) => (dispatch) => {
  dispatch({ type: types.SAVE_FILTRO_ATENDENTES, payload: filtro });
};

export const resetFiltro = () => (dispatch) => {
  dispatch({ type: types.RESET_FILTRO_ATENDENTES });
};

export const closeAtendentesModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_ATENDENDENTES_MODAL });
  dispatch({ type: types.RESET_ENDERECO });
};

export const openAtendentesModal = (modal, atendente) => (dispatch) => {
  if (atendente) {
    const { endereco, ...dados } = atendente;
    dispatch({
      type: types.ATENDENDENTE_DATA,
      payloadData: dados,
      payloadEndereco: endereco,
    });
  }
  dispatch({ type: types.OPEN_ATENDENDENTES_MODAL, payload: modal });
};

export const resetAtendentes = () => (dispatch) => {
  dispatch({ type: types.RESET_ATENDENTES });
};

export const buscarEndereco = (cep) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_CEP_INIT });

    const response = await services.buscarEndereco(cep);
    dispatch({
      type: types.BUSCAR_CEP_SUCCESS,
      payload: response.endereco,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_CEP_FAIL });
    sendErrorMsg(error);
  }
};

export const inserirAtendente = (body, filtro) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING_MODAL_INIT });
    await services.inserirAtendente(body);

    dispatch({ type: types.LOADING_MODAL_FINISH });

    sendSuccessMsg("Cadastro realizado com sucesso.")

    await dispatch(refreshAtendentes(filtro));

    dispatch(closeAtendentesModal());
  } catch (error) {
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendErrorMsg(error);
  }
};

export const atualizarAtendente = (body, filtro) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING_MODAL_INIT });

    await services.atualizarAtendente(body);

    dispatch({ type: types.LOADING_MODAL_FINISH });

    sendSuccessMsg("Cadastro atualizado com sucesso.")

    await dispatch(refreshAtendentes(filtro));

    dispatch(closeAtendentesModal());
  } catch (error) {
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendErrorMsg(error);
  }
};

export const openActionModal = (modal, record) => (dispatch) => {
  dispatch({
    type: types.OPEN_ACTION_MODAL,
    payloadType: modal,
    payloadMatricula: record.matricula,
    payloadAfastamentos: record.afastamentos,
  });
};

export const closeActionModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_ACTION_MODAL });
};

export const buscarOpcoesDesligamento = (motivo) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_MOTIVOS_INIT });

    const response = await services.buscarOpcoesDesligamento();

    dispatch({
      type: types.BUSCAR_MOTIVOS_SUCCESS,
      payload: response.motivo,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_MOTIVOS_FAIL });
    sendErrorMsg(error);
  }
};

export const buscarOpcoesAfastamento = () => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_MOTIVOS_INIT });

    const response = await services.buscarOpcoesAfastamento();

    dispatch({
      type: types.BUSCAR_MOTIVOS_SUCCESS,
      payload: response.motivo,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_MOTIVOS_FAIL });
    sendErrorMsg(error);
  }
};

export const registrarAfastamento = (body, filtro, checked) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.LOADING_MODAL_INIT });

    const response = await services.registrarAfastamento(body);
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendMsg(response);

    await dispatch(refreshAtendentes(filtro));

    dispatch({
      type: types.ATUALIZAR_AFASTAMENTOS_MODAL,
      payload: response.afastamentos,
    });

    if (!checked) {
      dispatch(closeActionModal());
    }
    return true;
  } catch (error) {
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendErrorMsg(error);
  }
};

export const registrarDesligamento = (body, filtro) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING_MODAL_INIT });

    await services.registrarDesligamento(body);

    dispatch({ type: types.LOADING_MODAL_FINISH });

    sendSuccessMsg("Desligamento registrado com sucesso.")

    await dispatch(refreshAtendentes(filtro));

    dispatch(closeActionModal());
  } catch (error) {
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendErrorMsg(error);
  }
};

export const deletarAfastamento = (idAfastamento, filtro) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.LOADING_MODAL_INIT });

    const response = await services.deletarAfastamento({ id: idAfastamento });
    dispatch({ type: types.LOADING_MODAL_FINISH });

    sendSuccessMsg("Afastamento deletado com sucesso.")

    dispatch({
      type: types.ATUALIZAR_AFASTAMENTOS_MODAL,
      payload: response.afastamentos,
    });

    await dispatch(refreshAtendentes(filtro));
  } catch (error) {
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendErrorMsg(error);
  }
};

export const changeCheckBox = (checked) => (dispatch) => {
  dispatch({ type: types.CHECK_ANOTHER_REGISTRY, payload: checked });
};

export const buscarOpcoesUbs = () => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_UBS_INIT });

    const response = await services.buscarOpcoesUbs();

    dispatch({
      type: types.BUSCAR_UBS_SUCCESS,
      payload: response.opcoesUbs,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_UBS_FAIL });
    sendErrorMsg(error);
  }
};

export const atualizarAfastamento = (body, filtro) => async (dispatch) => {
  try {
    dispatch({ type: types.LOADING_MODAL_INIT });

    const response = await services.atualizarAfastamento(body);
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendMsg(response);

    await dispatch(refreshAtendentes(filtro));

    dispatch({
      type: types.ATUALIZAR_AFASTAMENTOS_MODAL,
      payload: response.afastamentos,
    });
  } catch (error) {
    dispatch({ type: types.LOADING_MODAL_FINISH });
    sendErrorMsg(error);
  }
};
