import React, { useEffect, useState } from "react";
import { Typography, Button, Tag, Table, Popconfirm } from "antd";
import * as actions from "../action";
import { connect } from "react-redux";
import MedicamentoModal from "../MedicamentoModal";
const { Text } = Typography;

const Medicamentos = (props) => {
  const { rotina, readOnly } = props;
  const [cancelarModal, setCancelarModal] = useState(false);
  const [medicamentoID, setMedicamentoID] = useState("");
  useEffect(() => {
    //buscar medicamento()
    if (props.paciente) props.getMedicamentoListaPaciente(props.paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);
  const columns = !readOnly
    ? [
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Nome comercial
            </Text>
          ),
          dataIndex: "nome",
        },
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Princípio ativo
            </Text>
          ),
          dataIndex: "principio",
        },
        {
          title: "",
          dataIndex: "deleteAction",
        },
      ]
    : [
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Nome comercial
            </Text>
          ),
          dataIndex: "nome",
        },
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Princípio ativo
            </Text>
          ),
          dataIndex: "principio",
        },
      ];

  const modal = () => {
    return (
      <MedicamentoModal
        title="Adicionar medicamento"
        visible={cancelarModal}
        onOk={handleConfirmar}
        confirmLoading={false}
        medicamentoID={medicamentoID}
        onCancel={handleCancelar}
      />
    );
  };

  const handleOpenModal = async (id, nome) => {
    if (id) {
      props.changeMedicamento({ id: id, nome: nome });
      setMedicamentoID(id);
      await props.getMedicamentoDetalhesPaciente(props.paciente, id);
    }
    setCancelarModal(true);
  };
  const handleCancelar = async (value) => {
    setMedicamentoID("");
    setCancelarModal(false);
  };

  const handleConfirmar = async (value) => {
    setMedicamentoID("");
    setCancelarModal(false);
    await props.getMedicamentoListaPaciente(props.paciente);
  };

  const handleExcluir = async (id) => {
    await props.excluiMedicamento(props.paciente, id);
    await props.getMedicamentoListaPaciente(props.paciente);
  };
  return (
    <>
      {modal()}
      {!rotina && (
        <div className="cadastro-object-title">
          <Tag color="orange">NOVO</Tag>
          <Text className="cadastro-section-title">
            Medicamentos e posologia
          </Text>
        </div>
      )}
      <div className="spacing-sections">
        {!rotina && (
          <Text className="cadastro-section-description">
            Quais medicamentos o idoso faz uso?
          </Text>
        )}
        {!readOnly && (
          <div style={{ display: "flex", width: "40%", margin: "1vw 0" }}>
            <Button
              className="buttonFooterBack"
              onClick={() => handleOpenModal()}
            >
              Adicionar medicamento
            </Button>
          </div>
        )}
        {props.medicamentosLista.length > 0 && (
          <Table
            pagination={false}
            columns={columns}
            style={{ backgroundColor: "#fff", marginBottom: 60 }}
            dataSource={props.medicamentosLista.map((item) => {
              return {
                key: item.id,
                nome: (
                  <span
                    style={{ cursor: "pointer", color: "#40a9ff" }}
                    onClick={() =>
                      !readOnly && handleOpenModal(item.id, item.nome)
                    }
                  >
                    {item.nome}
                  </span>
                ),
                principio: item.principioAtivo,
                deleteAction: (
                  <Popconfirm
                    title="Tem certeza?"
                    onConfirm={() => handleExcluir(item.id)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Button type="link">Excluir</Button>
                  </Popconfirm>
                ),
              };
            })}
            size="small"
          />
        )}
      </div>
      {!!rotina && !readOnly && (
        <div className="footer-save-button" style={{ padding: "14px" }}>
          <span className="affix-title" style={{ color: "#888888" }}>
            Alterações nos medicamentos são salvas automaticamente.
          </span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  medicamentosLista: store.acompanhamento.medicamentosLista,
  paciente: store.adesao.paciente,
});

const mapDispatchToProps = (dispatch) => ({
  getMedicamentoListaPaciente: (pacienteID) =>
    dispatch(actions.getMedicamentoListaPaciente(pacienteID)),
  getMedicamentoDetalhesPaciente: (pacienteID, medicamentoID) =>
    dispatch(actions.getMedicamentoDetalhesPaciente(pacienteID, medicamentoID)),
  excluiMedicamento: (pacienteID, medicamentoId) =>
    dispatch(actions.excluiMedicamento(pacienteID, medicamentoId)),
  changeMedicamento: (medicamento) =>
    dispatch(actions.changeMedicamento(medicamento)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Medicamentos);
