import * as types from "./actionType";

const initialState = {
    perguntas: [],
    acompanhamento: false,
    loading: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case (types.FETCH_FOLLOWUP_PROCESSOS):
            return {
                ...state,
                perguntas: action.payload.perguntas,
                acompanhamento: action.payload.acompanhamento
            };
        case (types.SAVE_FOLLOWUP_PROCESSOS_START):
            return {
                ...state,
                 loading: true
            };
        case (types.SAVE_FOLLOWUP_PROCESSOS_END):
            return {
                ...state,
                 loading: false
            };
        default:
            return {
                ...state,
            }
    }
}

export default reducer;
