import React, { useEffect, useState } from "react";
import { Avatar, Button } from "antd";
import * as service from "./service";
import SemaforoRiscoBaixo from "./Semaforos/SemaforoRiscoBaixo";
import SemaforoRiscoModerado from "./Semaforos/SemaforoRiscoModerado";
import SemaforoRiscoAlto from "./Semaforos/SemafotoRiscoAlto";
import "./style.scss";
import IndiceIvcfModal from "Components/IndiceIvcfModal";

const IndiceIvcfCard = ({ paciente, activeTabKey }) => {
  const [ivcf, setIvcf] = useState({
    indice: null,
    classe: {
      id: null,
      descricao: null,
    },
  });
  const [historyModal, setHistoryModal] = useState(false);
  const [history, setHistory] = useState(false);

  const getIndiceIvcf = async () => {
    const response = await service.buscarIndice(paciente);
    const history = await service.buscarHistorico(paciente);
    setHistory(history.listaHistorico);
    setIvcf({ ...ivcf, indice: response.indice, classe: response.classe });
  };

  useEffect(() => {
    if (activeTabKey === "1" && paciente) getIndiceIvcf();
    // eslint-disable-next-line
  }, [paciente, activeTabKey]);

  const handleOpenModal = async () => {
    setHistoryModal(true);
  };

  return (
    <>
      <div className="card-container">
        <div className="card-header">
          <span className="ivcf-20">IVCF-20</span>
          {ivcf.classe.id === 0 && <SemaforoRiscoBaixo />}
          {ivcf.classe.id === 1 && <SemaforoRiscoModerado />}
          {ivcf.classe.id === 2 && <SemaforoRiscoAlto />}
        </div>
        <div className="card-body">
          <Avatar
            className={`${ivcf.classe.id === 0 && "riscoBaixo"} ${
              ivcf.classe.id === 1 && "riscoModerado"
            } ${ivcf.classe.id === 2 && "riscoAlto"}`}
          >
            <span style={{ fontWeight: "700" }}>{ivcf.indice}</span>
          </Avatar>

          <div
            className={`classe ${ivcf.classe.id === 0 && "riscoBaixo"} ${
              ivcf.classe.id === 1 && "riscoModerado"
            } ${ivcf.classe.id === 2 && "riscoAlto"}`}
          >
            <span>{ivcf.classe.descricao}</span>
          </div>
        </div>

        <div className="card-footer">
          <Button
            onClick={handleOpenModal}
            style={{ fontSize: "12px" }}
            type="primary"
            ghost
          >
            Ver histórico
          </Button>
        </div>
      </div>
      <IndiceIvcfModal
        open={historyModal}
        onClose={() => setHistoryModal(false)}
        historyIvcf={history}
      />
    </>
  );
};

export default IndiceIvcfCard;
