import React, { useState } from "react";
import "./style.scss";
import { Button, Typography, Icon, Row, Col, Badge } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CancelarModal from "./CancelarModal";
import { SEXO } from "../../Misc/constants";
import { maskPhoneSymbol } from "../../Misc/utils";
const { Text } = Typography;

const BarraInformacao = (props) => {
  const [cancelarModal, setCancelarModal] = useState(false);
  const { history } = props;

  const handleCancelar = async (value) => {
    setCancelarModal(false);
    history.push("/painel");
  };

  const handleOpenModal = async (id) => {
    setCancelarModal(true);
  };

  const handlePausar = () => {
    history.push("/painel");
  };

  const verificarTipoBarra = (tipo) => {
    switch (tipo) {
      case "1":
        return (
          <div className="barra-layout">
            <div>
              {props.loading ? (
                <Icon type="loading" />
              ) : (
                <>
                  <Text className="labelsBarra">Telefone: </Text>
                  {props.telefone.map((tel, i) => {
                    return (
                      <React.Fragment key={`tel_${i}`}>
                        <Text key={tel} className="telefone">
                          {maskPhoneSymbol(tel)}
                        </Text>
                        {i < props.telefone.length - 1 && <strong> - </strong>}
                      </React.Fragment>
                    );
                  })}
                  <Col>
                    <Text className="labelsBarra">UBS: </Text>
                    <Text>{props.ubs}</Text>
                  </Col>
                </>
              )}
            </div>
            {props.tipoTarefa === "2" && (
              <>
                <div>
                  {props.loading ? (
                    <Icon type="loading" />
                  ) : (
                    <>
                      <Text className="labelsBarra">Contato: </Text>
                      <Text>{props.nome}</Text>
                    </>
                  )}
                </div>
                <div>
                  {props.loading ? (
                    <Icon type="loading" />
                  ) : (
                    <>
                      <Text className="labelsBarra">Email: </Text>
                      <Text>{props.email}</Text>
                    </>
                  )}
                </div>
              </>
            )}
            <div>
              {props.tipoTarefa === "2" && (
                <Button
                  onClick={() => handleOpenModal()}
                  className="marginBotaoCancelarBarra"
                  type="danger"
                  ghost
                >
                  Cancelar adesão
                </Button>
              )}
              <Button onClick={handlePausar} type="primary">
                Pausar atendimento
              </Button>
            </div>
          </div>
        );
      case "8":
        return (
          <div className="barra-layout">
            <div>
              {props.loading ? (
                <Icon type="loading" />
              ) : (
                <>
                  <Text className="labelsBarra">Telefone: </Text>
                  {props.telefone.map((tel, i) => {
                    return (
                      <React.Fragment key={`tel_${i}`}>
                        <Text key={tel} className="telefone">
                          {maskPhoneSymbol(tel)}
                        </Text>
                        {i < props.telefone.length - 1 && <strong> - </strong>}
                      </React.Fragment>
                    );
                  })}
                  <Col>
                    <Text className="labelsBarra">UBS: </Text>
                    <Text>{props.ubs}</Text>
                  </Col>
                </>
              )}
            </div>
            {props.tipoTarefa === "2" && (
              <>
                <div>
                  {props.loading ? (
                    <Icon type="loading" />
                  ) : (
                    <>
                      <Text className="labelsBarra">Contato: </Text>
                      <Text>{props.nome}</Text>
                    </>
                  )}
                </div>
                <div>
                  {props.loading ? (
                    <Icon type="loading" />
                  ) : (
                    <>
                      <Text className="labelsBarra">Email: </Text>
                      <Text>{props.email}</Text>
                    </>
                  )}
                </div>
                <Col>
                  <Text className="labelsBarra">UBS: </Text>
                  <Text>{props.ubs}</Text>
                </Col>
              </>
            )}
            <div>
              {props.tipoTarefa === "2" && (
                <Button
                  onClick={() => handleOpenModal()}
                  className="marginBotaoCancelarBarra"
                  type="danger"
                  ghost
                >
                  Cancelar adesão
                </Button>
              )}
              <Button onClick={handlePausar} type="primary">
                Pausar atendimento
              </Button>
            </div>
          </div>
        );

      case "2":
        return (
          <div className="barra-layout">
            <div className="info-group">
              <div>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Text className="labelsBarra">Telefone: </Text>
                    {props.telefone.map((tel, i) => {
                      return (
                        <React.Fragment key={`tel_${i}`}>
                          <Text key={tel} className="telefone">
                            {maskPhoneSymbol(tel)}
                          </Text>
                          {i < props.telefone.length - 1 && (
                            <strong> - </strong>
                          )}
                        </React.Fragment>
                      );
                    })}
                    <Col>
                      <Text className="labelsBarra">UBS: </Text>
                      <Text>{props.ubs}</Text>
                    </Col>
                  </>
                )}
              </div>
              {props.tipoTarefa === "2" && (
                <>
                  <div>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Text className="labelsBarra">Contato: </Text>
                        <Text>{props.nome}</Text>
                      </>
                    )}
                  </div>
                  <div>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Text className="labelsBarra">Email: </Text>
                        <Text>{props.email}</Text>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div>
              {props.tipoTarefa === "2" && (
                <Button
                  onClick={() => handleOpenModal()}
                  className="marginBotaoCancelarBarra"
                  type="danger"
                  ghost
                >
                  Cancelar adesão
                </Button>
              )}
              <Button onClick={handlePausar} type="primary">
                Pausar atendimento
              </Button>
            </div>
          </div>
        );

      case "3":
      case "7":
        return (
          <>
            <Row
              type="flex"
              className="barra-layout-rotina"
              align="middle"
              justify="space-between"
            >
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="labelsBarra">Telefone: </Text>
                      {props.telefone.map((tel, i) => {
                        return (
                          <React.Fragment key={`tel_${i}`}>
                            <Text key={tel} className="telefone">
                              {maskPhoneSymbol(tel)}
                            </Text>
                            {i < props.telefone.length - 1 && (
                              <strong> - </strong>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                    <Col>
                      <Text className="labelsBarra">Sexo: </Text>
                      <Text>{SEXO[props.sexo]}</Text>
                    </Col>
                    <Col>
                      <Text className="labelsBarra">UBS: </Text>
                      <Text>{props.ubs}</Text>
                    </Col>
                  </>
                )}
              </Col>
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="labelsBarra">Peso: </Text>
                      <Text>{props.peso} Kg</Text>
                    </Col>
                    <Col>
                      <Text className="labelsBarra">IMC: </Text>
                      <Text>{props.imc.toString().replace(".", ",")}</Text>
                    </Col>
                  </>
                )}
              </Col>
              {!!props.doencas && (
                <>
                  <Col>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Col>
                          {props.doencas[0] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[0]}
                            />
                          )}
                        </Col>

                        <Col>
                          {props.doencas[3] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[3]}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Col>
                          {props.doencas[1] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[1]}
                            />
                          )}
                        </Col>

                        <Col>
                          {props.doencas[4] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[4]}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Col>
                          {props.doencas[2] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[2]}
                            />
                          )}
                        </Col>

                        <Col>
                          {props.doencas[5] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[5]}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Col>
                </>
              )}
              <Col>
                <Button onClick={() => history.push("/painel")} type="primary">
                  Pausar atendimento
                </Button>
              </Col>
            </Row>
          </>
        );
      case "4":
        return (
          <>
            <Row
              type="flex"
              className="barra-layout-urgencia"
              align="middle"
              justify="space-between"
            >
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="labelsBarra">Telefone: </Text>
                      {props.telefone.map((tel, i) => {
                        return (
                          <React.Fragment key={`tel_${i}`}>
                            <Text key={tel} className="telefone">
                              {maskPhoneSymbol(tel)}
                            </Text>
                            {i < props.telefone.length - 1 && (
                              <strong> - </strong>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                    <Col>
                      <Text className="labelsBarra">UBS: </Text>
                      <Text>{props.ubs}</Text>
                    </Col>
                  </>
                )}
              </Col>
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="urgencias-labels">{props.urgencia}</Text>
                    </Col>
                  </>
                )}
              </Col>
              <Col>
                <Button onClick={() => history.push("/painel")} type="primary">
                  Pausar atendimento
                </Button>
              </Col>
            </Row>
          </>
        );
      case "5":
      case "9":
      case "10":
      case "11":
        return (
          <>
            <Row
              type="flex"
              className="barra-layout-ausencia"
              align="middle"
              justify="space-between"s
            >
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="labelsBarra">Telefone: </Text>
                      {props.telefone.map((tel, i) => {
                        return (
                          <React.Fragment key={`tel_${i}`}>
                            <Text key={tel} className="telefone">
                              {maskPhoneSymbol(tel)}
                            </Text>
                            {i < props.telefone.length - 1 && (
                              <strong> - </strong>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                    <Col>
                      <Text className="labelsBarra">UBS: </Text>
                      <Text>{props.ubs}</Text>
                    </Col>
                  </>
                )}
              </Col>
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="wearables-labels">
                        <Badge
                          color="#D9D9D9"
                          text={
                            <>
                              <i>Wearable</i>{" "}
                              {props.wearable ? "ativo" : "inativo"}
                            </>
                          }
                        />
                      </Text>
                    </Col>
                  </>
                )}
              </Col>
              <Col>
                <Button onClick={() => history.push("/painel")} type="primary">
                  Pausar atendimento
                </Button>
              </Col>
            </Row>
          </>
        );
      default:
        return (
          <>
            <Row
              type="flex"
              className="barra-layout-rotina"
              align="middle"
              justify="space-between"
            >
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="labelsBarra">Telefone: </Text>
                      {props.telefone.map((tel, i) => {
                        return (
                          <React.Fragment key={`tel_${i}`}>
                            <Text key={tel} className="telefone">
                              {maskPhoneSymbol(tel)}
                            </Text>
                            {i < props.telefone.length - 1 && (
                              <strong> - </strong>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Col>
                    <Col>
                      <Text className="labelsBarra">Sexo: </Text>
                      <Text>{SEXO[props.sexo]}</Text>
                    </Col>
                    <Col>
                      <Text className="labelsBarra">UBS: </Text>
                      <Text>{props.ubs}</Text>
                    </Col>
                  </>
                )}
              </Col>
              <Col>
                {props.loading ? (
                  <Icon type="loading" />
                ) : (
                  <>
                    <Col>
                      <Text className="labelsBarra">Peso: </Text>
                      <Text>{props.peso} Kg</Text>
                    </Col>
                    <Col>
                      <Text className="labelsBarra">IMC: </Text>
                      <Text>{props.imc.toString().replace(".", ",")}</Text>
                    </Col>
                  </>
                )}
              </Col>
              {!!props.doencas && (
                <>
                  <Col>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Col>
                          {props.doencas[0] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[0]}
                            />
                          )}
                        </Col>

                        <Col>
                          {props.doencas[3] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[3]}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Col>
                          {props.doencas[1] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[1]}
                            />
                          )}
                        </Col>

                        <Col>
                          {props.doencas[4] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[4]}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Col>
                  <Col>
                    {props.loading ? (
                      <Icon type="loading" />
                    ) : (
                      <>
                        <Col>
                          {props.doencas[2] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[2]}
                            />
                          )}
                        </Col>

                        <Col>
                          {props.doencas[5] && (
                            <Badge
                              className="labelsBarra"
                              status="processing"
                              text={props.doencas[5]}
                            />
                          )}
                        </Col>
                      </>
                    )}
                  </Col>
                </>
              )}
            </Row>
          </>
        );
    }
  };

  return (
    <>
      {verificarTipoBarra(props.tipoTarefa)}
      <CancelarModal
        visible={cancelarModal}
        onOk={handleCancelar}
        confirmLoading={false}
        onCancel={() => setCancelarModal(false)}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  nome: store.barrainformacao.nome,
  ubs: store.barrainformacao.ubs,
  email: store.barrainformacao.email,
  peso: store.barrainformacao.peso,
  sexo: store.barrainformacao.sexo,
  imc: store.barrainformacao.imc,
  urgencia: store.barrainformacao.urgencia,
  wearable: store.barrainformacao.wearable,
  doencas: store.barrainformacao.doencas,
  telefone: store.barrainformacao.telefone,
  loading: store.barrainformacao.loading,
  step: store.adesao.step,
  infopessoal: store.informacaopessoal,
  tipoTarefa: store.barrainformacao.tipoTarefa,
});

export default connect(mapStateToProps, null)(withRouter(BarraInformacao));
