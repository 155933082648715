/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import * as actionsHeader from "Components/Header/action";
import './style.scss';

const Home = ({changeCabecalhoInfo}) => {

    useEffect(() => {
        changeCabecalhoInfo({
            nome: "Home",
            criticidade: null,
            tipoTarefa: null
          });
    }, []);

    return <div className='home-page' />
}

const mapDispatchToProps = dispatch => ({   
    changeCabecalhoInfo: info =>
      dispatch(actionsHeader.changeCabecalhoInfo(info)),
  });


export default connect(null, mapDispatchToProps)(Home);
