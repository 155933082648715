import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

const Gauge = ({data, status, index}) => {
const newData = data.map((item) => {
    return [
        { value: item.valor },
        { value: item.total - item.valor }
    ]
})
  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      payload,
    } = props;

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={status} className="indicator">
          {payload.value}
        </text>
        <text x={cx} y={cy + 30} dy={8} textAnchor="middle" fill="black" className="indicator-legend">
          {data[index].legenda}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={status}
        />
      </g>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={180}>
      <PieChart >
        <Pie
          startAngle={180}
          endAngle={0}
          activeIndex={0}
          activeShape={renderActiveShape}
          data={newData[index]}
          cx="50%"
          cy="60%"
          innerRadius={50}
          outerRadius={90}
          fill="#8884d8"
          dataKey="value"
        >
          <Cell fill={status} />
          <Cell fill="#eaeaea" />
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Gauge;
