import { Button, Popconfirm, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../action";
import TarefasNaoFarmacologicasModal from "../TarefasNaoFarmacologicasModal";
const { Text } = Typography;

const TarefaNaoFarmacologica = (props) => {
  const { rotina, readOnly } = props;
  const [cancelarModal, setCancelarModal] = useState(false);
  const [tarefaID, setTarefaID] = useState("");

  useEffect(() => {
    if (props.paciente) props.getTarefaNFListaPaciente(props.paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);

  const columns = !readOnly
    ? [
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Tipo tarefa
            </Text>
          ),
          dataIndex: "tipo",
        },
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Frequencia
            </Text>
          ),
          dataIndex: "frequencia",
        },
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Horário
            </Text>
          ),
          dataIndex: "horario",
        },
        {
          title: "",
          dataIndex: "deleteAction",
        },
      ]
    : [
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Tipo tarefa
            </Text>
          ),
          dataIndex: "tipo",
        },
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Frequencia
            </Text>
          ),
          dataIndex: "frequencia",
        },
        {
          title: (
            <Text
              className="cadastro-section-title"
              style={{ fontSize: "14px" }}
            >
              Horário
            </Text>
          ),
          dataIndex: "horario",
        },
      ];

  const modal = () => {
    return (
      <TarefasNaoFarmacologicasModal
        title="Adicionar Tarefa não Farmacológica"
        visible={cancelarModal}
        onOk={handleConfirmar}
        confirmLoading={false}
        tarefaID={tarefaID}
        onCancel={handleCancelar}
      />
    );
  };

  const handleOpenModal = async (id, tipo) => {
    if (id) {
      props.changeTarefaNF({ id: id, tipo: tipo });
      setTarefaID(id);
      await props.getTarefaNFDetalhesPaciente(props.paciente, id);
    }
    setCancelarModal(true);
  };

  const handleCancelar = async (value) => {
    setTarefaID("");
    setCancelarModal(false);
  };

  const handleConfirmar = async (value) => {
    setTarefaID("");
    setCancelarModal(false);
    await props.getTarefaNFListaPaciente(props.paciente);
  };

  const handleExcluir = async (id) => {
    await props.excluiTarefaNF(props.paciente, id);
    await props.getTarefaNFListaPaciente(props.paciente);
  };
  return (
    <>
      {modal()}
      {!rotina && (
        <div className="cadastro-object-title">
          <Tag color="orange">NOVO</Tag>
          <Text className="cadastro-section-title">
            Tarefas não farmacológicas
          </Text>
        </div>
      )}
      <div className="spacing-sections">
        {!rotina && (
          <Text className="cadastro-section-description">
            Quais tarefas não farmacológicas o idoso realiza?
          </Text>
        )}
        {!readOnly && (
          <div
            style={{
              display: "flex",
              width: "40%",
              margin: rotina ? "0 0 1vw" : "1vw 0",
            }}
          >
            <Button
              className="buttonFooterBack"
              onClick={() => handleOpenModal()}
            >
              Adicionar tarefa não farmacológica
            </Button>
          </div>
        )}
        {props.tarefasNFLista?.length > 0 && (
          <Table
            pagination={false}
            columns={columns}
            style={{ backgroundColor: "#fff", marginBottom: 60 }}
            dataSource={props.tarefasNFLista.map((item) => {
              return {
                key: item.id,
                tipo: (
                  <span
                    style={{ cursor: "pointer", color: "#40a9ff" }}
                    onClick={() =>
                      !readOnly && handleOpenModal(item.idPlanoTarefa, item.nome)
                    }
                  >
                    {item.nome[0].toUpperCase()}
                    {item.nome.slice(1)}
                  </span>
                ),
                frequencia: item.frequencia.nome,
                horario: item.horarios.map((itemHorario, index) => {
                  return (
                    <span>
                      {item.horarios.length - 1 === index
                        ? itemHorario
                        : `${itemHorario}, `}
                    </span>
                  );
                }),
                deleteAction: (
                  <Popconfirm
                    title="Tem certeza?"
                    onConfirm={() => handleExcluir(item.idPlanoTarefa)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <Button type="link">Excluir</Button>
                  </Popconfirm>
                ),
              };
            })}
            size="small"
          />
        )}
      </div>
      {!!rotina && !readOnly && (
        <div className="footer-save-button" style={{ padding: "14px" }}>
          <span className="affix-title" style={{ color: "#888888" }}>
            Alterações nas tarefas não farmacológicas são salvas
            automaticamente.
          </span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  tarefasNFLista: store.acompanhamento.tarefasNFLista,
  paciente: store.adesao.paciente,
});

const mapDispatchToProps = (dispatch) => ({
  getTarefaNFListaPaciente: (pacienteID) =>
    dispatch(actions.getTarefaNFListaPaciente(pacienteID)),
  getTarefaNFDetalhesPaciente: (pacienteID, tarefaID) =>
    dispatch(actions.getTarefaNFDetalhesPaciente(pacienteID, tarefaID)),
  excluiTarefaNF: (pacienteID, tarefaId) =>
    dispatch(actions.excluiTarefaNF(pacienteID, tarefaId)),
  changeTarefaNF: (tarefa) => dispatch(actions.changeTarefaNF(tarefa)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TarefaNaoFarmacologica);
