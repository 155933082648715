/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { Table, Popover, Icon, Divider } from "antd";
import { abrirAtendimento } from "../service";
import { useHistory } from "react-router-dom";
import { PERFIS } from "Misc/constants";

const MeusPacientesTabela = ({ pacientesPlano, user }) => {
  const history = useHistory();

  const handleClick = async (id) => {
    const res = await abrirAtendimento(id);

    history.push(`/atendimento/7/${res.data.idTarefa}`);
  };

  const columns = [
    {
      title: "Nome do Paciente",
      dataIndex: "nomePaciente",
      key: "nomePaciente",
    },
    {
      title: "Telefone",
      dataIndex: "telefonePrincipal",
      key: "telefonePrincipal",
    },
    {
      title: "Cuidador Principal",
      dataIndex: "nomeCuidador",
      key: "nomeCuidador",
    },
    {
      title: "Telefone Cuidador",
      dataIndex: "telefoneCuidador",
      key: "telefoneCuidador",
    },
    {
      title: "Data/Hora (última ligação periódica)",
      dataIndex: "ultimaLigacao",
      key: "ultimaLigacao",
    },
    {
      title: "Ações",
      dataIndex: "acoes",
      key: "acoes",
      render: (i, record) => (
        <Popover
          placement="right"
          content={
            <>
              <span
                className="popover-menu-item"
                onClick={() => history.push(`/pacientes/${record.idPaciente}`)}
              >
                <Icon style={{ color: "#0F79B2" }} type="audit" /> Consultar
                dados
              </span>
              {user.perfil === PERFIS.ENFERMEIRO_VIVER && (
                <>
                  {" "}
                  <Divider style={{ margin: 4 }} />
                  <span
                    className="popover-menu-item"
                    onClick={() => handleClick(record.idPaciente)}
                  >
                    <Icon style={{ color: "#138183" }} type="play-circle" />{" "}
                    Iniciar atendimento solicitado
                  </span>
                </>
              )}
            </>
          }
        >
          <Icon type="more" className="icon-more" />
        </Popover>
      ),
    },
  ];
  return <Table columns={columns} dataSource={pacientesPlano} size="small" />;
};

const mapStateToProps = (store) => ({
  pacientesPlano: store.meuspacientes.pacientesPlano?.pacientes,
  user: store.login.user,
});

export default connect(mapStateToProps)(MeusPacientesTabela);
