import React, { useEffect, useState } from "react";
import { Typography, Divider, Button, Form, Spin, message, Alert } from "antd";
import Medicamentos from "./Medicamentos";
import TarefasNaoFarmacologicas from "./TarefasNaoFarmacologicas";
import ParametroSaude from "./ParametroSaude";
import LigacaoPeriodica from "./LigacaoPeriodica";
import OutraInformacao from "./OutraInformacao";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "./action";
import { unsavedFields } from "Pages/Rotina/action";
import { SCROLL_CONFIG } from "Misc/constants";
import { mapSubitem, sendSuccessMsg } from "Misc/utils";

import "./style.scss";

const { Text } = Typography;

const Acompanhamento = (props) => {
  const {
    form: { validateFieldsAndScroll },
    rotina,
    title,
    saude,
    paciente,
    getMedicamentoListaPaciente,
    getTarefaNFListaPaciente,
    getAcompanhamento,
    badge_ligacoes,
    badge_parametroSaude,
    wearable,
    readOnly,
    medicamentoLoading,
  } = props;

  useEffect(() => {
    //buscar medicamento()
    if (!!rotina && !!paciente) {
      getMedicamentoListaPaciente(paciente);
      getTarefaNFListaPaciente(paciente);
      getAcompanhamento(paciente);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paciente]);

  //GECRO-1335
  const beforeValidate = (parametros) => {
    let validate = true;
    parametros.forEach(function (parametros, i) {
      switch (parametros.id) {
        case "1":
          if (parametros.selecionado) {
            if (
              (!parametros.subitens[2].valoresDesejaveis[0] &&
                parametros.subitens[3].valoresDesejaveis[0]) ||
              (!parametros.subitens[3].valoresDesejaveis[0] &&
                parametros.subitens[2].valoresDesejaveis[0])
            ) {
              message.error(
                "Pressão Arterial: É necessário informar os dois valores desejáveis."
              );
              validate = false;
            }
          }
          break;

        case "2":
          if (parametros.selecionado) {
            if (
              (!parametros.subitens[2].valoresDesejaveis[0] &&
                parametros.subitens[2].valoresDesejaveis[1]) ||
              (!parametros.subitens[2].valoresDesejaveis[1] &&
                parametros.subitens[2].valoresDesejaveis[0])
            ) {
              message.error(
                "Glicemia: É necessário informar os dois valores desejáveis."
              );
              validate = false;
            }
          }
          break;

        case "3":
          if (parametros.selecionado) {
            if (
              (!parametros.subitens[2].valoresDesejaveis[0] &&
                parametros.subitens[2].valoresDesejaveis[1]) ||
              (!parametros.subitens[2].valoresDesejaveis[1] &&
                parametros.subitens[2].valoresDesejaveis[0])
            ) {
              message.error(
                "Frequência cardíaca: É necessário informar os dois valores desejáveis."
              );
              validate = false;
            }
          }
          break;

        default:
          break;
      }
    });
    return validate;
  };

  const [erroParametro, setErroParametro] = useState()

  const validateInput = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const newValue = { ...values };
        const { horario } = values;
        const newHorario = horario;

        newValue.horario = newHorario;
        const paramentroSaude = { parametros: null };
        const ligacaoPeriodica = {
          enfermeiroResponsavel: { ...values.enfermeiroResponsavel },
          enfermeiroSubstituto: { ...values.enfermeiroSubstituto },
          periodicidade: { ...values.periodicidade },
          horario: newHorario,
        };
        const outrasInformacoes = values.outrasInformacoes;

        let parametros = Object.keys(newValue.saude_checkbox).map((el) => {
          const newId = +el === 7 ? "6" : (+el === 6 ? "5" : (+el === 5 ? "7" : el.toString()))
          if (!newValue.saude_checkbox[el]){
            return { selecionado: newValue.saude_checkbox[el], id: newId };
          }
          else if (+el === 6 || +el === 7) {
            return { selecionado: newValue.saude_checkbox[el], id: newId };
          }
          else {
            return {
              selecionado: newValue.saude_checkbox[el],
              id: newId,
              horarios: Object.values(newValue.parametrosSaude[newId].horarios),
              periodicidade: newValue.parametrosSaude[newId].periodicidade,
              subitens: mapSubitem(newValue, newId),
            };
          }
        });
        parametros = parametros.map((parametro) => {
          const subitens = parametro.subitens?.map((subitem) => {
            const valores = subitem.valores?.map((valor) => valor.replace(",", "."))
            const valoresDesejaveis = subitem.valoresDesejaveis?.map((valor) => valor?.replace(",", "."))
            return {
              ...subitem,
              ...(valores && { valores }),
              ...(valoresDesejaveis && { valoresDesejaveis })
            }
            
          })
          return {...parametro, ...(subitens && {subitens})}
        })
        if (beforeValidate(parametros)) {
          paramentroSaude.parametros = parametros;
          props
            .atualizarAcompanhamento(
              props.paciente,
              paramentroSaude,
              ligacaoPeriodica,
              values.wearable,
              values.idWearableModelo,
              outrasInformacoes
            )
            .then((res) => {
              props.nextStep(props.step);
            });
        }
      } else {
        setErroParametro(err)
      }
    });
  };

  const saveTab = (saude) => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        if (saude) {
          const newValue = { ...values };
          const paramentroSaude = { parametros: null };          

          let parametros = Object.keys(newValue.saude_checkbox).map((el) => {
            const newId = +el === 7 ? "6" : (+el === 6 ? "5" : (+el === 5 ? "7" : el.toString()))

            if (!newValue.saude_checkbox[el]){
              return { selecionado: newValue.saude_checkbox[el], id: newId };
            }
            else if (+el === 6 || +el === 7) {
              return { selecionado: newValue.saude_checkbox[el], id: newId };
            }
            else {
              return {
                selecionado: newValue.saude_checkbox[el],
                id: newId,
                horarios: Object.values(newValue.parametrosSaude[newId].horarios),
                periodicidade: newValue.parametrosSaude[newId].periodicidade,
                subitens: mapSubitem(newValue, newId),
              };
            }
          });
          parametros = parametros.map((parametro) => {
            const subitens = parametro.subitens?.map((subitem) => {
              const valores = subitem.valores?.map((valor) => valor.replace(",", "."))
              const valoresDesejaveis = subitem.valoresDesejaveis?.map((valor) => valor?.replace(",", "."))
              return {
                ...subitem,
                ...(valores && { valores }),
                ...(valoresDesejaveis && { valoresDesejaveis })
              }
              
            })
            return {...parametro, ...(subitens && {subitens})}
          })
          if (beforeValidate(parametros)) {
            paramentroSaude.parametros = parametros;
            props.attParametros(
              JSON.stringify({
                token: "gecro",
                wearable: values.wearable,
                paciente: props.paciente,
                parametrosSaude: paramentroSaude,
                idWearableModelo: values.idWearableModelo,
              })
            ).then(() => sendSuccessMsg("Parâmetros de saúde atualizados com sucesso."));
          }
        } else {
          const ligacaoPeriodica = {
            enfermeiroResponsavel: { ...values.enfermeiroResponsavel },
            enfermeiroSubstituto: { ...values.enfermeiroSubstituto },
            periodicidade: { ...values.periodicidade },
            horario: values.horario,
          };

          props.attLigacao(
            JSON.stringify({
              token: "gecro",
              paciente: props.paciente,
              ligacaoPeriodica,
            })
          );
        }
      } else {
        setErroParametro(err)
        console.error("Erro:", err);
      }
    });
  };

  if (!!rotina) {
    return (
      <>
        {medicamentoLoading ? (
          <div className="container-loading">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {!!saude && wearable !== null && (
              <Form style={{ width: "100%", marginBottom: "100px" }}>
                <ParametroSaude form={props.form} rotina readOnly={readOnly} erroParametro={erroParametro} />
              </Form>
            )}
            {!saude && wearable !== null && (
              <LigacaoPeriodica form={props.form} rotina readOnly={readOnly} />
            )}

            {!medicamentoLoading && wearable === null && (
              <Alert
                message="Ocorreu uma falha"
                description="Não foi possível encontrar todas as informações solicitadas"
                type="error"
              />
            )}
          </>
        )}
        {!readOnly && (
          <div className="footer-save-button">
            <Button
              className="buttonFooterNext"
              onClick={() => saveTab(saude)}
              disabled={
                (!!saude && !badge_parametroSaude) ||
                (!saude && !badge_ligacoes)
              }
              type="primary"
            >
              Salvar alterações
            </Button>
            <span className="affix-title">{title}</span>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <Text>Acrescente informações para o acompanhamento do idoso</Text>
        <Divider className="cadastro-divider" />
        <Medicamentos />
        <Divider className="cadastro-divider" />
        <Form style={{ width: "100%" }} colon={false} hideRequiredMark>
          <ParametroSaude form={props.form} erroParametro={erroParametro} />
          <Divider className="cadastro-divider" />
          <TarefasNaoFarmacologicas />
          <Divider className="cadastro-divider" />
          <LigacaoPeriodica form={props.form} />
          <Divider className="cadastro-divider" />
          <OutraInformacao form={props.form} />
        </Form>

        <Divider className="cadastro-divider" />
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            className="buttonFooterNext"
            style={{ fontSize: "15px" }}
            onClick={(e) => validateInput(e)}
            type="primary"
          >
            Gerar plano de cuidados
          </Button>
          <Button
            onClick={() => props.backStep(props.step)}
            className="buttonFooterBack"
            style={
              props.step > 1
                ? { opacity: 1 }
                : { opacity: 0, pointerEvents: "none" }
            }
          >
            Voltar
          </Button>
        </div>
      </>
    );
  }
};

const mapStateToProps = (store) => ({
  step: store.adesao.step,
  enfermeiros: store.acompanhamento.enfermeiros,
  wearable: store.acompanhamento.wearable,
  plano: store.acompanhamento.plano,
  paciente: store.adesao.paciente,
  badge_parametroSaude: store.rotina.unsaved.parametroSaude,
  badge_ligacoes: store.rotina.unsaved.ligacoes,
  medicamentoLoading: store.acompanhamento.medicamentoLoading,
  modelos: store.acompanhamento.modelos,
  idWearableModelo: store.acompanhamento.idWearableModelo,
});

const mapDispatchToProps = (dispatch) => ({
  atualizarAcompanhamento: (
    pacienteID,
    parametroSaude,
    ligacaoPeriodica,
    wearable,
    idWearableModelo,
    outrasInformacoes
  ) =>
    dispatch(
      actions.atualizarAcompanhamento(
        pacienteID,
        parametroSaude,
        ligacaoPeriodica,
        wearable,
        idWearableModelo,
        outrasInformacoes
      )
    ),
  getAcompanhamento: (pacienteID) =>
    dispatch(actions.getAcompanhamento(pacienteID)),
  getMedicamentoListaPaciente: (pacienteID) =>
    dispatch(actions.getMedicamentoListaPaciente(pacienteID)),
  getTarefaNFListaPaciente: (pacienteID) =>
    dispatch(actions.getTarefaNFListaPaciente(pacienteID)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
  attParametros: (body) => dispatch(actions.atualizarParametrosSaude(body)),
  attLigacao: (body) => dispatch(actions.atualizarLigacaoPeriodica(body)),
});

const handleChange = (props, changed) => {
  const key = Object.keys(changed);
  if (
    key.includes("parametrosSaude") ||
    key.includes("saude_checkbox") ||
    key.includes("wearable") ||
    key.includes("idWearableModelo") 
  ) {
    props.unsavedFields({ parametroSaude: true });
  } else {
    props.unsavedFields({ ligacoes: true });
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "acompanhamento_fields",
    onValuesChange: _.debounce(handleChange, 300),
  })(Acompanhamento)
);
