import React from "react";
import { Form } from "antd";
import CollapsedGroup from "./CollapsedGroup";
import QuestionFollowUp from "./QuestionFollowUp";
import InformationFollowUp from "./InformationFollowUp";

const GroupFollowUp = ({
  form,
  perguntas = [],
  informacoes = [],
  required,
  ...rest
}) => (
  <CollapsedGroup {...rest}>
    <Form>
      {perguntas.map((pergunta, i) => (
        <QuestionFollowUp
          key={`pergunta_${i}`}
          pergunta={pergunta}
          required={required}
          form={form}
        />
      ))}
    </Form>
    <div>
      {informacoes.map((info, i) => (
        <InformationFollowUp key={`info_${i}`} info={info} />
      ))}
    </div>
  </CollapsedGroup>
);

export default GroupFollowUp;
