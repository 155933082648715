import React, { useEffect } from "react";
import DadosPessoais from "./DadosPessoais";
import "./style.scss";
import Endereco from "../Endereco";
import Telefone from "../Telefone";
import { Typography, Form, Radio, Divider, Button } from "antd";
import * as actions from "./actions";
import { getInfoCabecalho } from "../../Pages/Adesao/action";
import { unsavedFields } from "../../Pages/Rotina/action";
import _ from "lodash";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { SCROLL_CONFIG } from "Misc/constants";
import { sendSuccessMsg } from "Misc/utils";

const { Text, Paragraph } = Typography;

const InformacaoPessoal = (props) => {
  const { tarefa, tipo } = useParams();
  useEffect(() => {
    if (props.paciente) {
      props.getInfo(props.paciente);
      props.getFotoPaciente(props.paciente)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);

  const {
    form: {
      getFieldDecorator,
      resetFields,
      validateFieldsAndScroll,
      getFieldValue,
    },
    infoPessoal,
    readOnly,
  } = props;

  const isRequired = true;

  const validateInput = (e) => {
    e.preventDefault();

    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        props.atualizarInfoPessoal(
          values,
          props.paciente,
          tarefa,
          props.step,
          tipo
        );
      } else {
        console.error("Erro INFO PESSOAL");
      }
    });
  };

  const saveTab = (e) => {
    const { atualizarInfoPessoal, paciente, step } = props;
    e.preventDefault();

    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        atualizarInfoPessoal(values, paciente, tarefa, step, tipo).then(() => sendSuccessMsg("Informações atualizadas com sucesso."));
      } else {
        console.error("Erro INFO PESSOAL");
      }
    });
  };

  const formItemLayout = !!props.rotina
    ? {
        labelCol: { span: 12, xl: 8, xxl: 6 },
        wrapperCol: { span: 12, xl: 14 },
      }
    : {};
  if (props.paciente)
    return (
      <div style={!!props.rotina ? { marginBottom: "60px" } : {}}>
        {!props.rotina && (
          <>
            <div className="marginbottomTextInitial">
              {tipo === "2" ? (
                <Text>Quem está fazendo o cadastro?</Text>
              ) : (
                <Text>Confirme as informações</Text>
              )}
            </div>
            {tipo !== "1" && tipo !== "8" && (
              <Form.Item>
                {getFieldDecorator("idosoFezCadastro", {
                  rules: [
                    { required: isRequired, message: "Escolha uma opção." },
                  ],
                  initialValue: props.idosoFezCadastro,
                })(
                  <Radio.Group
                    size="large"
                    buttonStyle="solid"
                    disabled={readOnly}
                  >
                    <Radio.Button value={true}>O idoso</Radio.Button>
                    <Radio.Button value={false}>Outra pessoa</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            )}
            <Divider className="cadastro-divider" />
            {tipo === "1" ||
              (tipo === "8" && (
                <Paragraph style={{ fontSize: "14px", marginBottom: "2em" }}>
                  Fez o cadastro:{" "}
                  <strong>
                    {props.idosoFezCadastro ? "Paciente" : "Outra pessoa"}
                  </strong>
                </Paragraph>
              ))}
          </>
        )}

        <DadosPessoais
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
          isRequired={isRequired}
          formItemLayout={formItemLayout}
          titulo="Dados pessoais do Idoso"
          subtitulo="As informações serão usadas para criar um plano de cuidados personalizado para o idoso."
          tipo={tipo}
          readOnly={readOnly}
          {...props}
        />
        {!props.rotina && <Divider className="cadastro-divider" />}
        <Endereco
          getFieldDecorator={getFieldDecorator}
          decoratorCep="cep"
          decoratorRua="logradouro"
          decoratorComplemento="complemento"
          decoratorNumero="numero"
          decoratorBairro="bairro"
          decoratorEstado="uf"
          decoratorCidade="cidade"
          titulo="Endereço do idoso"
          subtitulo="Saber o endereço é importante em casos de emergências."
          isRequired={isRequired}
          rotina={props.rotina}
          resetFields={resetFields}
          formItemLayout={formItemLayout}
          readOnly={readOnly}
          {...props.endereco}
        />
        {!props.rotina && <Divider className="cadastro-divider" />}
        <Telefone
          getFieldDecorator={getFieldDecorator}
          isRequired={isRequired}
          titulo="Telefone do Idoso"
          readOnly={readOnly}
          {...props}
        />
        {!props.rotina && <Divider className="cadastro-divider" />}

        {!props.rotina ? (
          <div style={{ display: "flex", width: "40%" }}>
            <Button
              className="buttonFooterNext"
              onClick={(event) => validateInput(event)}
              type="primary"
            >
              Próximo
            </Button>
            <Button
              onClick={() => props.backStep(props.step)}
              type="primary"
              style={
                props.step > 1
                  ? { opacity: 1 }
                  : { opacity: 0, pointerEvents: "none" }
              }
            >
              Voltar
            </Button>
          </div>
        ) : (
          !readOnly && (
            <div className="footer-save-button">
              <Button
                className="buttonFooterNext"
                onClick={(event) => saveTab(event)}
                disabled={!infoPessoal}
                type="primary"
              >
                Salvar alterações
              </Button>
              <span className="affix-title">{props.title}</span>
            </div>
          )
        )}
      </div>
    );
  else return null;
};

const mapStateToProps = (store) => ({
  infopessoal: store.informacaopessoal,
  idosoFezCadastro: store.informacaopessoal.idosoFezCadastro,
  grauParentesco: store.informacaopessoal.grauParentesco,
  nome: store.informacaopessoal.nome,
  email: store.informacaopessoal.email,
  ncto: store.informacaopessoal.ncto,
  sexo: store.informacaopessoal.sexo,
  peso: store.informacaopessoal.peso,
  altura: store.informacaopessoal.altura,
  sozinho: store.informacaopessoal.sozinho,
  telefone: store.informacaopessoal.telefone,
  telefoneOpcional: store.informacaopessoal.telefoneOpcional,
  tech: store.informacaopessoal.tech,
  cpf: store.informacaopessoal.cpf,
  endereco: store.informacaopessoal.endereco,
  paciente: store.adesao.paciente,
  step: store.adesao.step,
  infoPessoal: store.rotina.unsaved.infoPessoal,
  foto_perfil: store.informacaopessoal.foto_perfil
});

const mapDispatchToProps = (dispatch) => ({
  getInfo: (pacienteID) => dispatch(actions.getInfo(pacienteID)),
  getFotoPaciente: (pacienteID) => dispatch(actions.getFotoPaciente(pacienteID)),
  editInfo: (paciente) => dispatch(actions.editInfo(paciente)),
  getInfoCabecalho: (enfermeiroID, tarefaID) =>
    dispatch(getInfoCabecalho(enfermeiroID, tarefaID)),
  atualizarInfoPessoal: (paciente, pacienteID, tarefa, step) =>
    dispatch(actions.atualizarInfoPessoal(paciente, pacienteID, tarefa, step)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
});

const handleChange = (props) => {
  props.unsavedFields({ infoPessoal: true });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "info_pessoal",
    onValuesChange: _.debounce(handleChange, 500),
  })(InformacaoPessoal)
);
