import * as types from "./actionType";
import { cancelarAdesao } from "../../Pages/Adesao/service";
import {sendErrorMsg} from 'Misc/utils'

export const changeBarraInfo = info => async dispatch => {
  let newinfo = info;
  if (info.nome) {
  } else {
    newinfo.nome = info.criticidade;
  }

  dispatch({ type: types.EDIT_BARRA_COMPLETE, payload: newinfo });
};

export const cancelaAdesao = (
  enfermeiroID,
  tarefa,
  motivo
) => async dispatch => {
  try {
    dispatch({ type: types.CANCELA_ADESAO });
    const response = await cancelarAdesao(enfermeiroID, tarefa, motivo);
    dispatch({ type: types.CANCELOU_ADESAO });
    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.CANCELA_ADESAO_ERRO });
  }
};
