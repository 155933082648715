import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import * as actions from "../action";
import BasicModal from "Components/BasicModal";
import { maskDate, isValidDate } from "Misc/utils";
import { DATE_FORMAT, SCROLL_CONFIG } from "Misc/constants";

const { TextArea } = Input;

function ModalDesistencia({
  filtro,
  modal,
  modalType,
  modalLoading,
  form,
  handleCloseModal,
  dados,
  suspenderMonitoramento,
  cancelarMonitoramento,
}) {
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const isRequired = true;

  const formLayout = {
    labelCol: {
      xs: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 17 },
    },
  };

  const dateLayout = {
    wrapperCol: {
      xs: { span: 8 },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        if (modalType === "cancelar") {
          cancelarMonitoramento(
            JSON.stringify({ paciente: dados.paciente, ...values }),
            filtro
          );
        } else if (modalType === "suspender") {
          suspenderMonitoramento(
            JSON.stringify({ paciente: dados.paciente, ...values }),
            filtro
          );
        }
      }
    });
  };

  const titleModal = {
    cancelar: "Cancelar monitoramento",
    suspender: "Suspender monitoramento",
  };

  return (
    <BasicModal
      title={<span className="modal-title">{titleModal[modalType]}</span>}
      visible={modal}
      onClose={handleCloseModal}
      destroyOnClose
      bodyStyle={{}}
      footer={[
        <Button
          className="secondary-btn"
          key="back"
          onClick={handleCloseModal}
          disabled={modalLoading}
        >
          Cancelar
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={modalLoading}
        >
          Confirmar
        </Button>,
      ]}
    >
      <Form layout="horizontal" hideRequiredMark {...formLayout}>
        <Form.Item label="Data" {...dateLayout}>
          {getFieldDecorator("data", {
            rules: [
              {
                required: isRequired,
                message: "Campo obrigatório.",
              },
              {
                pattern: DATE_FORMAT,
                validator: isValidDate,
                message: "Insira uma data válida.",
              },
            ],
            getValueFromEvent: (e) => maskDate(e.target.value),
          })(<Input maxLength={10} allowClear />)}
        </Form.Item>

        <Form.Item label="Motivo">
          {getFieldDecorator("motivo", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: "",
          })(<TextArea rows={4} />)}
        </Form.Item>
      </Form>
    </BasicModal>
  );
}

const mapStateToProps = (store) => ({
  modal: store.pacientes.modalDesistencia.open,
  modalType: store.pacientes.modalDesistencia.type,
  modalLoading: store.pacientes.modalLoading,
  filtro: store.pacientes.filtro,
  dados: store.pacientes.dados,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(actions.closeModal()),
  suspenderMonitoramento: (body, filtro) =>
    dispatch(actions.suspenderMonitoramento(body, filtro)),
  cancelarMonitoramento: (body, filtro) =>
    dispatch(actions.cancelarMonitoramento(body, filtro)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "cancelar_suspender_monitoramento",
  })(ModalDesistencia)
);
