import api from "../../api";

export const logar = async (emai, senha) => {
  const res = await api.post("/auth/login", {
    email: emai,
    senha: senha,
    plat: "B",
  });

  return res.data;
};
