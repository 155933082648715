import React, { useEffect } from "react";
import { Result, Layout } from "antd";
import PainelAtividades from "../Pages/PainelAtividades";
import MeusPacientes from "../Pages/MeusPacientes";
import Adesao from "../Pages/Adesao";
import Login from "../Components/Login";
import { Route, Switch, Link } from "react-router-dom";
import BoasVindas from "Pages/BoasVindas";
import Rotina from "Pages/Rotina";
import Urgencia from "Pages/Urgencia";
import AtendimentoSolicitado from "Pages/AtendimentoSolicitado";
import MedicoesIncomuns from "Pages/MedicoesIncomuns";
import Ausencia from "Pages/Ausencia";
import Pacientes from "Pages/Pacientes";
import Dashboard from "Pages/Dashboard";
import Home from "Pages/Home";
import Atendentes from "Pages/Atendentes";
import ProcessosInflamatorios from "Pages/ProcessosInflamatorios";
import SideBar from "Components/SideBar";
import HeaderBackoffice from "Components/Header";
import { connect } from "react-redux";
import * as loginActions from "Components/Login/action";
import BarLoader from "react-spinners/BarLoader";
import logo from "Assets/Images/logo.png";
import { isAdmin } from "Misc/utils";
import { PERFIS } from "Misc/constants";
import DesvioPadrao from "Pages/DesvioPadrao";

const { Header, Content } = Layout;

const Routes = ({ user, saveUser, logOut }) => {
  useEffect(() => {
    if (localStorage.getItem("user")) {
      const token = localStorage.getItem("user");
      saveUser(token);
    } else {
      console.info("Faça login novamente!");
      logOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  switch (user) {
    case undefined:
      return <LogoPage />;
    case false:
      return (
        <TitleRoute path="*" title="Entrar no Sistema - Viver360">
          <Login />
        </TitleRoute>
      );
    default:
      return (
        <>
          <SideBar />
          <Layout className="layout">
            <Header className="layout-header">
              <HeaderBackoffice />
            </Header>

            <Content className="layout-content">
              <Switch>
                <TitleRoute exact path="/" title="Home - Viver360">
                  <Home />
                </TitleRoute>
                {user?.perfil !== PERFIS.ADMIN_VIVER && (
                  <TitleRoute
                    exact
                    path="/painel"
                    title="Painel de Atendimento - Viver360"
                  >
                    <PainelAtividades />
                  </TitleRoute>
                )}

                {user?.perfil !== PERFIS.ADMIN_VIVER && (
                  <TitleRoute
                    path="/meuspacientes"
                    title="Plano de Cuidados - Viver360"
                  >
                    <MeusPacientes />
                  </TitleRoute>
                )}

                {user?.perfil === PERFIS.ADMIN_VIVER && (
                  <PrivateTitleRoute
                    exact
                    path="/equipesaude"
                    title="Equipe de Saúde - Viver360"
                    perfil={user?.perfil}
                    pageName="Painel"
                    pageRedirect="/painel"
                  >
                    <Atendentes />
                  </PrivateTitleRoute>
                )}

                <TitleRoute
                  exact
                  path="/pacientes/:idPaciente?"
                  title="Pacientes - Viver360"
                >
                  <Pacientes />
                </TitleRoute>
                <PrivateTitleRoute
                  path="/dashboard/:tipo?/:filtro?"
                  title="Dashboard - Viver360"
                  perfil={user?.perfil}
                  pageName="Painel"
                  pageRedirect="/painel"
                >
                  <Dashboard />
                </PrivateTitleRoute>

                <TitleRoute
                  path="/adesao/:tipo?/:tarefa?/"
                  title="Adesão - Viver360"
                >
                  <Adesao />
                </TitleRoute>

                <TitleRoute
                  path="/boasvindas/:tipo?/:tarefa?/"
                  title="Boas Vindas - Viver360"
                >
                  <BoasVindas />
                </TitleRoute>

                <TitleRoute
                  path="/rotina/:tipo?/:tarefa?/"
                  title="Ligação de Rotina - Viver360"
                >
                  <Rotina />
                </TitleRoute>

                <TitleRoute
                  path="/urgencia/:tipo?/:tarefa?/"
                  title="Urgência Médica - Viver360"
                >
                  <Urgencia />
                </TitleRoute>

                <TitleRoute
                  path="/ausencia/:tipo?/:tarefa?/"
                  title="Ausencia de Reporte - Viver360"
                >
                  <Ausencia />
                </TitleRoute>

                <TitleRoute
                  path="/atendimento/:tipo?/:tarefa?/"
                  title="Atendimento Solicitado - Viver360"
                >
                  <AtendimentoSolicitado />
                </TitleRoute>
                <TitleRoute
                  path="/desvio-padrao/:tipo?/:tarefa?/"
                  title="Desvio Padrão - Viver360"
                >
                  <DesvioPadrao />
                </TitleRoute>

                <TitleRoute
                  path="/medicoes-incomuns/:tipo?/:tarefa?/"
                  title="Medições incomuns - Viver360"
                >
                  <MedicoesIncomuns />
                </TitleRoute>
              
                <TitleRoute
                  path="/sintomas-inflamatorios/:tipo?/:tarefa?/"
                  title="Sintomas inflamatórios - Viver360"
                >
                  <ProcessosInflamatorios />
                </TitleRoute>

                <TitleRoute
                  path="/reativacao/:tipo?/:tarefa?/"
                  title="Reativar Monitoramento - Viver360"
                >
                  <BoasVindas />
                </TitleRoute>

                <TitleRoute title="Ops, não encontramos essa página - Viver360">
                  <Result
                    status="404"
                    title="404"
                    subTitle="Ops, não encontramos essa página."
                    extra={<Link to="/">Voltar para Home</Link>}
                  />
                </TitleRoute>
              </Switch>
            </Content>
          </Layout>
        </>
      );
  }
};

const LogoPage = (props) => (
  <div className="logo-page">
    <img src={logo} alt="Logo da Viver360 Care Platform" width={450} />
    <BarLoader color={"#0F79B2"} width={400} />
  </div>
);

const TitleRoute = ({
  children,
  title = "Viver360 Care Platform",
  ...props
}) => {
  document.title = title;
  return <Route {...props}>{children}</Route>;
};

const PrivateTitleRoute = ({
  perfil,
  children,
  title = "Viver360 Care Platform",
  pageRedirect = "/",
  pageName = "Home",
  ...props
}) => {
  document.title = title;

  return isAdmin(perfil) ? (
    <Route {...props}>{children}</Route>
  ) : (
    <Route {...props}>
      <Result
        status="403"
        title="Acesso negado"
        subTitle="Desculpe, você não tem acesso a esta página. Qualquer dúvida entrar em contato com o Administrador."
        extra={<Link to={pageRedirect}>Voltar para {pageName}</Link>}
      />
    </Route>
  );
};

const mapStateToProps = (store) => ({
  user: store.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(loginActions.logOut()),
  saveUser: (token) => dispatch(loginActions.saveUser(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
