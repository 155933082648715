import React from "react";

export const NotFound = () => {
  return (
    <svg width="225" height="155" viewBox="0 0 225 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.75">
    <g clip-path="url(#clip0_10693_32364)">
    <path d="M138.748 152.459L137.446 151.798C135.029 150.569 132.999 148.696 131.581 146.387C130.164 144.078 129.413 141.422 129.413 138.714V93.2344H91.5468V138.714C91.5468 141.422 90.7964 144.078 89.3787 146.387C87.9611 148.696 85.9313 150.569 83.514 151.798L82.2126 152.459C81.2943 152.926 81.6269 154.31 82.6574 154.31H138.303C139.333 154.31 139.666 152.926 138.748 152.459Z" fill="#9EC9FF"/>
    <path d="M201.517 13.7344H19.4434C18.1436 13.7344 16.8969 14.25 15.9778 15.1678C15.0586 16.0856 14.5422 17.3304 14.5422 18.6284V120.424C14.5422 121.722 15.0586 122.967 15.9778 123.885C16.8969 124.802 18.1436 125.318 19.4434 125.318H201.517C202.817 125.318 204.064 124.802 204.983 123.885C205.902 122.967 206.419 121.722 206.419 120.424V18.6284C206.419 17.3304 205.902 16.0856 204.983 15.1678C204.064 14.25 202.817 13.7344 201.517 13.7344Z" fill="#0F79B2"/>
    <path d="M206.419 108.125H14.5422V120.426C14.5422 121.724 15.0586 122.968 15.9778 123.886C16.8969 124.804 18.1436 125.32 19.4434 125.32H201.517C202.817 125.32 204.064 124.804 204.983 123.886C205.902 122.968 206.419 121.724 206.419 120.426V108.125ZM21.4522 20.3594H199.509V101.584H21.4522V20.3594Z" fill="#9EC9FF"/>
    <path d="M21.4523 20.3594H199.509V101.584H21.4523V20.3594Z" fill="#E9F8FD"/>
    <path d="M112.39 120.834H108.571C108.052 120.832 107.554 120.625 107.187 120.259C106.819 119.892 106.612 119.395 106.611 118.876V115.063C106.612 114.544 106.819 114.047 107.187 113.681C107.554 113.314 108.052 113.107 108.571 113.105H112.39C112.909 113.107 113.407 113.314 113.774 113.681C114.142 114.047 114.349 114.544 114.35 115.063V118.876C114.349 119.395 114.142 119.892 113.774 120.259C113.407 120.625 112.909 120.832 112.39 120.834Z" fill="#0F79B2"/>
    </g>
    <g clip-path="url(#clip1_10693_32364)">
    <path d="M159.566 117.539L162.789 130.786L134.073 137.981L130.851 124.734L159.566 117.539Z" fill="#1890FF"/>
    <path d="M86.0915 149.12L83.271 137.523C83.2093 137.27 83.1975 137.007 83.236 136.749C83.2746 136.491 83.3629 136.243 83.4958 136.02C83.6287 135.797 83.8037 135.602 84.0107 135.447C84.2177 135.293 84.4528 135.181 84.7024 135.118L147.169 119.469L150.928 134.923L88.4614 150.573C88.2118 150.635 87.9525 150.647 87.6983 150.608C87.4441 150.569 87.2 150.48 86.98 150.345C86.7599 150.21 86.5682 150.032 86.4157 149.822C86.2633 149.612 86.1531 149.373 86.0915 149.12Z" fill="#0F79B2"/>
    <path d="M190.77 149.49C208.589 149.49 223.035 134.83 223.035 116.745C223.035 98.6605 208.589 84 190.77 84C172.951 84 158.505 98.6605 158.505 116.745C158.505 134.83 172.951 149.49 190.77 149.49Z" fill="white"/>
    <path d="M190.708 151.49C184.548 151.49 178.484 149.789 173.076 146.473C165.251 141.677 159.736 134.075 157.545 125.068C155.354 116.061 156.751 106.728 161.477 98.7866C166.203 90.8454 173.694 85.2479 182.568 83.0243C200.889 78.4337 219.473 89.8274 223.995 108.42C228.517 127.013 217.292 145.874 198.971 150.464C196.268 151.144 193.493 151.488 190.708 151.49ZM190.768 85.9822C188.37 85.9822 185.937 86.2731 183.507 86.8822C175.647 88.8511 169.014 93.8085 164.828 100.841C160.643 107.873 159.406 116.139 161.346 124.116C163.286 132.092 168.171 138.824 175.1 143.072C182.029 147.32 190.173 148.574 198.033 146.606C214.258 142.542 224.199 125.839 220.194 109.373C216.789 95.3729 204.38 85.9822 190.768 85.9822Z" fill="#38C1E5"/>
    </g>
    <g clip-path="url(#clip2_10693_32364)">
    <path d="M152.693 4.77065V84.0216H0V4.77065C0 2.1369 2.1369 0 4.77065 0H147.927C150.56 0 152.693 2.1369 152.693 4.77065Z" fill="#9EC9FF"/>
    <path d="M152.693 4.77065V14.5947H0V4.77065C0 2.1369 2.1369 0 4.77065 0H147.927C150.56 0 152.693 2.1369 152.693 4.77065Z" fill="#0F79B2"/>
    <path d="M10.4805 8.94825C11.4338 8.94825 12.2066 8.17546 12.2066 7.22217C12.2066 6.26889 11.4338 5.49609 10.4805 5.49609C9.52719 5.49609 8.75439 6.26889 8.75439 7.22217C8.75439 8.17546 9.52719 8.94825 10.4805 8.94825Z" fill="#E9F8FD"/>
    <path d="M16.6 8.94825C17.5533 8.94825 18.3261 8.17546 18.3261 7.22217C18.3261 6.26889 17.5533 5.49609 16.6 5.49609C15.6467 5.49609 14.8739 6.26889 14.8739 7.22217C14.8739 8.17546 15.6467 8.94825 16.6 8.94825Z" fill="#E9F8FD"/>
    <path d="M22.7197 8.94825C23.673 8.94825 24.4458 8.17546 24.4458 7.22217C24.4458 6.26889 23.673 5.49609 22.7197 5.49609C21.7664 5.49609 20.9937 6.26889 20.9937 7.22217C20.9937 8.17546 21.7664 8.94825 22.7197 8.94825Z" fill="#E9F8FD"/>
    <path d="M134.039 9.55213H37.0908C35.6466 9.55213 34.4651 8.37058 34.4651 6.92646C34.4651 5.48234 35.6466 4.30078 37.0908 4.30078H134.039C135.483 4.30078 136.664 5.48234 136.664 6.92646C136.664 8.37058 135.483 9.55213 134.039 9.55213Z" fill="#E9F8FD"/>
    <path d="M76.4274 66.4908C79.0221 66.4908 81.4744 65.9925 83.7844 64.9957C86.0943 63.999 88.1115 62.6383 89.836 60.9138C91.5606 59.1893 92.9212 57.1721 93.918 54.8621C94.9147 52.5522 95.4131 50.0999 95.4131 47.5052C95.4131 44.8472 94.9226 42.3712 93.9417 40.0771C92.9608 37.7829 91.6001 35.7815 89.8598 34.0728L76.4274 47.5052V28.5195C73.8011 28.5195 71.3329 29.0179 69.023 30.0147C66.7131 31.0114 64.7038 32.3641 62.9951 34.0728C61.2864 35.7815 59.9336 37.7909 58.9369 40.1008C57.9401 42.4107 57.4418 44.8788 57.4418 47.5052C57.4418 50.0999 57.9401 52.5522 58.9369 54.8621C59.9336 57.1721 61.2864 59.1893 62.9951 60.9138C64.7038 62.6383 66.7131 63.999 69.023 64.9957C71.3329 65.9925 73.8011 66.4908 76.4274 66.4908Z" fill="#0F79B2"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_10693_32364">
    <rect width="191.876" height="140.575" fill="white" transform="translate(14.5422 13.7344)"/>
    </clipPath>
    <clipPath id="clip1_10693_32364">
    <rect width="141.786" height="69.4794" fill="white" transform="translate(83.2134 82.0039)"/>
    </clipPath>
    <clipPath id="clip2_10693_32364">
    <rect width="152.693" height="84.0216" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
  );
};

export default NotFound;
