import React from "react";

export const circleMore = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 10C8.5 9.60218 8.65804 9.22064 8.93934 8.93934C9.22064 8.65804 9.60218 8.5 10 8.5C10.3978 8.5 10.7794 8.65804 11.0607 8.93934C11.342 9.22064 11.5 9.60218 11.5 10C11.5 10.3978 11.342 10.7794 11.0607 11.0607C10.7794 11.342 10.3978 11.5 10 11.5C9.60218 11.5 9.22064 11.342 8.93934 11.0607C8.65804 10.7794 8.5 10.3978 8.5 10ZM8.5 14.5C8.5 14.1022 8.65804 13.7206 8.93934 13.4393C9.22064 13.158 9.60218 13 10 13C10.3978 13 10.7794 13.158 11.0607 13.4393C11.342 13.7206 11.5 14.1022 11.5 14.5C11.5 14.8978 11.342 15.2794 11.0607 15.5607C10.7794 15.842 10.3978 16 10 16C9.60218 16 9.22064 15.842 8.93934 15.5607C8.65804 15.2794 8.5 14.8978 8.5 14.5ZM8.5 5.5C8.5 5.10218 8.65804 4.72064 8.93934 4.43934C9.22064 4.15804 9.60218 4 10 4C10.3978 4 10.7794 4.15804 11.0607 4.43934C11.342 4.72064 11.5 5.10218 11.5 5.5C11.5 5.89782 11.342 6.27936 11.0607 6.56066C10.7794 6.84196 10.3978 7 10 7C9.60218 7 9.22064 6.84196 8.93934 6.56066C8.65804 6.27936 8.5 5.89782 8.5 5.5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z"
      fill="#0F79B2"
    />
  </svg>
);

export const DesligarIcon = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0ZM8 2C8.53043 2 9.03914 2.21071 9.41421 2.58579C9.78929 2.96086 10 3.46957 10 4C10 4.53043 9.78929 5.03914 9.41421 5.41421C9.03914 5.78929 8.53043 6 8 6C7.46957 6 6.96086 5.78929 6.58579 5.41421C6.21071 5.03914 6 4.53043 6 4C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2ZM8 9C5.33 9 0 10.33 0 13V16H9.5C9.24833 15.394 9.08987 14.7534 9.03 14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9C8.5 10.9 9 10.95 9.5 11.03C9.7566 10.3985 10.1109 9.81136 10.55 9.29C9.61 9.1 8.71 9 8 9ZM15.5 9C13 9 11 11 11 13.5C11 16 13 18 15.5 18C18 18 20 16 20 13.5C20 11 18 9 15.5 9ZM15.5 10.5C17.16 10.5 18.5 11.84 18.5 13.5C18.5 14.06 18.35 14.58 18.08 15L14 10.92C14.42 10.65 14.94 10.5 15.5 10.5ZM12.92 12L17 16.08C16.58 16.35 16.06 16.5 15.5 16.5C13.84 16.5 12.5 15.16 12.5 13.5C12.5 12.94 12.65 12.42 12.92 12Z"
      fill="#888888"
    />
  </svg>
);

export const AfastarIcon = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 9.83317H16.0417V12.4182L18.2784 13.7107L17.5909 14.9023L14.6667 13.2157V9.83317ZM15.5834 7.99984C14.3678 7.99984 13.2021 8.48272 12.3425 9.34227C11.483 10.2018 11.0001 11.3676 11.0001 12.5832C11.0001 13.7987 11.483 14.9645 12.3425 15.8241C13.2021 16.6836 14.3678 17.1665 15.5834 17.1665C16.799 17.1665 17.9648 16.6836 18.8243 15.8241C19.6839 14.9645 20.1667 13.7987 20.1667 12.5832C20.1667 11.3676 19.6839 10.2018 18.8243 9.34227C17.9648 8.48272 16.799 7.99984 15.5834 7.99984ZM15.5834 6.1665C17.2852 6.1665 18.9173 6.84254 20.1207 8.0459C21.324 9.24926 22.0001 10.8814 22.0001 12.5832C22.0001 14.285 21.324 15.9171 20.1207 17.1204C18.9173 18.3238 17.2852 18.9998 15.5834 18.9998C13.0259 18.9998 10.8167 17.4965 9.78092 15.3332H0.916748V12.5832C0.916748 10.1448 5.80258 8.9165 8.25008 8.9165C8.80008 8.9165 9.47841 8.98067 10.1934 9.09984C11.3301 7.33984 13.3192 6.1665 15.5834 6.1665ZM9.16675 12.5832C9.16675 11.9415 9.25842 11.3182 9.43258 10.7498C9.04758 10.6857 8.64425 10.6582 8.25008 10.6582C5.52758 10.6582 2.65841 11.9965 2.65841 12.5832V13.5915H9.24925C9.19425 13.2615 9.16675 12.9223 9.16675 12.5832ZM8.25008 0.666504C9.22254 0.666504 10.1552 1.05281 10.8428 1.74045C11.5304 2.42808 11.9167 3.36071 11.9167 4.33317C11.9167 5.30563 11.5304 6.23826 10.8428 6.9259C10.1552 7.61353 9.22254 7.99984 8.25008 7.99984C7.27762 7.99984 6.34499 7.61353 5.65736 6.9259C4.96972 6.23826 4.58341 5.30563 4.58341 4.33317C4.58341 3.36071 4.96972 2.42808 5.65736 1.74045C6.34499 1.05281 7.27762 0.666504 8.25008 0.666504ZM8.25008 2.40817C7.73954 2.40817 7.24991 2.61098 6.8889 2.97199C6.52789 3.333 6.32508 3.82263 6.32508 4.33317C6.32508 4.84371 6.52789 5.33334 6.8889 5.69435C7.24991 6.05536 7.73954 6.25817 8.25008 6.25817C8.76062 6.25817 9.25025 6.05536 9.61126 5.69435C9.97227 5.33334 10.1751 4.84371 10.1751 4.33317C10.1751 3.82263 9.97227 3.333 9.61126 2.97199C9.25025 2.61098 8.76062 2.40817 8.25008 2.40817Z"
      fill="#1890FF"
    />
  </svg>
);

export const ClusterNegativoIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1792 16.8146C14.9456 17.0482 14.6689 17.1643 14.3493 17.1628C14.0296 17.1614 13.7534 17.0443 13.5206 16.8115L7.98751 11.2784L2.4544 16.8115C2.22165 17.0443 1.9444 17.1603 1.62267 17.1597C1.30092 17.1591 1.02324 17.042 0.789608 16.8084C0.555968 16.5748 0.439885 16.2981 0.441358 15.9784C0.442831 15.6588 0.559946 15.3826 0.792702 15.1498L7.15666 8.78584C7.38942 8.55308 7.66637 8.4367 7.98751 8.4367C8.30866 8.4367 8.58561 8.55308 8.81836 8.78584L15.1823 15.1498C15.4151 15.3826 15.5312 15.6598 15.5306 15.9815C15.53 16.3033 15.4129 16.581 15.1792 16.8146ZM15.1969 8.87732C14.9515 9.12274 14.6689 9.24472 14.3493 9.24324C14.0296 9.24177 13.7475 9.11876 13.5029 8.87422L7.98751 3.35879L2.47208 8.87423C2.22754 9.11877 1.9444 9.24074 1.62267 9.24015C1.30092 9.23955 1.01734 9.11655 0.771929 8.87113C0.538289 8.63749 0.422206 8.36084 0.423679 8.04117C0.425152 7.72149 0.548159 7.43939 0.7927 7.19485L7.15666 0.830885C7.38942 0.598128 7.66637 0.481751 7.98751 0.481751C8.30865 0.481751 8.58561 0.598129 8.81836 0.830884L15.1823 7.19484C15.4269 7.43939 15.5488 7.72252 15.5483 8.04426C15.5476 8.366 15.4305 8.64369 15.1969 8.87732Z"
      fill="#F5222D"
    />
  </svg>
);

export const ClusterPositivoIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.820769 1.18541C1.05441 0.951765 1.33107 0.835682 1.65074 0.837156C1.97041 0.838628 2.24662 0.955742 2.47938 1.1885L8.01249 6.72161L13.5456 1.1885C13.7784 0.955744 14.0556 0.83966 14.3773 0.840249C14.6991 0.84085 14.9768 0.957965 15.2104 1.19159C15.444 1.42523 15.5601 1.70189 15.5586 2.02156C15.5572 2.34123 15.4401 2.61744 15.2073 2.8502L8.84334 9.21416C8.61058 9.44692 8.33363 9.56329 8.01249 9.5633C7.69134 9.5633 7.41439 9.44692 7.18164 9.21416L0.817676 2.8502C0.58492 2.61744 0.468837 2.3402 0.469427 2.01847C0.470027 1.69672 0.587142 1.41903 0.820769 1.18541ZM0.803091 9.12268C1.04852 8.87725 1.33106 8.75528 1.65074 8.75675C1.97041 8.75822 2.25251 8.88123 2.49705 9.12577L8.01249 14.6412L13.5279 9.12577C13.7725 8.88123 14.0556 8.75926 14.3773 8.75985C14.6991 8.76045 14.9827 8.88345 15.2281 9.12887C15.4617 9.36251 15.5778 9.63916 15.5763 9.95883C15.5748 10.2785 15.4518 10.5606 15.2073 10.8052L8.84334 17.1691C8.61058 17.4019 8.33363 17.5182 8.01249 17.5182C7.69134 17.5182 7.41439 17.4019 7.18164 17.1691L0.817675 10.8052C0.573135 10.5606 0.451158 10.2775 0.451748 9.95574C0.452349 9.63399 0.569463 9.35631 0.803091 9.12268Z"
      fill="#558B2F"
    />
  </svg>
);

export const ClusterIgualIcon = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.79469 7.99976C1.43505 7.99976 1.12936 7.87366 0.877623 7.62148C0.625875 7.36929 0.5 7.06306 0.5 6.70279C0.5 6.34253 0.625875 6.03706 0.877623 5.78638C1.12936 5.53572 1.43505 5.41038 1.79469 5.41038H12.5386C12.9036 5.41038 13.2139 5.53648 13.4694 5.78866C13.7249 6.04085 13.8527 6.34708 13.8527 6.70735C13.8527 7.06761 13.7249 7.37308 13.4694 7.62376C13.2139 7.87442 12.9036 7.99976 12.5386 7.99976H1.79469ZM1.79469 2.58913C1.43505 2.58913 1.12936 2.46303 0.877623 2.21085C0.625875 1.95866 0.5 1.65243 0.5 1.29216C0.5 0.931904 0.625875 0.626436 0.877623 0.375756C1.12936 0.125089 1.43505 -0.000244141 1.79469 -0.000244141H12.5386C12.9036 -0.000244141 13.2139 0.125849 13.4694 0.378036C13.7249 0.630223 13.8527 0.936451 13.8527 1.29672C13.8527 1.65698 13.7249 1.96245 13.4694 2.21313C13.2139 2.46379 12.9036 2.58913 12.5386 2.58913H1.79469Z"
      fill="#0F79B2"
    />
  </svg>
);

export const InfoIcon = ({color = "#333333"}) => (
  <svg
  width="21"
  height="20"
  viewBox="0 0 21 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.5794 15C10.7931 15 10.9708 14.9281 11.1125 14.7844C11.2542 14.6406 11.325 14.4625 11.325 14.25V9.75C11.325 9.5375 11.2527 9.35938 11.1081 9.21563C10.9635 9.07188 10.7844 9 10.5706 9C10.3569 9 10.1792 9.07188 10.0375 9.21563C9.89583 9.35938 9.825 9.5375 9.825 9.75V14.25C9.825 14.4625 9.89729 14.6406 10.0419 14.7844C10.1865 14.9281 10.3656 15 10.5794 15ZM10.4996 7.15C10.7332 7.15 10.9292 7.07333 11.0875 6.92C11.2458 6.76667 11.325 6.57667 11.325 6.35C11.325 6.10917 11.246 5.90729 11.088 5.74438C10.9299 5.58146 10.7341 5.5 10.5005 5.5C10.2668 5.5 10.0708 5.58146 9.9125 5.74438C9.75417 5.90729 9.675 6.10917 9.675 6.35C9.675 6.57667 9.75402 6.76667 9.91205 6.92C10.0701 7.07333 10.2659 7.15 10.4996 7.15ZM10.5067 20C9.12775 20 7.83192 19.7375 6.61915 19.2125C5.40638 18.6875 4.34583 17.9708 3.4375 17.0625C2.52917 16.1542 1.8125 15.093 1.2875 13.879C0.7625 12.665 0.5 11.3678 0.5 9.9875C0.5 8.60718 0.7625 7.31003 1.2875 6.09603C1.8125 4.88201 2.52917 3.825 3.4375 2.925C4.34583 2.025 5.40701 1.3125 6.62103 0.7875C7.83503 0.2625 9.13218 0 10.5125 0C11.8928 0 13.19 0.2625 14.404 0.7875C15.618 1.3125 16.675 2.025 17.575 2.925C18.475 3.825 19.1875 4.88333 19.7125 6.1C20.2375 7.31667 20.5 8.61445 20.5 9.99335C20.5 11.3723 20.2375 12.6681 19.7125 13.8809C19.1875 15.0936 18.475 16.1526 17.575 17.0579C16.675 17.9632 15.6167 18.6798 14.4 19.2079C13.1833 19.736 11.8856 20 10.5067 20Z"
    fill={color}
  />
</svg>
)