import React from "react";
import { Input, Select, Form, Icon } from "antd";
import { connect } from "react-redux";
import { changeFamilyDoctor } from "../action";
import "./style.scss";
import { maskPhone } from "Misc/utils";

const { Option } = Select;

const Profissional = ({
  especialidades,
  unsavedFields,
  disabled,
  medicoFamilia,
  changeFamilyDoctor,
}) => {
  const handleValues = (valor, key) => {
    const modified = { ...medicoFamilia };
    modified[key] = valor;
    changeFamilyDoctor(modified);
    unsavedFields({ profissional: true });
  };

  const handleClear = () => {
    changeFamilyDoctor(null);
    unsavedFields({ profissional: true });
  };

  const allDoctors = especialidades.map((e) => e.profissionais).flat();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: "0.5vh",
        lineHeight: "1",
        alignItems: "center",
      }}
    >
      <Form.Item label="Nome" colon={false} style={{ marginRight: "1vw" }}>
        <Select
          onChange={(e) => handleValues(e, "id")}
          value={medicoFamilia?.id}
          size="large"
          style={{
            minWidth: "190px",
          }}
          disabled={disabled}
          allowClear
        >
          {allDoctors.map((el) => {
            return (
              <Option key={el.id} value={el.id}>
                {el.nome}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <div
        style={{
          marginRight: "1vw",
          maxWidth: "210px",
        }}
      >
        <Form.Item label="Telefone" colon={false}>
          <ProfissionalPhone
            name="telefone"
            data={medicoFamilia}
            onChange={handleValues}
            disabled={disabled}
          />
        </Form.Item>
      </div>
      <Icon
        className="dynamic-delete-button"
        type="delete"
        onClick={() => (disabled ? () => {} : handleClear())}
        disabled={disabled}
      />
    </div>
  );
};
const mapStateToProps = (store) => ({
  especialidades: store.informacaosaude.especialidades,
  profissionais: store.informacaosaude.profissionais,
  medicoFamilia: store.informacaosaude.medicoFamilia,
});

const mapDispatchToProps = (dispatch) => ({
  changeFamilyDoctor: (doctor) => dispatch(changeFamilyDoctor(doctor)),
});

const ProfissionalPhone = ({ data, onChange, name, disabled }) => {
  const handleChance = (e) => {
    const value = maskPhone(e.target.value);
    onChange(value, "telefone");
  };

  return (
    <Input
      onChange={handleChance}
      placeholder="Ex.:99 9 9999-9999"
      value={data?.telefone}
      size="large"
      name={name}
      maxLength={14}
      allowClear
      disabled={disabled}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profissional);
