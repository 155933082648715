import { hot } from "react-hot-loader/root";
import React from "react";
import Routes from "./Router/routers";
import { HashRouter } from "react-router-dom";
import { createHashHistory } from "history";
import { Layout } from "antd";
import "antd/dist/antd.css";
import "./App.scss";

const App = () => {
  return (
    <Layout className="layout">
      <HashRouter history={history}>
        <Routes />
      </HashRouter>
    </Layout>
  );
};

export const history = createHashHistory();
//export const history = createHashHistory({ basename: process.env.PUBLIC_URL });
export default hot(App);
