import React from "react";
import { Typography, Tag, Input, Form } from "antd";
import { connect } from "react-redux";

const { Text } = Typography;
const { TextArea } = Input;

const OutraInformacao = props => {
  const { getFieldDecorator } = props.form;
  return (
    <>
      <div className="cadastro-object-title">
        <Tag color="orange">NOVO</Tag>
        <Text className="cadastro-section-title">Outras Informações</Text>
      </div>
      <div className="spacing-sections">
        <Form.Item>
          {getFieldDecorator("outrasInformacoes", {
            initialValue: null
          })(<TextArea rows={4} placeholder="Anotações adicionais" />)}
        </Form.Item>
      </div>
    </>
  );
};

const mapStateToProps = store => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OutraInformacao);
