import React from "react";
import { Typography, Radio, Form } from "antd";
import _ from "lodash";
import { connect } from "react-redux";

const { Text } = Typography;

const QuestionFollowUp = (props) => {
  const {
    form: { getFieldDecorator },
    required,
    pergunta: { nome, numero, somenteExibicao, resposta },
  } = props;


  // TODO: Achar o required da data
  return (
    <div style={{ margin: "24px 0" }}>
      {/* {!somenteExibicao && ( */}
      <>
        {numero === 419 && (
          <Text
            level={4}
            style={{
              marginBottom: "16px",
              display: "block",
              fontWeight: "800",
              borderTop: "1px solid #EEEEEE",
              paddingTop: "16px",
            }}
          >
            4. Pergunta ao próprio atendente/enfermeiro:
          </Text>
        )}
        <Text
          level={4}
          style={{
            marginBottom: "8px",
            display: "block",
          }}
        >
          {nome}
        </Text>
        <Form.Item>
          {getFieldDecorator(`${numero}.valor`, {
            rules: [
              {
                required: required,
                message: "Resposta obrigatória.",
              },
            ],
            initialValue: resposta,
          })(
            <Radio.Group buttonStyle="solid" size="large">
              <Radio.Button value={true}>Sim</Radio.Button>
              <Radio.Button value={false}>Não</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
      </>
      {/* )} */}
    </div>
  );
};

export default connect()(QuestionFollowUp);
