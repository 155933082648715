import { sendErrorMsg } from "Misc/utils";
import * as services from "./service";
import * as servicesInfo from "../InformacaoPessoal/service";

export const getRaioXPaciente = async (pacienteId) => {
  try {
    const response = await services.getRaioXPaciente(pacienteId);
    return response;
  } catch (error) {
    console.log(error);
    sendErrorMsg(error);
  }
};

export const getInfo = async (pacienteID) => {
  try {
    const response = await servicesInfo.getInfo(pacienteID);
    return response;
  } catch (error) {
    sendErrorMsg(error);
  }
};
