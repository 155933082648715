import React from "react";

import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  LineChart,
  Legend,
  Line,
} from "recharts";
import TooltipMsg from "./TooltipMsg";

const CustomTooltip = ({ active, payload }) => {
  return active ? (
    <div className="custom-tooltip">
      <span
        style={{ color: payload[0].payload.cor }}
      >{`${payload[0].name} ${payload[0].value}`}</span>
      <span>({payload[0].payload.porcentagem.toFixed(2)}%)</span>
    </div>
  ) : (
    <div></div>
  );
};

export const Rosca = (props) => {
  const { dados, total } = props;

  const renderCustomizedLabel = ({ cx, cy, fill, x, y, label, valor }) => {
    return (
      <>
        <text
          x={x}
          y={y}
          fill={fill}
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${label} ${valor}`}
        </text>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="black">
          {total}
        </text>
      </>
    );
  };

  return (
    <ResponsiveContainer>
      <PieChart margin={{ top: 15, bottom: 15 }}>
        <Pie
          innerRadius="55%"
          dataKey="valor"
          nameKey="label"
          data={dados}
          label={renderCustomizedLabel}
          paddingAngle={6}
          isAnimationActive={false}
        >
          {dados.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.cor} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

const BarTooltip = ({ active, payload }) => {
  return active ? (
    <div className="custom-tooltip">
      <span
        style={{ color: payload[0].payload.cor }}
      >{`${payload[0].payload.label} ${payload[0].value}`}</span>
    </div>
  ) : (
    <div></div>
  );
};

export const Barra = (props) => {
  const { dados } = props;

  const CustomXAxisTick = (value) => {
    if (value.payload.value !== "Atrasados") {
      return (
        <text
          width={value.width}
          height={value.height}
          x={value.x}
          y={value.y}
          textAnchor={value.textAnchor}
          fill={value.fill}
          dy="0.355em"
        >
          {value.payload.value}
        </text>
      );
    } else {
      return (
        <g>
          <text
            width={value.width}
            height={value.height}
            x={value.x}
            y={value.y}
            textAnchor={value.textAnchor}
            fill={value.fill}
            dy="0.355em"
            className="label-tooltip"
          >
            {value.payload.value}
          </text>
          <circle
            style={{ fill: "rgba(100, 100, 100, 1)" }}
            cx={value.x}
            cy={value.y + 16}
            r="6"
            className="label-tooltip"
          ></circle>
          <text
            x={value.x}
            y={value.y + 16}
            text-anchor="middle"
            fill="#FFF"
            dy=".3em"
            style={{ fontSize: "9px" }}
            className="label-tooltip"
          >
            i
          </text>
          <TooltipMsg x={value.x - 540} y={value.y - 5} />
        </g>
      );
    }
  };

  return (
    <ResponsiveContainer>
      <BarChart data={dados}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" tick={<CustomXAxisTick />} />
        <YAxis dataKey="valor" />
        <Tooltip content={<BarTooltip />} />
        <Bar dataKey="valor" barSize={60} maxBarSize={200}>
          {dados.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.cor} />
          ))}
          <LabelList dataKey="valor" position="insideBottom" fill="black" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export const BarraHorizontal = (props) => {
  const { dados } = props;
  const CustomYAxisTick = (value) => {
    if (value.payload.value !== "Atrasados") {
      return (
        <text
          width={value.width}
          height={value.height}
          x={value.x}
          y={value.y}
          textAnchor={value.textAnchor}
          fill={value.fill}
          dy="0.355em"
        >
          {value.payload.value}
        </text>
      );
    } else {
      return (
        <g>
          <text
            width={value.width}
            height={value.height}
            x={value.x - 20}
            y={value.y}
            textAnchor={value.textAnchor}
            fill={value.fill}
            dy="0.355em"
            className="label-tooltip"
          >
            {value.payload.value}
          </text>
          <circle
            style={{ fill: "rgba(100, 100, 100, 1)" }}
            cx={value.x - 7}
            cy={value.y}
            r="6"
            className="label-tooltip"
          ></circle>
          <text
            x={value.x - 7}
            y={value.y}
            text-anchor="middle"
            fill="#FFF"
            dy=".3em"
            style={{ fontSize: "9px" }}
            className="label-tooltip"
          >
            i
          </text>
          <TooltipMsg x={value.x - 500} y={value.y + 10} />
        </g>
      );
    }
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        layout="vertical"
        data={dados}
        margin={{
          top: 5,
          right: 40,
          bottom: 5,
          left: 60,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis dataKey="label" type="category" tick={<CustomYAxisTick />} />
        <Tooltip content={<BarTooltip />} />

        <Bar dataKey="valor">
          {dados.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.cor} />
          ))}
          <LabelList dataKey="valor" position="insideLeft" fill="black" />
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const Linha = (props) => {
  const { dados, labels, unit } = props;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={dados}
        margin={{
          top: 5,
          right: 40,
          bottom: 5,
          left: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" />
        <YAxis tickFormatter={(value) => `${value} ${unit}`} width={70} />
        <Tooltip formatter={(value) => `${value} `} />
        <Legend />
        {labels.map((el, i) => (
          <Line
            type="linear"
            name={el}
            dataKey={`y[${i}]`}
            stroke={el === "TMA" ? "#E65100" : "#6A3EB8"}
            strokeWidth={3}
            activeDot={{ r: 8 }}
            unit={unit}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
