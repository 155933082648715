import React from "react";
import { Button, Empty, Select, Icon, Form } from "antd";
import { connect } from "react-redux";
import { selectEspecialidade, changeProfissional } from "../action";
import HeaderSection from "./HeaderSection";
import Profissional from "./Profissional";
import CardComponent from "Components/CardComponent";
import { unsavedFields } from "Pages/Rotina/action";
import "./style.scss";

const { Option } = Select;

const AcompanhamentoProfissional = ({
  especialidades,
  profissionais,
  changeProfissional,
  unsavedFields,
  rotina,
  readOnly,
}) => {
  const handleChangeEspecialidade = (index, cbo) => {
    const arrayPro = [...profissionais];

    arrayPro[index] = {
      cbo: cbo,
      id: null,
    };

    changeProfissional(arrayPro);
    unsavedFields({ profissional: true });
  };

  const handleChangeProfissional = (index, id) => {
    const arrayPro = [...profissionais];

    arrayPro[index].id = id;

    changeProfissional(arrayPro);
    unsavedFields({ profissional: true });
  };

  const handleAddProfissional = () => {
    const newPro = { cbo: null, id: null };
    changeProfissional([...profissionais, newPro]);
    unsavedFields({ profissional: true });
  };

  const handleRemoveProfissional = (index) => {
    const auxPros = [...profissionais];
    auxPros.splice(index, 1);
    changeProfissional(auxPros);
    unsavedFields({ profissional: true });
  };

  return (
    <div className="layoutAcompanhamentoPessoal">
      {!rotina && <HeaderSection />}
      <CardComponent
        rotina={rotina}
        title="Profissionais de saúde"
        cardStyle={{
          width: "100%",
          marginBottom: "15px",
        }}
      >
        {profissionais.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "8px 0px",
              width: "100%",
            }}
          >
            {profissionais.map((pro, index) => {
              return (
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 0,
                  }}
                >
                  <Form.Item label="Especialidade" colon={false}>
                    <Select
                      style={{ width: 220, marginRight: 16 }}
                      value={pro.cbo}
                      onChange={(cbo) => handleChangeEspecialidade(index, cbo)}
                      disabled={readOnly}
                    >
                      {especialidades.map((esp) => (
                        <Option value={esp.cbo}>{esp.nome}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Nome" colon={false}>
                    <Select
                      value={pro.id}
                      style={{ width: 220, marginRight: 16 }}
                      onChange={(id) => handleChangeProfissional(index, id)}
                      disabled={!Boolean(pro.cbo) || readOnly}
                    >
                      {especialidades
                        .filter((e) => e.cbo === pro.cbo)
                        .map((e) => e.profissionais)
                        .flat()
                        .map((pro) => (
                          <Option
                            disabled={profissionais
                              .map((p) => p.id)
                              .includes(pro.id)}
                            value={pro.id}
                          >
                            {pro.nome}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    disabled={readOnly}
                    onClick={() =>
                      readOnly ? () => {} : handleRemoveProfissional(index)
                    }
                  />
                </p>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Empty
              description="Nenhum profissional adicionado"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}

        <Button
          onClick={handleAddProfissional}
          className="add-more-button"
          disabled={readOnly}
        >
          {profissionais.length > 0 ? "Adicionar mais um" : "Adicionar"}
        </Button>
      </CardComponent>

      {!rotina && (
        <HeaderSection
          title="Médico da família"
          style={{ marginTop: "50px" }}
          subtitle={false}
        />
      )}
      <CardComponent
        rotina={rotina}
        title="Médico da família"
        cardStyle={{ width: "100%", marginBottom: "75px" }}
      >
        <Profissional unsavedFields={unsavedFields} disabled={readOnly} />
      </CardComponent>
    </div>
  );
};

const mapStateToProps = (store) => ({
  especialidades: store.informacaosaude.especialidades,
  profissionais: store.informacaosaude.profissionais,
  especialidadesSelected: store.informacaosaude.especialidadesSelected,
});

const mapDispatchToProps = (dispatch) => ({
  selectEspecialidade: (array) => dispatch(selectEspecialidade(array)),
  changeProfissional: (array) => dispatch(changeProfissional(array)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcompanhamentoProfissional);
