import axios from "axios";
import { returnStatus } from "Misc/utils";
import { logInExpires } from "Components/Login/action";
import store from "./store";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://viver360-dev.cpqd.com.br/vida-prefeitura/vida-service-adt/rest/",
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
});

api.interceptors.response.use(
  (res) => res,
  function (error) {
    // Do something with response error
    if (returnStatus(error) === 401) {
      store.dispatch(logInExpires());
    }
    return Promise.reject(error);
  }
);

export default api;
