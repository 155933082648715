import React, { useEffect } from "react";
import FluxoCadastral from "./Components/FluxoCadastral";
import BarraInformacao from "../../Components/BarraInformacao";
import { useParams, Redirect } from "react-router-dom";
import * as actions from "./action";
import "./style.scss";
import { connect } from "react-redux";
import BarLoader from "react-spinners/BarLoader";

const Adesao = props => {
  const { tarefa, tipo } = useParams();

  useEffect(() => {
    props.getInfoCabecalho(props.user.id, tarefa, tipo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarefa]);
  return (
    <>
      {!tarefa && <Redirect to="/" />}
      <BarraInformacao />
      {!props.loading ? (
        <FluxoCadastral />
      ) : (
        <BarLoader color={"#0F79B2"} width={"100vw"} />
      )}
    </>
  );
};

const mapStateToProps = store => ({
  paciente: store.adesao.paciente,
  loading: store.barrainformacao.loading,
  user: store.login.user
});

const mapDispatchToProps = dispatch => ({
  getInfoCabecalho: (enfermeiroID, tarefaID, tipo) =>
    dispatch(actions.getInfoCabecalho(enfermeiroID, tarefaID, tipo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Adesao);
