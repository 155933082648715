import * as types from "./actionType";
import * as typesHeader from "../../Components/Header/actionType";
import * as services from "./service";
import * as typesBarra from "../../Components/BarraInformacao/actionType";
import * as typesRotina from "Pages/Rotina/actionType";
import { TIPO_TAREFA } from "../../Misc/constants";
import { sendErrorMsg } from "Misc/utils";
import { history } from "App";

export const getInfoCabecalho = (enfermeiroID, tarefaID, tipo) => async (
  dispatch
) => {
  try {
    dispatch({ type: types.FETCHING_TASK_INIT });
    dispatch({ type: typesBarra.INIT_FETCH });
    dispatch({ type: types.INICIAL_STEP });
    dispatch({ type: typesRotina.RESET_FIELDS });
    dispatch({ type: types.FETCHING_TASK });
    dispatch({ type: typesBarra.EDITING_BARRA });
    const response = await services.getInfoCabecalho(enfermeiroID, tarefaID);
    // const foto_perfil = await services.getFotoPaciente(response.paciente);
    await dispatch({
      type: typesHeader.EDIT_HEADER_COMPLETE,
      payload: {
        nome: TIPO_TAREFA[tipo].headerDescricao,
        criticidade: response.criticidadeTarefa,
        tipoTarefa: tipo,
        // foto_perfil: foto_perfil,
      },
    });

    if (
      tipo === "1" ||
      tipo === "3" ||
      tipo === "4" ||
      tipo === "5" ||
      tipo === "7" ||
      tipo === "8" ||
      tipo === "9" ||
      tipo === "10" ||
      tipo === "11"
    ) {
      await dispatch({
        type: typesHeader.EDIT_HEADER_COMPLETE,
        payload: {
          nome: `${response.nome}, ${response.idade} `,
          criticidade: response.criticidadeTarefa,
          tipoTarefa: tipo,
          // foto_perfil: foto_perfil,
        },
      });
    }

    await dispatch({
      type: typesBarra.EDIT_BARRA_COMPLETE,
      payload: {
        ubs: response.ubs,
        nome: response.nome,
        email: response.email,
        telefone: response.telefone,
        tipoTarefa: tipo,
        peso: response.peso,
        imc: response.imc,
        doencas: response.morbidadesResumo,
        sexo: response.sexo,
        urgencia: response.urgencia,
        wearable: response.wearable,
      },
    });

    await dispatch({
      type: types.FETCHING_TASK_COMPLETE,
      payload: response,
    });
  } catch (error) {
    history.push("/painel");
    dispatch({ type: types.FETCHING_TASK_ERROR });
    sendErrorMsg(error);
  }
};

export const changePacienteID = (paciente) => async (dispatch) => {
  dispatch({ type: types.CHANGE_PACIENTE, payload: paciente });
};

export const nextStep = (step) => async (dispatch) => {
  let newStep = step + 1;
  if (step === 7) {
    newStep = step;
  }

  dispatch({ type: types.CHANGE_STEP, payload: newStep });
};

export const backStep = (step) => async (dispatch) => {
  let newStep = step - 1;
  if (step === 1) {
    newStep = step;
  }

  dispatch({ type: types.CHANGE_STEP, payload: newStep });
};

export const completarAdesao = (pacienteID, tarefa) => async (dispatch) => {
  try {
    dispatch({ type: types.COMPLETE_ADESAO });
    const response = await services.completarAdesao(pacienteID, tarefa);
    dispatch({ type: types.COMPLETE_ADESAO_COMPLETE });
    return response;
  } catch (error) {
    sendErrorMsg(error);
    console.error("Falha na requisicao de adesao", error);
  }
};
