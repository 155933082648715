import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Select, Radio } from "antd";
import * as actions from "../action";
import BasicModal from "Components/BasicModal";
import { maskDate, isValidDate } from "Misc/utils";
import { DATE_FORMAT, SCROLL_CONFIG } from "Misc/constants";
import moment from "moment";

const { TextArea } = Input;
const { Option } = Select;

function ModalPlano({
  filtro,
  modal,
  planos,
  planoPaciente,
  modalType,
  modalLoading,
  form,
  handleCloseModal,
  dados,
  reativarMonitoramento,
  atualizarPlano,
  mudarPlano,
}) {
  const { getFieldDecorator, validateFieldsAndScroll } = form;

  const isRequired = true;

  const formLayout = {
    labelCol: {
      xs: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 16 },
    },
  };

  const dateLayout = {
    wrapperCol: {
      xs: { span: 8 },
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        if (modalType === "alterar") {
          atualizarPlano(
            JSON.stringify({ paciente: dados.paciente, ...values }),
            filtro
          );
        } else if (modalType === "reativar") {
          reativarMonitoramento(
            JSON.stringify({ paciente: dados.paciente, ...values }),
            filtro
          );
        }
      }
    });
  };

  const handleSelectChange = (value) => {
    mudarPlano(planos.filter((p) => p.id === value)[0]);
  };

  const titleModal = {
    alterar: {
      titulo: "Alterar modelo",
      botao: "Salvar",
      planoLabel: "Novo modelo",
      dataLabel: "Data de alteração do modelo",
      classe: "",
      decorator: "idPlano",
    },
    reativar: {
      titulo: "Reativar monitoramento",
      botao: "Confirmar",
      planoLabel: "Modelo",
      dataLabel: "Data da reativação",
      classe: "form-ordered",
      decorator: "plano",
    },
  };

  return (
    <BasicModal
      title={
        <span className="modal-title">{titleModal[modalType]?.titulo}</span>
      }
      visible={modal}
      onClose={handleCloseModal}
      destroyOnClose
      bodyStyle={{}}
      footer={[
        <Button
          className="secondary-btn"
          key="back"
          onClick={handleCloseModal}
          disabled={modalLoading}
        >
          Cancelar
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={modalLoading}
        >
          {titleModal[modalType]?.botao}
        </Button>,
      ]}
    >
      <Form
        className={titleModal[modalType]?.classe}
        layout="horizontal"
        hideRequiredMark
        {...formLayout}
      >
        <Form.Item label={titleModal[modalType]?.planoLabel}>
          {getFieldDecorator(`${titleModal[modalType]?.decorator}`, {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: planoPaciente.id,
          })(
            <Select size="large" onChange={handleSelectChange}>
              {planos.map((plano) => {
                return (
                  <Option key={plano.id} value={plano.id}>
                    {plano.id}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Titulo">
          <Input value={planoPaciente.titulo} disabled={true} />
        </Form.Item>

        <Form.Item label="Descrição">
          <TextArea value={planoPaciente.descricao} disabled={true} />
        </Form.Item>

        <Form.Item label="Wereable">
          <Radio.Group value={planoPaciente.wearable} disabled={true}>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Tablet">
          <Radio.Group value={planoPaciente.tablet} disabled={true}>
            <Radio value={true}>Sim</Radio>
            <Radio value={false}>Não</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item label={titleModal[modalType]?.dataLabel} {...dateLayout}>
          {getFieldDecorator("data", {
            rules: [
              {
                required: isRequired,
                message: "Campo obrigatório.",
              },
              {
                pattern: DATE_FORMAT,
                validator: isValidDate,
                message: "Insira uma data válida.",
              },
            ],
            initialValue:
              modalType === "alterar" ? moment().format("DD/MM/YYYY") : null,
            getValueFromEvent: (e) => maskDate(e.target.value),
          })(<Input maxLength={10} allowClear />)}
        </Form.Item>
      </Form>
    </BasicModal>
  );
}

const mapStateToProps = (store) => ({
  modal: store.pacientes.modalPlano.open,
  modalType: store.pacientes.modalPlano.type,
  modalLoading: store.pacientes.modalLoading,
  filtro: store.pacientes.filtro,
  dados: store.pacientes.dados,
  planos: store.pacientes.planos,
  planoPaciente: store.pacientes.planoPaciente,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(actions.closeModal()),
  suspenderMonitoramento: (body, filtro) =>
    dispatch(actions.suspenderMonitoramento(body, filtro)),
  cancelarMonitoramento: (body, filtro) =>
    dispatch(actions.cancelarMonitoramento(body, filtro)),
  mudarPlano: (plano) => dispatch(actions.mudarPlano(plano)),
  reativarMonitoramento: (body, filtro) =>
    dispatch(actions.reativarMonitoramento(body, filtro)),
  atualizarPlano: (body, filtro) =>
    dispatch(actions.atualizarPlano(body, filtro)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "form_planos",
  })(ModalPlano)
);
