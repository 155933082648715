import React from "react";
import { Modal, Button, Table, Empty } from "antd";
import { connect } from "react-redux";

const ReportModal = ({
  open,
  onClose,
  relatorios
}) => {

  const columns = [
    {
      title: <strong>Endereço de e-mail enviado</strong>,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <strong>Perídodo correspondente</strong>,
      dataIndex: 'periodo',
      key: 'periodo',
    },
    {
      title: <strong>Data do envio</strong>,
      dataIndex: 'dataEnvio',
      key: 'dataEnvio',
    },
  ];
 
  return (
    <Modal
      title={
        <span style={{ color: "#0F79B2", fontWeight: "bold" }}>
          Histórico de envio do relatório
        </span>
      }
      destroyOnClose
      bodyStyle={{ height: "420px" }}
      centered
      width='70vw'
      style={{ minWidth:'600px', overflowY: "hidden" }}
      visible={open}
      onCancel={onClose}
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Fechar
        </Button>
      ]}
    >
      {
        relatorios.length > 0 ? 
          <Table bordered dataSource={relatorios} columns={columns} pagination={{pageSize:5}}  />
        :
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Nenhum relatório enviado' />
      }
    </Modal>
  );
};


const mapStateToProps = store => ({
  relatorios: store.rotina.relatorioHistorico
});

export default connect(mapStateToProps, null)(ReportModal);

