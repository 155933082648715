import api from "../../api";

export const getMedicamentoListaPaciente = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarListaMedicamentos",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );

  return res.data;
};

export const getMedicamentoDetalhesPaciente = async (
  pacienteID,
  medicamentoID
) => {
  const res = await api.post(
    "/backoffice/buscarMedicamentoPaciente",
    {
      token: "gecro",
      paciente: pacienteID,
      medicamentoId: medicamentoID,
    }
  );

  return res.data;
};

export const getMedicamentos = async (nome) => {
  const res = await api.post(
    "/backoffice/buscarMedicamentos",
    {
      token: "gecro",
      nome: nome,
    }
  );

  return res.data;
};

export const getDosagemMedicamento = async () => {
  const res = await api.post(
    "/backoffice/buscarDosagens",
    {
      token: "gecro",
    }
  );

  return res.data;
};

export const getFrequenciaMedicamento = async () => {
  const res = await api.post(
    "/backoffice/buscarFrequenciasMedicamentos",
    {
      token: "gecro",
    }
  );

  return res.data;
};

export const getRecomendacoesMedicamento = async () => {
  const res = await api.post(
    "/backoffice/buscarRecomendacoesIngestao",
    {
      token: "gecro",
    }
  );

  return res.data;
};

export const adicionaMedicamento = async (
  pacienteID,
  medicamento,
  apelido,
  quantidade,
  unidadeID,
  horarios,
  frequenciaID,
  xDias,
  diaSemana,
  recomendacaoID,
  personalizacao,
  inicio,
  fim,
  idMedicamentoAtual
) => {
  const res = await api.post(
    "/backoffice/atualizarMedicamento",
    {
      token: "gecro",
      paciente: pacienteID,
      medicamento: {
        ...(typeof medicamento === "string" && idMedicamentoAtual && {id: idMedicamentoAtual}),
        ...(typeof medicamento === "string" && {nomeComercial: medicamento}),
        ...(typeof medicamento === "number" && {id: medicamento}),
        idMedicamentoAtual: idMedicamentoAtual,
        apelido: apelido,
        quantidade: quantidade,
        unidade: {
          id: unidadeID,
        },
        horarios: horarios,
        frequencia: {
          id: frequenciaID,
          xDias: xDias || null,
          diaSemana: diaSemana || null,
        },
        recomendacao: {
          id: recomendacaoID,
          personalizacao: personalizacao || null,
        },
        inicio: inicio,
        fim: fim || null,
      },
    }
  );

  return res.data;
};

export const excluirMedicamentoPaciente = async (pacienteID, medicamentoId) => {
  const res = await api.post(
    "/backoffice/excluirMedicamentoPaciente",
    {
      token: "gecro",
      paciente: pacienteID,
      medicamentoId: medicamentoId,
    }
  );

  return res.data;
};

export const atualizarAcompanhamento = async (
  pacienteID,
  parametrosSaude,
  ligacaoPeriodica,
  wearable,
  idWearableModelo,
  outrasInformacoes
) => {
  const res = await api.post(
    "/backoffice/atualizarAcompanhamento",
    {
      token: "gecro",
      paciente: pacienteID,
      parametrosSaude: parametrosSaude,
      ligacaoPeriodica: ligacaoPeriodica,
      outrasInformacoes: outrasInformacoes,
      wearable: wearable,
      idWearableModelo: idWearableModelo,
    }
  );

  return res.data;
};

export const getAcompanhamento = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarAcompanhamento",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );

  // const data = {
  //   ...res.data,
  //   idWearableModelo: 3,
  // }
  return res.data;
  // return data;
};

export const getModelos = async () => {
  const res = await api.get(
    "/backoffice/buscarModelosPulseira",
    {
      token: "gecro",
    }
  );

  return res.data;
}

export const atualizarLigacaoPeriodica = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarLigacaoPeriodicaPaciente",
    body
  );

  return res.data;
};

export const atualizarParametrosSaude = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarParametrosSaudePaciente",
    body
  );

  return res.data;
};

export const getMorbidadesPaciente = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarMorbidadesPaciente",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );
  return res.data;
};

export const getFrequenciaTarefaNF = async () => {
  const res = await api.post("/backoffice/buscarFrequenciasTarefaNF", {
    token: "gecro",
  });

  return res.data;
};

export const getTarefaNFListaPaciente = async (pacienteID, tarefaID) => {

  const res = await api.post("/backoffice/buscarTarefasNFListaPaciente", {
    token: "gecro",
    paciente: pacienteID,
  });

  return res.data;
};

export const getTarefaNFDetalhesPaciente = async (pacienteID, tarefaID) => {
  const res = await api.post("/backoffice/buscarTarefasNFListaPaciente", {
    token: "gecro",
    paciente: pacienteID
  });

  const filter = res.data.tarefasNF.find(tarefa => tarefa.idPlanoTarefa === tarefaID);

  const data = {
    error: res.data.error,
    success: res.data.success,
    tarefaNF: filter
  }

  return data;
};

export const getTarefasNF = async () => {
  const res = await api.post("/backoffice/buscarTarefasNF", {
    token: "gecro",
  });

  return res.data;
};

export const adicionaTarefaNF = async (
  pacienteID,
  tipoTarefaId,
  idPlanoTarefa,
  horarios,
  frequenciaID,
  xDias,
  diasSemana,
  outrosTipoTarefa,
  inicio,
  fim,
  recomendacao
) => {
  const tarefaNF = {
    token: "gecro",
    paciente: +pacienteID,
    tipoTarefaId,
    ...(idPlanoTarefa && {idPlanoTarefa}),
    ...(outrosTipoTarefa && {outrosTipoTarefa}),
    horarios,
    frequencia: {
      id: frequenciaID,
      ...(xDias && {xDias}),
      ...(diasSemana && {diasSemana}),
    },
    inicio,
    ...(fim && {fim}),
    ...(recomendacao && {recomendacao}),
  }

  if (!idPlanoTarefa) {
    const res = await api.post("/backoffice/adicionaTarefaNF", tarefaNF);
    return res.data;
  } else {
    const res = await api.put("/backoffice/adicionaTarefaNF", tarefaNF);
    return res.data;
  }

};

export const excluirTarefaNFPaciente = async (pacienteID, tarefaID) => {
  const res = await api.post("/backoffice/excluirTarefaNFPaciente", {
    token: "gecro",
    paciente: pacienteID,
    idPlanoTarefa: tarefaID,
  });

  return res.data;
};

export const getValoresOutliers = async () => {
  const res = await api.get("/backoffice/buscarParametrosSaudeExcecao", {
    token: "gecro",
  });

  return res.data;
};
