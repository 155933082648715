import * as types from "./actionType";

const initialState = {
  telefone: null,
  nome: null,
  email: null,
  criticidadeTarefa: null,
  tipoTarefa: null,
  paciente: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_TASK_INIT:
      return {
        ...initialState,
      };
    case types.FETCHING_TASK_COMPLETE:
      return {
        ...state,
        telefone: action.payload.telefone,
        nome: action.payload.nome,
        email: action.payload.email,
        criticidadeTarefa: action.payload.criticidadeTarefa,
        tipoTarefa: action.payload.tipoTarefa,
        paciente: action.payload.paciente,
      };
    case types.FETCHING_TASK:
      return {
        ...state,
      };

    case types.CHANGE_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case types.INICIAL_STEP:
      return {
        ...state,
        step: 1,
      };

    case types.CHANGE_PACIENTE:
      return {
        ...state,
        paciente: action.payload,
      };

    case types.COMPLETE_ADESAO:
      return {
        ...state,
      };

    case types.COMPLETE_ADESAO_COMPLETE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
