import * as types from "./actionType";

const initialState = {
  morbidades: [],
  morbSelected: [],
  especialidades: [],
  profissionais: [],
  medicoFamilia: null,
  outrasMorbidades: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_INFO_COMPLETE:
      return {
        ...state,
        morbidades: action.payload.morbidades,
        especialidades: action.payload.especialidades,
        profissionais: action.payload.profissionais,
        medicoFamilia: action.payload.medicoFamilia,
        outrasMorbidades: action.payload.outrasMorbidades,
      };
    case types.FETCHING_INFO:
      return {
        ...state,
        morbidades: [],
        morbSelected: [],
        especialidades: [],
        profissionais: [],
        medicoFamilia: {},
        outrasMorbidades: "",
      };
    case types.FILTER_MORB_SELECTED:
      return {
        ...state,
        morbSelected: action.payload,
      };
    case types.CHANGE_PROFISSIONAL:
      return {
        ...state,
        profissionais: action.payload,
      };
    case types.CHANGE_DOCTOR:
      return {
        ...state,
        medicoFamilia: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
