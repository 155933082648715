import * as types from "./actionType";
import * as typesAdesao from "Pages/Adesao/actionType";
import * as services from "./service";
import { sendErrorMsg, sendSuccessMsg } from "Misc/utils";

export const getTasks = (enfermeiroID) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCHING_TASK });
    await dispatch({ type: typesAdesao.FETCHING_TASK_INIT });
    const response = await services.getTasks(enfermeiroID);
    dispatch({
      type: types.FETCHING_TASK_COMPLETE,
      payload: response,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_TASK_ERROR });
  }
};

export const getTasksWithPolling = (enfermeiroID) => async (dispatch) => {
  try {
    dispatch({ type: types.POLLING_TASK });

    const response = await services.getTasks(enfermeiroID);

    dispatch({
      type: types.FETCHING_TASK_COMPLETE,
      payload: response,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_TASK_ERROR });
  }
};

export const initTask = (enfermeiroID, tarefaID) => async (dispatch) => {
  try {
    await dispatch({ type: typesAdesao.FETCHING_TASK_INIT });
    await dispatch({ type: types.EXECUTE_TASK });
    const response = await services.initTask(enfermeiroID, tarefaID);
    await dispatch({
      type: types.EXECUTE_TASK_COMPLETE,
      payload: response,
    });
    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.EXECUTE_TASK_ERROR });
  }
};

export const getNurses = (body) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_INIT });
    const response = await services.getNurses(body);
    dispatch({
      type: types.NURSES_FETCH_COMPLETE,
      payload: response,
    });
    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCH_ERROR });
  }
};

export const sendDelegarTarefas = (info) => async (dispatch) => {
  try {
    dispatch({ type: types.SEND_INFO });
    const response = await services.sendDelegateTask(info);
    dispatch({
      type: types.SEND_INFO_COMPLETE,
      payload: response,
    });
    sendSuccessMsg("Tarefa delegada com sucesso.")
    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.SEND_INFO_ERROR });
  }
};
