import React, { useState } from "react";
import { Form, InputNumber, Select, Icon, Button, Input } from "antd";
import { DIAS_SEMANA, PERIODICIDADE, TIME_FORMAT } from "Misc/constants";
import { maskTime } from "Misc/utils";
import { useCallback } from "react";
import { connect } from "react-redux";
import { unsavedFields } from "Pages/Rotina/action";

const { Option } = Select;
const FrequenciaMedida = (props) => {
  const { getFieldDecorator, getFieldValue } = props.form;
  const [id, setId] = useState(0);
  const [showHorarios, setShowHorarios] = useState(props.horarios ?? [])

  const verificaPeriodicidade = () => {
    switch (
      props.form.getFieldValue(
        `parametrosSaude[${props.idParametro}[periodicidade[codigo]]]`
      )
    ) {
      case "M":
        return (
          <>
            <Form.Item key="input-dia-mes" label="Melhor dia do mês">
              {getFieldDecorator(
                `parametrosSaude[${props.idParametro}[periodicidade[diaMes[0]]]]`,
                {
                  rules: [{ required: true, message: "Insira a quantidade!" }],
                  initialValue:
                    props.periodicidade && props.periodicidade.diaMes
                      ? props.periodicidade.diaMes[0]
                      : null,
                }
              )(
                <InputNumber
                  min={1}
                  max={31}
                  style={{ width: "20%" }}
                  disabled={props.readOnly}
                />
              )}
            </Form.Item>
          </>
        );
      case "S":
        return (
          <>
            <Form.Item key="input-dia-semana" label="Dia da semana">
              {getFieldDecorator(
                `parametrosSaude[${props.idParametro}[periodicidade[diaSemana]]]`,
                {
                  rules: [{ required: true, message: "Insira os dias!" }],
                  initialValue:
                    (props.periodicidade && props.periodicidade.diaSemana) ||
                    [],
                }
              )(
                <Select
                  mode="tags"
                  style={{ width: "80%" }}
                  disabled={props.readOnly}
                >
                  {DIAS_SEMANA.map((dia) => {
                    return <Option key={dia.valor}>{dia.descricao}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </>
        );
      case "Q":
        return (
          <>
            <Form.Item
              key="input-dia-mes-quinzenal-1"
              label="Melhor dia do mês"
            >
              {getFieldDecorator(
                `parametrosSaude[${props.idParametro}[periodicidade[diaMes[0]]]]`,
                {
                  rules: [{ required: true, message: "Insira a quantidade!" }],
                  initialValue:
                    props.periodicidade && props.periodicidade.diaMes
                      ? props.periodicidade.diaMes[0]
                      : 1,
                }
              )(
                <InputNumber
                  min={1}
                  max={15}
                  style={{ width: "20%" }}
                  disabled={props.readOnly}
                />
              )}
            </Form.Item>
            <Form.Item key="input-dia-mes-quinzenal-2" label="Próximo dia">
              {getFieldDecorator(
                `parametrosSaude[${props.idParametro}[periodicidade[diaMes[1]]]]`,
                {
                  initialValue:
                    getFieldValue(`parametrosSaude`)[props.idParametro]
                      ?.periodicidade?.diaMes[0] + 15,
                }
              )(
                <InputNumber
                  disabled
                  style={{ width: "20%", minWidth: "70px" }}
                />
              )}
            </Form.Item>
          </>
        );

      default:
        break;
    }
  };

  const add = useCallback(() => {
    props.unsavedFields({ parametroSaude: true });
    const horarios = props.form.getFieldValue(`horarios${props.idParametro}`);
    const nextHorarios = horarios.concat(id);
    setId(id + 1);
    props.form.setFieldsValue({
      [`horarios${props.idParametro}`]: nextHorarios,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.form, id]);

  const remove = useCallback(
    (k) => {
      props.unsavedFields({ parametroSaude: true });
      const horarios = props.form.getFieldValue(`horarios${props.idParametro}`);
      if (horarios.length === 1) {
        return;
      }
      
      const horariosFiltered = horarios.filter((key) => key !== k);
      
      props.form.setFieldsValue({
        [`horarios${props.idParametro}`]: horariosFiltered,
      });

      setShowHorarios(horariosFiltered)
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.form]
  );

  getFieldDecorator(`horarios${props.idParametro}`, {
    initialValue: showHorarios,
  });
  const horarios = getFieldValue(`horarios${props.idParametro}`);

  return (
    <>
      <div className="spacing-sections">
        <Form.Item
          key="input-parametro-frequencia"
          label="Frequencia de medida"
          shouldUpdate
        >
          {getFieldDecorator(
            `parametrosSaude[${props.idParametro}[periodicidade[codigo]]]`,
            {
              rules: [{ required: true, message: "Insira uma frequência!" }],
              initialValue: props.periodicidade && props.periodicidade.codigo,
            }
          )(
            <Select
              showSearch
              style={{ width: 250, textTransform: "capitalize" }}
              placeholder="Selecione"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              disabled={props.readOnly}
            >
              {PERIODICIDADE.map((item) => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.valor}
                  >
                    {item.descricao}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
        {verificaPeriodicidade()}
        {horarios &&
          horarios.map((k, index) => {
            // console.log('k', k)
            // console.log('index', index)
            return (
            <Form.Item
              label={index === 0 ? "Horários" : ""}
              required={false}
              key={k}
            >
              {getFieldDecorator(
                `parametrosSaude[${props.idParametro}[horarios[${k}]]`,
                {
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    {
                      required: true,
                      pattern: TIME_FORMAT,
                      message: "Insira um horário!",
                    },
                  ],
                  getValueFromEvent: (e) => maskTime(e.target.value),
                  initialValue:
                    (showHorarios && showHorarios[index]) || null,
                }
              )(
                <Input
                  style={{ width: 130, marginRight: 8 }}
                  placeholder="HH:mm"
                  maxLength={5}
                  allowClear
                  disabled={props.readOnly}
                />
              )}
              {horarios.length > 1 ? (
                <Icon
                  className="dynamic-delete-button"
                  type="minus-circle-o"
                  onClick={() => remove(k)}
                />
              ) : null}
            </Form.Item>
          )})}
        <Form.Item>
          {/* <Button type="dashed" onClick={add} style={{ width: "60%" }}>
            <Icon type="plus" />
          </Button> */}
          <Button
            className="buttonFooterBack"
            style={{ marginTop: "1vh", width: "8vw" }}
            onClick={add}
            disabled={props.readOnly}
          >
            + horário
          </Button>
        </Form.Item>
        {/* <Form.Item >
          <Button type="dashed" onClick={changeHorariosField} style={{ width: '60%' }}>
            <Icon type="plus" /> Add field
          </Button>
        </Form.Item> */}
        {/* <Form.Item key="input-medicao-horario" label="Horário">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {counterHorariosField.map((campo, index) => {
              return (
                <>
                  <Form.Item key={`input-medicao-horario-${index}`}>
                    {getFieldDecorator(
                      `parametrosSaude[${props.idParametro}[horarios[${index}]]`,
                      {
                        rules: [
                          {
                            required: true,
                            message: "Insira um horário!",
                            pattern: TIME_FORMAT,
                          },
                        ],
                        getValueFromEvent: (e) => maskTime(e.target.value),
                        initialValue:
                          (props.horarios && props.horarios[index]) || null,
                      }
                    )(
                      <Input
                        style={{ width: 130 }}
                        placeholder="HH:mm"
                        maxLength={5}
                        allowClear
                        disabled={props.readOnly}
                      />
                    )}

                    {index > 0 ? (
                      <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        style={{ alignSelf: "center", padding: "1vw" }}
                        onClick={(e) => removeHorariosField(e, index, campo)}
                      />
                    ) : null}
                  </Form.Item>
                </>
              );
            })}
            <Button
              className="buttonFooterBack"
              style={{ marginTop: "1vh", width: "8vw" }}
              onClick={() => changeHorariosField()}
              disabled={props.readOnly}
            >
              + horário
            </Button>
          </div>
        </Form.Item> */}
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
});

const mapDispatchToProps = (dispatch) => ({
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrequenciaMedida);
