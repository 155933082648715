import * as React from "react";
import { connect } from "react-redux";

export const withPolling = (pollingAction, duration = 5000) => Component => {
  const Wrapper = () =>
    class extends React.Component {
      componentDidMount() {
        this.dataPolling = setInterval(() => {
          this.props.pollingAction(this.props.user.id);
        }, duration);
      }
      componentWillUnmount() {
        clearInterval(this.dataPolling);
      }
      render() {
        return <Component {...this.props} />;
      }
    };
  const mapStateToProps = store => ({
    user: store.login.user
  });
  const mapDispatchToProps = { pollingAction };
  return connect(mapStateToProps, mapDispatchToProps)(Wrapper());
};
