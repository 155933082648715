import * as types from "./actionType";

const initialState = {
  listaAtendentes: null,
  loadingAtendentes: false,
  situacoes: [],
  loadingSituacoes: false,
  perfis: [],
  loadingPerfis: false,
  modal: null,
  endereco: {},
  cepLoading: false,
  dados: {},
  loadingModalSave: false,
  filtro: {},
  actionModal: null,
  motivos: [],
  loadingMotivos: false,
  matricula: null,
  another: false,
  afastamentos: [],
  opcoesUbs: [],
  loadingUbs: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHECK_ANOTHER_REGISTRY:
      return {
        ...state,
        another: action.payload,
      };
    //Situações
    case types.BUSCAR_SITUACOES_INIT:
      return {
        ...state,
        situacoes: [],
        loadingSituacoes: true,
      };
    case types.BUSCAR_SITUACOES_FAIL:
      return {
        ...state,
        situacoes: [],
        loadingSituacoes: false,
      };
    case types.BUSCAR_SITUACOES_SUCCESS:
      return {
        ...state,
        situacoes: action.payload,
        loadingSituacoes: false,
      };
    //Perfis
    case types.BUSCAR_PERFIS_INIT:
      return {
        ...state,
        perfis: [],
        loadingPerfis: true,
      };
    case types.BUSCAR_PERFIS_FAIL:
      return {
        ...state,
        perfis: [],
        loadingPerfis: false,
      };
    case types.BUSCAR_PERFIS_SUCCESS:
      return {
        ...state,
        perfis: action.payload,
        loadingPerfis: false,
      };
    //Atendentes
    case types.SAVE_FILTRO_ATENDENTES:
      return {
        ...state,
        filtro: action.payload,
      };
    case types.RESET_FILTRO_ATENDENTES:
      return {
        ...state,
        filtro: {},
      };
    case types.BUSCAR_ATENDENTES_INIT:
      return {
        ...state,
        loadingAtendentes: true,
      };
    case types.BUSCAR_ATENDENTES_FAIL:
      return {
        ...state,
        listaAtendentes: null,
        loadingAtendentes: false,
      };
    case types.BUSCAR_ATENDENTES_SUCCESS:
      return {
        ...state,
        listaAtendentes: action.payload,
        loadingAtendentes: false,
      };
    case types.RESET_ATENDENTES:
      return {
        ...state,
        listaAtendentes: null,
        loadingAtendentes: false,
      };
    //Modal
    case types.ATENDENDENTE_DATA:
      return {
        ...state,
        dados: action.payloadData,
        endereco: action.payloadEndereco,
      };
    case types.ATUALIZAR_AFASTAMENTOS_MODAL:
      return {
        ...state,
        afastamentos: action.payload,
      };
    case types.OPEN_ATENDENDENTES_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case types.CLOSE_ATENDENDENTES_MODAL:
      return {
        ...state,
        modal: null,
        dados: {},
        endereco: {},
      };
    case types.LOADING_MODAL_INIT:
      return {
        ...state,
        loadingModalSave: true,
      };
    case types.LOADING_MODAL_FINISH:
      return {
        ...state,
        loadingModalSave: false,
      };
    //Endereço
    case types.BUSCAR_CEP_INIT:
      return {
        ...state,
        endereco: {},
        cepLoading: true,
      };
    case types.BUSCAR_CEP_SUCCESS:
      return {
        ...state,
        endereco: action.payload,
        cepLoading: false,
      };
    case types.BUSCAR_CEP_FAIL:
      return {
        ...state,
        cepLoading: false,
        endereco: {},
      };
    case types.RESET_ENDERECO:
      return {
        ...state,
        cepLoading: false,
        endereco: {},
      };
    //Afastar/Desligar Modal
    case types.OPEN_ACTION_MODAL:
      return {
        ...state,
        actionModal: action.payloadType,
        matricula: action.payloadMatricula,
        afastamentos: action.payloadAfastamentos,
      };
    case types.CLOSE_ACTION_MODAL:
      return {
        ...state,
        actionModal: null,
        matricula: null,
        afastamentos: [],
        another: false,
      };
    case types.BUSCAR_MOTIVOS_INIT:
      return {
        ...state,
        motivos: [],
        loadingMotivos: true,
      };
    case types.BUSCAR_MOTIVOS_SUCCESS:
      return {
        ...state,
        motivos: action.payload,
        loadingMotivos: false,
      };
    case types.BUSCAR_MOTIVOS_FAIL:
      return {
        ...state,
        motivos: [],
        loadingMotivos: false,
      };
    case types.BUSCAR_UBS_INIT:
      return {
        ...state,
        opcoesUbs: [],
        loadingUbs: true,
      };
    case types.BUSCAR_UBS_SUCCESS:
      return {
        ...state,
        opcoesUbs: action.payload,
        loadingUbs: false,
      };
    case types.BUSCAR_UBS_FAIL:
      return {
        ...state,
        opcoesUbs: [],
        loadingUbs: false,
      };
    default:
      return state;
  }
};

export default reducer;
