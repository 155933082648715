import React, { useState } from "react";
import { connect } from "react-redux";
import { Table, Empty, Icon, Popconfirm, DatePicker } from "antd";
import * as actions from "../action";
import moment from "moment";

const AtendentesAfastamentoTabela = ({
  afastamentos,
  deletarAfastamento,
  filtro,
  loadingSave,
  matricula,
  atualizarAfastamento,
}) => {
  const handleDeletar = (id) => {
    deletarAfastamento(id, filtro);
  };

  const [editableRow, setEditable] = useState(new Map());
  const [newEndDate, setNewEndDate] = useState(null);
  const [newInitDate, setNewInitDate] = useState(null);

  const handleChange = async (record) => {
    const body = {
      id: record.id,
      matricula: matricula,
      dataInicio: newInitDate ? newInitDate : record.dataInicio,
      dataFim: newEndDate ? newEndDate : record.dataFim,
      motivo: record.motivo.id,
      substituto: record.substituto,
    };
    await atualizarAfastamento(body, filtro);
    handleCancel();
  };

  const handleCancel = () => {
    setNewInitDate(null);
    setNewEndDate(null);
    setEditable(new Map());
  };

  const columns = [
    {
      title: "Motivo",
      dataIndex: "motivo.nome",
      key: "motivo.nome",
    },
    {
      title: "Data inicial",
      dataIndex: "dataInicio",
      key: "dataInicio",
      render: (i, record) =>
        editableRow.get(record.id) ? (
          <DatePicker
            defaultValue={moment(i, "DD/MM/YYYY")}
            format={"DD/MM/YYYY"}
            onChange={(current) =>
              setNewInitDate(current?.format("DD/MM/YYYY"))
            }
          />
        ) : (
          i
        ),
    },
    {
      title: "Data final",
      dataIndex: "dataFim",
      key: "dataFim",
      render: (i, record) =>
        editableRow.get(record.id) ? (
          <DatePicker
            defaultValue={moment(i, "DD/MM/YYYY")}
            format={"DD/MM/YYYY"}
            disabledDate={(current) => {
              return (
                current &&
                current <
                  moment(
                    newInitDate ? newInitDate : record.dataInicio,
                    "DD/MM/YYYY"
                  ).add(1, "day")
              );
            }}
            onChange={(current) => setNewEndDate(current?.format("DD/MM/YYYY"))}
          />
        ) : (
          i
        ),
    },
    {
      title: "Ações",
      dataIndex: "acoes",
      key: "acoes",
      render: (_, record) =>
        editableRow.get(record.id) ? (
          <>
            <Popconfirm
              title="Confirmar edição?"
              okText="Sim"
              cancelText="Não"
              onConfirm={() => handleChange(record)}
            >
              <Icon type="check" className="icon-check" />
            </Popconfirm>
            <Popconfirm
              title="Cancelar edição?"
              okText="Sim"
              cancelText="Não"
              onConfirm={handleCancel}
            >
              <Icon type="close" className="icon-delete" />
            </Popconfirm>
          </>
        ) : (
          <>
            <Popconfirm
              title="Gostaria de editar o afastamento?"
              okText="Sim"
              cancelText="Não"
              onConfirm={() =>
                setEditable(new Map(editableRow.set(record.id, true)))
              }
            >
              <Icon type="edit" className="icon-edit" />
            </Popconfirm>
            <Popconfirm
              title="Gostaria de deletar?"
              okText="Sim"
              cancelText="Não"
              onConfirm={() => handleDeletar(record.id)}
            >
              <Icon type="delete" className="icon-delete" />
            </Popconfirm>
          </>
        ),
    },
  ];

  return (
    <Table
      dataSource={afastamentos}
      columns={columns}
      pagination={false}
      scroll={{ y: 300 }}
      size="middle"
      bordered
      loading={loadingSave}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum afastamento registrado"
          />
        ),
      }}
    />
  );
};

const mapStateToProps = (store) => ({
  afastamentos: store.atendentes.afastamentos,
  matricula: store.atendentes.matricula,
  filtro: store.atendentes.filtro,
  loadingSave: store.atendentes.loadingModalSave,
});

const mapDispatchToProps = (dispatch) => ({
  deletarAfastamento: (body, filtro) =>
    dispatch(actions.deletarAfastamento(body, filtro)),
  atualizarAfastamento: (body, filtro) =>
    dispatch(actions.atualizarAfastamento(body, filtro)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AtendentesAfastamentoTabela);
