import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Row,
  Col,
  Divider,
  List,
  Typography,
  message,
} from "antd";
import BasicModal from "Components/BasicModal";
import { connect } from "react-redux";
import * as actions from "../action";
import _ from "lodash";

export const TransferirTarefasModal = (props) => {
  const { Text } = Typography;
  const [selected, setSelected] = useState([]);

  const handleClick = async (toAtendId) => {
    if (!_.isEmpty(selected)) {
      const info = {
        token: "gecro",
        idEnfermeiroSolicitante: user.id,
        idEnfermeiroAtual: filtro.atendente,
        idNovoEnfermeiro: toAtendId,
        idTipoTarefa: selected,
      };

      await transferirTarefas(info);
      //Atualiza Valores da Tela
      await buscarOpcoesTransferenciaTarefas(filtro, selectedUbs);
      await buscarDetalhamentoDashboard(filtro);
      setSelected([]);
    } else {
      message.warn("Selecione ao menos uma tarefa para transferir.");
    }
  };

  const {
    user,
    transferModal,
    fecharModal,
    tasksAtendenteFiltrado,
    todosAtendentes,
    filtro,
    buscarOpcoesTransferenciaTarefas,
    opcoesLoading,
    transferLoading,
    transferirTarefas,
    buscarDetalhamentoDashboard,
    selectedUbs,
  } = props;

  const buildTasksList = (tarefas) => {
    let {
      boasVindasValue,
      adesaoValue,
      rotinaValue,
      urgenciaValue,
      ausenciaValue,
      reativValue,
      atendSoliValue,
      medicoesIncomunsValue
    } = 0;

    tarefas.forEach((tar) => {
      switch (tar.id) {
        case 1:
          boasVindasValue = tar.valor ? tar.valor : 0;
          break;

        case 2:
          adesaoValue = tar.valor ? tar.valor : 0;
          break;

        case 3:
          rotinaValue = tar.valor ? tar.valor : 0;
          break;

        case 4:
          urgenciaValue = tar.valor ? tar.valor : 0;
          break;

        case 5:
          ausenciaValue = tar.valor ? tar.valor : 0;
          break;

        case 7:
          atendSoliValue = tar.valor ? tar.valor : 0;
          break;

        case 8:
          reativValue = tar.valor ? tar.valor : 0;
          break;

        case 9:
          medicoesIncomunsValue = tar.valor ? tar.valor : 0;
          break;

        default:
          break;
      }
    });

    const data = [
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Ligação rotina
              <span style={{ marginLeft: "40%" }}>{rotinaValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Boas Vindas
              <span style={{ marginLeft: "40%" }}>{boasVindasValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Ausência reporte
              <span style={{ marginLeft: "40%" }}>{ausenciaValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Adesão<span style={{ marginLeft: "40%" }}>{adesaoValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Urgência Médica
              <span style={{ marginLeft: "40%" }}>{urgenciaValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Atendimento solicitado
              <span style={{ marginLeft: "40%" }}>{atendSoliValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Reativação<span style={{ marginLeft: "40%" }}>{reativValue}</span>
            </div>
          </Text>
        ),
      },
      {
        title: (
          <Text>
            <div className="label-tasks-col">
              Medições Incomuns<span style={{ marginLeft: "40%" }}>{medicoesIncomunsValue}</span>
            </div>
          </Text>
        ),
      },
    ];

    return data;
  };

  useEffect(() => {
    buscarOpcoesTransferenciaTarefas(filtro, selectedUbs);
  }, [buscarOpcoesTransferenciaTarefas, filtro, selectedUbs]);

  return (
    <BasicModal
      title="Transferir Tarefas"
      visible={transferModal}
      onClose={fecharModal}
      width="60vw"
      destroyOnClose
      bodyStyle={{}}
      style={{ minWidth: "400px", marginTop: "1%" }}
      footer={[
        <Button
          className="secondary-btn"
          key="back"
          onClick={fecharModal}
          disabled={opcoesLoading || transferLoading}
        >
          Cancelar
        </Button>,
      ]}
    >
      <div>
        <span>Selecione abaixo as tarefas que deseja transferir:</span>
        <br />
        <span>
          <i>
            (Lembrete: São apresentadas <b>TODAS</b> as tarefas pendentes do
            atendente )
          </i>
        </span>
        <Checkbox.Group
          style={{ width: "100%", marginTop: 10 }}
          onChange={setSelected}
        >
          <Row>
            {tasksAtendenteFiltrado.map((tarefa, i) => {
              return (
                tarefa.valor !== 0 && (
                  <Col span={8}>
                    <Checkbox style={{ padding: 2 }} value={tarefa.id}>
                      {`${tarefa.label} (${tarefa.valor})`}
                    </Checkbox>
                  </Col>
                )
              );
            })}
          </Row>
        </Checkbox.Group>
      </div>
      <Divider />
      <div className="header-list">
        <h4>Atendente</h4>
        <h4>Tarefas</h4>
        <h4>Qtde tarefas atribuidas</h4>
      </div>
      <Divider style={{ margin: 0, background: "#C0C0C0", height: 1.2 }} />
      {todosAtendentes.map((tarefa) => {
        return (
          <>
            {tarefa.id !== filtro.atendente && (
              <>
                <div className="components-list">
                  <div className="clerk-col">
                    <span>{tarefa.atendente}</span>
                  </div>
                  <div className="tasks-col">
                    <List
                      itemLayout="horizontal"
                      dataSource={buildTasksList(tarefa.tarefas)}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            style={{ marginLeft: 30 }}
                            description={item.title}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                  <Button
                    type="primary"
                    style={{
                      fontWeight: "bold",
                      width: 110,
                      marginLeft: "25%",
                    }}
                    value={tarefa.id}
                    loading={transferLoading}
                    disabled={transferLoading}
                    onClick={(e) => handleClick(e.target.value)}
                  >
                    Transferir
                  </Button>
                </div>
                <Divider />
              </>
            )}
          </>
        );
      })}
    </BasicModal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fecharModal: () => dispatch(actions.fecharModalTransferTasks()),
  buscarOpcoesTransferenciaTarefas: (filter, selectedUbs) =>
    dispatch(actions.buscarOpcoesTransferenciaTarefas(filter, selectedUbs)),
  transferirTarefas: (info) => dispatch(actions.transferirTarefas(info)),
  buscarDetalhamentoDashboard: (body) =>
    dispatch(actions.buscarDetalhamentoDashboard(body)),
});

const mapStateToProps = (store) => ({
  transferModal: store.dashboard.transferTasksModal,
  filtro: store.dashboard.filtro,
  tasksAtendenteFiltrado: store.dashboard.tasksAtendenteFiltrado,
  todosAtendentes: store.dashboard.todosAtendentes,
  user: store.login.user,
  transferLoading: store.dashboard.transferLoading,
  selectedUbs: store.dashboard.selectedUbs,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferirTarefasModal);
