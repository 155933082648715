import * as types from "./actionType";
import * as services from "./service";
import * as typesAdesao from "../../Pages/Adesao/actionType";
import * as typesRotina from "../../Pages/Rotina/actionType";
import { sendErrorMsg } from "Misc/utils";

export const getInfo = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_INFO });
    const response = await services.getInfo(pacienteID);
    dispatch({
      type: types.FETCHING_INFO_COMPLETE,
      payload: response
    });
  } catch (error) {
    dispatch({ type: types.FETCHING_INFO_ERROR });
    sendErrorMsg(error);
  }
};

export const getFotoPaciente = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_PHOTO });
    const response = await services.getFotoPaciente(pacienteID);
    dispatch({
      type: types.FETCHING_PHOTO_COMPLETE,
      payload: response
    });
  } catch (error) {
    dispatch({ type: types.FETCHING_PHOTO_ERROR });
  }
};

export const editInfo = paciente => async dispatch => {
  const newPacienteInfo = paciente;

  dispatch({ type: types.EDITED_INFO, payload: newPacienteInfo });
};

export const atualizarInfoPessoal = (
  paciente,
  pacienteID,
  tarefa,
  step,
  tipo
) => async dispatch => {
  try {
    const {
      ncto,
      cep,
      uf,
      bairro,
      cidade,
      numero,
      complemento,
      logradouro,
      ...rest
    } = paciente;

    const newPacienteInfo = {
      ncto: ncto,
      endereco: {
        cep: cep,
        logradouro: logradouro,
        cidade: cidade,
        uf: uf,
        complemento: complemento,
        bairro: bairro,
        numero: numero
      },
      ...rest
    };

    const newJSON = {
      token: "gecro",
      paciente: pacienteID,
      ncto: ncto,
      endereco: {
        cep: cep,
        logradouro: logradouro,
        cidade: cidade,
        uf: uf,
        complemento: complemento,
        bairro: bairro,
        numero: numero
      },
      ...rest
    };


    const response = await services.attInfoPessoal(tarefa, newJSON);
    let newStep = step + 1;
    if (step === 6) {
      newStep = step;
    }

    await dispatch({ type: types.UPDATE_INFO, payload: newPacienteInfo });

    await dispatch({
      type: typesAdesao.CHANGE_PACIENTE,
      payload: response.paciente
    });
    await dispatch({ type: typesAdesao.CHANGE_STEP, payload: newStep });

    dispatch({
      type: typesRotina.MODIFY_FIELDS,
      payload: { infoPessoal: false }
    });
  } catch (error) {
    dispatch({ type: types.UPDATED_INFO_ERROR });
    sendErrorMsg(error);
  }
};
