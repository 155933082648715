import api from "api";

export const getRotinaGeral = async (pacienteID) => {
  const res = await api.post("/backoffice/rotinaGeral", {
    token: "gecro",
    paciente: pacienteID,
  });
  return res.data;
};

export const getFollowUp = async (pacienteID, tarefa) => {
  const res = await api.post(
    "/backoffice/buscarQuestionarioRotina",
    {
      token: "gecro",
      paciente: pacienteID,
      tarefa: tarefa,
    }
  );
  return res.data;
};

export const saveFollowUp = async (body) => {
  const res = await api.post(
    "/backoffice/salvarQuestionarioRotina",
    body
  );
  return res.data;
};

export const finalizarRotina = async (pacienteID, tarefa) => {
  const res = await api.post(
    "/backoffice/finalizarRotina",
    {
      token: "gecro",
      paciente: pacienteID,
      tarefa: tarefa,
    }
  );
  return res.data;
};

export const buscarHistoricoObservacoes = async (body) => {
  const res = await api.post(
    "/backoffice/buscarHistoricoObservacoes",
    body
  );
  return res.data;
};

export const buscarHistoricoGraficos = async (body) => {
  const sonoResponse = await api.post("/backoffice/buscarClassificacaoSono", body);
  const res = await api.post("/backoffice/buscarHistoricoMedicoes", body);

  const index = res.data.parametros.findIndex(
    (obj) => obj.titulo === "Qualidade do sono"
  );
  const newSleep = sonoResponse.data.parametros[0];

  if (index !== -1) {
    res.data.parametros[index] = newSleep;
  }

  return res.data;
};

export const enviarRelatorioAcompanhamento = async (body) => {
  const res = await api.post(
    "/backoffice/enviarRelatorio",
    body
  );
  return res.data;
};

export const buscarHistoricoRelatorios = async (body) => {
  const res = await api.post(
    "/backoffice/buscarHistoricoRelatorios",
    body
  );
  return res.data;
};
