import React from "react";
import "./style.scss";
import FiltroMeusPacientes from "../FiltroMeusPacientes";

const ColunaFiltro = () => {
  return (
    <>
      <div className="layoutColunaFiltro">
        <div>
          <span>{"Plano de Cuidados"}</span>
        </div>
        <div className="coluna-overflow-filter">
          <FiltroMeusPacientes />
        </div>
      </div>
    </>
  );
};

export default ColunaFiltro;
