import { Badge, Icon } from "antd";
import { Link, useHistory } from "react-router-dom";
import React from "react";
import "./style.scss";
import { connect } from "react-redux";
import { showConfirmationTab } from "Misc/utils";

const UrgencyBadge = ({ urgencyCount, unsaved }) => {
  let history = useHistory();
  const scrollTop = () => {
    const el = document.querySelector(
      ".layout-painel .layoutColuna .coluna-overflow"
    );
    if (el)
      el.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  const showConfirm = (answers) => {
    if (
      !(
        history.location.pathname.startsWith("/rotina") ||
        history.location.pathname.startsWith("/ausencia") ||
        history.location.pathname.startsWith("/atendimento") ||
        history.location.pathname.startsWith("/pacientes") ||
        history.location.pathname.startsWith("/urgencia") 
      )
    ) {
      if (!history.location.pathname.startsWith("/painel")) history.push("/painel")
      scrollTop();
      return;
    }
    const auxArray = [];
    Object.keys(unsaved).forEach((item) => {
      if (unsaved[item]) {
        auxArray.push(item);
      }
    });

    if (auxArray.length > 0) {
      return showConfirmationTab(
        auxArray,
        () => {
          history.push("/painel");
          scrollTop();
        },
        () => {},
        "Ao sair da página, as alterações não serão salvas. Deseja continuar mesmo assim?",
        "Sim, continuar"
      );
    } else {
      history.push("/painel");
      scrollTop();
    }
  };

  return (
    <div
      className={"urgency-badge " + (urgencyCount > 0 && "animated")}
      style={{ marginRight: "1rem" }}
    >
      <Link disabled={urgencyCount > 0 ? false : true} onClick={showConfirm}>
        <Badge count={urgencyCount}>
          <Icon
            type="bell"
            theme={urgencyCount > 0 && "filled"}
            style={{
              fontSize: urgencyCount > 0 ? "1.5rem" : "1.2rem",
              color: urgencyCount > 0 ? "#f5222d" : "rgba(85, 85, 85, .5)",
            }}
          />
        </Badge>
      </Link>
    </div>
  );
};

// export default UrgencyBadge;

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
});

export default connect(mapStateToProps)(UrgencyBadge);
