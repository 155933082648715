 import React, { useState } from "react";
import { Typography, Radio, Form, Input, Checkbox, Button } from "antd";
import _ from "lodash";
import { DATE_FORMAT } from "Misc/constants";
import { maskDate, futureDate } from "Misc/utils";
import { connect } from "react-redux";
import { setActiveTab, setRelatedQuestion } from "Pages/Rotina/action";

const { Text, Paragraph } = Typography;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

const QuestionFollowUp = ({
  form: { getFieldDecorator },
  required,
  pergunta: {
    nome,
    numero,
    resposta,
    subtitulo,
    somenteExibicao,
    multipleChoice,
    singleChoice,
    posPergunta,
    subitem = [],
    positiva = [],
    hasGrauParentesco,
    grauParentesco,
    dataUltimoExame,
  },
  dispatch,
}) => {
  const [subitemRequired, setSubitemRequired] = useState(resposta);

  const titleStyle = !somenteExibicao
    ? { margin: 0, fontStyle: "italic" }
    : { fontStyle: "italic", marginBottom: "22px" };

  const findTabReference = (questionSubtitle) => {
    const normalized = questionSubtitle
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    if (normalized) {
      if (normalized.includes("informacoes pessoais")) return "2";
      if (normalized.includes("doencas")) return "3";
      if (normalized.includes("medicamentos")) return "4";
      if (normalized.includes("parametros de saude")) return "5";
      if (normalized.includes("questionario")) return "6";
      if (normalized.includes("rede de apoio")) return "7";
      if (normalized.includes("responsavel")) return "8";
      if (normalized.includes("ligacoes periodicas")) return "9";
    } else {
      return "1";
    }
  };

  const handleClick = (tab, questionNumber) => {
    dispatch(setActiveTab(tab));
    if (questionNumber) dispatch(setRelatedQuestion(questionNumber));
  };

  // TODO: Achar o required da data
  return (
    <div style={{ margin: "8px 0" }}>
      <Text level={4}>{nome}</Text>
      {subtitulo && !subtitulo?.tab && (
        <Paragraph type="secondary" style={titleStyle}>
          {subtitulo.text}
        </Paragraph>
      )}

      {!posPergunta && subtitulo && subtitulo?.tab && (
        <Button
          type="link"
          onClick={() =>
            handleClick(findTabReference(subtitulo.tab), subtitulo.question)
          }
          style={{
            display: "block",
            marginBottom: "8px",
            paddingLeft: "0",
            height: "auto",
            whiteSpace: 'initial',
            textAlign: 'left',
          }}
        >
          <Paragraph type="secondary" style={titleStyle}>
            {subtitulo.text.split(subtitulo.tab)[0]}
            <span style={{ color: "#1890ff", textDecoration: "underline" }}>
              {subtitulo.tab}
            </span>
            {subtitulo.text.split(subtitulo.tab)[1]}
          </Paragraph>
        </Button>
      )}

       {!subtitulo && posPergunta && (
        <Button
        type="link"
        onClick={() =>
          handleClick(findTabReference(subtitulo.tab), subtitulo.question)
        }
        style={{
          display: "block",
          marginBottom: "8px",
          paddingLeft: "0",
          height: "auto",
          whiteSpace: 'initial',
          textAlign: 'left',
        }}
      >
        <Paragraph type="secondary" style={titleStyle}>
          {posPergunta}
        </Paragraph>
      </Button>
      )}

      {!somenteExibicao && (
        <>
          <Form.Item>
            {getFieldDecorator(`${numero}.valor`, {
              rules: [
                {
                  required: required,
                  message: "Resposta obrigatória.",
                },
              ],
              initialValue: resposta,
            })(
              <Radio.Group
                buttonStyle="solid"
                size="large"
                onChange={(e) => setSubitemRequired(e.target.value)}
              >
                <Radio.Button value={true}>Sim</Radio.Button>
                <Radio.Button value={false}>Não</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          {dataUltimoExame && subitemRequired && (
            <div>
              <Text className="questionsModal">Data do último exame:</Text>
              <Form.Item>
                {getFieldDecorator(`${numero}.dataUltimoExame`, {
                  rules: [
                    {
                      required: subitemRequired,
                      message: "Resposta obrigatória.",
                    },
                    {
                      pattern: DATE_FORMAT,
                      validator: futureDate,
                      message: "Insira uma data válida.",
                    },
                  ],
                  getValueFromEvent: (e) => maskDate(e.target.value),
                })(
                  <Input
                    style={{ width: "30%", minWidth: "170px" }}
                    size="large"
                    placeholder="DD/MM/AAAA"
                    maxLength={10}
                    allowClear
                  />
                )}
              </Form.Item>
            </div>
          )}
        </>
      )}

      {!singleChoice &&
        !multipleChoice &&
        subitem.map((item) => (
          <React.Fragment key={`subitem_${numero}-${item.numero}`}>
            <Text className="questionsModal">{item.nome}</Text>
            <Form.Item>
              {getFieldDecorator(`${numero}-${item.numero}.valor`, {
                rules: [
                  {
                    required: subitemRequired,
                    message: "Resposta obrigatória.",
                  },
                ],
                initialValue: item.resposta,
              })(
                <Radio.Group buttonStyle="solid" size="large">
                  <Radio.Button value={true}>Sim</Radio.Button>
                  <Radio.Button value={false}>Não</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            {item.alteracoes && (
              <>
                <Text className="questionsModal">
                  Especifique as alterações:
                </Text>
                <Form.Item>
                  {getFieldDecorator(
                    `${numero}-${item.numero}.alteracoes`,
                    {}
                  )(<Input />)}
                </Form.Item>
              </>
            )}
          </React.Fragment>
        ))}

      {singleChoice && (
        <Form.Item>
          {getFieldDecorator(`${numero}.singleChoice`)(
            <Radio.Group>
              {subitem.map((item) => {
                return (
                  <Radio
                    key={`single_choice_${numero}-${item.numero}`}
                    style={radioStyle}
                    value={item.numero}
                  >
                    {item.nome}
                  </Radio>
                );
              })}
            </Radio.Group>
          )}
        </Form.Item>
      )}

      {multipleChoice && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: 0,
            padding: 0,
            marginBottom: "22px",
          }}
        >
          <Text>Selecione:</Text>
          {subitem.map((item, i) => {
            return (
              <div key={`multiple_choice_${i}`} style={{ margin: "4px 0" }}>
                {getFieldDecorator(`${numero}-${item.numero}.valor`, {
                  valuePropName: "checked",
                  initialValue: _.isUndefined(item.resposta)
                    ? false
                    : item.resposta,
                })(<Checkbox>{item.nome}</Checkbox>)}
              </div>
            );
          })}
        </div>
      )}

      {hasGrauParentesco && (
        <>
          <Text className="questionsModal">Grau de parentesco:</Text>
          <Form.Item>
            {getFieldDecorator(`${numero}.grauParentesco`, {
              rules: [
                {
                  required: subitemRequired,
                  message: "Resposta obrigatória.",
                },
              ],
              initialValue: grauParentesco,
            })(<Input />)}
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default connect()(QuestionFollowUp);
