import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";

export const getPatient = mock => async dispatch => {

    try {
      await dispatch({ type: types.FETCHING_PATIENT_INIT });
      const response = await services.getPatient(mock);
      await dispatch({
        type: types.FETCHING_PATIENT_COMPLETE,
        payload: response
      });
      return response;
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.FETCHING_PATIENT_ERROR });
    }
  };

  export const resetFilterPlan = () => (dispatch) => {
    dispatch({ type: types.RESET_PATIENT_FILTER });
  };