/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Coluna from "./ColunaCards";
import DelegateModal from "./DelegateModal";
import "./style.scss";
import { connect } from "react-redux";
import * as actions from "./action";
import * as actionsHeader from "../../Components/Header/action";
import { withPolling } from "./withPolling";
import ColunaFiltro from "./ColunaFiltro";

const PainelAtividades = (props) => {
  const { loading, tarefas, admin } = props;
  const [delegateModal, setDelegateModal] = useState(false);
  const [tarefaAtual, setTarefaAtual] = useState(null);
  useEffect(() => {
    props.changeCabecalhoInfo({
      nome: "Painel de Atendimento",
      criticidade: null,
      tipoTarefa: null,
    });
    props.getTasks(props.user.id);
  }, []);

  const handleDelegate = async (value) => {
    const mock = {
      token: "gecro",
      idEnfermeiroSolicitante: parseInt(props.user.id),
      idEnfermeiroAtual: parseInt(props.user.id),
      idNovoEnfermeiro: parseInt(value),
      idTarefa: parseInt(tarefaAtual),
    };
    await props.sendDelegarTarefas(JSON.stringify(mock));
    setDelegateModal(false);
    await props.getTasks(props.user.id);
  };

  const handleOpenModal = async (id) => {
    const res = await props.getNurses({ tarefa: id });
    if (res) setDelegateModal(true);
    setTarefaAtual(id);
  };

  return (
    <>
      <div className="layout-painel">
        <Coluna
          initTask={props.initTask}
          delegateTask={handleOpenModal}
          admin={admin}
          loading={loading}
          tarefas={tarefas.tarefasPendentes}
          isPendente={true}
        />
        <Coluna
          initTask={props.initTask}
          delegateTask={handleOpenModal}
          admin={admin}
          loading={loading}
          tarefas={tarefas.tarefasAndamento}
          isAndamento={true}
        />
        <Coluna
          initTask={props.initTask}
          delegateTask={handleOpenModal}
          admin={admin}
          loading={loading}
          tarefas={tarefas.tarefasConcluidas}
          isConcluido={true}
        />
        <ColunaFiltro />
      </div>
      <DelegateModal
        visible={delegateModal}
        onOk={handleDelegate}
        confirmLoading={false}
        onCancel={() => setDelegateModal(false)}
      />
    </>
  );
};

const mapStateToProps = (store) => ({
  tarefas: store.painelatividades.tarefas,
  loading: store.painelatividades.loading,
  admin: store.painelatividades.admin,
  success: store.painelatividades.success,
  user: store.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  getTasks: (enfermeiroID) => dispatch(actions.getTasks(enfermeiroID)),
  getNurses: (body) => dispatch(actions.getNurses(body)),
  initTask: (enfermeiroID, tarefaID) =>
    dispatch(actions.initTask(enfermeiroID, tarefaID)),
  changeCabecalhoInfo: (info) =>
    dispatch(actionsHeader.changeCabecalhoInfo(info)),
  sendDelegarTarefas: (info) => dispatch(actions.sendDelegarTarefas(info)),
});

export default withPolling(actions.getTasksWithPolling)(
  connect(mapStateToProps, mapDispatchToProps)(PainelAtividades)
);
