import * as types from "./actionsType";
import * as typesRotina from "Pages/Rotina/actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";

export const getInfoQuestionario = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_QUESTIONARIO });
    const response = await services.getInfoQuestionario(pacienteID);
    dispatch({
      type: types.FETCHING_QUESTIONARIO_COMPLETE,
      payload: response
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_QUESTIONARIO_ERROR });
  }
};

export const editInfo = (req, questions, restCall = true) => async dispatch => {
  try {
    // dispatch({ type: types.ATT_QUESTIONS });

    if (restCall){
      await services.attQuestionario(req);
    }
    dispatch({
      type: types.ATT_QUESTIONS_COMPLETE,
      payload: { req, questions }
    });
    dispatch({
      type: typesRotina.MODIFY_FIELDS,
      payload: { questionario: false }
    });
  } catch (error) {
    // dispatch({ type: types.FETCHING_QUESTIONARIO_ERROR });
    sendErrorMsg(error);
  }
};


export const setRefs = (ref) => (dispatch) => {
  dispatch({
    type: types.SET_REFS,
    payload: ref,
  });
};

export const setActiveRef = (ref) => (dispatch) => {
  dispatch({
    type: types.SET_ACTIVE_REF,
    payload: ref,
  });
};

export const goToRef = (ref) => (dispatch) => {
  dispatch({
    type: types.GO_TO_REF,
    payload: ref,
  });
  return ref.current?.scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "start",
  });
};
