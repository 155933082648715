import React, { useState } from "react";
import { Typography, Form, Input, Select, Icon, Button } from "antd";
import "./style.scss";
import { getDadosAmbientais, getEndereco } from "./service";
import CardComponent from "Components/CardComponent";
import DadosAmbientaisModal from "./DadosAmbientaisModal";
import { useParams } from "react-router-dom";
import { sendErrorMsg } from "Misc/utils";

const { Text } = Typography;
const { Option } = Select;

const Endereco = (props) => {
  const {
    getFieldDecorator,
    isRequired,
    decoratorCep,
    decoratorRua,
    decoratorNumero,
    decoratorComplemento,
    decoratorBairro,
    decoratorCidade,
    decoratorEstado,
    resetFields,
    rotina,
    formItemLayout,
    titulo,
    subtitulo,
    readOnly,
  } = props;

  const states = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  const [rua, setRua] = useState(null);
  const [bairro, setBairro] = useState(null);
  const [cidade, setCidade] = useState(null);
  const [uf, setUF] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dadosModal, setDadosModal] = useState({});
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const { tarefa } = useParams();
  const [loadingModal, setLoadingModal] = useState(false);

  const buscarEndereco = async (cep) => {
    if (String(cep).length === 8) {
      setLoading(true);

      let data = await getEndereco(cep);
      if (data.endereco) {
        resetFields([
          decoratorRua,
          decoratorNumero,
          decoratorComplemento,
          decoratorBairro,
          decoratorCidade,
          decoratorEstado,
        ]);
        setRua(data.endereco.logradouro.slice(0, 60));
        setBairro(data.endereco.bairro.slice(0, 30));
        setCidade(data.endereco.cidade.slice(0, 25));
        setUF(data.endereco.uf);
      }
      setLoading(false);
    } else {
      setRua("");
      setBairro("");
      setCidade("");
      setUF("");
    }
  };

  const handleCancelar = async (value) => {
    setModalIsVisible(false);
    setTimeout(() => {
      setDadosModal({});
    }, 1000);
  };

  const handleOpenModal = async (id, nome) => {
    setLoadingModal(true);
    await getDadosAmbientais(tarefa)
      .then((res) => {
        setDadosModal(res.data)
        setModalIsVisible(true)
      })
      .catch((error) => sendErrorMsg(error));
    setLoadingModal(false);
  };

  const modal = () => {
    return (
      <DadosAmbientaisModal
        visible={modalIsVisible}
        confirmLoading={false}
        onCancel={handleCancelar}
        dados={dadosModal}
      />
    );
  };

  return (
    <>
      {modal()}
      <CardComponent
        title="Endereço"
        classText="layoutInformacaoPessoal"
        rotina={rotina}
      >
        {!rotina && (
          <>
            <div className="cadastro-object-title">
              <Icon style={{ marginRight: "10px" }} type="home" />
              <Text className="cadastro-section-title">{titulo}</Text>
            </div>
            <Text className="cadastro-section-description color-gray">
              {subtitulo}
            </Text>
          </>
        )}
        <Form
          style={{ width: "100%" }}
          hideRequiredMark={true}
          colon={!!rotina}
          {...formItemLayout}
        >
          <Form.Item label="CEP">
            {getFieldDecorator(decoratorCep, {
              rules: [{ required: isRequired, message: "Insira o cep." }],
              initialValue: props.cep,
              getValueFromEvent: (cep) => {
                buscarEndereco(
                  cep.target.value.replace(/\D/gi, "").substring(0, 8)
                );
                return cep.target.value.replace(/\D/gi, "").substring(0, 8);
              },
            })(
              <Input
                size="large"
                style={{ width: "40%", minWidth: "170px" }}
                suffix={loading ? <Icon type="loading" /> : ""}
                allowClear
                disabled={readOnly}
              />
            )}
          </Form.Item>
          <DivFlex rotina={rotina}>
            <Form.Item
              style={!rotina ? { width: "50%", marginRight: "1vw" } : {}}
              label="Rua, avenida, etc"
            >
              {getFieldDecorator(decoratorRua, {
                rules: [{ required: isRequired, message: "Insira uma rua." }],
                initialValue: rua || props.logradouro,
              })(
                <Input
                  disabled={loading || readOnly}
                  size="large"
                  style={
                    !!rotina ? { maxWidth: "500px", minWidth: "170px" } : {}
                  }
                  maxLength={60}
                />
              )}
            </Form.Item>
            <Form.Item
              style={!rotina ? { width: "15%", marginRight: "1vw" } : {}}
              label="Número"
            >
              {getFieldDecorator(decoratorNumero, {
                rules: [{ required: isRequired, message: "Insira um número." }],
                initialValue: props.numero,
              })(
                <Input
                  style={!!rotina ? { width: "20%", minWidth: "100px" } : {}}
                  disabled={loading || readOnly}
                  size="large"
                  maxLength={20}
                />
              )}
            </Form.Item>
            <Form.Item
              style={!rotina ? { width: "29%", marginRight: "1vw" } : {}}
              label={!rotina ? "Complemento (opcional)" : "Complemento"}
            >
              {getFieldDecorator(decoratorComplemento, {
                initialValue: props.complemento,
              })(
                <Input
                  style={!!rotina ? { width: "40%", minWidth: "170px" } : {}}
                  disabled={loading || readOnly}
                  size="large"
                  maxLength={20}
                />
              )}
              {!!rotina && (
                <span className="color-gray" style={{ marginLeft: "8px" }}>
                  (opcional)
                </span>
              )}
            </Form.Item>
          </DivFlex>
          <DivFlex rotina={rotina}>
            <Form.Item
              style={!rotina ? { width: "24%", marginRight: "1vw" } : {}}
              label="Bairro"
            >
              {getFieldDecorator(decoratorBairro, {
                rules: [{ required: isRequired, message: "Insira o bairro." }],
                initialValue: bairro || props.bairro,
              })(
                <Input
                  style={!!rotina ? { width: "40%", minWidth: "170px" } : {}}
                  disabled={loading || readOnly}
                  size="large"
                  maxLength={30}
                />
              )}
            </Form.Item>
            <Form.Item
              style={!rotina ? { width: "24%", marginRight: "1vw" } : {}}
              label="Cidade"
            >
              {getFieldDecorator(decoratorCidade, {
                rules: [
                  { required: isRequired, message: "Insira uma cidade." },
                ],
                initialValue: cidade || props.cidade,
              })(
                <Input
                  style={!!rotina ? { width: "40%", minWidth: "170px" } : {}}
                  disabled={loading || readOnly}
                  size="large"
                  maxLength={25}
                />
              )}
            </Form.Item>
            <Form.Item
              style={!rotina ? { width: "15%", marginRight: "1vw" } : {}}
              label="Estado"
            >
              {getFieldDecorator(decoratorEstado, {
                rules: [
                  { required: isRequired, message: "Selecione um estado." },
                ],
                initialValue: uf || props.uf,
              })(
                <Select
                  style={!!rotina ? { width: "20%", minWidth: "100px" } : {}}
                  disabled={loading || readOnly}
                  size="large"
                >
                  {states.map((state) => {
                    return (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </DivFlex>
        </Form>
        <Button
          className="buttonFooterBack"
          onClick={() => handleOpenModal()}
          style={{ display: "table", marginLeft: "auto" }}
          disabled={!props.cep}
          loading={loadingModal}
        >
          Verificar previsão do tempo
        </Button>
      </CardComponent>
    </>
  );
};

const DivFlex = ({ rotina, children }) => {
  if (!!rotina) {
    return <>{children}</>;
  } else {
    return <div style={{ display: "flex" }}>{children}</div>;
  }
};

export default Endereco;
