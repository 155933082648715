import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import openWeatherLogo from "Assets/Images/OpenWeather.png";

const DadosAmbientaisModal = (props) => {
  const { onCancel, dados, ...rest } = props;

  const [formattedRecomendacao, setFormattedRecomendacao] = useState([]);

  const handleCloseModal = async () => {
    onCancel();
  };

  useEffect(() => {
    if (dados?.recomendacaoGeral) {
      const text = dados?.recomendacaoGeral.split("\n");
      setFormattedRecomendacao(text);
    }
  }, [dados]);

  return (
    <Modal
      wrapClassName="modal-delegate"
      title="Previsão do Tempo"
      width={"55vw"}
      style={{ minWidth: "700px" }}
      centered={true}
      bodyStyle={{
        maxHeight: "calc(90vh - 110px)",
        overflowY: "auto",
      }}
      onCancel={handleCloseModal}
      footer={[
        <Button key="button" type="primary" onClick={handleCloseModal}>
          Fechar
        </Button>,
      ]}
      {...rest}
    >
      <span className="location">
            <svg
              width="12"
              height="14"
              viewBox="0 0 12 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.99714 13.3333C5.87769 13.3333 5.75964 13.3138 5.64297 13.2749C5.5263 13.236 5.42352 13.1721 5.33464 13.0833C4.86797 12.661 4.36241 12.1749 3.81797 11.6249C3.27352 11.0749 2.76797 10.4833 2.3013 9.84992C1.83464 9.21659 1.44575 8.55547 1.13464 7.86659C0.823524 7.1777 0.667969 6.48881 0.667969 5.79992C0.667969 4.13325 1.20408 2.80547 2.2763 1.81659C3.34852 0.827696 4.59019 0.333252 6.0013 0.333252C7.41241 0.333252 8.65408 0.827696 9.7263 1.81659C10.7985 2.80547 11.3346 4.13325 11.3346 5.79992C11.3346 6.48881 11.1791 7.1777 10.868 7.86659C10.5569 8.55547 10.168 9.21659 9.7013 9.84992C9.23464 10.4833 8.72908 11.0749 8.18464 11.6249C7.64019 12.1749 7.13464 12.661 6.66797 13.0833C6.57908 13.1721 6.47491 13.236 6.35547 13.2749C6.23602 13.3138 6.11658 13.3333 5.99714 13.3333ZM6.00279 6.83325C6.32402 6.83325 6.59852 6.71887 6.8263 6.4901C7.05408 6.26135 7.16797 5.98635 7.16797 5.6651C7.16797 5.34387 7.05359 5.06936 6.82482 4.84159C6.59606 4.61381 6.32106 4.49992 5.99982 4.49992C5.67859 4.49992 5.40408 4.6143 5.1763 4.84307C4.94852 5.07182 4.83464 5.34682 4.83464 5.66807C4.83464 5.9893 4.94902 6.26381 5.17779 6.49159C5.40654 6.71936 5.68154 6.83325 6.00279 6.83325Z"
                fill="#888888"
              />
            </svg>
            {dados?.cidade}, {dados?.uf}
          </span>
          <div className="dados">
            <div>
              <svg
                width="14"
                height="28"
                viewBox="0 0 14 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.2487 27.3334C5.40425 27.3334 3.83203 26.6834 2.53203 25.3834C1.23203 24.0834 0.582031 22.5112 0.582031 20.6667C0.582031 19.5334 0.837587 18.4667 1.3487 17.4667C1.85981 16.4667 2.61536 15.7223 3.61536 15.2334V4.30008C3.61536 3.29081 3.96861 2.43295 4.6751 1.72648C5.38156 1.01999 6.23943 0.666748 7.2487 0.666748C8.25796 0.666748 9.11583 1.01999 9.8223 1.72648C10.5288 2.43295 10.882 3.29081 10.882 4.30008V15.2334C11.882 15.7223 12.6376 16.4667 13.1487 17.4667C13.6598 18.4667 13.9154 19.5334 13.9154 20.6667C13.9154 22.5112 13.2654 24.0834 11.9654 25.3834C10.6654 26.6834 9.09314 27.3334 7.2487 27.3334ZM5.61536 12.8334H8.88203V11.0667H7.2487V9.76675H8.88203V6.86675H7.2487V5.58341H8.88203V4.30008C8.88203 3.8373 8.72511 3.44939 8.41126 3.13635C8.09742 2.82328 7.70853 2.66675 7.2446 2.66675C6.78066 2.66675 6.39314 2.82328 6.08203 3.13635C5.77092 3.44939 5.61536 3.8373 5.61536 4.30008V12.8334Z"
                  fill="#FF9800"
                />
              </svg>
              <p>
                Temperatura:{" "}
                <strong>{dados && Math.round(+dados?.temperatura)} °C</strong>
              </p>
            </div>
            <div>
              <svg
                width="22"
                height="26"
                viewBox="0 0 22 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7487 25.6666C7.79314 25.6666 5.27648 24.6476 3.1987 22.6096C1.12092 20.5716 0.0820312 18.0906 0.0820312 15.1666C0.0820312 13.7634 0.354253 12.4215 0.898698 11.1409C1.44314 9.86022 2.21536 8.7299 3.21536 7.74992L10.7487 0.333252L18.282 7.74992C19.282 8.7299 20.0543 9.86022 20.5987 11.1409C21.1431 12.4215 21.4154 13.7634 21.4154 15.1666C21.4154 18.0906 20.3765 20.5716 18.2987 22.6096C16.2209 24.6476 13.7043 25.6666 10.7487 25.6666ZM2.08203 15.1666H19.4154C19.4154 14.0333 19.1987 12.9555 18.7654 11.9333C18.332 10.911 17.7265 10.011 16.9487 9.23325L10.7487 3.13325L4.5487 9.23325C3.77092 10.011 3.16536 10.9115 2.73203 11.9347C2.2987 12.9578 2.08203 14.0351 2.08203 15.1666Z"
                  fill="#0F79B2"
                />
              </svg>

              <div className="text">
                <p>
                  Umidade: <strong>{dados?.umidade}%</strong>
                </p>
                <p>
                  Condição do ar:{" "}
                  <strong style={{ marginRight: ".5rem" }}>
                    {dados?.nomeCondicao}
                  </strong>
                  <br />
                  Estado de: <strong>{dados?.nomeEstado}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="recomendacoes">
            <p>Sobre a umidade do ar:</p>
            <div>
              {dados?.recomendacaoGeral && (
                <p>
                  {formattedRecomendacao.map((line) => (
                    <>
                      {line}
                      <br />
                    </>
                  ))}
                </p>
              )}
              {dados?.recomendacoes && dados?.recomendacoes.length === 0 ? (
                <strong>Nenhuma recomendação</strong>
              ) : (
                <>
                  <strong>Recomendações</strong>
                  <ul style={{ marginBottom: "0px" }}>
                    {dados?.recomendacoes &&
                      dados?.recomendacoes.map((recomendacao) => (
                        <li>{recomendacao}</li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          </div>
          <div className="credits">
            <img src={openWeatherLogo} alt="Logo da OpenWeather" />
            <span>
              Dados fornecidos pela{" "}
              <a
                href="https://openweathermap.org/"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#ea6d4a" }}
              >
                OpenWeather
              </a>
            </span>
          </div>
    </Modal>
  );
};

export default DadosAmbientaisModal;
