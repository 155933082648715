import React from "react";
import { Modal, Button } from "antd";

const BasicModal = ({
  title,
  visible,
  onClose,
  onOk,
  okText,
  cancelText,
  children,
  ...others
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onClose}
      width={"55vw"}
      style={{ minWidth: "700px" }}
      centered={true}
      bodyStyle={{
        height: "calc(90vh - 110px)",
        overflowY: "auto"
      }}
      footer={[
        <Button className="secondary-btn" key="back" onClick={onClose}>
          {cancelText}
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={onOk}
        >
          {okText}
        </Button>
      ]}
      {...others}
    >
      {children}
    </Modal>
  );
};

export default BasicModal;
