import * as types from "./actionType";

const initialState = {
  cuidador: null,
  secundarios: [],
  emergencia: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_REDE_APOIO_COMPLETE:
      return {
        ...state,
        cuidador: action.payload.cuidador,
        secundarios: action.payload.secundarios,
        emergencia: action.payload.emergencia
      };
    case types.FETCHING_REDE_APOIO:
      return {
        ...state,
        cuidador: null,
        secundarios: [],
        emergencia: null
      };

    default:
      return state;
  }
};

export default reducer;
