/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionsHeader from "Components/Header/action";
import * as actions from "./action";
import { useParams } from "react-router-dom";
import { Typography } from "antd";
import _ from "lodash";

// Components
import DashboardHeader from "./Components/DashboardHeader";
import DashboardFooter from "./Components/DashboardFooter";
import DashboardCharts from "./Components/DashboardCharts";
import FilterModal from "./Components/FilterModal";
import Detalhamento from "./Components/Detalhamento";

import "./style.scss";
import TempoMedioCharts from "./Components/TempoMedioCharts";

const Dashboard = ({
  changeCabecalhoInfo,
  changeBackingValue,
  buscarDashboard,
  resetFiltro,
  buscarTMEeTMA,
  frase,
  isBacking,
  filtro: storeFiltro,
}) => {
  const { tipo, filtro } = useParams();
  const { Text } = Typography;

  // console.log("storeFiltro", storeFiltro);
  // console.log("_.isEmpty(storeFiltro)", _.isEmpty(storeFiltro));

  useEffect(() => {
    if (tipo === "detalhamento") {
      changeCabecalhoInfo({
        nome: (
          <Text>
            Detalhamento - <span style={{ fontWeight: 600 }}>{frase}</span>
          </Text>
        ),
      });
    } else if (tipo === "tempo-medio") {
      changeCabecalhoInfo({
        nome: `Tempo Médio de Espera (TME) e Tempo Médio de Atendimento (TMA) por ${filtro}/Dashboard`,
      });
      if (filtro === 'dia') {
        if (isBacking) {
          buscarTMEeTMA(storeFiltro);
        } else {
          buscarTMEeTMA({});
        }
      } else {
        if (isBacking) {
          buscarTMEeTMA({
            dataInicio: new Date().toLocaleDateString('pt-BR'),
            dataFim: new Date().toLocaleDateString('pt-BR'),
            ...storeFiltro,
          });
        } else {
          buscarTMEeTMA({
            dataInicio: new Date().toLocaleDateString('pt-BR'),
            dataFim: new Date().toLocaleDateString('pt-BR'),
          });
        }
      }
    } else {
      changeCabecalhoInfo({
        nome: "Atendimentos/Dashboard",
      });
      if (isBacking) {
        buscarDashboard(storeFiltro);
      } else {
        buscarDashboard({});
      }
    }
    changeBackingValue(false)
  }, [tipo, filtro]);

  return (
    <>
      {tipo === "detalhamento" && <Detalhamento />}
      {tipo === "tempo-medio" ? (
        <div className="dashboard">
          <DashboardHeader />
          <TempoMedioCharts />
          <DashboardFooter />
        </div>
      ) : (
        <div className="dashboard">
          <DashboardHeader />
          <DashboardCharts />
          <DashboardFooter />
        </div>
      )}
      <FilterModal />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeCabecalhoInfo: (info) =>
    dispatch(actionsHeader.changeCabecalhoInfo(info)),
  buscarDashboard: (body) => dispatch(actions.buscarDashboard(body)),
  buscarTMEeTMA: (body) => dispatch(actions.buscarTMEeTMA(body)),
  resetFiltro: () => dispatch(actions.resetFiltro()),
  changeBackingValue: (state) => dispatch(actions.changeBackingValue(state))
});

const mapStateToProps = (store) => ({
  loading: store.dashboard.loading,
  frase: store.dashboard.frase,
  filtro: store.dashboard.filtro,
  isBacking: store.dashboard.isBacking,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
