import api from '../../api'

export const buscarIndice = async (paciente) => {
  const res = await api.post('/backoffice/buscarIndiceIvcf', {
    paciente: paciente,
  })

  return res.data
}

export const buscarHistorico = async (paciente) => {
  const res = await api.get(`/backoffice/buscarHistoricoIvcf/${paciente}`)

  return res.data;
};

