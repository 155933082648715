import React from "react";
import { Typography, Form, Icon } from "antd";
import { InputPhone } from "../FormInputs";
import "./style.scss";
import CardComponent from "Components/CardComponent";

const { Text } = Typography;

const Telefone = (props) => {
  const {
    getFieldDecorator,
    isRequired,
    rotina,
    titulo,
    subtitulo,
    readOnly,
  } = props;
  const formItemLayout = !!rotina
    ? {
        labelCol: { span: 12, xl: 8, xxl: 6 },
        wrapperCol: { span: 12, xl: 14 },
      }
    : {};
  return (
    <>
      <CardComponent
        title="Contato"
        classText="layoutInformacaoPessoal"
        rotina={rotina}
      >
        {!rotina && (
          <>
            <div className="cadastro-object-title">
              <Icon style={{ marginRight: "10px" }} type="phone" />
              <Text className="cadastro-section-title">{titulo}</Text>
            </div>
            <Text className="cadastro-section-description">{subtitulo}</Text>
          </>
        )}
        <Form hideRequiredMark={true} colon={!!rotina} {...formItemLayout}>
          <InputPhone
            isRequired={isRequired}
            getFieldDecorator={getFieldDecorator}
            initialValue={props.telefone}
            decorator="telefone"
            label="Telefone principal"
            style={{ width: "40%", minWidth: "170px" }}
            placeholder="Ex.:99 9 9999-9999"
            colon={!!rotina}
            disabled={readOnly}
          />
          <InputPhone
            isRequired={false}
            getFieldDecorator={getFieldDecorator}
            initialValue={props.telefoneOpcional}
            decorator="telefoneOpcional"
            label={!rotina ? "Outro telefone (opcional)" : "Outro telefone"}
            style={{ width: "40%", minWidth: "170px" }}
            placeholder="Ex.:99 9 9999-9999"
            colon={!!rotina}
            optionalLabel={!!rotina}
            disabled={readOnly}
          />
        </Form>
      </CardComponent>
    </>
  );
};
export default Telefone;
