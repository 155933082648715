export const FETCHING_CONTRATO_RESPONSAVEL =
  "contratoresponsavel.buscando_info";
export const FETCHING_CONTRATO_RESPONSAVEL_COMPLETE =
  "contratoresponsavel.buscou_info";
export const FETCHING_CONTRATO_RESPONSAVEL_ERROR =
  "contratoresponsavel.buscou_info_Error";

export const EDITING_CONTRATO_RESPONSAVEL = "contratoresponsavel.editando_info";
export const EDITED_CONTRATO_RESPONSAVEL = "contratoresponsavel.editou_info";
export const EDITED_CONTRATO_RESPONSAVEL_ERROR =
  "contratoresponsavel.editou_info_Error";

export const SELECT_RESPONSAVEL = "contratoresponsavel.selecionar_responsavel";
