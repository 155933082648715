export const FETCHING_QUESTIONARIO = "questionario.buscando_info";
export const FETCHING_QUESTIONARIO_COMPLETE = "questionario.EDITED_INFO";
export const FETCHING_QUESTIONARIO_ERROR = "questionario.buscou_info_Error";
export const EDITED_INFO = "questionario.editou_info";

export const ATT_QUESTIONS = "questionario.atualiza_info";
export const ATT_QUESTIONS_COMPLETE = "questionario.atualiza_info";

export const SET_REFS =
  "questionario.set_refs";

export const GO_TO_REF =
  "questionario.got_to_ref";

export const SET_ACTIVE_REF =
  "questionario.set_active_ref";
