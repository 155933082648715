import * as types from "./actionType";

const initialState = {
  lista_ausencias: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_AUSENCIAS:
      return {
        ...state,
        lista_ausencias: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
