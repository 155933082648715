import api from "api";

export const abrirAtendimento = async (id) => {
  const res = await api.post(
    "/backoffice/abrirAtendimento",
    {
      paciente: id,
    }
  );

  return res;
};
