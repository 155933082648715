/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, Input, Button, Select } from "antd";
import _ from "lodash";
import { maskCPF, validaCPFUnrequired } from "Misc/utils";
import { connect } from "react-redux";
import * as actions from "../action";

const { Option } = Select;

const PacientesFiltro = ({
  situacoes,
  loadingSituacoes,
  loadingPacientes,
  buscarCadastros,
  resetCadastros,
  salvarFiltro,
  resetFiltro,
  filtro,
  ...props
}) => {
  const { getFieldDecorator, validateFields, getFieldsValue, resetFields } = props.form;

  useEffect(() => {
    resetCadastros();
  }, []);

  const isAllEmpty = () => {
    const values = getFieldsValue(["nome", "cpf"]);
    return !Object.keys(values).some((field) => values[field]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        buscarCadastros(values);
      }
    });
  };

  const handleClear = () => {
    resetFiltro();
    resetFields();
  };

  const nameLayout = {
    labelCol: {
      xs: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 21 },
    },
  };

  return (
    <Form onSubmit={handleSubmit} layout="horizontal">
      <div style={{ display: "flex" }}>
        <Form.Item style={{ width: "30%", marginRight: "25px", marginBottom: '12px' }}>
          {getFieldDecorator("personNome", { initialValue: "Paciente" })(
            <Select>
              <Option key="Paciente" value="Paciente">
                Paciente
              </Option>
              <Option key="Responsavel" value="Responsavel">
                Responsável
              </Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Nome" {...nameLayout} style={{ width: "100%", marginBottom: '12px' }}>
          {getFieldDecorator("nome")(<Input allowClear />)}
        </Form.Item>
      </div>

      <div style={{ display: "flex" }}>
        <Form.Item style={{ width: "30%", marginRight: "25px", marginBottom: '12px' }}>
          {getFieldDecorator("personCPF", { initialValue: "Paciente" })(
            <Select>
              <Option key="Paciente" value="Paciente">
                Paciente
              </Option>
              <Option key="Responsavel" value="Responsavel">
                Responsável
              </Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label="CPF" {...nameLayout} style={{ width: "100%", marginBottom: '12px' }}>
          {getFieldDecorator("cpf", {
            getValueFromEvent: (e) => maskCPF(e.target.value),
            rules: [
              {
                message: "Insira um cpf válido.",
                validator: validaCPFUnrequired,
              },
            ],
          })(<Input maxLength={14} allowClear />)}
        </Form.Item>
      </div>

      <div className="filtro-btn">
      <Button
          disabled={isAllEmpty() || loadingSituacoes || loadingPacientes}
          className="secondary-btn"
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isAllEmpty() || loadingSituacoes}
          loading={loadingPacientes}
          className="primary-btn"
        >
          Buscar
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  loadingPacientes: store.pacientes.loadingPacientes,
  filtro: store.pacientes.filtro,
});

const mapDispatchToProps = (dispatch) => ({
  buscarCadastros: (body) => dispatch(actions.buscarCadastros(body)),
  resetCadastros: () => dispatch(actions.resetCadastros()),
  salvarFiltro: (filtro) => dispatch(actions.saveFiltro(filtro)),
  resetFiltro: () => dispatch(actions.resetFiltro()),
});

const handleChange = (props, changed, allValues) => {
  props.salvarFiltro(allValues);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "filtro_pacientes",
    onValuesChange: _.debounce(handleChange, 300),
  })(PacientesFiltro)
);
