export const FETCHING_TASK = "adesao.buscando_tarefa";
export const FETCHING_TASK_INIT = "adesao.buscou_tarefa_init";
export const FETCHING_TASK_COMPLETE = "adesao.buscou_tarefa";
export const FETCHING_TASK_ERROR = "adesao.buscou_tarefa_Error";

export const CHANGE_STEP = "adesao.muda_passo";
export const INICIAL_STEP = "adesao.reinicia_passos";

export const CHANGE_PACIENTE = "adesao.muda_paciente";

export const COMPLETE_ADESAO = "adesao.completa_adesao";
export const COMPLETE_ADESAO_COMPLETE = "adesao.completou_adesao";
export const COMPLETE_ADESAO_ERROR = "adesao.error_adesao";
