import * as types from "./actionType";

const initialState = {
  opcoesResponsaveis: [],
  cuidadorSelecionado: null,
  responsavel: {
    nome: "",
    telefone: "",
    email: "",
    cpf: "",
    isIdoso: null,
    endereco: {
      cep: "",
      logradouro: "",
      numero: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: ""
    },
    grauParentesco: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_CONTRATO_RESPONSAVEL_COMPLETE:
      return {
        ...state,
        opcoesResponsaveis: action.payload.opcoesResponsaveis
      };
    case types.FETCHING_CONTRATO_RESPONSAVEL:
      return {
        ...state,
        opcoesResponsaveis: [],
        cuidadorSelecionado: null,
        responsavel: {
          nome: "",
          telefone: "",
          email: "",
          cpf: "",
          isIdoso: null,
          endereco: {
            cep: "",
            logradouro: "",
            numero: "",
            complemento: "",
            bairro: "",
            cidade: "",
            uf: ""
          },
          grauParentesco: null
        }
      };

    case types.SELECT_RESPONSAVEL:
      return {
        ...state,
        responsavel: action.payload
      };

    case types.EDITED_CONTRATO_RESPONSAVEL:
      return {
        ...state,
        responsavel: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
