import * as types from "./actionsType";
import * as services from "./service";
import { sendErrorMsg } from 'Misc/utils'

export const getPlanoCuidados = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_PLANO_CUIDADO });
    const response = await services.getPlanoCuidados(pacienteID);
    dispatch({
      type: types.FETCHING_PLANO_CUIDADO_COMPLETE,
      payload: response
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_PLANO_CUIDADO_ERROR });
  }
};

export const finalizarPlanoCuidados = (
  pacienteID,
  tarefaID
) => async dispatch => {
  try {
    dispatch({ type: types.CLOSE_PLANO_CUIDADO });
    const response = await services.finalizarPlanoCuidados(
      pacienteID,
      tarefaID
    );
    dispatch({
      type: types.CLOSE_PLANO_CUIDADO_COMPLETE
    });
    return response;
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.CLOSE_PLANO_CUIDADO_ERROR });
  }
};
