import React from "react";
import { Typography, Select, Tag, Form, Input, InputNumber } from "antd";
import { connect } from "react-redux";
import {
  PERIODICIDADE_LIGACAO,
  DIAS_SEMANA,
  TIME_FORMAT,
} from "Misc/constants";
import { maskTime } from "Misc/utils";
import CardComponent from "Components/CardComponent";
const { Text } = Typography;
const { Option } = Select;

const LigacaoPeriodica = (props) => {
  const {
    form: { getFieldDecorator, getFieldValue },
    rotina,
    readOnly,
  } = props;

  const handleSelectChangePeriodicidade = (value) => {
    props.form.setFieldsValue({
      periodicidade: {
        codigo: value,
      },
    });
  };
  const handleSelectChange = (value) => {
    props.form.setFieldsValue({
      periodicidade: { diaMes: [value, value + 15] },
    });
  };

  const verificaPeriodicidade = () => {
    switch (props.form.getFieldValue("periodicidade.codigo")) {
      case "B":
      case "T":
      case "F":
        return (
          <Form.Item key="input-dia-mes" label="Melhor dia">
            {getFieldDecorator("periodicidade.diaMes[0]", {
              rules: [{ required: true, message: "Insira a quantidade!" }],
              initialValue:
                props.ligacaoPeriodica.periodicidade &&
                props.ligacaoPeriodica.periodicidade.diaMes &&
                props.ligacaoPeriodica.periodicidade.diaMes[0],
            })(
              <InputNumber
                min={1}
                max={31}
                style={{ width: "20%", minWidth: "70px" }}
                disabled={readOnly}
              />
            )}
          </Form.Item>
        );
      case "M":
        return (
          <Form.Item key="input-dia-mes" label="Melhor dia do mês">
            {getFieldDecorator("periodicidade.diaMes[0]", {
              rules: [{ required: true, message: "Insira a quantidade!" }],
              initialValue:
                props.ligacaoPeriodica.periodicidade &&
                props.ligacaoPeriodica.periodicidade.diaMes &&
                props.ligacaoPeriodica.periodicidade.diaMes[0],
            })(
              <InputNumber
                min={1}
                max={31}
                style={{ width: "20%", minWidth: "70px" }}
                disabled={readOnly}
              />
            )}
          </Form.Item>
        );
      case "Q":
        return (
          <>
            <Form.Item
              key="input-dia-mes-quinzenal-1"
              label="Melhor dia do mês"
            >
              {getFieldDecorator("periodicidade.diaMes[0]", {
                rules: [{ required: true, message: "Insira a quantidade!" }],
                initialValue:
                  props.ligacaoPeriodica.periodicidade &&
                  props.ligacaoPeriodica.periodicidade.diaMes &&
                  props.ligacaoPeriodica.periodicidade.diaMes[0],
              })(
                <InputNumber
                  min={1}
                  max={15}
                  style={{ width: "20%", minWidth: "70px" }}
                  onChange={handleSelectChange}
                  disabled={readOnly}
                />
              )}
            </Form.Item>
            <Form.Item key="input-dia-mes-quinzenal-2" label="Próximo dia">
              {getFieldDecorator("periodicidade.diaMes[1]", {
                initialValue:
                  (props.ligacaoPeriodica.periodicidade &&
                    props.ligacaoPeriodica.periodicidade.diaMes &&
                    props.ligacaoPeriodica.periodicidade.diaMes[1]) ||
                  getFieldValue("periodicidade").diaMes[0] + 15,
              })(
                <InputNumber
                  disabled
                  style={{ width: "20%", minWidth: "70px" }}
                />
              )}
            </Form.Item>
          </>
        );
      case "S":
        return (
          <>
            <Form.Item key="input-dia-semana" label="Dia da semana">
              {getFieldDecorator("periodicidade.diaSemana", {
                rules: [{ required: true, message: "Insira os dias!" }],
                initialValue:
                  props.ligacaoPeriodica.periodicidade &&
                  props.ligacaoPeriodica.periodicidade.diaSemana,
              })(
                <Select
                  style={{ width: "30%", minWidth: "170px" }}
                  disabled={readOnly}
                >
                  {DIAS_SEMANA.map((dia) => {
                    return <Option key={dia.valor}>{dia.descricao}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </>
        );

      default:
        break;
    }
  };

  const formItemLayout = !!rotina
    ? {
        labelCol: { span: 12, lg: 8, xl: 6, xxl: 5 },
        wrapperCol: { span: 12, lg: 14 },
      }
    : {};

  return (
    <>
      {!rotina && (
        <div className="cadastro-object-title">
          <Tag color="orange">NOVO</Tag>
          <Text className="cadastro-section-title">Ligação periódica</Text>
        </div>
      )}
      <CardComponent classText="spacing-sections" rotina={rotina}>
        <Form {...formItemLayout} colon={!!rotina} hideRequiredMark>
          <Form.Item key="enfermeiroResponsavel" label="Enfermeiro responsável">
            {getFieldDecorator("enfermeiroResponsavel[id]", {
              rules: [{ required: true, message: "Selecione um enfermeiro!" }],
              initialValue:
                props.ligacaoPeriodica.enfermeiroResponsavel &&
                props.ligacaoPeriodica.enfermeiroResponsavel.id,
            })(
              <Select
                style={{ width: "30%", minWidth: "170px" }}
                disabled={readOnly}
              >
                {props.enfermeiros.map((enfermeiro) => {
                  return (
                    <Option value={enfermeiro.id}>{enfermeiro.nome}</Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item key="enfermeiroSubstituto" label="Enfermeiro substituto">
            {getFieldDecorator("enfermeiroSubstituto[id]", {
              rules: [{ required: true, message: "Selecione um enfermeiro!" }],
              initialValue:
                props.ligacaoPeriodica.enfermeiroSubstituto &&
                props.ligacaoPeriodica.enfermeiroSubstituto.id,
            })(
              <Select
                style={{ width: "30%", minWidth: "170px" }}
                disabled={readOnly}
              >
                {props.enfermeiros.map((enfermeiro) => {
                  return (
                    <Option value={enfermeiro.id}>{enfermeiro.nome}</Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item key="input-periodicidade" label="Periodicidade">
            {getFieldDecorator("periodicidade.codigo", {
              rules: [{ required: true, message: "Selecione uma opção!" }],
              initialValue:
                props.ligacaoPeriodica.periodicidade &&
                props.ligacaoPeriodica.periodicidade.codigo,
            })(
              <Select
                showSearch
                style={{
                  textTransform: "capitalize",
                  width: "30%",
                  minWidth: "170px",
                }}
                placeholder="Selecione"
                optionFilterProp="children"
                onChange={(v) => handleSelectChangePeriodicidade(v)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                disabled={readOnly}
              >
                {PERIODICIDADE_LIGACAO.map((item) => {
                  return <Option value={item.valor}>{item.descricao}</Option>;
                })}
              </Select>
            )}
          </Form.Item>
          {verificaPeriodicidade()}
          <Form.Item
            key="input-horario-acompanhamento"
            label={
              !!rotina ? "Melhor horário" : "Melhor horário para acompanhamento"
            }
          >
            {getFieldDecorator("horario", {
              rules: [
                {
                  required: true,
                  message: "Selecione um horário!",
                  pattern: TIME_FORMAT,
                },
              ],
              getValueFromEvent: (e) => maskTime(e.target.value),
              initialValue: props.ligacaoPeriodica.horario,
            })(
              <Input
                style={{ width: 130 }}
                placeholder="HH:mm"
                maxLength={5}
                allowClear
                disabled={readOnly}
              />
            )}
          </Form.Item>
        </Form>
      </CardComponent>
    </>
  );
};

const mapStateToProps = (store) => ({
  enfermeiros: store.acompanhamento.enfermeiros,
  ligacaoPeriodica: store.acompanhamento.ligacaoPeriodica,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LigacaoPeriodica);
