import api from "api";

export const buscarOpcoesSituacao = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesSituacao"
  );

  return res.data;
};

export const buscarAtendentes = async (body) => {
  const res = await api.post(
    "/backoffice/buscarAtendentes",
    body
  );

  return res.data;
};

export const buscarOpcoesPerfil = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesPerfil"
  );

  return res.data;
};

export const buscarEndereco = async (cep) => {
  const res = await api.post("/cadastro/buscarEndereco", {
    token: "gecro",
    cep: cep,
  });

  return res.data;
};

export const inserirAtendente = async (body) => {
  const res = await api.post(
    "/backoffice/inserirAtendente",
    body
  );

  return res.data;
};

export const atualizarAtendente = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarAtendente",
    body
  );

  return res.data;
};

export const buscarOpcoesAfastamento = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesAfastamento"
  );

  return res.data;
};

export const buscarOpcoesDesligamento = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesDesligamento"
  );

  return res.data;
};

export const registrarAfastamento = async (body) => {
  const res = await api.post(
    "/backoffice/registrarAfastamento",
    body
  );

  return res.data;
};

export const registrarDesligamento = async (body) => {
  const res = await api.post(
    "/backoffice/registrarDesligamento",
    body
  );

  return res.data;
};

export const deletarAfastamento = async (body) => {
  const res = await api.post(
    "/backoffice/removerAfastamento",
    body
  );

  return res.data;
};

export const buscarOpcoesUbs = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesUbs"
  );
  return res.data;
};

export const atualizarAfastamento = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarAfastamento",
    body
  );

  return res.data;
};
