import { Button, notification } from "antd";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useState } from "react";
import { TIPO_TAREFA_URGENTE } from "./constants";
import * as service from "./service";
import { useInterval } from "./useInterval";
import useSound from "use-sound";
import "./style.scss";
import alarm from "./alarm.mp3";
import { connect } from "react-redux";
import UrgencyBadge from "./UrgencyBadge";

const UrgencyPopupBadge = (props) => {
  const [urgencyCount, setUrgencyCount] = useState();
  const [play, { stop }] = useSound(alarm, { volume: 1 });
  const { pathname } = useLocation();

  const fetchTasks = async () => {
    const response = await service.getTasksByType(TIPO_TAREFA_URGENTE);
    if (response.quant !== urgencyCount) {
        setUrgencyCount(response.quant)
    };
  };

  useInterval(async () => {
    fetchTasks();
  }, 5000);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => fetchTasks, []);

  const btn = (
    <Button
      type="primary"
      size="small"
      onClick={() => {
        notification.destroy();
        stop();
      }}
    >
      OK, estou ciente
    </Button>
  );

  const openNotification = () => {
    play();
    notification.open({
      message: "ATENÇÃO",
      className: "urgency-notification",
      description: (
        <p>
          Existem novas tarefas de <strong>URGÊNCIA MÉDICA.</strong> <br /> Por
          favor, inicie o atendimento o mais breve possível.
        </p>
      ),
      style: {
        width: 450,
        backgroundColor: "#E25241",
        color: "#FFF",
      },
      duration: 0,
      btn,
      onClose: stop,
    });
  };

  useEffect(() => {
    if (urgencyCount > 0 && pathname === "/painel") openNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urgencyCount, pathname]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchTasks, [pathname]);

  return <UrgencyBadge urgencyCount={urgencyCount} />;
};

// export default UrgencyPopupBadge;

const mapStateToProps = (store) => ({
  tarefas: store.painelatividades.tarefas,
});

export default connect(mapStateToProps)(UrgencyPopupBadge);
