import React, { useState } from "react";
import "./style.scss";
import { Modal, Select } from "antd";
import { connect } from "react-redux";

const { Option } = Select;

const DelegateModal = ({
  enfermeiros,
  user,
  onOk,
  confirmLoading,
  ...rest
}) => {
  const [value, setvalue] = useState(user.id);
  const handleChange = (value, opt) => {
    setvalue(opt.key);
  };
  
  return (
    <Modal
      wrapClassName="modal-delegate"
      title="Delegar tarefa"
      okText="Confirmar"
      cancelText="Fechar"
      destroyOnClose
      confirmLoading={confirmLoading}
      onOk={() => onOk(value)}
      {...rest}
    >
      <p>Selecione um novo responsável pela tarefa</p>
      <Select
        defaultValue={user.nome}
        style={{ minWidth: 250 }}
        onChange={handleChange}
        showSearch
        filterOption={true}
      >
        {enfermeiros.map((enfermeiro) => (
          <Option
            key={enfermeiro.id}
            value={enfermeiro.id}
            disabled={enfermeiro.afastado}
          >
            {enfermeiro.nome} (
            {enfermeiro.afastado ? "afastado" : enfermeiro.atendimentos})
          </Option>
        ))}
      </Select>
    </Modal>
  );
};

const mapStateToProps = (store) => ({
  enfermeiros: store.painelatividades.enfermeiros,
  confirmLoading: store.painelatividades.confirmLoadingDelegate,
  user: store.login.user,
});

export default connect(mapStateToProps, null)(DelegateModal);
