import * as types from "./actionType";

const initialState = {
  listaPacientes: null,
  loadingPacientes: false,
  filtro: {},
  modalPaciente: false,
  modalResponsavel: { open: false, type: "" },
  modalDesistencia: { open: false, type: "" },
  modalPlano: { open: false, type: "" },
  modalLoading: false,
  cepLoading: false,
  dados: {},
  endereco: {},
  planoPaciente: {},
  planos: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_PACIENTES:
      return {
        ...state,
        listaPacientes: null,
        loadingPacientes: false,
        filtro: {},
      };
    case types.BUSCAR_PACIENTES_INIT:
      return {
        ...state,
        listaPacientes: null,
        loadingPacientes: true,
      };
    case types.BUSCAR_PACIENTES_SUCCESS:
      return {
        ...state,
        listaPacientes: action.payload,
        loadingPacientes: false,
      };
    case types.BUSCAR_PACIENTES_FAIL:
      return {
        ...state,
        listaPacientes: null,
        loadingPacientes: false,
      };
    case types.SAVE_FILTRO_PACIENTES:
      return {
        ...state,
        filtro: action.payload,
      };
    case types.RESET_FILTRO_PACIENTES:
      return {
        ...state,
        filtro: {},
      };
    // Modal
    case types.OPEN_MODAL_PACIENTE:
      return {
        ...state,
        modalPaciente: true,
      };
    case types.OPEN_MODAL_RESPONSAVEL:
      return {
        ...state,
        modalResponsavel: { open: true, type: "update" },
      };

    case types.OPEN_MODAL_DESISTENCIA:
      return {
        ...state,
        modalDesistencia: { open: true, type: action.payload.type },
        dados: { paciente: action.payload.id },
      };

    case types.OPEN_MODAL_PLANO:
      return {
        ...state,
        modalPlano: { open: true, type: action.payload.type },
        dados: { paciente: action.payload.id },
        planos: action.planos,
        planoPaciente: action.planoPaciente,
      };

    case types.OPEN_NEW_MODAL_RESPONSAVEL:
      return {
        ...state,
        modalResponsavel: { open: true, type: "create" },
        dados: action.payload,
        endereco: {},
      };
    case types.CHANGE_PLANO:
      return {
        ...state,
        planoPaciente: action.payload,
      };

    case types.CLOSE_MODAL:
      return {
        ...state,
        modalPaciente: false,
        modalResponsavel: { open: false, type: "" },
        modalDesistencia: { open: false, type: "" },
        modalPlano: { open: false, type: "" },
        dados: {},
        endereco: {},
        planos: [],
        planoPaciente: {},
      };
    // Paciente
    case types.BUSCAR_INIT:
      return {
        ...state,
        dados: {},
      };
    case types.BUSCAR_SUCCESS:
      return {
        ...state,
        dados: action.payload,
      };
    case types.BUSCAR_FAIL:
      return {
        ...state,
        dados: {},
      };
    case types.BUSCAR_CEP_PACIENTE:
      return {
        ...state,
        endereco: action.payload,
        cepLoading: false,
      };
    case types.BUSCAR_CEP_PACIENTE_FAIL:
      return {
        ...state,
        endereco: {},
        cepLoading: false,
      };

    case types.BUSCAR_CEP_PACIENTE_INIT:
      return {
        ...state,
        endereco: {},
        cepLoading: true,
      };

    case types.INIT_SALVAR:
      return {
        ...state,
        modalLoading: true,
      };
    case types.FINISH_SALVAR:
      return {
        ...state,
        modalLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
