import React from 'react'
import '../style.scss'

const SemaforoRiscoModerado = () => {
  return (
    <>
      <div className="default riscoModerado" />
      <div className="default riscoModerado" />
      <div className="default" />
    </>
  )
}

export default SemaforoRiscoModerado
