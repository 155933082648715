import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const InstructionsFollowUp = (props) => {
  return (
    <>
      <Text className={"cadastro-section-title"} style={{ color: "#0F79B2" }}>
        Instruções - {props.acompanhamento ? 'Acompanhamento' : 'Indícios'} de processo inflamatório
      </Text>
      <div
        style={{
          marginTop: "8px",
          display: "flex",
          flexDirection: "column",
          lineHeight: "22px",
        }}
      >
        <span>
          <strong>1.</strong> <strong>Ligar para o paciente.</strong>
        </span>
        <span>
          <strong>2.</strong> Caso o paciente não atenda, ligar para cuidador ou
          contato para emergências, na ordem abaixo.
        </span>
        <span>
          <strong>3.</strong> Informar ao paciente/cuidador que o motivo de
          contato é para {props.acompanhamento ? 'acompanhamento de' : 'verificar'} alguns parâmetros de saúde
          relacionados a processos inflamatórios, e se o mesmo pode responder as
          seguintes questões:
        </span>
      </div>
    </>
  );
};

export default InstructionsFollowUp;
