import React from "react";
import { connect } from "react-redux";
import * as actionsHeader from "Components/Header/action";
import { Button, Avatar } from "antd";
import { useHistory } from "react-router-dom";
import DetalhamentoPainel from "./DetalhamentoPainel";
import * as actions from "../action";
import TransferirTarefasModal from "./TransferirTarefasModal";
import TransferirPacientesModal from "./TransferirPacientesModal";

export const Detalhamento = ({ dashCompleteDetail, filtro, abrirModalTransferTasks, abrirModalTransferPatients, changeBackingValue}) => {

  // Fiz uma verificação para saber se foi feita uma busca por atendente
  const detalhamentoGeral = typeof filtro?.atendente !== "number";

  const history = useHistory();

  const handleBackButton = () => {
    changeBackingValue(true)
    history.goBack()
  }
  
  return (
    <div className="detalhamento">
      <div className="detalhamento-header">
        {!detalhamentoGeral && (
        <>
          <TransferirTarefasModal />
          <TransferirPacientesModal />
          <Button type="ghost" onClick={abrirModalTransferTasks}>Transferir tarefas</Button>
          <Button type="ghost" onClick={abrirModalTransferPatients} style={{marginRight: "1%"}}>Transferir pacientes</Button>
        </>
        )}
        <Button
          type="primary"
          onClick={() => handleBackButton()}
          style={{marginRight: "1%", fontWeight: 'bold'}}
        >
          Voltar
        </Button>
      </div>
      <div className="detalhamento-infos">
        {!detalhamentoGeral && (
          <>
            <div className="section-detalhamento">
              <h3>Atendimentos por tipo da tarefa</h3>
              <div className="card-lista">
                {dashCompleteDetail.assunto.map((tarefa) => (
                  <CardInfo tarefa={tarefa} />
                ))}
              </div>
            </div>

            <div className="section-detalhamento">
              <h3>Atendimentos por prioridade da tarefa</h3>
              <div className="card-lista">
                {dashCompleteDetail.prioridade?.map((tarefa) => (
                  <CardInfo
                    tarefa={{
                      label: `Prioridade ${tarefa.label}`,
                      valor: tarefa.valor,
                      id: tarefa.label,
                    }}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        <div className="section-detalhamento-painel">
          <h3>Atendimentos por status da tarefa</h3>
          <DetalhamentoPainel />
        </div>
      </div>
    </div>
  );
};

const iconSize = 43;
/**
 * Eu mapeei cada icone pelo id que vem em cada tarefa, talvez isso precise mudar quando tiver a nova rota
 * Talvez o ideal é estar em outro arquivo de js com import do react para exportar essa constante
 *
 */
const ICONS_LIST = {
  1: (
    <Avatar
      icon="unordered-list"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  2: (
    <Avatar
      icon="file-text"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  3: (
    <Avatar
      icon="phone"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  4: (
    <Avatar
      icon="medicine-box"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  5: (
    <Avatar
      icon="stop"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  6: (
    <Avatar
      icon="form"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  7: (
    <Avatar
      icon="customer-service"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  8: (
    <Avatar
      icon="check"
      style={{ backgroundColor: "#0f79b2", color: "#fff" }}
      size={iconSize}
    />
  ),
  Alta: (
    <Avatar
      icon="exclamation"
      style={{ backgroundColor: "#DD0000", color: "#fff" }}
      size={iconSize}
    />
  ),
  Média: (
    <Avatar
      icon="exclamation"
      style={{ backgroundColor: "#FFD600", color: "#fff" }}
      size={iconSize}
    />
  ),
  Baixa: (
    <Avatar
      icon="exclamation"
      style={{ backgroundColor: "#2E7D32", color: "#fff" }}
      size={iconSize}
    />
  ),
};

const CardInfo = (props) => {
  const { tarefa } = props;
  return (
    <div className="card-info-detalhamento">
      <div>
        <span>{tarefa.valor}</span>
        {ICONS_LIST[tarefa.id]}
      </div>
      <span>{tarefa.label}</span>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeCabecalhoInfo: (info) => dispatch(actionsHeader.changeCabecalhoInfo(info)),
  abrirModalTransferTasks: () => dispatch(actions.abrirModalTransferTasks()),
  abrirModalTransferPatients: () => dispatch(actions.abrirModalTransferPatients()),
  changeBackingValue: (isBacking) => dispatch(actions.changeBackingValue(isBacking))
});

const mapStateToProps = (store) => ({
  filtro: store.dashboard.filtro,
  dashCompleteDetail: store.dashboard.dashCompleteDetail,
  isBacking: store.dashboard.isBacking,
});

export default connect(mapStateToProps, mapDispatchToProps)(Detalhamento);
