import api from "../../api";

export const getInfoRedeApoio = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarRedeApoio",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );
  return res.data;
};

export const attInfo = async (redeApoioInfo) => {
  const res = await api.post(
    "/backoffice/atualizarRedeApoio",
    redeApoioInfo
  );

  return res.data;
};
