import { sendErrorMsg } from "Misc/utils";
import api from "../../api";

export const getEndereco = async (cep) => {
  return api
    .post("/cadastro/buscarEndereco", {
      token: "gecro",
      cep: cep,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getDadosAmbientais = async (idTarefa) => {
  return api
    .post("backoffice/buscarDadosAmbientais", {
      token: "gecro",
      idTarefa,
    })
};
