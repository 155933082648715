import React from "react";
import { Form, Icon, Input, Button, Alert } from "antd";
import { withRouter } from "react-router-dom";
import "./style.scss";
import { connect } from "react-redux";
import * as actions from "./action";
import logo from "Assets/Images/logo.png";

const Login = ({ form, logIn, error, loading }) => {
  const { getFieldDecorator } = form;

  const handleSubmit = e => {

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        logIn(values.username, values.password);
      }
    });
  };

  return (
    <div className="form-page">
      <Form onSubmit={handleSubmit} className="login-form">
        <center style={{ margin: "12px" }}>
          <img src={logo} alt="Logo da empresa" width={250} />
        </center>
        {error && (
          <Alert
            message={error.msg}
            type={error.type}
            showIcon
            style={{ marginBottom: "16px" }}
          />
        )}
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Usuário é obrigatório." }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Usuário"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Senha é obrigatória." }]
          })(
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Senha"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            size="large"
            loading={loading}
          >
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const mapStateToProps = store => ({
  user: store.login.user,
  error: store.login.error,
  loading: store.login.loading,
});

const mapDispatchToProps = dispatch => ({
  logIn: (user, password) => dispatch(actions.logIn(user, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "medicamento_fields"
  })(withRouter(Login))
);
