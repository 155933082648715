import * as types from "./actionType";

const initialState = {
  medicamentosLista: [],
  medicamentoDetalhes: {},
  dosagens: [],
  frequencias: [],
  recomendacoes: [],
  medicamentos: [],
  enfermeiros: [],
  parametrosSaude: null,
  wearable: null,
  ligacaoPeriodica: {},
  outrasInformacoes: null,
  morbidades: [],
  modalMedicamentoLoading: false,
  plano: {},
  medicamentoLoading: false,
  modelos: [],
  idWearableModelo: null,
  tarefasNFLista: [],
  tarefaNFDetalhes: {},
  tarefasNF: [],
  modalTarefaNFLoading: false,
  tarefaNFLoading: false,
  outliers: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_LISTA_MEDICAMENTO_COMPLETE:
      return {
        ...state,
        medicamentosLista: action.payload.medicamentosLista,
      };
    case types.FETCHING_LISTA_MEDICAMENTO:
      return {
        ...state,
        medicamentoLoading: true,
        medicamentosLista: [],
        dosagens: [],
        frequencias: [],
        recomendacoes: [],
        medicamentos: [],
        // enfermeiros: [],
        // parametrosSaude: null,
        medicamentoDetalhes: [],
        ligacaoPeriodica: {},
        outrasInformacoes: null,
        // plano: {},
      };
    case types.FETCHING_MEDICAMENTO_DETALHES_COMPLETE:
      return {
        ...state,
        medicamentoDetalhes: action.payload.medicamento,
      };
    case types.FETCHING_MEDICAMENTO_DETALHES:
      return {
        ...state,
        medicamentoDetalhes: {},
      };
    case types.MORBIDADES:
      return {
        ...state,
        morbidades: action.payload,
      };

    case types.CHANGE_MEDICAMENTO_COMPLETE:
      return {
        ...state,
        medicamentos: action.payload,
      };

    case types.SEND_MEDICAMENTO:
      return {
        ...state,
        modalMedicamentoLoading: true,
      };

    case types.SEND_MEDICAMENTO_COMPLETE:
      return {
        ...state,
        modalMedicamentoLoading: false,
      };

    case types.SEND_MEDICAMENTO_ERROR:
      return {
        ...state,
        modalMedicamentoLoading: false,
      };

    case types.FETCHING_MEDICAMENTO_COMPLETE:
      return {
        ...state,
        medicamentos: action.payload.medicamentos,
      };

    case types.FETCHING_DOSAGEM_COMPLETE:
      return {
        ...state,
        dosagens: action.payload.dosagens,
      };

    case types.FETCHING_FREQUENCIA_COMPLETE:
      return {
        ...state,
        frequencias: action.payload.frequencias,
      };

    case types.FETCHING_RECOMENDACAO_COMPLETE:
      return {
        ...state,
        recomendacoes: action.payload.recomendacoes,
      };

    case types.FETCHING_ENFERMEIROS_COMPLETE:
      return {
        ...state,
        enfermeiros: action.payload.enfermeiros,
      };
    case types.FETCHING_ACOMPANHAMENTO_COMPLETE:
      return {
        ...state,
        parametrosSaude: action.payload.parametrosSaude,
        ligacaoPeriodica: action.payload.ligacaoPeriodica,
        outrasInformacoes: action.payload.outrasInformacoes,
        wearable: action.payload.wearable,
        plano: action.payload.plano,
        medicamentoLoading: false,
        idWearableModelo: action.payload.idWearableModelo,
      };
    case types.FETCHING_LISTA_MEDICAMENTO_ERROR:
      return {
        ...state,
        medicamentoLoading: false,
      };
    case types.FETCHING_MODELOS_COMPLETE:
      return {
        ...state,
        modelos: action.payload,
      };
    case types.FETCHING_LISTA_TAREFA_NF_COMPLETE:
      return {
        ...state,
        tarefasNFLista: action.payload.tarefasNF,
      };

    case types.FETCHING_LISTA_TAREFA_NF:
      return {
        ...state,
        tarefaNFLoading: true,
        tarefasNFLista: [],
        frequencias: [],
        tarefasNF: [],
        // enfermeiros: [],
        // parametrosSaude: null,
        tarefaNFDetalhes: [],
        ligacaoPeriodica: {},
        outrasInformacoes: null,
        // plano: {},
      };

    case types.FETCHING_TAREFA_NF_DETALHES_COMPLETE:
      return {
        ...state,
        tarefaNFDetalhes: action.payload.tarefaNF,
      };
    case types.FETCHING_TAREFA_NF_DETALHES:
      return {
        ...state,
        tarefaNFDetalhes: {},
      };

    case types.CHANGE_TAREFA_NF_COMPLETE:
      return {
        ...state,
        tarefaNF: action.payload,
      };

    case types.SEND_TAREFA_NF:
      return {
        ...state,
        modalTarefaNfLoading: true,
      };

    case types.SEND_TAREFA_NF_COMPLETE:
      return {
        ...state,
        modalTarefaNfLoading: false,
      };

    case types.SEND_TAREFA_NF_ERROR:
      return {
        ...state,
        modalTarefaNfLoading: false,
      };

    case types.FETCHING_TAREFA_NF_COMPLETE:
      return {
        ...state,
        tarefasNF: action.payload.tarefasNF,
      };
    case types.FETCHING_LISTA_TAREFA_NF_ERROR:
      return {
        ...state,
        tarefaNfLoading: false,
      };
    case types.FETCHING_VALORES_OUTLIERS:
      return {
        ...state,
        outliers: {}
      };
    case types.FETCHING_VALORES_OUTLIERS_COMPLETE:
      return {
        ...state,
        outliers: action.payload.parametros
      }

    default:
      return state;
  }
};

export default reducer;
