import React, { useEffect } from "react";
import { Typography, Form, Divider, Button } from "antd";
import AcompanhamentoProfissional from "./AcompanhamentoProfissional";
import CondicoesSaude from "./CondicoesSaude";
import * as actions from "./action";
import { attInfo, attMorbidades, attProfissionais } from "./service";
import { connect } from "react-redux";
import { getInfoCabecalho } from "../../Pages/Adesao/action";
import { unsavedFields } from "Pages/Rotina/action";
import { sendErrorMsg, sendSuccessMsg } from "Misc/utils";
import { useParams } from "react-router-dom";
import { SCROLL_CONFIG } from "Misc/constants";
import _ from "lodash";

const { Text } = Typography;

const Saude = (props) => {
  useEffect(() => {
    if (!!props.paciente) props.getInfoSaude(props.paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);

  const {
    form: { validateFieldsAndScroll, ...form },
    morbSelected,
    profissionais,
    medicoFamilia,
    paciente,
    rotina,
    title,
    morbidade,
    doenca,
    profissional,
    unsavedFields,
    readOnly,
  } = props;
  const { tipo } = useParams();

  const handleNextClick = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const req = {
          token: "gecro",
          paciente: paciente,
          morbidades: morbSelected,
          profissionais: profissionais.map((e) => e.id),
          medicoFamilia: medicoFamilia,
          outrasMorbidades: values.outrasMorbidades,
        };
        attInfo(JSON.stringify(req))
          .then((res) => {
            props.nextStep(props.step);
          })
          .catch((error) => {
            sendErrorMsg(error);
          });
      }
    });
  };

  const saveTab = (morbidade) => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        let req;
        if (morbidade) {
          req = {
            token: "gecro",
            paciente: paciente,
            morbidades: morbSelected,
            outrasMorbidades: values.outrasMorbidades,
          };
          attMorbidades(JSON.stringify(req))
            .then((res) => {
              sendSuccessMsg("Doenças atualizadas com sucesso.")
              unsavedFields({ doenca: false });
            })
            .catch((error) => {
              sendErrorMsg(error);
            });
        } else {
          req = {
            token: "gecro",
            paciente: paciente,
            profissionais: profissionais.map((e) => e.id),
            medicoFamilia: medicoFamilia,
          };

          attProfissionais(JSON.stringify(req))
            .then((res) => {
              sendSuccessMsg("Profissionais atualizados com sucesso.")
              unsavedFields({ profissional: false });
            })
            .catch((error) => {
              sendErrorMsg(error);
            });
        }
      }
    });
  };

  if (!!rotina) {
    return (
      <>
        {!!morbidade && (
          <CondicoesSaude form={form} rotina={rotina} readOnly={readOnly} />
        )}
        {!morbidade && (
          <AcompanhamentoProfissional rotina={rotina} readOnly={readOnly} />
        )}

        {!readOnly && (
          <div className="footer-save-button">
            <Button
              className="buttonFooterNext"
              onClick={() => saveTab(morbidade)}
              disabled={
                (!!morbidade && !doenca) || (!morbidade && !profissional)
              }
              type="primary"
            >
              Salvar alterações
            </Button>
            <span className="affix-title">{title}</span>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {tipo !== "2" && (
          <>
            <Text>Confirme as informações</Text>
            <Divider className="cadastro-divider" />
          </>
        )}
        <CondicoesSaude form={form} />
        <Divider className="cadastro-divider" />
        <AcompanhamentoProfissional readOnly={readOnly} />
        <Divider className="cadastro-divider" />
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            className="buttonFooterNext"
            onClick={handleNextClick}
            type="primary"
          >
            Próximo
          </Button>
          <Button
            onClick={() => props.backStep(props.step)}
            className="buttonFooterBack"
            style={
              props.step > 1
                ? { opacity: 1 }
                : { opacity: 0, pointerEvents: "none" }
            }
          >
            Voltar
          </Button>
        </div>
      </>
    );
  }
};

const mapStateToProps = (store) => ({
  morbidades: store.informacaosaude.morbidades,
  especialidades: store.informacaosaude.especialidades,
  profissionais: store.informacaosaude.profissionais,
  medicoFamilia: store.informacaosaude.medicoFamilia,
  morbSelected: store.informacaosaude.morbSelected,
  step: store.adesao.step,
  paciente: store.adesao.paciente,
  doenca: store.rotina.unsaved.doenca,
  profissional: store.rotina.unsaved.profissional,
  outrasMorbidades: store.informacaosaude.outrasMorbidades,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoSaude: (pacienteID) => dispatch(actions.getInfoSaude(pacienteID)),
  getInfoCabecalho: (enfermeiroID, tarefaID) =>
    dispatch(getInfoCabecalho(enfermeiroID, tarefaID)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
});

const handleChange = (props) => {
  props.unsavedFields({ doenca: true });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "info_saude",
    onValuesChange: _.debounce(handleChange, 300),
  })(Saude)
);
