import api from "../../api";

export const getInfoQuestionario = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarQuestionario",
    {
      token: "gecro",
      paciente: pacienteID,
      isIdoso: false,
      tipo: "C",
    }
  );
  return res.data;
};

export const attQuestionario = async (questionarios) => {
  const res = await api.post(
    "/backoffice/atualizarQuestionario",
    questionarios
  );

  return res.data;
};
