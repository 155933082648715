import React from "react";
import { Modal, Button, Form, DatePicker, Divider, Empty, Tabs } from "antd";
import { connect } from "react-redux";
import { getHistoricoObs, getHistoricoChart } from "Pages/Rotina/action";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  Bar,
} from "recharts";
import "./style.scss";
import { LINE_COLORS, BAR_COLORS, BAR_COLORS_EXTRA, SLEEP_BAR_COLORS } from "Misc/constants";

const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const HistoryModal = ({
  open,
  onClose,
  observacoes,
  getHistoricoObs,
  getHistoricoChart,
  loading,
  charts = false,
  graficos,
  form: { getFieldDecorator, validateFieldsAndScroll },
}) => {
  const { tarefa, idPaciente } = useParams();

  const handleSearch = async (e) => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (charts) {
          await getHistoricoChart(
            tarefa,
            values.range[0].format("DD/MM/YYYY"),
            values.range[1].format("DD/MM/YYYY"),
            idPaciente
          );
        } else {
          await getHistoricoObs(
            tarefa,
            values.range[0].format("DD/MM/YYYY"),
            values.range[1].format("DD/MM/YYYY"),
            idPaciente
          );
        }
      }
    });
  };

  const resetDatePickers = () => {
    onClose();
  };

  return (
    <Modal
      title={
        <span style={{ color: "#0F79B2", fontWeight: "bold" }}>
          {charts ? "Histórico de medições" : "Histórico de observações"}
        </span>
      }
      destroyOnClose
      bodyStyle={{ height: "calc(90vh - 100px)" }}
      centered
      style={{ minWidth: "70vw", overflowY: "hidden" }}
      visible={open}
      onCancel={resetDatePickers}
      footer={[
        <Button key="submit" type="primary" onClick={resetDatePickers}>
          Fechar
        </Button>,
      ]}
    >
      <Form layout="inline" hideRequiredMark>
        <Form.Item label="Período:" style={{ marginBottom: "0px" }}>
          {getFieldDecorator("range", {
            rules: [{ required: true, message: "Insira o período." }],
            initialValue: [moment().subtract(1, "month"), moment()],
          })(
            <RangePicker
              format={dateFormat}
              disabled={loading}
              ranges={{
                Hoje: [moment(), moment()],
                "Esse Mês": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Mês Passado": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
              }}
              placeholder={["Início", "Fim"]}
            />
          )}
        </Form.Item>
        <Button
          type="primary"
          style={{ margin: "4px" }}
          loading={loading}
          onClick={handleSearch}
        >
          Buscar
        </Button>
      </Form>
      {!charts && <Divider />}
      {charts ? (
        <ChartsPanel graficos={graficos} loading={loading} />
      ) : (
        <Commentary observacoes={observacoes} loading={loading} />
      )}
    </Modal>
  );
};

const Commentary = ({ observacoes, loading }) => (
  <div
    style={
      loading
        ? {
            overflowY: "auto",
            minHeight: "calc(20vh + 50px)",
            maxHeight: "calc(100% - 120px)",
            opacity: "30%",
          }
        : {
            overflowY: "auto",
            minHeight: "calc(20vh + 50px)",
            maxHeight: "calc(100% - 120px)",
          }
    }
  >
    {observacoes.length > 0 ? (
      observacoes.map((obs, i) => (
        <div
          style={{ display: "block", paddingBottom: "20px" }}
          key={`observacao_${i}`}
        >
          <p style={{ margin: 0 }}>
            <strong>{obs.title}</strong>
          </p>
          <span>{obs.text}</span>
        </div>
      ))
    ) : (
      <Empty
        description="Nenhuma observação"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    )}
  </div>
);

const ChartsPanel = ({ graficos, loading }) => {
  
  const formatTime = (time) => {
    return `${Math.floor(time)}h${Math.round(
      (time - Math.floor(time)) * 60
    )}min`;
  };

  const CustomTooltip = ({ active, payload, label, ...props }) => {
    if (active && payload && payload.length) {
      const total = payload.reduce((acc, obj) => acc + +obj.value, 0);
      return (
        <div className="custom-tooltip">
          <p style={{ marginBottom: "0.25rem" }}>{label}</p>
          {payload[0].name !== "Passos" && (
            <p style={{ marginBottom: "0.25rem" }}>
              TOTAL : {Number(total.toFixed(2))} hrs = {formatTime(total)}
            </p>
          )}
          {payload.map((item) => (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginBottom: "0.25rem"
            }}>
              <span
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "100%",
                  backgroundColor: item.color,
                }}
              />
              <p style={{ marginBottom: "0" }}>
                <strong>{item.name}:</strong> {item.value}{" "}
                {item.name !== "Passos" && `hrs = ${formatTime(item.value)}`}
              </p>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const CustomLineTooltip = ({ active, payload, label, ...props }) => {
    console.log(payload[0]?.payload.codigoManualAutomatico);
    return (
      <div className="custom-tooltip">
        <p style={{ marginBottom: "0.25rem" }}>{label}</p>
        {payload.map((item) => (
          <p style={{ color: item.color, marginBottom: "0.25rem" }}>
            {item.name} : {item.value} {item.unit}
          </p>
        ))}
        <span
          style={{
            background: "rgba(63, 81, 181, 1)",
            color: "white",
            borderRadius: "16px",
            display: "table",
            marginRight: "auto",
            padding: "4px 12px",
            fontSize: '12px',
          }}
        >
          {payload[0]?.payload.codigoManualAutomatico === "M" ? "MAN" : "AUTO"}
        </span>
      </div>
    );
  };
  const renderCusomizedLegend = (props, item) => {
    const { payload } = props;

    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
          }}
        >
          {payload.map((entry, index) => (
            <span
              key={`item-${index}`}
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <div
                style={{
                  display: "inline-block",
                  width: "12px",
                  height: "2px",
                  backgroundColor: `${entry.color}`,
                }}
              ></div>
              {entry.value}
            </span>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            marginTop: ".5rem",
          }}
        >
          {item.constantes &&
            item.constantes.map((entry, index) => (
              <span
                key={`item-${index}`}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "12px",
                    height: "2px",
                    backgroundColor: `red`,
                  }}
                ></div>
                {entry.label} <br /> {entry.y} {item.unit}
              </span>
            ))}
          {item.constantesDesejaveis &&
            item.constantesDesejaveis.map((entry, index) => (
              <span
                key={`item-${index}`}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "12px",
                    height: "2px",
                    backgroundColor: `blue`,
                  }}
                ></div>
                {entry.label} <br /> {entry.y} {item.unit}
              </span>
            ))}
        </div>
      </>
    );
  };

  return (
    <Tabs
      defaultActiveKey="0"
      destroyInactiveTabPane
      style={loading ? { opacity: "30%" } : {}}
    >
      {graficos.map((item, i) => (
        <TabPane tab={item.titulo} key={i} className="tab-charts">
          {item.mensagem ? (
            <Empty
              description={item.mensagem}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ) : (
            <>
              {item.tipo === "linha" && (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={item.dados}
                    margin={{
                      top: 10,
                      right: 50,
                      left: 50,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    {item.constantes.map((r) => (
                      <ReferenceLine y={r.y} stroke="red" isFront={true} />
                    ))}
                    {item.constantesDesejaveis.map((r) => (
                      <ReferenceLine y={r.y} stroke="blue" isFront={true} />
                    ))}
                    <XAxis dataKey="x" />
                    <YAxis domain={item.eixoY} unit={item.unit} />
                    {/* <Tooltip /> */}
                    <Tooltip content={<CustomLineTooltip />} />
                    <Legend
                      content={(props) => renderCusomizedLegend(props, item)}
                    />
                    {item.labels.map((el, i) => (
                      <Line
                        type="monotone"
                        name={el}
                        dataKey={`y[${i}]`}
                        stroke={LINE_COLORS[i]}
                        dot={false}
                        unit={item.unit}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              )}

              {item.tipo === "barra" && (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={item.dados}
                    margin={{
                      top: 10,
                      right: 50,
                      left: 50,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    {item.constantes &&
                      item.constantes.map((r) => (
                        <ReferenceLine
                          y={r.y}
                          label={`${r.label} ${r.y}${item.unit}`}
                          stroke="red"
                          strokeDasharray="3 3"
                        />
                      ))}

                    <XAxis dataKey="x" />
                    <YAxis domain={item.eixoY} unit={item.unit} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    {item.labels.map((el, i) => (
                      <Bar
                        name={el}
                        dataKey={`y[${i}]`}
                        fill={
                          item.titulo === "Qualidade do sono"
                            ? SLEEP_BAR_COLORS[i]
                            : BAR_COLORS[i]
                        }
                        stackId="sono"
                        unit={item.unit}
                      />
                    ))}

                    {item.labelsExtra &&
                      item.labelsExtra.map((el, i) => (
                        <Bar
                          name={el}
                          dataKey={`yExtra[${i}]`}
                          fill={BAR_COLORS_EXTRA[i]}
                          stackId={item.titulo === "Qualidade do Sono" ? "sono" : "soneca"}
                          unit={item.unit}
                        />
                      ))}
                  </BarChart>
                </ResponsiveContainer>
              )}
            </>
          )}
        </TabPane>
      ))}
    </Tabs>
  );
};

const mapStateToProps = (store) => ({
  observacoes: store.rotina.observacoesHistorico,
  graficos: store.rotina.graficosHistorico,
  loading: store.rotina.obsLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getHistoricoObs: (tarefa, inicio, fim, idPaciente) =>
    dispatch(getHistoricoObs(tarefa, inicio, fim, idPaciente)),
  getHistoricoChart: (tarefa, inicio, fim, idPaciente) =>
    dispatch(getHistoricoChart(tarefa, inicio, fim, idPaciente)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: "history_modal_form" })(HistoryModal));