import * as types from "./actionType";
import * as typesRotina from "Pages/Rotina/actionType";
import * as services from "./services";
import { sendErrorMsg } from "Misc/utils";

export const getContratoResponsavelInfo = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_CONTRATO_RESPONSAVEL });
    const response = await services.getContratoResponsavelInfo(pacienteID);

    const newRes = { ...response };

    const other = {
      nome: "",
      telefone: "",
      email: "",
      cpf: "",
      isIdoso: false,
      isResponsavel: false,
      endereco: null,
      grauParentesco: null
    };

    newRes.opcoesResponsaveis.push(other);

    const index = newRes.opcoesResponsaveis.findIndex(i => {
      return i.isResponsavel === true;
    });

    await dispatch({
      type: types.FETCHING_CONTRATO_RESPONSAVEL_COMPLETE,
      payload: response
    });

    if (index > -1) {
      await dispatch({
        type: types.SELECT_RESPONSAVEL,
        payload: newRes.opcoesResponsaveis[index]
      });
    }
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_CONTRATO_RESPONSAVEL_ERROR });
  }
};

export const editInfo = (responsavel, value) => async dispatch => {
  const { isResponsavel, ...rest } = responsavel;

  const newJSON = {
    ...rest,
    ...value
  };

  await dispatch({
    type: types.EDITED_CONTRATO_RESPONSAVEL,
    payload: newJSON
  });
};

export const atualizarResponsavel = (req, questions) => async dispatch => {
  try {
    await services.attInfo(req);
    dispatch({
      type: typesRotina.MODIFY_FIELDS,
      payload: { responsavel: false }
    });
  } catch (error) {
    // dispatch({ type: types.FETCHING_QUESTIONARIO_ERROR });
    sendErrorMsg(error);
  }
};
