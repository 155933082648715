import React from "react";
import { Card } from "antd";

const CardComponent = ({
  children,
  rotina,
  title,
  classText,
  divStyle,
  cardStyle = {}
}) => {
  if (!!rotina) {
    return (
      <Card style={{ margin: "8px 0", ...cardStyle }} title={title}>
        {children}
      </Card>
    );
  } else {
    return (
      <div className={classText} style={divStyle}>
        {children}
      </div>
    );
  }
};

export default CardComponent;
