import React from "react";
import { Typography, Collapse } from "antd";

const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

const CollapsedGroup = ({
  pannel,
  closed,
  children,
  color = "#333",
  nome,
  descricao
}) => {
  if (pannel) {
    return (
      <Collapse defaultActiveKey={!closed ? ["1"] : []}>
        <Panel header={nome} key="1" style={{ margin: 0, padding: 0 }}>
          <Paragraph style={{ fontStyle: "italic" }}>{descricao}</Paragraph>
          {children}
        </Panel>
      </Collapse>
    );
  } else {
    return (
      <div style={{ padding: "16px 16px 0 16px" }}>
        <Text className={"cadastro-section-title"} style={{ color: color }}>
          {nome}
        </Text>
        <Paragraph type="secondary" style={{ fontStyle: "italic" }}>
          {descricao}
        </Paragraph>
        {children}
      </div>
    );
  }
};

export default CollapsedGroup;
