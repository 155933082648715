import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg, sendSuccessMsg } from "Misc/utils";

export const unsavedFields = (unsaved) => async (dispatch) => {
  dispatch({ type: types.MODIFY_FIELDS, payload: unsaved });
};

export const resetUnsaved = () => async (dispatch) => {
  dispatch({ type: types.RESET_FIELDS });
};

export const getRotinaGeral = (pacienteID) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_INIT });
    const response = await services.getRotinaGeral(pacienteID);
    await dispatch({ type: types.FETCH_ROTINA_GERAL, payload: response });
    dispatch({ type: types.FETCH_COMPLETE });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCH__ROTINA_GERAL_ERROR });
    console.error("Falha na requisicao de [getRotinaGeral]", error);
  }
};

export const getFollowUp = (pacienteID, tarefa) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_INIT });
    const response = await services.getFollowUp(pacienteID, tarefa);
    await dispatch({ type: types.FETCH_FOLLOW_UP, payload: response });
    dispatch({ type: types.FETCH_COMPLETE });
  } catch (error) {
    sendErrorMsg(error);
    console.error("Falha na requisicao de [getFollowUp]", error);
  }
};

export const saveFollowUp = (body, pacienteID, tarefa) => async (dispatch) => {
  try {
    dispatch({ type: types.SAVE_FOLLOW_UP_START });
    await services.saveFollowUp(body);
    await services.finalizarRotina(pacienteID, tarefa);
    await dispatch({ type: types.SAVE_FOLLOW_UP_END });
    return true;
  } catch (error) {
    dispatch({ type: types.SAVE_FOLLOW_UP_END });
    sendErrorMsg(error);
    console.error("Falha na requisicao de [saveFollowUp]", error);
  }
};

export const getHistoricoObs =
  (tarefa, inicio, fim, idPaciente) => async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_OBS_INIT });
      const req = {
        tarefa: tarefa,
        dataInicio: inicio,
        dataFim: fim,
        paciente: idPaciente,
      };

      const res = await services.buscarHistoricoObservacoes(req);
      await dispatch({ type: types.FETCH_OBS, payload: res.observacoes });
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.FETCH_ERROR });
      console.error("Falha na requisicao de [getFollowUp]", error);
    }
  };

export const getHistoricoChart =
  (tarefa, inicio, fim, paciente) => async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_CHART_INIT });
      const req = {
        tarefa: tarefa,
        dataInicio: inicio,
        dataFim: fim,
        paciente: paciente,
      };

      const res = await services.buscarHistoricoGraficos(req);
      await dispatch({ type: types.FETCH_CHART, payload: res.parametros });
    } catch (error) {
      sendErrorMsg(error);
      dispatch({ type: types.FETCH_ERROR });
      console.error("Falha na requisicao de [getFollowUp]", error);
    }
  };

export const enviarRelatorioAcompanhamento = (paciente) => async (dispatch) => {
  try {
    dispatch({ type: types.SEND_REPORT_INIT });
    const req = {
      paciente: paciente,
    };

    await services.enviarRelatorioAcompanhamento(req);
    await dispatch({ type: types.SEND_REPORT_FINISH });
    sendSuccessMsg("O relatório foi enviado com sucesso!");
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.SEND_REPORT_FINISH });
    console.error(
      "Falha na requisicao de [enviarRelatorioAcompanhamento]",
      error
    );
  }
};

export const getHistoricoRelatorios = (paciente) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_REPORT_HISTORY_INIT });
    const req = {
      paciente: paciente,
    };

    const res = await services.buscarHistoricoRelatorios(req);
    await dispatch({
      type: types.FETCH_REPORT_HISTORY_SUCCESS,
      payload: res.relatorios,
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCH_REPORT_HISTORY_ERROR });
    console.error("Falha na requisicao de [getHistoricoRelatorios]", error);
  }
};

export const setActiveTab = (tab) => (dispatch) => {
  dispatch({
    type: types.SET_ACTIVE_TAB,
    payload: tab,
  });
};

export const setRelatedQuestion = (question) => (dispatch) => {
  dispatch({
    type: types.SET_RELATED_QUESTION,
    payload: question,
  });
};
