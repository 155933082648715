import React from "react";
import { connect } from "react-redux";
import { Form, Input, Icon, Button, Row, Col, Select } from "antd";
import * as actions from "../action";
import BasicModal from "Components/BasicModal";
import { maskPhone, maskNumber } from "Misc/utils";
import { SCROLL_CONFIG, STATES } from "Misc/constants";
import { InputCPF } from "Components/FormInputs";

const { Option } = Select;

function ModalPaciente({
  filtro,
  modal,
  responsavel,
  endereco,
  cepLoading,
  modalLoading,
  form,
  buscarCep,
  handleCloseModal,
  atualizarResponsavel,
  trocarResponsavel,
  modalType,
}) {
  const { getFieldDecorator, validateFieldsAndScroll, resetFields } = form;

  const isRequired = true;

  const formLayout = {
    labelCol: {
      xs: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 17 },
    },
  };

  const halfWidth = {
    wrapperCol: {
      xs: { span: 8 },
    },
  };

  const thirdWidth = {
    wrapperCol: {
      xs: { span: 6 },
    },
  };

  const quarterWidth = {
    wrapperCol: {
      xs: { span: 3 },
    },
  };

  const handleGetCEP = (cep) => {
    if (cep.target.value.length >= 8) {
      buscarCep(cep.target.value.replace(/\D/gi, "").substring(0, 8));
      resetFields([
        "endereco.uf",
        "endereco.logradouro",
        "endereco.cidade",
        "endereco.numero",
        "endereco.complemento",
        "endereco.bairro",
      ]);
    }
    return cep.target.value.replace(/\D/gi, "").substring(0, 8);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        if (modalType === "update") {
          delete values.cpf;
          atualizarResponsavel(
            JSON.stringify({ paciente: responsavel.paciente, ...values }),
            filtro
          );
        } else if (modalType === "create") {
          trocarResponsavel(
            JSON.stringify({ paciente: responsavel.paciente, ...values }),
            filtro
          );
        }
      }
    });
  };

  const titleModal = {
    create: "Trocar responsável",
    update: "Alterar dados do responsável",
  };

  return (
    <BasicModal
      title={<span className="modal-title">{titleModal[modalType]}</span>}
      visible={modal}
      onClose={handleCloseModal}
      destroyOnClose
      bodyStyle={{}}
      style={{ minWidth: "400px" }}
      footer={[
        <Button
          className="secondary-btn"
          key="back"
          onClick={handleCloseModal}
          disabled={modalLoading}
        >
          Cancelar
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={modalLoading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form layout="horizontal" hideRequiredMark {...formLayout}>
        <Form.Item label="Nome">
          {getFieldDecorator("nome", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: responsavel.nome,
          })(<Input maxLength={60} />)}
        </Form.Item>

        <InputCPF
          isRequired={isRequired}
          getFieldDecorator={getFieldDecorator}
          initialValue={responsavel.cpf}
          decorator="cpf"
          label="CPF"
          disabled={modalType === "update"}
          layout={halfWidth}
        />

        <Form.Item label="Telefone" {...halfWidth}>
          {getFieldDecorator("telefone", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            getValueFromEvent: (e) => maskPhone(e.target.value),
            initialValue: responsavel.telefone,
          })(<Input maxLength={14} />)}
        </Form.Item>

        <Form.Item label="E-mail">
          {getFieldDecorator("email", {
            rules: [
              { required: isRequired, message: "Campo obrigatório." },
              {
                type: "email",
                message: "Insira um email válido.",
              },
            ],
            initialValue: responsavel.email,
          })(<Input maxLength={50} />)}
        </Form.Item>

        <Form.Item label="CEP" {...thirdWidth}>
          {getFieldDecorator("endereco.cep", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.cep,
            getValueFromEvent: handleGetCEP,
          })(
            <Input
              allowClear
              suffix={cepLoading ? <Icon type="loading" /> : ""}
            />
          )}
        </Form.Item>

        <Form.Item label="Rua, avenida, etc.">
          {getFieldDecorator("endereco.logradouro", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.logradouro,
          })(<Input maxLength={100} disabled={cepLoading} />)}
        </Form.Item>

        <Form.Item label="Número" {...quarterWidth}>
          {getFieldDecorator("endereco.numero", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.numero,
            getValueFromEvent: (e) => maskNumber(e.target.value),
          })(<Input maxLength={10} disabled={cepLoading} />)}
        </Form.Item>

        <Form.Item label="Complemento">
          <Row gutter={8}>
            <Col span={20}>
              {getFieldDecorator("endereco.complemento", {
                initialValue: endereco.complemento,
              })(<Input maxLength={30} disabled={cepLoading} />)}
            </Col>
            <Col span={4}>
              <span className="color-gray">(opcional)</span>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Bairro">
          {getFieldDecorator("endereco.bairro", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.bairro,
          })(<Input maxLength={72} disabled={cepLoading} />)}
        </Form.Item>

        <Form.Item label="Cidade">
          {getFieldDecorator("endereco.cidade", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.cidade,
          })(<Input maxLength={60} disabled={cepLoading} />)}
        </Form.Item>

        <Form.Item label="Estado" {...quarterWidth}>
          {getFieldDecorator("endereco.uf", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.uf,
          })(
            <Select disabled={cepLoading} size="large">
              {STATES.map((state) => {
                return (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
      </Form>
    </BasicModal>
  );
}

const mapStateToProps = (store) => ({
  modal: store.pacientes.modalResponsavel.open,
  modalType: store.pacientes.modalResponsavel.type,
  responsavel: store.pacientes.dados,
  endereco: store.pacientes.endereco,
  cepLoading: store.pacientes.cepLoading,
  modalLoading: store.pacientes.modalLoading,
  filtro: store.pacientes.filtro,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseModal: () => dispatch(actions.closeModal()),
  buscarCep: (cep) => dispatch(actions.buscarCep(cep)),
  atualizarResponsavel: (body, filtro) =>
    dispatch(actions.atualizarResponsavel(body, filtro)),
  trocarResponsavel: (body, filtro) =>
    dispatch(actions.trocarResponsavel(body, filtro)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "responsavel_paciente",
  })(ModalPaciente)
);
