import React from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import * as actions from "../action";
import _ from 'lodash';

export const DashboardHeader = (props) => {
  const { abrirModal, frase, resetFiltro, filtro } = props;

  const clearFilter = () => {
    resetFiltro()
    window.location.reload()
  }
  console.log(props)

  return (
    <div className="dashboard-header">
      <span>
        <i>
          <strong>Dados exibidos: {frase}</strong>
        </i>
      </span>
      <Button onClick={() => clearFilter()} disabled={_.isEmpty(filtro)} style={{marginLeft: "auto", marginRight: "1%", display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center'}}>
        Limpar filtros
      </Button>
      <Button onClick={abrirModal} type="primary"  style={{width: 105, marginRight: "1%", fontWeight: 600, display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center'}}>
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.95833 10C6.78125 10 6.63281 9.93976 6.51302 9.81927C6.39323 9.69877 6.33333 9.54947 6.33333 9.37136C6.33333 9.19323 6.39323 9.04514 6.51302 8.92708C6.63281 8.80903 6.78125 8.75 6.95833 8.75H9.04167C9.21875 8.75 9.36719 8.81024 9.48698 8.93073C9.60677 9.05123 9.66667 9.20053 9.66667 9.37865C9.66667 9.55677 9.60677 9.70486 9.48698 9.82292C9.36719 9.94097 9.21875 10 9.04167 10H6.95833ZM1.125 1.25C0.947917 1.25 0.799479 1.18976 0.679688 1.06927C0.559896 0.948771 0.5 0.799466 0.5 0.621354C0.5 0.443229 0.559896 0.295139 0.679688 0.177083C0.799479 0.0590278 0.947917 0 1.125 0H14.875C15.0521 0 15.2005 0.0602428 15.3203 0.180729C15.4401 0.301229 15.5 0.450534 15.5 0.628646C15.5 0.806771 15.4401 0.954861 15.3203 1.07292C15.2005 1.19097 15.0521 1.25 14.875 1.25H1.125ZM3.625 5.625C3.44792 5.625 3.29948 5.56476 3.17969 5.44427C3.0599 5.32377 3 5.17447 3 4.99635C3 4.81823 3.0599 4.67014 3.17969 4.55208C3.29948 4.43403 3.44792 4.375 3.625 4.375H12.375C12.5521 4.375 12.7005 4.43524 12.8203 4.55573C12.9401 4.67623 13 4.82553 13 5.00365C13 5.18177 12.9401 5.32986 12.8203 5.44792C12.7005 5.56597 12.5521 5.625 12.375 5.625H3.625Z" fill="white"/>
        </svg>
        Filtrar
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  abrirModal: () => dispatch(actions.abrirModal()),
  resetFiltro: () => dispatch(actions.resetFiltro())
});

const mapStateToProps = (store) => ({
  frase: store.dashboard.frase,
  filtro: store.dashboard.filtro,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
