import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const Acompanhamento = () => {
  return (
    <div style={{ padding: "16px 16px 0 16px", height: "100%" }}>
      <Text className={"cadastro-section-title"} style={{ color: "#0F79B2" }}>
        Acompanhamento
      </Text>
      <div
        style={{
          display: "grid",
          placeItems: "center",
          lineHeight: "22px",
          height: "100%",
        }}
      >
        <span style={{ padding: "0 24px", textAlign: "center" }}>
          <i style={{ color: "rgba(0, 0, 0, 0.65)" }}>
            Ao lado você pode consultar mais detalhes do paciente
          </i>
        </span>
      </div>
    </div>
  );
};

export default Acompanhamento;
