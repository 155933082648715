import React from "react";
import "antd/dist/antd.css";
import PerguntaQuestionarioCadastro from "./Perguntas";
import { Typography } from "antd";

const { Text, Paragraph } = Typography;

const GrupoPerguntasCadastro = (props) => {
  const { titulo, perguntas, getFieldDecorator } = props;
  return (
    <>
      <Text className="cadastro-section-title">{titulo}</Text>
      {perguntas[0].subitem.length > 0 && (
        <Paragraph className="questionsModal">{perguntas[0].nome}</Paragraph>
      )}

      {perguntas.map((pergunta) => {
        if (pergunta.subitem.length > 0) {
          return pergunta.subitem.map((subitem) => {
            return (
              <PerguntaQuestionarioCadastro
                id={subitem.numero}
                pergunta={subitem.nome}
                defaultValue={subitem.resposta}
                fieldDecorator={`${pergunta["numero"]}-${subitem["numero"]}`}
                getFieldDecorator={getFieldDecorator}
                key={`${pergunta["numero"]}-${subitem["numero"]}`}
                derivateQuestions={[]}
                ivcf={subitem.ivcf}
                textoDeResposta={subitem.respTxt}
                {...props}
              />
            );
          });
        } else if (pergunta.positiva.length > 0) {
          return (
            <PerguntaQuestionarioCadastro
              id={pergunta.numero}
              pergunta={pergunta.nome}
              defaultValue={pergunta.resposta}
              fieldDecorator={`${pergunta["numero"]}`}
              getFieldDecorator={getFieldDecorator}
              key={`${pergunta["numero"]}`}
              derivateQuestions={pergunta.positiva}
              ivcf={pergunta.ivcf}
              textoDeResposta={pergunta.respTxt}
              {...props}
            />
          );
        } else {
          return (
            <PerguntaQuestionarioCadastro
              id={pergunta.numero}
              pergunta={pergunta.nome}
              defaultValue={pergunta.resposta}
              fieldDecorator={pergunta["numero"]}
              getFieldDecorator={getFieldDecorator}
              key={pergunta["numero"]}
              derivateQuestions={[]}
              ivcf={pergunta.ivcf}
              textoDeResposta={pergunta.respTxt}
              {...props}
            />
          );
        }
      })}
    </>
  );
};

export default GrupoPerguntasCadastro;
