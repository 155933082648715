import * as types from "./actionType";

const initialState = {
  pacientesPlano: null,
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_PATIENT_INIT:
      return {
        ...state,
        loading: true
      };
    case types.FETCHING_PATIENT_COMPLETE:
      return {
        ...state,
        pacientesPlano: action.payload,
        loading: false
      };
    case types.RESET_PATIENT_FILTER:
    case types.FETCHING_PATIENT_ERROR:
      return {
        ...state,
        pacientesPlano: null,
        loading: false
      };
    default:
      return state;
  }
};

export default reducer;
