import * as types from "./actionType";

const initialState = {
  telefone: [],
  nome: "",
  email: "",
  loading: false,
  cancelaLoading: false,
  tipoTarefa: "",
  sexo: "",
  peso: "",
  imc: "",
  doencas: [],
  urgencia: "",
  wearable: false,
  ubs: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INIT_FETCH:
      return {
        ...state,
        loading: true,
      };
    case types.EDIT_BARRA_COMPLETE:
      return {
        ...state,
        ubs: action.payload.ubs,
        telefone: action.payload.telefone.filter(Boolean),
        nome: action.payload.nome,
        email: action.payload.email,
        tipoTarefa: action.payload.tipoTarefa,
        sexo: action.payload.sexo,
        peso: action.payload.peso,
        imc: action.payload.imc,
        doencas: action.payload.doencas,
        urgencia: action.payload.urgencia,
        wearable: action.payload.wearable,
        loading: false,
      };

    case types.EDITING_BARRA:
      return {
        ...state,
        telefone: [],
        nome: "",
        email: "",
        loading: true,
        tipoTarefa: "",
        idade: null,
        sexo: "",
        peso: "",
        imc: "",
        urgencia: "",
        wearable: false,
        doencas: [],
      };

    case types.CANCELA_ADESAO:
      return {
        ...state,
        cancelaLoading: true,
      };

    case types.CANCELOU_ADESAO:
      return {
        ...state,
        cancelaLoading: false,
      };

    case types.CANCELA_ADESAO_ERRO:
      return {
        ...state,
        cancelaLoading: false,
      };

    default:
      return state;
  }
};

export default reducer;
