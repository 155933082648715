import React, { useState, useEffect } from "react";
import {
  Typography,
  Form,
  Input,
  Radio,
  Icon,
  Avatar,
  Row,
  Col,
  Button,
} from "antd";
import { InputCPF } from "Components/FormInputs";
import CardComponent from "Components/CardComponent";
import moment from "moment";
import { maskDate, futureDate, confirmReenvioEmail } from "Misc/utils";
import { DATE_FORMAT } from "Misc/constants";
import "./style.scss";

const { Text } = Typography;

const DadosPessoais = (props) => {
  const {
    getFieldDecorator,
    getFieldValue,
    isRequired,
    rotina,
    formItemLayout,
    titulo,
    subtitulo,
    tipo,
    readOnly,
  } = props;
  const [imc, setImc] = useState(null);
  const [age, setAge] = useState(null);

  useEffect(() => {
    const peso = getFieldValue("peso");
    const altura = getFieldValue("altura");
    const imc = calculateIMC(peso, altura);
    setImc(imc);

    const age = moment().diff(
      moment(getFieldValue("ncto")).format("YYYY-MM-DD"),
      "years",
      false
    );
    setAge(age);
    // eslint-disable-next-line
  }, [props]);

  const calculateIMC = (peso, altura) => {
    const h = Math.pow(altura / 100, 2);
    const imc = (peso / h).toFixed(1);
    return imc;
  };

  const techProps = !!rotina
    ? { label: "Familiarizado(a) com tecnologia?", colon: false }
    : {
      label:
        "O idoso é familiarizado com tecnologia? Por exemplo, usa smartphone ou aplicativos de mensagem, como WhatsApp?",
    };

  const sozinhoProps = !!rotina
    ? { label: "Mora sozinho(a)?", colon: false }
    : { label: "O idoso mora sozinho?" };

  return (
    <>
      <CardComponent
        title="Dados pessoais"
        classText="layoutInformacaoPessoal"
        rotina={rotina}
      >
        <div className="container">
          <div class="row">
            <div className="cadastro-object-avatar">
            <Avatar size={185} icon="user" src={props.foto_perfil != null ? `data:image/${props.foto_perfil.tipo_foto};base64,${props.foto_perfil.foto_paciente}` : null} />
            </div>
            <div className="cadastro-object">
            {!rotina && (
              <>
                <div className="cadastro-object-title">
                  <Icon style={{ marginRight: "10px" }} type="user" />
                  <Text className="cadastro-section-title">{titulo}</Text>
                </div>
                <Text className="cadastro-section-description color-gray">
                  {subtitulo}
                </Text>
              </>
            )}
            <Form
              style={{ width: "100%" }}
              {...formItemLayout}
              hideRequiredMark={true}
              colon={!!rotina}
            >
              <Form.Item label="Nome completo">
                {getFieldDecorator("nome", {
                  rules: [
                    {
                      required: isRequired,
                      message: "Insira um nome.",
                    },
                  ],
                  initialValue: props.nome,
                })(
                  <Input
                    size="large"
                    maxLength={60}
                    style={{ minWidth: "170px", maxWidth: "600px" }}
                    disabled={readOnly}
                  />
                )}
              </Form.Item>
              <Form.Item label="Data de nascimento">
                {getFieldDecorator("ncto", {
                  rules: [
                    {
                      required: isRequired,
                      message: "Insira uma data válida.",
                      validator: futureDate,
                      pattern: DATE_FORMAT,
                    },
                  ],
                  initialValue: props.ncto,
                  getValueFromEvent: (e) => maskDate(e.target.value),
                })(
                  <Input
                    style={{ width: "30%", minWidth: "170px" }}
                    placeholder="DD/MM/AAAA"
                    maxLength={10}
                    allowClear
                    disabled={readOnly}
                  />
                )}
                {!rotina && tipo === "2" && (
                  <span style={{ marginLeft: "8px" }}>
                    {isNaN(age) ? "" : `${age} anos`}
                  </span>
                )}
              </Form.Item>
              <InputCPF
                isRequired={isRequired}
                getFieldDecorator={getFieldDecorator}
                initialValue={props.cpf}
                decorator="cpf"
                label="CPF"
                disabled={!!rotina}
                style={{ width: "30%" }}
              />
              <Form.Item label="Sexo">
                {getFieldDecorator("sexo", {
                  rules: [{ required: isRequired, message: "Escolha uma opção." }],
                  initialValue: props.sexo,
                })(
                  <Radio.Group size="large" buttonStyle="solid" disabled={readOnly}>
                    <Radio.Button value="F">Feminino</Radio.Button>
                    <Radio.Button value="M">Masculino</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="Peso">
                <div style={{ display: "flex" }}>
                  {getFieldDecorator("peso", {
                    rules: [{ required: isRequired, message: "Insira o peso." }],
                    initialValue: props.peso,
                    getValueFromEvent: (e) => e.target.value.replace(/\D/gi, ""),
                  })(
                    <Input
                      size="large"
                      style={{
                        minWidth: "115px",
                        maxWidth: "6vw",
                        marginRight: "1vw",
                      }}
                      addonAfter={!!rotina ? "Kg" : null}
                      disabled={readOnly}
                    />
                  )}
                  {!!rotina ? (
                    <span>
                      IMC=
                      {imc < 1000 ? (
                        String(imc).replace(".", ",")
                      ) : (
                          <Icon style={{ marginLeft: "8px" }} type="loading" />
                        )}
                    </span>
                  ) : (
                      "Kg"
                    )}
                </div>
              </Form.Item>
              <Form.Item label="Altura">
                <div style={{ display: "flex" }}>
                  {getFieldDecorator("altura", {
                    rules: [
                      {
                        required: isRequired,
                        message: "Insira a altura.",
                      },
                    ],
                    getValueFromEvent: (e) => e.target.value.replace(/\D/gi, ""),
                    initialValue: props.altura,
                  })(
                    <Input
                      size="large"
                      style={{
                        minWidth: "115px",
                        maxWidth: "6vw",
                        marginRight: "1vw",
                      }}
                      maxLength={3}
                      addonAfter={!!rotina ? "cm" : null}
                      disabled={readOnly}
                    />
                  )}
                  {!!rotina ? "" : "centímetros"}
                </div>
              </Form.Item>
              <Form.Item label="E-mail">
              <Row gutter={8}>
                  <Col span={20}>
                      {getFieldDecorator("email", {
                        rules: [
                          {
                            type: 'email',
                            message: 'Digite um e-mail válido',
                          },
                        ],
                        initialValue: props.email,
                      })(
                        <Input
                          size="large"
                          maxLength={60}
                          style={{ minWidth: "170px", maxWidth: "600px" }}
                          disabled={readOnly}
                        />
                      )}
                       <Button
                        className="buttonFooterBack"
                        onClick={() => confirmReenvioEmail(getFieldValue(`email`), props.paciente)}
                        style={{ display: "table", width: "100%", marginTop: ".5rem" }}
                        disabled={!getFieldValue(`email`) || props.infoPessoal}
                      >
                        Reenviar e-mail de Boas Vindas
                      </Button>
                      </Col>
                  <Col span={4}>
                    <span className="color-gray">(opcional)</span>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item {...sozinhoProps}>
                {getFieldDecorator("sozinho", {
                  rules: [{ required: isRequired, message: "Escolha uma opção." }],
                  initialValue: props.sozinho,
                })(
                  <Radio.Group size="large" buttonStyle="solid" disabled={readOnly}>
                    <Radio.Button value={true}>Sim</Radio.Button>
                    <Radio.Button value={false}>Não</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item {...techProps}>
                {getFieldDecorator("tech", {
                  rules: [{ required: isRequired, message: "Escolha uma opção." }],
                  initialValue: props.tech,
                })(
                  <Radio.Group size="large" buttonStyle="solid" disabled={readOnly}>
                    <Radio.Button value={true}>Sim</Radio.Button>
                    <Radio.Button value={false}>Não</Radio.Button>
                  </Radio.Group>
                )}
              </Form.Item>
            </Form>
            </div>
          </div>
        </div>
      </CardComponent>
    </>
  );
};

export default DadosPessoais;
