import React, { useEffect } from "react";
import { Row, Button, Input, Form } from "antd";
import { connect } from "react-redux";
import { showConfirmationTab } from "Misc/utils";
import { SCROLL_CONFIG } from "Misc/constants";
import { getFollowUp, saveFollowUp } from "Pages/Rotina/action";
//Componentes internos
import GroupFollowUp from "./Components/GroupFollowUp";
import _ from "lodash";
import { useParams, useHistory } from "react-router-dom";

const { TextArea } = Input;

const Followup = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    unsaved,
    getFollowUp,
    saveFollowUp,
    paciente,
    grupos,
    finishLoading,
  } = props;

  const { tarefa } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (paciente) getFollowUp(paciente, tarefa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paciente]);

  const handleValidate = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const { observacao, ...rest } = values;

        const answers = Object.keys(rest).map((n) => {
          const obj = {
            numero: n,
            valor: rest[n].valor,
            dataUltimoExame: rest[n].dataUltimoExame,
            grauParentesco: rest[n].grauParentesco,
            alteracoes: rest[n].alteracoes,
            singleChoice: rest[n].singleChoice,
          };

          return _.omitBy(obj, _.isUndefined);
        });

        const questionario = _.filter(answers, (i) => {
          if (!_.isUndefined(i.valor) || !_.isUndefined(i.singleChoice))
            return i;
        });

        const req = {
          token: "gecro",
          tarefa: tarefa,
          questionario: { respostas: questionario },
          observacao: observacao,
        };

        showConfirm(req);
      } else {
        console.error("On ERROR", err);
      }
    });
  };

  const showConfirm = (answers) => {
    const auxArray = [];
    Object.keys(unsaved).forEach((item) => {
      if (unsaved[item]) {
        auxArray.push(item);
      }
    });

    if (auxArray.length > 0) {
      return showConfirmationTab(auxArray, () => handleSaveFollowUp(answers));
    } else {
      handleSaveFollowUp(answers);
    }
  };

  const handleSaveFollowUp = async (answers) => {
    const res = await saveFollowUp(JSON.stringify(answers), paciente, tarefa);
    if (res) history.push("/painel");
  };

  return (
    <>
      <Row className="followup-body">
        {grupos.map((grupo, i) => {
          return (
            <GroupFollowUp
              key={`grupo_follow_up_${i}`}
              form={props.form}
              color={i === 0 ? "#0F79B2" : undefined}
              {...grupo}
            />
          );
        })}
      </Row>
      <Row className={"followup-footer"}>
        <Form>
          <Form.Item label="Observações">
            {getFieldDecorator("observacao", { initialValue: null })(
              <TextArea rows={2} />
            )}
          </Form.Item>
        </Form>
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            onClick={handleValidate}
            className="buttonComplete"
            type="primary"
            loading={finishLoading}
          >
            Finalizar atendimento
          </Button>
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
  grupos: store.rotina.grupos,
  finishLoading: store.rotina.finishLoading,
  paciente: store.adesao.paciente,
});

const mapDispatchToProps = (dispatch) => ({
  getFollowUp: (pacienteID, tarefa) =>
    dispatch(getFollowUp(pacienteID, tarefa)),
  saveFollowUp: (body, pacienteID, tarefa) =>
    dispatch(saveFollowUp(body, pacienteID, tarefa)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Followup));
