import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon } from "antd";
import "antd/dist/antd.css";
import { useLocation } from "react-router-dom";
import { isAdmin } from "Misc/utils";
import { PERFIS } from "Misc/constants";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideBar = ({ user }) => {
  const [collapsed, setCollapsed] = useState(true);
  let location = useLocation();

  return (
    <Sider
      style={{ backgroundColor: "#0F79B2" }}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <Menu
        style={{ backgroundColor: "#0F79B2" }}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item disabled={true} key="0">
          <Icon
            style={{ color: "white" }}
            onClick={() => setCollapsed(!collapsed)}
            type="menu"
          />
          <span>{collapsed ? "Expandir" : "Recolher"}</span>
        </Menu.Item>

        {user.perfil !== PERFIS.ADMIN_VIVER && (
          <Menu.Item key="/painel">
            <Link to="/painel">
              <Icon type="project" />
              <span>Painel de atividades</span>
            </Link>
          </Menu.Item>
        )}

        {isAdmin(user.perfil) && (
          <SubMenu
            key="settings"
            title={
              <span>
                <Icon type="setting" />
                <span>Administrativo</span>
              </span>
            }
          >
            {user.perfil === PERFIS.ADMIN_VIVER && (
              <Menu.Item key="/equipesaude">
                <Link to="/equipesaude">
                  <li>Equipe de Saúde</li>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="/pacientes">
              <Link to="/pacientes">
                <li>Pacientes</li>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
        {isAdmin(user.perfil) && (
          <Menu.Item key="/dashboard">
            <Link to="/dashboard">
              <Icon type="customer-service" />
              <span>Atendimentos</span>
            </Link>
          </Menu.Item>
        )}
        {isAdmin(user.perfil) && (
          <Menu.Item key="/dashboard/tempo-medio/dia">
            <Link to="/dashboard/tempo-medio/dia">
              <Icon type="calendar" />
              <span>TME & TMA por Dia</span>
            </Link>
          </Menu.Item>
        )}
        {isAdmin(user.perfil) && (
          <Menu.Item key="/dashboard/tempo-medio/hora">
            <Link to="/dashboard/tempo-medio/hora">
              <Icon type="clock-circle" />
              <span>TME & TMA por Hora</span>
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};

const mapStateToProps = (store) => ({
  user: store.login.user,
});

export default connect(mapStateToProps, null)(SideBar);
