export const FETCHING_INFO = "informacaopessoal.buscando_info";
export const FETCHING_INFO_COMPLETE = "informacaopessoal.buscou_info";
export const FETCHING_INFO_ERROR = "informacaopessoal.buscou_info_Error";

export const FETCHING_PHOTO = "informacaopessoal.buscando_foto";
export const FETCHING_PHOTO_COMPLETE = "informacaopessoal.buscou_foto";
export const FETCHING_PHOTO_ERROR = "informacaopessoal.buscou_foto_Error";

export const EDITING_INFO = "informacaopessoal.editando_info";
export const EDITED_INFO = "informacaopessoal.editou_info";
export const EDITED_INFO_ERROR = "informacaopessoal.editou_info_Error";

export const UPDATE_INFO = "informacaopessoal.atualiza_info";
export const UPDATED_INFO = "informacaopessoal.atualizou_info";
export const UPDATED_INFO_ERROR = "informacaopessoal.atualizou_info_Error";
