import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Typography, Radio, Icon, Form } from "antd";
import * as actions from "../action";
import { connect } from "react-redux";

const { Text } = Typography;

const InfoPessoalResponsavel = props => {
  const {
    setShowFields,
    setShowFieldEmail,
    form: { getFieldDecorator }
  } = props;

  const [initialResponsavel, setinitial] = useState();

  useEffect(() => {
    if (props.opcoesResponsaveis.length > 0) {
      const index = props.opcoesResponsaveis.findIndex(i => {
        return i.isResponsavel === true;
      });
      if (index > -1) {
        setinitial(index);
        verify({ target: { value: index } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.opcoesResponsaveis]);

  const verify = event => {
    if (event.target.value > 0) {
      setShowFields(true);
      setShowFieldEmail(false);
    } else {
      setShowFields(false);
      setShowFieldEmail(true);
    }
    props.editInfo(props.opcoesResponsaveis[event.target.value]);
  };

  return (
    <>
      <div className="cadastro-object-title">
        <Icon style={{ marginRight: "10px" }} type="profile" />
        <Text className="cadastro-section-title">
          Responsável pelo contrato
        </Text>
      </div>
      <Text className="cadastro-section-description">
        Quem será o responsável pelo pagamento do serviço e pelas questões
        legais?
      </Text>
      <Form.Item key="radio-group">
        {getFieldDecorator("radio-group", {
          rules: [{ required: true, message: "Selecione um responsável!" }],
          initialValue: initialResponsavel
        })(
          <Radio.Group onChange={event => verify(event)}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "2vh"
              }}
            >
              {props.opcoesResponsaveis.map((responsavel, i) => {
                return (
                  <>
                    <Radio key={i} value={i}>
                      {responsavel.nome ? responsavel.nome : "Outra pessoa"}
                    </Radio>
                  </>
                );
              })}
            </div>
          </Radio.Group>
        )}
      </Form.Item>
    </>
  );
};
const mapStateToProps = store => ({
  opcoesResponsaveis: store.responsavelContrato.opcoesResponsaveis,
  responsavel: store.responsavelContrato.responsavel
});

const mapDispatchToProps = dispatch => ({
  editInfo: paciente => dispatch(actions.editInfo(paciente))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoPessoalResponsavel);
