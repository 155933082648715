import * as types from "./actionType";
import * as services from "./service";

export const changeCabecalhoInfo = info => async dispatch => {
  let newinfo = info;
  if (info.nome) {
  } else {
    newinfo.nome = info.criticidade;
  }
  dispatch({ type: types.EDIT_HEADER_COMPLETE, payload: newinfo });
};

export const getFotoPaciente = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_PHOTO });
    const response = await services.getFotoPaciente(pacienteID);
    dispatch({
      type: types.FETCHING_PHOTO_COMPLETE,
      payload: response
    });
  } catch (error) {
    dispatch({ type: types.FETCHING_PHOTO_ERROR });
  }
};