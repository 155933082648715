export const OPEN_FILTER_MODAL = "dashboard.abrir_modal";
export const CLOSE_FILTER_MODAL = "dashboard.fechar_modal";
export const SALVAR_FILTRO = "dashboard.salvar_filtro";
export const RESET_FILTRO = "dashboard.reset_filtro";

export const OPEN_TRANSFER_TASKS_MODAL = "dashboard.abrir_modal_transferencia_tarefas";
export const CLOSE_TRANSFER_TASKS_MODAL = "dashboard.fechar_modal_transferencia_tarefas";

export const OPEN_TRANSFER_PATIENTS_MODAL = "dashboard.abrir_modal_transferencia_pacientes";
export const CLOSE_TRANSFER_PATIENTS_MODAL = "dashboard.fechar_modal_transferencia_pacientes";

export const BUSCAR_DASHBOARD_INIT = "dashboard.BUSCAR_DASHBOARD_INIT";
export const BUSCAR_DASHBOARD_SUCCESS = "dashboard.BUSCAR_DASHBOARD_SUCCESS";
export const BUSCAR_DASHBOARD_FAIL = "dashboard.BUSCAR_DASHBOARD_FAIL";

export const BUSCAR_DASHBOARD_DETAILS_INIT = "dashboard.BUSCAR_DASHBOARD_DETAILS_INIT";
export const BUSCAR_DASHBOARD_DETAILS_SUCCESS = "dashboard.BUSCAR_DASHBOARD_DETAILS_SUCCESS";
export const BUSCAR_DASHBOARD_DETAILS_FAIL = "dashboard.BUSCAR_DASHBOARD_DETAILS_FAIL";

export const BUSCAR_ASSUNTOS = "dashboard.BUSCAR_ASSUNTOS";
export const BUSCAR_PRIORIDADES = "dashboard.BUSCAR_PRIORIDADES";
export const BUSCAR_STATUS = "dashboard.BUSCAR_STATUS";
export const BUSCAR_ATENDENTES = "dashboard.BUSCAR_ATENDENTES";

export const BUSCAR_OPCOES_INIT = "dashboard.BUSCAR_OPCOES_INIT";
export const BUSCAR_OPCOES_SUCCESS = "dashboard.BUSCAR_OPCOES_SUCCESS";
export const BUSCAR_OPCOES_FAIL = "dashboard.BUSCAR_OPCOES_FAIL";

export const BUSCAR_OPCOES_TRANSFER_TAREFA_INIT = "dashboard.BUSCAR_OPCOES_TRANSFER_TAREFA_INIT";
export const BUSCAR_OPCOES_TRANSFER_TAREFA_SUCCESS = "dashboard.BUSCAR_OPCOES_TRANSFER_TAREFA_SUCCESS";
export const BUSCAR_OPCOES_TRANSFER_TAREFA_FAIL = "dashboard.BUSCAR_OPCOES_TRANSFER_TAREFA_FAIL";

export const BUSCAR_OPCOES_TRANSFER_PATIENT_INIT = "dashboard.BUSCAR_OPCOES_TRANSFER_PATIENT_INIT";
export const BUSCAR_OPCOES_TRANSFER_PATIENT_SUCCESS = "dashboard.BUSCAR_OPCOES_TRANSFER_PATIENT_SUCCESS";
export const BUSCAR_OPCOES_TRANSFER_PATIENT_FAIL = "dashboard.BUSCAR_OPCOES_TRANSFER_PATIENT_FAIL";

export const MUDAR_FRASE = "dashboard.MUDAR_FRASE";

export const TRANSFER_TAREFA_INIT = "dashboard.TRANSFER_TAREFA_INIT";
export const TRANSFER_TAREFA_SUCCESS = "dashboard.TRANSFER_TAREFA_SUCCESS";
export const TRANSFER_TAREFA_FAIL = "dashboard.TRANSFER_TAREFA_FAIL";

export const TRANSFER_PATIENT_INIT = "dashboard.TRANSFER_PATIENT_INIT";
export const TRANSFER_PATIENT_SUCCESS = "dashboard.TRANSFER_PATIENT_SUCCESS";
export const TRANSFER_PATIENT_FAIL = "dashboard.TRANSFER_PATIENT_FAIL";

export const BUSCAR_ENFERMEIROS_INIT = "dashboard.BUSCAR_ENFERMEIROS_INIT";
export const BUSCAR_ENFERMEIROS_SUCCESS = "dashboard.BUSCAR_ENFERMEIROS_SUCCESS";
export const BUSCAR_ENFERMEIROS_FAIL = "dashboard.BUSCAR_ENFERMEIROS_FAIL";

export const BUSCAR_BAIRROS_INIT = "dashboard.BUSCAR_BAIRROS_INIT";
export const BUSCAR_BAIRROS_SUCCESS = "dashboard.BUSCAR_BAIRROS_SUCCESS";
export const BUSCAR_BAIRROS_FAIL = "dashboard.BUSCAR_BAIRROS_FAIL";

export const BUSCAR_PACIENTES_INIT = "dashboard.BUSCAR_PACIENTES_INIT";
export const BUSCAR_PACIENTES_SUCCESS = "dashboard.BUSCAR_PACIENTES_SUCCESS";
export const BUSCAR_PACIENTES_FAIL = "dashboard.BUSCAR_PACIENTES_FAIL";

export const VOLTAR_DETALHAMENTO = "dashboard.VOLTAR_DETALHAMENTO"