import * as types from "./actionType";
const initialState = {
  filterModal: false,
  dash: [],
  dashCompleteDetail: {},
  tarefasPendentes: [],
  tarefasAndamento: [],
  tarefasConcluidas: [],
  filtro: {},
  prioridades: [],
  status: [],
  assuntos: [],
  atendentes: [],
  opcoesLoading: false,
  buscarLoading: false,
  frase: "",
  periodo: "",
  //Transferencia Tarefas
  transferTasksModal: false,
  transferLoading: false,
  tasksAtendenteFiltrado: [],
  todosAtendentes: [],
  //Transferencia Pacientes
  transferPatientsModal: false,
  atendTotalPacientes: [],
  pacientesAtendente: [],
  nomeAtend: null,
  map: new Map(),
  //ubs
  ubsOption: [],
  selectedUbs: null,
  bairros: [],
  morbidades: [],
  pacientes: [],
  isBacking: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.OPEN_FILTER_MODAL:
      return {
        ...state,
        filterModal: true,
      };
    case types.OPEN_TRANSFER_TASKS_MODAL:
      return {
        ...state,
        transferTasksModal: true,
      };
    case types.OPEN_TRANSFER_PATIENTS_MODAL:
      return {
        ...state,
        transferPatientsModal: true,
      };
    case types.CLOSE_FILTER_MODAL:
      return {
        ...state,
        filterModal: false,
      };
    case types.CLOSE_TRANSFER_TASKS_MODAL:
      return {
        ...state,
        transferTasksModal: false,
      };
    case types.CLOSE_TRANSFER_PATIENTS_MODAL:
      return {
        ...state,
        transferPatientsModal: false,
      };
    case types.SALVAR_FILTRO:
      return {
        ...state,
        filtro: action.payload,
      };
    case types.RESET_FILTRO:
      return {
        ...state,
        filtro: {},
      };
    case types.BUSCAR_DASHBOARD_INIT:
      return {
        ...state,
        dash: [],
        buscarLoading: true,
      };
    case types.BUSCAR_DASHBOARD_DETAILS_INIT:
      return {
        ...state,
        detailLoading: true,
      };
    case types.BUSCAR_DASHBOARD_SUCCESS:
      return {
        ...state,
        buscarLoading: false,
        dash: action.dash,
        filtro: action.filtro,
        periodo: action.periodo,
      };
    case types.BUSCAR_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        detailLoading: false,
        tarefasPendentes: action.tarefasPendentes,
        tarefasAndamento: action.tarefasAndamento,
        tarefasConcluidas: action.tarefasConcluidas,
        dashCompleteDetail: action.dashCompleteDetail,
      };
    case types.BUSCAR_DASHBOARD_FAIL:
      return {
        ...state,
        buscarLoading: false,
        filtro: {},
      };
    case types.BUSCAR_DASHBOARD_DETAILS_FAIL:
      return {
        ...state,
        detailLoading: false,
        dashCompleteDetail: {},
        tarefasPendentes: [],
        tarefasAndamento: [],
        tarefasConcluidas: [],
        filtro: {},
      };
    case types.BUSCAR_OPCOES_INIT:
      return {
        ...state,
        opcoesLoading: true,
        atendentes: [],
      };
    case types.BUSCAR_OPCOES_SUCCESS:
      return {
        ...state,
        opcoesLoading: false,
        prioridades: action.prioridades,
        status: action.status,
        assuntos: action.assuntos,
        ubsOption: action.ubsOption,
        morbidades: action.morbidades,
        bairros: action.bairros
      };
    case types.BUSCAR_OPCOES_FAIL:
      return {
        ...state,
        opcoesLoading: false,
        prioridades: [],
        status: [],
        assuntos: [],
      };
    case types.MUDAR_FRASE:
      return {
        ...state,
        frase: action.frase,
      };
    case types.BUSCAR_OPCOES_TRANSFER_TAREFA_INIT:
      return {
        ...state,
        tasksAtendenteFiltrado: [],
        todosAtendentes: [],
      };
    case types.BUSCAR_OPCOES_TRANSFER_TAREFA_SUCCESS:
      return {
        ...state,
        tasksAtendenteFiltrado: action.tasksAtendenteFiltrado,
        todosAtendentes: action.todosAtendentes,
      };
    case types.BUSCAR_OPCOES_TRANSFER_TAREFA_FAIL:
      return {
        ...state,
        tasksAtendenteFiltrado: [],
        todosAtendentes: [],
      };
    case types.TRANSFER_TAREFA_INIT:
    case types.TRANSFER_PATIENT_INIT:
      return {
        ...state,
        transferLoading: true,
      }
    case types.TRANSFER_TAREFA_SUCCESS:
    case types.TRANSFER_TAREFA_FAIL:
    case types.TRANSFER_PATIENT_SUCCESS:
    case types.TRANSFER_PATIENT_FAIL:
      return {
        ...state,
        transferLoading: false,
      }
      case types.BUSCAR_OPCOES_TRANSFER_PATIENT_INIT:
      return {
        ...state,
      };
    case types.BUSCAR_OPCOES_TRANSFER_PATIENT_SUCCESS:
      return {
        ...state,
        pacientesAtendente: action.pacientesAtendente,
        atendTotalPacientes: action.atendTotalPacientes,
        nomeAtend: action.nomeAtend.nome,
        map: action.map,
        transferPatientsModal: false,
      };
    case types.BUSCAR_OPCOES_TRANSFER_PATIENT_FAIL:
      return {
        ...state,
      };
    case types.BUSCAR_ENFERMEIROS_INIT:
      return {
        ...state,
        atendentes: [],
        };
    case types.BUSCAR_ENFERMEIROS_SUCCESS:
      return {
       ...state,
       atendentes: action.atendentes,
        };
    case types.BUSCAR_BAIRROS_INIT:
      return {
        ...state,
        bairros: [],
        };
    case types.BUSCAR_BAIRROS_SUCCESS:
      return {
       ...state,
       bairros: action.bairros,
        };
    case types.BUSCAR_PACIENTES_INIT:
      return {
        ...state,
        pacientes: [],
        };
    case types.BUSCAR_PACIENTES_SUCCESS:
      return {
       ...state,
       pacientes: action.pacientes,
        };
    case types.VOLTAR_DETALHAMENTO:
      return {
        ...state,
        isBacking: action.payload
      }
    default:
      return state;
  }
};

export default reducer;
