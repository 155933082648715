import api from "api";

export const getTasks = async (enfermeiroID) => {
  const res = await api.post(
    "/backoffice/buscarTarefas",
    {
      token: "gecro",
      idEnfermeiro: enfermeiroID,
    }
  );

  return res.data;
};

export const initTask = async (enfermeiroID, tarefaID) => {
  const res = await api.post(
    "/backoffice/iniciarTarefa",
    {
      token: "gecro",
      idEnfermeiro: enfermeiroID,
      idTarefa: tarefaID,
    }
  );

  return res.data;
};

export const getNurses = async (body) => {
  const res = await api.post(
    "/backoffice/buscarEnfermeiros",
    body
  );

  return res.data;
};

export const sendDelegateTask = async (info) => {
  const res = await api.post(
    "/backoffice/delegarTarefa",
    info
  );

  return res.data;
};
