import * as types from "./actionType";
import * as typesRotina from "Pages/Rotina/actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";

export const getInfoRedeApoio = pacienteID => async dispatch => {
  try {
    dispatch({ type: types.FETCHING_REDE_APOIO });
    const response = await services.getInfoRedeApoio(pacienteID);
    dispatch({
      type: types.FETCHING_REDE_APOIO_COMPLETE,
      payload: response
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_REDE_APOIO_ERROR });
  }
};

export const editInfo = paciente => async dispatch => {
  const newPacienteInfo = paciente;

  dispatch({ type: types.EDITED_INFO, payload: newPacienteInfo });
};

export const atualizarRedeApoio = body => async dispatch => {
  try {
    await services.attInfo(body);
    dispatch({
      type: typesRotina.MODIFY_FIELDS,
      payload: { redeApoio: false }
    });
  } catch (error) {
    // dispatch({ type: types.FETCHING_QUESTIONARIO_ERROR });
    sendErrorMsg(error);
  }
};
