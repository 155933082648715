export const FETCHING_LISTA_MEDICAMENTO =
  "acompanhamento.buscando_lista_medicamento";
export const FETCHING_LISTA_MEDICAMENTO_COMPLETE =
  "acompanhamento.buscou_lista_medicamento";
export const FETCHING_LISTA_MEDICAMENTO_ERROR =
  "acompanhamento.buscou_lista_medicamento_Error";

export const FETCHING_MEDICAMENTO_DETALHES =
  "acompanhamento.buscando_detalhes_medicamento";
export const FETCHING_MEDICAMENTO_DETALHES_COMPLETE =
  "acompanhamento.buscou_detalhes_medicamento";
export const FETCHING_MEDICAMENTO_DETALHES_ERROR =
  "acompanhamento.buscou_detalhes_medicamento_Error";

export const FETCHING_MEDICAMENTO = "acompanhamento.buscando_medicamento";
export const FETCHING_MEDICAMENTO_COMPLETE =
  "acompanhamento.buscou_medicamento";
export const FETCHING_MEDICAMENTO_ERROR =
  "acompanhamento.buscou_medicamento_Error";

export const CHANGE_MEDICAMENTO_COMPLETE = "acompanhamento.mudou_medicamento";

export const FETCHING_ACOMPANHAMENTO = "acompanhamento.buscando_acompanhamento";
export const FETCHING_ACOMPANHAMENTO_COMPLETE =
  "acompanhamento.buscando_acompanhamento";
export const FETCHING_ACOMPANHAMENTO_ERROR =
  "acompanhamento.buscando_acompanhamento_error";

export const FETCHING_MODELOS_COMPLETE = "acompanhamento.buscando_modelos"

export const FETCHING_DOSAGEM_COMPLETE = "acompanhamento.buscou_dosagem";
export const FETCHING_FREQUENCIA_COMPLETE = "acompanhamento.buscou_frequencia";
export const FETCHING_RECOMENDACAO_COMPLETE =
  "acompanhamento.buscou_recomendacoes";
export const FETCHING_ENFERMEIROS_COMPLETE =
  "acompanhamento.buscou_enfermeiros";

export const SEND_MEDICAMENTO = "acompanhamento.envia_medicamento";
export const SEND_MEDICAMENTO_COMPLETE = "acompanhamento.enviou_medicamento";
export const SEND_MEDICAMENTO_ERROR = "acompanhamento.enviou_medicamento_error";

export const DELETE_MEDICAMENTO = "acompanhamento.envia_medicamento";
export const DELETE_MEDICAMENTO_COMPLETE = "acompanhamento.enviou_medicamento";
export const DELETE_MEDICAMENTO_ERROR =
  "acompanhamento.deletou_medicamento_error";

export const SEND_ACOMPANHAMENTO = "acompanhamento.envia_acompanhamento";
export const SEND_ACOMPANHAMENTO_COMPLETE =
  "acompanhamento.enviou_acompanhamento";
export const SEND_ACOMPANHAMENTO_ERROR = "acompanhamento.enviou_acompanhamento";

export const MORBIDADES = "acompanhamento.fetch_morbidades_paciente";



export const FETCHING_LISTA_TAREFA_NF =
  "acompanhamento.buscando_lista_tarefanf";
export const FETCHING_LISTA_TAREFA_NF_COMPLETE =
  "acompanhamento.buscou_lista_tarefanf";
export const FETCHING_LISTA_TAREFA_NF_ERROR =
  "acompanhamento.buscou_lista_tarefanf_Error";

export const FETCHING_TAREFA_NF_DETALHES =
  "acompanhamento.buscando_detalhes_tarefanf";
export const FETCHING_TAREFA_NF_DETALHES_COMPLETE =
  "acompanhamento.buscou_detalhes_tarefanf";
export const FETCHING_TAREFA_NF_DETALHES_ERROR =
  "acompanhamento.buscou_detalhes_tarefanf_Error";

export const FETCHING_TAREFA_NF = "acompanhamento.buscando_tarefanf";
export const FETCHING_TAREFA_NF_COMPLETE =
  "acompanhamento.buscou_tarefanf";
export const FETCHING_TAREFA_NF_ERROR =
  "acompanhamento.buscou_tarefanf_Error";

export const CHANGE_TAREFA_NF_COMPLETE = "acompanhamento.mudou_tarefanf";

export const SEND_TAREFA_NF = "acompanhamento.envia_tarefanf";
export const SEND_TAREFA_NF_COMPLETE = "acompanhamento.enviou_tarefanf";
export const SEND_TAREFA_NF_ERROR = "acompanhamento.enviou_tarefanf_error";

export const DELETE_TAREFA_NF = "acompanhamento.envia_tarefanf";
export const DELETE_TAREFA_NF_COMPLETE = "acompanhamento.enviou_tarefanf";
export const DELETE_TAREFA_NF_ERROR =
  "acompanhamento.deletou_tarefanf_error";

export const FETCHING_VALORES_OUTLIERS = "acompanhamento.buscando_valores_outliers"
export const FETCHING_VALORES_OUTLIERS_COMPLETE = "acompanhamento.buscou_valores_outliers"
export const FETCHING_VALORES_OUTLIERS_ERROR = "acompanhamento.buscou_valores_outliers_error"