import React from "react";
import "./style.scss";
import Card from "../Card";
import { Icon } from "antd";
const Coluna = ({
  loading,
  tarefas,
  isPendente,
  isAndamento,
  isConcluido,
  admin,
  initTask,
  delegateTask
}) => {
  return (
    <>
      <div className="layoutColuna">
        <div>
          <span>
            {isPendente && "Não iniciado"}
            {isAndamento && "Em atendimento"}
            {isConcluido && "Concluído"}
          </span>
          {loading && <Icon style={{ marginLeft: "1vh" }} type="loading" />}
        </div>
        <div className="coluna-overflow">
          {tarefas.map((tarefa, i) => {
            return (
              <Card
                initTask={initTask}
                delegateTask={delegateTask}
                admin={admin}
                isPendente={isPendente}
                isAndamento={isAndamento}
                tipo={tarefa.tipo.id}
                criticidade={tarefa.criticidade}
                horario={tarefa.horario}
                nome={tarefa.nomePaciente}
                id={tarefa.id}
                key={`card_tarefa_${tarefa.id}`}
                status={tarefa.status.id}
                panico={tarefa.panico}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Coluna;
