import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tabs, Typography, Button, Table, Badge, Dropdown, Icon } from 'antd'
import InformacaoPassoal from 'Components/InformacaoPessoal'
import Saude from 'Components/Saude'
import Medicamento from 'Components/Acompanhamento/Medicamentos'
import Acompanhamento from 'Components/Acompanhamento'
import Questionario from 'Components/Questionario'
import HistoryModal from 'Components/HistoryModal'
import ReportModal from 'Components/ReportModal'
import RedeApoio from 'Components/RedeApoio'
import ContratoResponsavel from 'Components/ContratoResponsavel'
import PlanoCuidado from 'Components/PlanoCuidado'
import {
  getRotinaGeral,
  getHistoricoObs,
  getHistoricoChart,
  getHistoricoRelatorios,
  enviarRelatorioAcompanhamento,
  setActiveTab,
} from '../action'
import { TIPO_OCORRENCIA } from 'Misc/constants'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import IndiceIvcfCard from 'Components/IndiceIvcfCard'
import TarefaNaoFarmacologica from 'Components/Acompanhamento/TarefasNaoFarmacologicas'
import RaioXModal from 'Components/RaioXModal'

const { Text } = Typography
const { TabPane } = Tabs

const OpcoesRotina = ({
  unsaved,
  observacao,
  ocorrencias,
  paciente,
  getRotinaGeral,
  getHistoricoObs,
  getHistoricoChart,
  getHistoricoRelatorios,
  enviarRelatorioAcompanhamento,
  rotina_loading,
  obs_loading,
  report_loading,
  readOnly = false,
  grauParentescoRespondente,
  funcionarioACS,
  activeTab,
  setActiveTab
}) => {
  const {
    infoPessoal,
    doenca,
    profissional,
    parametroSaude,
    questionario,
    redeApoio,
    responsavel,
    ligacoes,
    tarefasNaoFarmacologicas
  } = unsaved

  const { tarefa, idPaciente } = useParams()

  useEffect(() => {
    if (paciente) getRotinaGeral(paciente)
    // eslint-disable-next-line
  }, [paciente])

  const [obsModal, setObsModal] = useState(false)
  const [raioXModal, setRaioXModal] = useState(false)
  const [chartModal, setChartModal] = useState(false)
  const [reportModal, setReportModal] = useState(false)
  // const { activeTab2, setActiveTab } = useRelatedQuestion(
  //   (state) => state
  // );

  const handleOpenModal = async () => {
    const inicio = moment().subtract(1, 'month').format('DD/MM/YYYY')
    const fim = moment().format('DD/MM/YYYY')

    await getHistoricoObs(tarefa, inicio, fim, idPaciente)
    setObsModal(true)
  }

  const handleOpenChartModal = async () => {
    const inicio = moment().subtract(1, 'month').format('DD/MM/YYYY')
    const fim = moment().format('DD/MM/YYYY')

    await getHistoricoChart(tarefa, inicio, fim, idPaciente)
    setChartModal(true)
  }

  const handleEnviarRelatorio = async () => {
    await enviarRelatorioAcompanhamento(paciente)
  }

  const handleOpenRelatorioModal = async () => {
    await getHistoricoRelatorios(paciente)
    setReportModal(true)
  }

  const handleChangeTab = (tabKey) => setActiveTab(tabKey)

  const columns = [
    {
      title: (
        <Text className="cadastro-section-title" style={{ fontSize: '14px' }}>
          Tipo
        </Text>
      ),
      dataIndex: 'tipo',
    },
    {
      title: (
        <Text className="cadastro-section-title" style={{ fontSize: '14px' }}>
          Descrição
        </Text>
      ),
      dataIndex: 'descricao',
    },
    {
      title: (
        <Text className="cadastro-section-title" style={{ fontSize: '14px' }}>
          Data
        </Text>
      ),
      dataIndex: 'data',
    },
  ]

  const menu = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={handleEnviarRelatorio}
        style={{ marginBottom: '2px' }}
        type="primary"
        ghost
      >
        Enviar
      </Button>
      <Button onClick={handleOpenRelatorioModal} type="primary" ghost>
        Ver histórico de envio
      </Button>
    </div>
  )

  return (
    <>
      <ReportModal open={reportModal} onClose={() => setReportModal(false)} />
      <HistoryModal open={obsModal} onClose={() => setObsModal(false)} />
      <HistoryModal
        open={chartModal}
        onClose={() => setChartModal(false)}
        charts
      />
      <RaioXModal onClose={() => setRaioXModal(false)} open={raioXModal} />
      <Tabs animated={true} activeKey={activeTab} onChange={handleChangeTab}>
        <TabPane tab="Geral" key="1" className="rotina-tabpane">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text
              className={'cadastro-section-title'}
              style={{ marginTop: '3vh' }}
            >
              ACS responsável pela adesão do paciente:{' '}
              <span className="span-rotina-geral">{funcionarioACS}</span>
            </Text>
            <Text
              className={'cadastro-section-title'}
              style={{ marginTop: '3vh' }}
            >
              Responsável por responder o questionário:{' '}
              <span className="span-rotina-geral">
                {grauParentescoRespondente?.grauParentesco}
              </span>
            </Text>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '3vh',
              }}
            >
              <Text className={'cadastro-section-title'}>
                Observações da última entrevista
                {'       '}
                <Button
                  onClick={handleOpenModal}
                  style={{ fontSize: '12px', marginLeft: '.5rem' }}
                  type="primary"
                  ghost
                  loading={obs_loading}
                >
                  Ver histórico
                </Button>
                <Button
                  onClick={() => setRaioXModal(true)}
                  style={{ fontSize: '12px', marginLeft: '1rem' }}
                  type="primary"
                  ghost
                >
                  Ver raio-x do paciente
                </Button>
              </Text>

              <IndiceIvcfCard paciente={paciente} activeTabKey={activeTab} />
            </div>
            <Text className="cadastro-section-description">{observacao}</Text>
            <Text
              className={'cadastro-section-title'}
              style={{ marginTop: '3vh' }}
            >
              Últimas ocorrências
              {'       '}
            </Text>
            <Text
              className="cadastro-section-description"
              style={{
                marginTop: '1vh',
                backgroundColor: '#fff',
                width: '100%',
              }}
            >
              <Table
                loading={rotina_loading}
                pagination={{ pageSize: 5 }}
                columns={columns}
                dataSource={ocorrencias?.map((item, index) => {
                  return {
                    key: index,
                    tipo: (
                      <Badge
                        className="labelsBarra"
                        color={TIPO_OCORRENCIA[item.tipo]}
                        text={item.titulo}
                      />
                    ),
                    descricao: item.descricao,
                    data: item.data,
                  }
                })}
                size="small"
              />
            </Text>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                marginTop: '2vh',
              }}
            >
              <Button
                style={{
                  fontSize: '12px',
                  width: '20%',
                  minWidth: '210px',
                }}
                type="primary"
                ghost
                onClick={handleOpenChartModal}
                loading={obs_loading}
              >
                Ver histórico de medições
              </Button>

              <Dropdown overlay={menu}>
                <Button
                  style={{
                    fontSize: '12px',
                    textAlign: 'left',
                  }}
                  type="primary"
                  ghost
                  loading={report_loading}
                >
                  Relatório <Icon type="down" />
                </Button>
              </Dropdown>
            </div>

            <PlanoCuidado rotina />
          </div>
        </TabPane>
        <TabPane
          tab={<Badge dot={infoPessoal}>Informações pessoais</Badge>}
          key="2"
          className="rotina-tabpane"
        >
          <InformacaoPassoal
            title="Informações pessoais"
            rotina
            readOnly={readOnly}
          />
        </TabPane>
        <TabPane
          tab={<Badge dot={doenca}>Doenças</Badge>}
          key="3"
          className="rotina-tabpane"
        >
          <Saude title="Doenças" rotina morbidade readOnly={readOnly} />
        </TabPane>
        <TabPane tab="Medicamentos" key="4" className="rotina-tabpane">
          <Medicamento rotina readOnly={readOnly} />
        </TabPane>
        <TabPane
          tab={<Badge dot={parametroSaude}>Parâmetros de saúde</Badge>}
          key="5"
          className="rotina-tabpane"
        >
          <Acompanhamento
            title="Parâmetros de saúde"
            rotina
            saude
            readOnly={readOnly}
          />
        </TabPane>
        <TabPane
          tab={<Badge dot={tarefasNaoFarmacologicas}>Tarefas não farmacológicas</Badge>}
          key="11"
          className="rotina-tabpane"
        >
          <TarefaNaoFarmacologica rotina readOnly={readOnly} />
        </TabPane>
        <TabPane
          tab={<Badge dot={questionario}>Questionário</Badge>}
          key="6"
          className="rotina-tabpane"
        >
          <Questionario rotina title="Questionário" readOnly={readOnly} />
        </TabPane>
        <TabPane
          tab={<Badge dot={redeApoio}>Rede de apoio</Badge>}
          key="7"
          className="rotina-tabpane"
        >
          <RedeApoio rotina title="Rede de apoio" readOnly={readOnly} />
        </TabPane>
        <TabPane
          tab={<Badge dot={profissional}>Profissionais</Badge>}
          key="8"
          className="rotina-tabpane"
        >
          <Saude title="Profissionais" rotina readOnly={readOnly} />
        </TabPane>
        <TabPane
          tab={<Badge dot={responsavel}>Responsável</Badge>}
          key="9"
          className="rotina-tabpane"
        >
          <ContratoResponsavel title="Responsável" rotina readOnly={readOnly} />
        </TabPane>
        <TabPane
          tab={<Badge dot={ligacoes}>Ligações periódicas</Badge>}
          className="rotina-tabpane"
          key="10"
        >
          <Acompanhamento
            title="Ligações periódicas"
            rotina
            readOnly={readOnly}
          />
        </TabPane>
      </Tabs>
    </>
  )
}

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
  observacao: store.rotina.observacao,
  ocorrencias: store.rotina.ocorrencias,
  paciente: store.adesao.paciente,
  rotina_loading: store.rotina.loading,
  obs_loading: store.rotina.obsLoading,
  report_loading: store.rotina.reportLoading,
  grauParentescoRespondente: store.rotina.grauParentescoRespondente,
  funcionarioACS: store.rotina.funcionarioACS,
  activeTab: store.rotina.activeTab
})

const mapDispatchToProps = (dispatch) => ({
  getRotinaGeral: (pacienteId) => dispatch(getRotinaGeral(pacienteId)),
  getHistoricoObs: (tarefa, inicio, fim, idPaciente) =>
    dispatch(getHistoricoObs(tarefa, inicio, fim, idPaciente)),
  getHistoricoChart: (tarefa, inicio, fim, idPaciente) =>
    dispatch(getHistoricoChart(tarefa, inicio, fim, idPaciente)),
  enviarRelatorioAcompanhamento: (paciente) =>
    dispatch(enviarRelatorioAcompanhamento(paciente)),
  getHistoricoRelatorios: (paciente) =>
    dispatch(getHistoricoRelatorios(paciente)),
  setActiveTab: (tab) => dispatch(setActiveTab(tab))
})

export default connect(mapStateToProps, mapDispatchToProps)(OpcoesRotina)
