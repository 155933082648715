import React from "react";
import { Steps, Popover, Typography, Button } from "antd";
import InformacaoPessoal from "../../../../Components/InformacaoPessoal";
import QuestionarioCadastro from "../../../../Components/Questionario";
import Saude from "../../../../Components/Saude";
import RedeApoio from "../../../../Components/RedeApoio";
import ContratoResponsavel from "../../../../Components/ContratoResponsavel";
import { connect } from "react-redux";
import * as actions from "../../action";
import { withRouter, useParams } from "react-router-dom";
import "./style.scss";

const { Step } = Steps;
const { Text } = Typography;
const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);

const FluxoCadastral = (props) => {
  const { history } = props;
  const { tarefa } = useParams();
  const getSteps = () => {
    const { step } = props;

    const handleComplete = async () => {
      const res = await props.completeAdesao(props.paciente, tarefa);
      if (res) {
        history.push("/painel");
      }
    };

    if (step === 1) {
      return (
        <>
          <InformacaoPessoal
            nextStep={props.nextStep}
            backStep={props.backStep}
          />
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <Saude
            nextStep={props.nextStep}
            backStep={props.backStep}
            morb={[]}
            especialidades={[]}
            especialidadeSelected={[]}
          />
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <QuestionarioCadastro
            nextStep={props.nextStep}
            backStep={props.backStep}
          />
        </>
      );
    } else if (step === 4) {
      return (
        <>
          <RedeApoio nextStep={props.nextStep} backStep={props.backStep} />
        </>
      );
    } else if (step === 5) {
      return (
        <>
          <ContratoResponsavel
            nextStep={props.nextStep}
            backStep={props.backStep}
          />
        </>
      );
    } else {
      return (
        <>
          <Text className="clienteMessage"> Mensagem para o cliente </Text>
          <p>
            Bem vindo(a) ao Viver360 Care Platform! Estamos muito felizes em ter
            você sob nossos cuidados.
          </p>
          <p>
            Em breve um de nossos profissionais da equipe de saúde especializada
            irá entrar em contato para darmos início ao seu acompanhamento.{" "}
          </p>
          <p>
            Em caso de dúvidas, sugestões, críticas ou elogios, entre em contato
            conosco através do e-mail atendimento@vida.com.br ou no telefone
            (11) 9999 9999.
          </p>
          <div style={{ display: "flex", width: "40%" }}>
            <Button
              className="buttonComplete"
              onClick={() => handleComplete()}
              type="primary"
            >
              Finalizar atendimento
            </Button>
          </div>
        </>
      );
    }
  };

  return (
    <div className="cadastro-overflow">
      <div className="cadastro-layout">
        {props.step < 6 && (
          <Steps
            style={{ marginBottom: "5vh", alignSelf: "center" }}
            current={props.step - 1}
            progressDot={customDot}
          >
            <Step title="Informações Pessoais" />
            <Step title="Saúde" />
            <Step title="Questionário" />
            <Step title="Rede de Apoio" />
            <Step title="Responsável pelo contrato" />
          </Steps>
        )}
        {getSteps()}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => ({
  step: store.adesao.step,
  paciente: store.adesao.paciente,
});

const mapDispatchToProps = (dispatch) => ({
  nextStep: (step) => dispatch(actions.nextStep(step)),
  backStep: (step) => dispatch(actions.backStep(step)),
  completeAdesao: (pacienteID, tarefa) =>
    dispatch(actions.completarAdesao(pacienteID, tarefa)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FluxoCadastral));
