import React from "react";
import Gauge from "./Gauge";
import { Tooltip } from "antd";
import {
  ClusterIgualIcon,
  ClusterNegativoIcon,
  ClusterPositivoIcon,
  InfoIcon,
} from "Misc/icons";

const Card = ({ data, title, parameter }) => {
  const TooltipAgudizacao = () => {
    return (
      <>
        {parameter === "agudizacao" &&
          data.agudizacoes.map((item) => <p>{item.nomeTipoUrgencia}: {item.quantidade}</p>)}
      </>
    );
  };
  return (
    <div className="card-wrapper">
      <h2>{title}</h2>
      <div className="gauge-row">
        {parameter === "agudizacao" ? (
          <div className="indicator-agudizacao">
            <div className={data?.variation}>
              <p>{data?.values}</p>
              <span className="tooltip">
                <Tooltip
                  title={<TooltipAgudizacao />}
                  overlayClassName="tooltip-agudizacao"
                >
                  <span>
                    <InfoIcon color={data?.color} />
                  </span>
                </Tooltip>
              </span>
            </div>
          </div>
        ) : (
          data?.graficos?.length > 0 &&
          data.graficos.map((grafico, index) => (
            <Gauge data={data.graficos} index={index} status={grafico.color} />
          ))
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        {parameter !== "reporte" && (
          <>
            <div className={`text-indicator ${data?.variation}`}>
              <p>
                {parameter === "rotina"
                  ? "Variação desde a última entrevista de rotina"
                  : "Variação no período (comparada ao período anterior)"}
              </p>
              {data?.variation === "negative" ? (
                <ClusterNegativoIcon />
              ) : data?.variation === "positive" ? (
                <ClusterPositivoIcon />
              ) : (
                <ClusterIgualIcon />
              )}
            </div>
            <Tooltip title={data?.descricao}>
              <span>
                <InfoIcon />
              </span>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
