/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, Input, Button, Select, Tooltip } from "antd";
import _ from "lodash";

import { connect } from "react-redux";
import * as actions from "../action";

const { Option } = Select;

const AtendentesFiltro = ({
  situacoes,
  loadingSituacoes,
  buscarOpcoesSituacao,
  buscarOpcoesUbs,
  buscarOpcoesPerfil,
  buscarAtendentes,
  loadingAtendentes,
  resetAtendentes,
  salvarFiltro,
  resetFiltro,
  filtro,
  opcoesUbs,
  loadingUbs,
  perfis,
  loadingPerfis,
  ...props
}) => {
  const {
    getFieldDecorator,
    validateFields,
    getFieldsValue,
    resetFields,
  } = props.form;

  useEffect(() => {
    resetAtendentes();
    buscarOpcoesSituacao();
    buscarOpcoesUbs();
    buscarOpcoesPerfil();
  }, []);

  const isAllEmpty = () => {
    const values = getFieldsValue([
      "nome",
      "situacao",
      "matricula",
      "perfil",
      "ubs",
    ]);
    return !Object.keys(values).some((field) => values[field]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        delete values.dataAdmissao;
        buscarAtendentes(values);
      }
    });
  };

  const handleClear = () => {
    resetFiltro();
    resetFields();
  };

  const nameLayout = {
    labelCol: {
      xs: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 21 },
    },
  };

  const matriculaLayout = {
    labelCol: {
      xs: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 17 },
    },
  };

  const situacaoLayout = {
    labelCol: {
      xs: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
    },
  };

  return (
    <Form onSubmit={handleSubmit} layout="horizontal">
      <Form.Item label="Nome" {...nameLayout} style={{  marginBottom: '12px' }}>
        {getFieldDecorator("nome")(<Input allowClear />)}
      </Form.Item>

      <div style={{ display: "flex" }}>
        <Form.Item label="Matrícula" {...matriculaLayout} style={{width: '50%', marginBottom: '12px' }}>
          {getFieldDecorator("matricula")(<Input allowClear />)}
        </Form.Item>

        <Form.Item
          label="Situação"
          {...situacaoLayout}
          style={{ width: "50%", marginLeft: "10px", marginBottom: '12px' }}
        >
          {getFieldDecorator("situacao")(
            <Select allowClear loading={loadingSituacoes}>
              {situacoes.map((opt) => (
                <Option key={opt.codigo} value={opt.codigo}>
                  {opt.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>

      <div style={{ display: "flex" }}>
        <Form.Item
          label="Perfil"
          {...matriculaLayout}
          style={{ width: "50%", marginBottom: '12px' }}
        >
          {getFieldDecorator("perfil")(
            <Select allowClear loading={loadingPerfis}>
              {perfis.map((opt) => (
                <Option key={opt.id} value={opt.id}>
                  {opt.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label="UBS"
          {...situacaoLayout}
          style={{ width: "50%", marginLeft: "10px", marginBottom: '12px' }}
        >
          {getFieldDecorator("ubs")(
            <Select allowClear loading={loadingUbs}>
              {opcoesUbs.map((opt) => (
                <Option key={opt.ubs} value={opt.ubs}>
                  <Tooltip placement="topLeft" title={opt.nome}>
                    {opt.nome}
                  </Tooltip>
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </div>

      <div className="filtro-btn">
        <Button
          disabled={isAllEmpty() || loadingSituacoes || loadingAtendentes}
          className="secondary-btn"
          onClick={handleClear}
        >
          Limpar filtros
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isAllEmpty() || loadingSituacoes}
          loading={loadingAtendentes}
          className="primary-btn"
        >
          Buscar
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (store) => ({
  situacoes: store.atendentes.situacoes,
  loadingSituacoes: store.atendentes.loadingSituacoes,
  loadingAtendentes: store.atendentes.loadingAtendentes,
  filtro: store.atendentes.filtro,
  opcoesUbs: store.atendentes.opcoesUbs,
  loadingUbs: store.atendentes.loadingUbs,
  perfis: store.atendentes.perfis,
  loadingPerfis: store.atendentes.loadingPerfis,
});

const mapDispatchToProps = (dispatch) => ({
  buscarOpcoesSituacao: () => dispatch(actions.buscarOpcoesSituacao()),
  buscarOpcoesUbs: () => dispatch(actions.buscarOpcoesUbs()),
  buscarOpcoesPerfil: () => dispatch(actions.buscarOpcoesPerfil()),
  buscarAtendentes: (body) => dispatch(actions.buscarAtendentes(body)),
  resetAtendentes: () => dispatch(actions.resetAtendentes()),
  salvarFiltro: (filtro) => dispatch(actions.saveFiltro(filtro)),
  resetFiltro: () => dispatch(actions.resetFiltro()),
});

const handleChange = (props, changed, allValues) => {
  props.salvarFiltro(allValues);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "filtro_atendentes",
    onValuesChange: _.debounce(handleChange, 300),
  })(AtendentesFiltro)
);
