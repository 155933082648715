import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getInfoCabecalho } from "../Adesao/action";
import { Row, Col } from "antd";
import { useParams, Redirect } from "react-router-dom";
import BarraInformacao from "../../Components/BarraInformacao";
import { buscarCuidadores } from "../Urgencia/action";
import BarLoader from "react-spinners/BarLoader";
import OpcoesRotina from "../Rotina/OpcoesRotina";
import Instructions from "../../Components/Instructions";

const MedicoesIncomuns = (props) => {
  const { tarefa, tipo } = useParams();

  useEffect(() => {
    if (props.paciente) {
      props.buscarCuidadores(props.paciente);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);
  useEffect(() => {
    props.getInfoCabecalho(props.user.id, tarefa, tipo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarefa]);

  return (
    <>
      {!tarefa && <Redirect to="/" />}
      <BarraInformacao />
      {!props.loading ? (
        <Row className={"layout-rotina"}>
          <Col className={"coluna-folloup"}>
            <Instructions />
          </Col>
          <Col className={"coluna-body"}>
            <OpcoesRotina />
          </Col>
        </Row>
      ) : (
        <BarLoader color={"#0F79B2"} width={"100vw"} />
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  loading: store.barrainformacao.loading,
  user: store.login.user,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoCabecalho: (enfermeiroID, tarefaID, tipo) =>
    dispatch(getInfoCabecalho(enfermeiroID, tarefaID, tipo)),
  buscarCuidadores: (paciente) => dispatch(buscarCuidadores(paciente)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicoesIncomuns);
