import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  InputNumber,
  Form,
  Select,
  Button,
  Icon,
  Tooltip,
  Checkbox,
} from "antd";
import InfoIcon from "Components/InfoIcon";
import _ from "lodash";
import { connect } from "react-redux";
import * as actions from "../action";
import moment from "moment";
import { DIAS_SEMANA } from "../../../Misc/constants";
import { SCROLL_CONFIG } from "Misc/constants";
import { maskDate, maskTime, sendSuccessMsg } from "Misc/utils";
import { DATE_FORMAT, TIME_FORMAT, MEDICAMENTO_FRACAO } from "Misc/constants";

const { Option } = Select;
let fieldId = 0;

const MedicamentoModal = (props) => {
  const { onOk, title, medicamentoID, onCancel, confirmLoading, ...rest } =
    props;
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    getFieldValue,
  } = props.form;

  const [medicalAdvice, setMedicalAdvice] = useState(false);
  const [filteredRecomendacoes, setFilteredRecomendacoes] = useState([])
  const [medicalAdviceOpt, setMedicalAdviceOpt] = useState([])

  useEffect(() => {
    setMedicalAdviceOpt(props.recomendacoes.filter((recomendacao) => recomendacao.nome ==='conforme orientação médica'))
    setFilteredRecomendacoes(props.recomendacoes.filter((recomendacao) => recomendacao.nome !=='conforme orientação médica'))
  }, [props.recomendacoes])

  const [recomendacaoField, setRecomendacaoField] = useState(false);
  const [loadSerach, setLoadSerach] = useState(false);
  const [inputValue, setInputValue] = useState();
  const onSearch = async (searchText) => {
    setLoadSerach(true);
    setInputValue(searchText);
    if (searchText !== "" && searchText.length > 3) {
      await props.getMedicamento(searchText);
      setLoadSerach(false);
    }
  };

  useEffect(() => {
    setRecomendacaoField(
      props.medicamentoDetalhes &&
        props.medicamentoDetalhes.recomendacao &&
        props.medicamentoDetalhes.recomendacao.id === -1
        ? true
        : false
    );
  }, [props.medicamentoDetalhes]);

  const removeHorarioField = (k) => {
    const horarios = getFieldValue(`horariosMedicamentos`);
    if (horarios.length === 1) return;

    setFieldsValue({
      horariosMedicamentos: horarios.filter((key) => key !== k),
    });
  };

  const addHorarioField = () => {
    const horarios = getFieldValue("horariosMedicamentos");
    const nexthorarios = horarios.concat(fieldId++);

    setFieldsValue({
      horariosMedicamentos: nexthorarios,
    });
  };

  const validateInput = e => {
    e.preventDefault();

    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const newValue = { ...values };
        const { fim, quantidade } = values;
        const newFinal = fim ? fim : null;
        const newQuantidade = quantidade ? quantidade : null;
        newValue.fim = newFinal;
        newValue.idMedicamentoAtual = medicamentoID;
        newValue.quantidade = newQuantidade;
        newValue.horarios = Object.values(values.horarios);
        props.adicionaMedicamento(props.paciente, newValue).then(res => {
          onOk();
          sendSuccessMsg(newValue.idMedicamentoAtual ? "Medicamento atualizado com sucesso." : "Medicamento salvo com sucesso.")
          props.form.resetFields();
          setMedicalAdvice(false)
        });
      }
    });
  };

  const handleCancelar = async () => {
    onCancel();
    props.form.resetFields();
    setMedicalAdvice(false)
    props.resetMedicamentoDetalhe();
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
  };

  const onChangeRecomendacao = value => {
    if (value === -1) {
      setRecomendacaoField(true);
    } else {
      setRecomendacaoField(false);
    }
  };

  const handleSelectChangeFrequencia = value => {
    props.form.setFieldsValue({
      frequencia: value
    });
  };

  const handleCheckboxChangeMedicalAdvice = (value) => {
    setMedicalAdvice(value.target.checked)
    if (value.target.checked) {
      props.form.setFieldsValue({
        recomendacao: medicalAdviceOpt[0]?.id,
        quantidade: undefined
      });
    } else {
      props.form.setFieldsValue({
        recomendacao: undefined,
      });
    }
  }

  useEffect(() => {
    props.medicamentoDetalhes?.recomendacao?.id === 11 && setMedicalAdvice(true)
  }, [props.medicamentoDetalhes])

  const verificaFrequencia = () => {
    switch (props.form.getFieldValue("frequencia")) {
      case 2:
        return (
          <Form.Item
            key="input-medicamento-qtd-dias"
            label="Quantidade de dias"
          >
            {getFieldDecorator("xDias", {
              rules: [{ required: true, message: "Insira a quantidade!" }],
              initialValue:
                props.medicamentoDetalhes &&
                props.medicamentoDetalhes.frequencia &&
                props.medicamentoDetalhes.frequencia.xDias
            })(<InputNumber style={{ width: 150 }} />)}
          </Form.Item>
        );
      case 3:
        return (
          <>
            <Form.Item
              key="input-medicamento-dias-semanas"
              label="Dias da semana"
            >
              {getFieldDecorator("diaSemana", {
                rules: [{ required: true, message: "Insira os dias!" }],
                initialValue:
                  props.medicamentoDetalhes &&
                  props.medicamentoDetalhes.frequencia &&
                  props.medicamentoDetalhes.frequencia.diaSemana
              })(
                <Select mode="tags">
                  {DIAS_SEMANA.map(dia => {
                    return <Option key={dia.valor}>{dia.descricao}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </>
        );

      default:
        break;
    }
  };

  getFieldDecorator(`horariosMedicamentos`, {
    initialValue:
      props.medicamentoDetalhes && props.medicamentoDetalhes.horarios
        ? props.medicamentoDetalhes.horarios
        : [""],
  });
  const horarios = getFieldValue(`horariosMedicamentos`);

  return (
    <Modal
      wrapClassName="modal-delegate"
      title={title}
      okText="Confirmar"
      cancelText="Cancelar"
      onOk={e => validateInput(e)}
      width={"55vw"}
      style={{ minWidth: "700px" }}
      centered={true}
      bodyStyle={{
        height: "calc(90vh - 110px)",
        overflowY: "auto"
      }}
      onCancel={handleCancelar}
      confirmLoading={confirmLoading}
      {...rest}
    >
      <Form {...formItemLayout} className="modal-medicamentos" hideRequiredMark>
        <Form.Item key="input-medicamento-nome" label="Medicamento">
          {getFieldDecorator("medicamento", {
            rules: [{ required: true, message: "Insira um medicamento!" }],
            initialValue:
              props.medicamentoDetalhes && props.medicamentoDetalhes.id
          })(
            <Select
              showSearch
              style={{ textTransform: "capitalize" }}
              placeholder="Selecione"
              loading={loadSerach}
              optionFilterProp="children"
              onSearch={_.debounce(text => onSearch(text), 300)}
              filterOption={(input, option) => {
                if (option.props.children?.props?.children) {
                  return (
                    option.props.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                } else {
                  return option.props.children
                }
              }}
            >
               {props.medicamentos.length <= 0 && (
                  <Option
                  key={inputValue}
                  value={inputValue}
                  style={{ textTransform: "capitalize" }}
                >
                  {inputValue}
                </Option>
                  )}
              {props.medicamentos.map(item => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.id}
                  >
                    <Tooltip placement="topLeft" title={item.nome}>
                      {item.nome}
                    </Tooltip>
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item key="input-medicamento-apelido" label="Apelido">
          {getFieldDecorator("apelido", {
            rules: [{ required: true, message: "Insira um apelido!" }],
            initialValue:
              props.medicamentoDetalhes && props.medicamentoDetalhes.apelido
          })(<Input placeholder="Como o idoso chama o medicamento?" />)}
        </Form.Item>
        <Form.Item key="input-medicamento-quantidade" label="Quantidade">
          {getFieldDecorator("quantidade", {
            rules: [
              {
                // required: true,
                required: !medicalAdvice,
                message: "Insira a quantidade válida!",
                pattern: MEDICAMENTO_FRACAO,
              },
            ],
            initialValue:
              props.medicamentoDetalhes && props.medicamentoDetalhes.quantidade
          })(
          <Input 
            style={{ width: 150 }} 
            disabled={medicalAdvice}
            suffix={ medicalAdvice || <InfoIcon title="Para comprimido podem ser frações 1/2, 1/3 ou 1/4"/> }/>
          )}
          <Checkbox
            onChange={handleCheckboxChangeMedicalAdvice}
            checked={medicalAdvice}
            style={{ marginLeft: "1rem" }}
          >
            Conforme orientação médica
          </Checkbox>
          <InfoIcon title="Use este campo para não ter que informar uma quantidade para o medicamento. Além disso o texto desse campo será usado no campo 'Recomendação'" />
        </Form.Item>

        <Form.Item key="input-medicamento-unidade" label="Unidade/Apresentação">
          {getFieldDecorator("unidade", {
            rules: [{ required: true, message: "Insira a unidade!" }],
            initialValue:
              props.medicamentoDetalhes &&
              props.medicamentoDetalhes.unidade &&
              props.medicamentoDetalhes.unidade.id
          })(
            <Select
              showSearch
              style={{ width: 150, textTransform: "capitalize" }}
              placeholder="Selecione"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.dosagens.map(item => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.id}
                  >
                    {item.nome}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item key="input-medicamento-frequencia" label="Frequencia">
          {getFieldDecorator("frequencia", {
            rules: [{ required: true, message: "Insira uma frequência!" }],
            initialValue:
              props.medicamentoDetalhes &&
              props.medicamentoDetalhes.frequencia &&
              props.medicamentoDetalhes.frequencia.id
          })(
            <Select
              showSearch
              style={{ width: 250, textTransform: "capitalize" }}
              placeholder="Selecione"
              onChange={value => handleSelectChangeFrequencia(value)}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.frequencias.map(item => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.id}
                  >
                    {item.nome}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
        {verificaFrequencia()}
        <Form.Item key="input-medicamento-horario" label="Horário">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {horarios.map((campo, index) => (
              <Form.Item key={`input-medicamento-horario-${index}`}>
                {getFieldDecorator(`horarios[${campo}]`, {
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    {
                      required: true,
                      message: "Insira um horário!",
                      pattern: TIME_FORMAT,
                    },
                  ],
                  getValueFromEvent: (e) => maskTime(e.target.value),
                  initialValue: typeof campo === "string" ? campo : null,
                })(
                  <Input
                    style={{ width: 130 }}
                    placeholder="HH:mm"
                    maxLength={5}
                    allowClear
                    disabled={props.readOnly}
                  />
                )}
                {horarios.length > 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    style={{ alignSelf: "center", padding: "0 .5vw" }}
                    onClick={() => removeHorarioField(campo)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Button
              className="buttonFooterBack"
              style={{ marginTop: "1vh", width: "8vw" }}
              onClick={addHorarioField}
            >
              + horário
            </Button>
          </div>
        </Form.Item>

        <Form.Item label="Período de uso" style={{marginTop:'12px'}}>
          <Form.Item
            style={{ display: "inline-block" }}
          >
            {getFieldDecorator("inicio", {
              rules: [
                { message: "Insira uma data válida.", pattern: DATE_FORMAT }
              ],
              initialValue:
                (props.medicamentoDetalhes.inicio &&
                  props.medicamentoDetalhes.inicio) ||
                moment().format("DD/MM/YYYY"),
              getValueFromEvent: e => maskDate(e.target.value)
            })(<Input placeholder="Início" maxLength={10} allowClear />)}
          </Form.Item>
          <Form.Item style={{ display: "inline-block", marginLeft:'16px' }}>
            {getFieldDecorator("fim", {
              rules: [
                { message: "Insira uma data válida.", pattern: DATE_FORMAT }
              ],
              initialValue:
                props.medicamentoDetalhes.fim && props.medicamentoDetalhes.fim,
              getValueFromEvent: e => maskDate(e.target.value)
            })(
              <Input
                placeholder="Fim (opcional)"
                maxLength={10}
                allowClear
              />
            )}
          </Form.Item>
        </Form.Item>

        <Form.Item key="input-medicamento-recomendacao" label="Recomendação">
          {getFieldDecorator("recomendacao", {
            rules: [{ required: true, message: "Insira uma recomendação!" }],
            initialValue:
              props.medicamentoDetalhes &&
              props.medicamentoDetalhes.recomendacao &&
              props.medicamentoDetalhes.recomendacao.id
          })(
            <Select
              showSearch
              disabled={medicalAdvice}
              placeholder="Selecione"
              optionFilterProp="children"
              onChange={onChangeRecomendacao}
              style={{ textTransform: "capitalize" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {!medicalAdvice ? filteredRecomendacoes.map((item) => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.id}
                  >
                    {item.nome}
                  </Option>
                );
              }) : (
                <Option
                style={{ textTransform: "capitalize" }}
                value={medicalAdviceOpt[0]?.id}
              >
                {medicalAdviceOpt[0] && medicalAdviceOpt[0].nome}
              </Option>
              )}
            </Select>
          )}

          {recomendacaoField && (
            <>
              {getFieldDecorator("personalizacao", {
                rules: [
                  { required: true, message: "Insira uma recomendação!" }
                ],
                initialValue:
                  props.medicamentoDetalhes &&
                  props.medicamentoDetalhes.recomendacao &&
                  props.medicamentoDetalhes.recomendacao.personalizacao
              })(<Input placeholder="Recomendação de ingestão" />)}
            </>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = store => ({
  paciente: store.adesao.paciente,
  medicamentoDetalhes: store.acompanhamento.medicamentoDetalhes,
  frequencias: store.acompanhamento.frequencias,
  dosagens: store.acompanhamento.dosagens,
  recomendacoes: store.acompanhamento.recomendacoes,
  medicamentos: store.acompanhamento.medicamentos,
  confirmLoading: store.acompanhamento.modalMedicamentoLoading
});
const mapDispatchToProps = dispatch => ({
  getMedicamento: nome => dispatch(actions.getMedicamento(nome)),
  adicionaMedicamento: (pacienteID, info) =>
    dispatch(actions.adicionaMedicamento(pacienteID, info)),
  resetMedicamentoDetalhe: () => dispatch(actions.resetMedicamentoDetalhe())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "medicamento_fields"
  })(MedicamentoModal)
);
