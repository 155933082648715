import api from "api";

export const buscarDashboard = async (body) => {
  const res = await api.post(
    "/backoffice/buscarDashboard",
    body
  );

  return res.data;
};

export const buscarTMEeTMA = async (body) => {
  const res = await api.post(
    "/backoffice/buscarTMEeTMA",
    body
  );

  return res.data;
};

export const buscarDetalhamentoDashboard = async (body) => {
  const res = await api.post(
    "/backoffice/buscarDetalhamentoDashboard",
    body
  );

  return res.data;
};

export const buscarOpcoesAssunto = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesAssunto"
  );

  return res.data;
};

export const buscarOpcoesPrioridade = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesPrioridade"
  );

  return res.data;
};

export const buscarOpcoesStatus = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesStatus"
  );

  return res.data;
};

export const buscarTarefasAtendentes = async (body) => {
  const res = await api.post(
    "/backoffice/buscarTarefasAtendentes",
    body
  );

  return res.data;
};

export const transferirTarefas = async (info) => {
  const res = await api.post(
    "/backoffice/delegarTarefa",
    info
  );

  return res.data;
};

export const buscarTotalPacientesAtendentes = async (body) => {
  const res = await api.post(
    "/backoffice/buscarTotalPacientesAtendentes",
    body
  );

  return res.data;
};

export const buscarPacientesAtendente = async (info) => {
  const res = await api.post(
    "/backoffice/buscarPacientesAtendente",
    info
  );

  return res.data;
};

export const transferirPacientes = async (info) => {
  const res = await api.post(
    "/backoffice/transferirPacientes",
    info
  );

  return res.data;
};

export const buscarOpcoesUbs = async () => {
  const res = await api.get(
    "/backoffice/buscarOpcoesUbs"
  );

  return res.data;
};

export const buscarOpcoesBairros = async (infoUbs) => {
  const res = await api.post(
    "/backoffice/buscarOpcoesBairros",
    infoUbs
  );

  return res.data;
};

export const buscarOpcoesMorbidades = async (body) => {
  const res = await api.post(
    "/backoffice/buscarOpcoesMorbidades",
    body
  );

  return res.data;
};


export const buscarPacientePorParteNome = async (name) => {
  const res = await api.post(
    "/backoffice/buscarPacientePorParteNome",
    name
  );

  return res.data;
};