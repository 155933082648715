import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Card from "Pages/PainelAtividades/Card";
import DelegateModal from "Pages/PainelAtividades/DelegateModal";
import _ from "lodash";
import { history } from "App";
import * as actions from "Pages/PainelAtividades/action";
import { buscarDetalhamentoDashboard } from "../action";
import { message } from "antd";

export const DetalhamentoPainel = ({
  user,
  buscarDetalhamentoDashboard,
  filtro,
  getNurses,
  sendDelegarTarefas,
  admin,
  tarefasPendentes,
  tarefasAndamento,
  tarefasConcluidas,
  dashCompleteDetail,
}) => {
  //TODO - TRATAR
  useEffect(() => {
    if (_.isEmpty(dashCompleteDetail)) {
      history.push("/dashboard");
    }
  }, [dashCompleteDetail]);

  const [delegateModal, setDelegateModal] = useState(false);
  const [tarefaAtual, setTarefaAtual] = useState(null);

  const handleDelegate = async (value) => {
    const mock = {
      token: "gecro",
      idEnfermeiroSolicitante: parseInt(user.id),
      idEnfermeiroAtual: parseInt(filtro.atendente),
      idNovoEnfermeiro: parseInt(value),
      idTarefa: parseInt(tarefaAtual),
    };
    try {
      await sendDelegarTarefas(JSON.stringify(mock));
      setDelegateModal(false);
      await buscarDetalhamentoDashboard(filtro);
      message.success("Transferência realizada com sucesso");
    } catch (error) {}
  };

  const handleOpenModal = async (id) => {
    const res = await getNurses({ tarefa: id });
    if (res) setDelegateModal(true);
    setTarefaAtual(id);
  };

  return (
    <>
      <DelegateModal
        visible={delegateModal}
        onOk={handleDelegate}
        confirmLoading={false}
        onCancel={() => setDelegateModal(false)}
      />
      <div className="painel-detalhamento">
        <div className="coluna-detalhamento">
          <h3>Não iniciado ({tarefasPendentes.length})</h3>
          <div className="detalhamento-overflow">
            {tarefasPendentes.map((tarefa) => (
              <Card
                tipo={tarefa.tipo.id}
                criticidade={tarefa.criticidade}
                horario={tarefa.horario}
                nome={tarefa.nomePaciente}
                id={tarefa.id}
                key={`card_tarefa_${tarefa.id}`}
                status={1}
                detalhamento={true}
                atendSolicitado={tarefa.tipo.id === 7}
                admin={admin}
                delegateTask={(id) => {
                  handleOpenModal(id);
                }}
              />
            ))}
          </div>
        </div>
        <div className="coluna-detalhamento">
          <h3>Em atendimento ({tarefasAndamento.length})</h3>
          <div className="detalhamento-overflow">
            {tarefasAndamento.map((tarefa) => (
              <Card
                tipo={tarefa.tipo.id}
                criticidade={tarefa.criticidade}
                horario={tarefa.horario}
                nome={tarefa.nomePaciente}
                id={tarefa.id}
                key={`card_tarefa_${tarefa.id}`}
                status={2}
                detalhamento={true}
                atendSolicitado={tarefa.tipo.id === 7}
                admin={admin}
                delegateTask={(id) => {
                  handleOpenModal(id);
                }}
              />
            ))}
          </div>
        </div>
        <div className="coluna-detalhamento">
          <h3>Concluído ({tarefasConcluidas.length})</h3>
          <div className="detalhamento-overflow">
            {tarefasConcluidas.map((tarefa) => (
              <Card
                tipo={tarefa.tipo.id}
                criticidade={tarefa.criticidade}
                horario={tarefa.horario}
                nome={tarefa.nomePaciente}
                id={tarefa.id}
                key={`card_tarefa_${tarefa.id}`}
                status={3}
                detalhamento={true}
                admin={admin}
                tarefaConcluida={true}
                delegateTask={(id) => {
                  handleOpenModal(id);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getTasks: (enfermeiroID) => dispatch(actions.getTasks(enfermeiroID)),
  getNurses: (body) => dispatch(actions.getNurses(body)),
  sendDelegarTarefas: (info) => dispatch(actions.sendDelegarTarefas(info)),
  buscarDetalhamentoDashboard: (body) =>
    dispatch(buscarDetalhamentoDashboard(body)),
});

const mapStateToProps = (store) => ({
  admin: store.painelatividades.admin,
  tarefasPendentes: store.dashboard.tarefasPendentes,
  tarefasAndamento: store.dashboard.tarefasAndamento,
  tarefasConcluidas: store.dashboard.tarefasConcluidas,
  dashCompleteDetail: store.dashboard.dashCompleteDetail,
  user: store.login.user,
  filtro: store.dashboard.filtro,
});

export default connect(mapStateToProps, mapDispatchToProps)(DetalhamentoPainel);
