import { Button, Form, Input, Row } from "antd";
import React from "react";
import {
  getFollowUpProcessosInflamatorios,
  saveFollowUpProcessosInflamatorios,
} from "Pages/ProcessosInflamatorios/action";
import { connect } from "react-redux";
import { showConfirmationTab } from "Misc/utils";
import { SCROLL_CONFIG } from "Misc/constants";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import GroupFollowupProcessosInflamatorios from "./Components/GroupFollowupProcessosInflamatorios";

const { TextArea } = Input;

const FollowupProcessosInflamatorios = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    unsaved,
    paciente,
    loading,
    perguntas,
    acompanhamento,
  } = props;
  const { tarefa } = useParams();
  const history = useHistory();
  const handleValidate = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const { observacao, ...rest } = values;

        const answers = Object.keys(rest).map((n) => {
          const obj = {
            numero: n,
            valor: rest[n].valor,
          };

          return _.omitBy(obj, _.isUndefined);
        });

        const questionario = _.filter(answers, (i) => {
          if (!_.isUndefined(i.valor)) return i;
        });

        const req = {
          token: "gecro",
          tarefa: tarefa,
          questionario: { respostas: questionario },
          observacao: observacao,
        };

        showConfirm(req);
      } else {
        console.error("On ERROR", err);
      }
    });
  };

  const showConfirm = (answers) => {
    const auxArray = [];
    Object.keys(unsaved).forEach((item) => {
      if (unsaved[item]) {
        auxArray.push(item);
      }
    });

    if (auxArray.length > 0) {
      return showConfirmationTab(auxArray, () =>
        handleSaveFollowUpProcessosInflamatorios(answers)
      );
    } else {
      handleSaveFollowUpProcessosInflamatorios(answers);
    }
  };

  const handleSaveFollowUpProcessosInflamatorios = async (answers) => {
    const res = await props.saveFollowUpProcessosInflamatorios(
      JSON.stringify(answers),
      paciente,
      tarefa
    );
    if (res) history.push("/painel");
  };
  return (
    <>
      <Row className="followup-body">
        <GroupFollowupProcessosInflamatorios form={props.form} perguntas={perguntas} acompanhamento={acompanhamento} />
      </Row>
      <Row className={"followup-footer"}>
        <Form>
          <Form.Item label="Observações">
            {getFieldDecorator("observacao", { initialValue: null })(
              <TextArea rows={2} />
            )}
          </Form.Item>
        </Form>
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            onClick={handleValidate}
            className="buttonComplete"
            type="primary"
            loading={loading}
          >
            Finalizar atendimento
          </Button>
        </div>
      </Row>
    </>
  );
};

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
  perguntas: store.processosInflamatorios.perguntas,
  acompanhamento: store.processosInflamatorios.acompanhamento,
  loading: store.processosInflamatorios.loading,
  paciente: store.adesao.paciente,
});

const mapDispatchToProps = (dispatch) => ({
  getFollowUpProcessosInflamatorios: (pacienteID, tarefa) =>
    dispatch(getFollowUpProcessosInflamatorios(pacienteID, tarefa)),
  saveFollowUpProcessosInflamatorios: (body, pacienteID, tarefa) =>
    dispatch(saveFollowUpProcessosInflamatorios(body, pacienteID, tarefa)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FollowupProcessosInflamatorios));
