import api from "../../api";

export const getInfoSaude = async (pacienteID) => {
  const res = await api.post("/backoffice/buscarSaude", {
    token: "gecro",
    paciente: pacienteID,
  });
  return res.data;
};

export const attInfo = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarSaude",
    body
  );
  return res.data;
};

export const attMorbidades = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarMorbidadesPaciente",
    body
  );
  return res.data;
};

export const attProfissionais = async (body) => {
  const res = await api.post(
    "/backoffice/atualizarProfissionaisPaciente",
    body
  );
  return res.data;
};
