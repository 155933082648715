/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form, Input, Select, Icon, Button, Row, Col } from "antd";
import * as actions from "../action";
import BasicModal from "Components/BasicModal";
import {
  maskDate,
  futureDate,
  isValidDate,
  maskPhone,
  maskNumber,
} from "Misc/utils";
import { DATE_FORMAT, SCROLL_CONFIG } from "Misc/constants";

const { Option } = Select;

const AtendentesAddModal = ({
  loadingSave,
  modalType,
  handleCloseAtendentesModal,
  listaPerfil,
  buscarOpcoesPerfil,
  loadingPerfis,
  buscarEndereco,
  cepLoading,
  dados,
  endereco,
  inserirAtendente,
  atualizarAtendente,
  filtro,
  situacoes,
  loadingUbs,
  opcoesUbs,
  ...props
}) => {
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    validateFields,
    getFieldValue,
    resetFields,
  } = props.form;

  useEffect(() => {
    buscarOpcoesPerfil();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        delete values.confirmarEmail;

        switch (modalType) {
          case "add":
            inserirAtendente(JSON.stringify(values), filtro);
            break;
          case "edit":
            atualizarAtendente(JSON.stringify(values), filtro);
            break;
          case "complete":
            inserirAtendente(JSON.stringify(values), filtro);
            break;
          default:
            console.log("Nenhum caso", JSON.stringify(values));
            break;
        }
      }
    });
  };

  const compareToFirstEmail = (rule, value, callback) => {
    if (value && value !== getFieldValue("email")) {
      callback("E-mails estão diferentes.");
    } else {
      callback();
    }
  };

  const validateToNextEmail = (rule, value, callback) => {
    if (value) {
      validateFields(["confirmarEmail"], { force: true });
    }
    callback();
  };

  const handleGetCEP = (cep) => {
    if (cep.target.value.length >= 8) {
      buscarEndereco(cep.target.value.replace(/\D/gi, "").substring(0, 8));
      resetFields([
        "endereco.uf",
        "endereco.logradouro",
        "endereco.cidade",
        "endereco.numero",
        "endereco.complemento",
        "endereco.bairro",
      ]);
    }
    return cep.target.value.replace(/\D/gi, "").substring(0, 8);
  };

  const titleModal = {
    add: "Cadastrar",
    edit: "Alterar",
    view: "Visualizar",
    complete: "Completar Cadastro",
  };

  const isDisabled = () => modalType === "view" || loadingSave;

  const showUBS = () =>
    Boolean(
      getFieldValue("perfil") === 1 ||
        getFieldValue("perfil") === 2 ||
        getFieldValue("perfil") === 6
    );

  const isRequired = true;

  const formLayout = {
    labelCol: {
      xs: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 17 },
    },
  };

  const halfWidth = {
    wrapperCol: {
      xs: { span: 8 },
    },
  };

  const thirdWidth = {
    wrapperCol: {
      xs: { span: 12 },
    },
  };

  return (
    <BasicModal
      title={titleModal[modalType]}
      visible={Boolean(modalType)}
      onClose={handleCloseAtendentesModal}
      destroyOnClose
      footer={
        modalType !== "view"
          ? [
              <Button
                className="secondary-btn"
                key="back"
                onClick={handleCloseAtendentesModal}
                disabled={loadingSave}
              >
                Cancelar
              </Button>,
              <Button
                className="primary-btn"
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={loadingSave}
              >
                Salvar
              </Button>,
            ]
          : [
              <Button
                className="secondary-btn"
                key="back"
                onClick={handleCloseAtendentesModal}
              >
                Fechar
              </Button>,
            ]
      }
    >
      <Form layout="horizontal" hideRequiredMark {...formLayout}>
        <Form.Item label="Nome">
          {getFieldDecorator("nome", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: dados.nome,
          })(<Input maxLength={60} disabled={isDisabled()} />)}
        </Form.Item>

        <Form.Item label="Matrícula" {...halfWidth}>
          {getFieldDecorator("matricula", {
            rules: [
              {
                required: isRequired,
                message: "Campo obrigatório.",
              },
            ],
            initialValue: dados.matricula,
          })(
            <Input
              maxLength={20}
              disabled={
                modalType === "complete" || modalType === "edit" || isDisabled()
              }
            />
          )}
        </Form.Item>

        <Form.Item label="E-mail">
          {getFieldDecorator("email", {
            rules: [
              { required: isRequired, message: "Campo obrigatório." },
              {
                type: "email",
                message: "Insira um email válido.",
              },
              {
                validator: validateToNextEmail,
              },
            ],
            initialValue: dados.email,
          })(
            <Input
              onPaste={(e) => e.preventDefault()}
              maxLength={50}
              disabled={isDisabled()}
            />
          )}
        </Form.Item>

        {modalType === "add" && (
          <Form.Item label="Confirmar e-mail">
            {getFieldDecorator("confirmarEmail", {
              rules: [
                { required: isRequired, message: "Campo obrigatório." },
                {
                  type: "email",
                  message: "Insira um email válido.",
                },
                {
                  validator: compareToFirstEmail,
                },
              ],
            })(
              <Input
                onPaste={(e) => e.preventDefault()}
                maxLength={50}
                disabled={isDisabled()}
              />
            )}
          </Form.Item>
        )}

        <Form.Item label="Data de nascimento" {...halfWidth}>
          {getFieldDecorator("ncto", {
            rules: [
              {
                required: isRequired,
                message: "Campo obrigatório.",
              },
              {
                pattern: DATE_FORMAT,
                validator: futureDate,
                message: "Insira uma data válida.",
              },
            ],
            initialValue: dados.ncto,
            getValueFromEvent: (e) => maskDate(e.target.value),
          })(<Input maxLength={10} disabled={isDisabled()} />)}
        </Form.Item>

        <Form.Item label="Telefone Residencial">
          <Row gutter={8}>
            <Col span={12}>
              {getFieldDecorator("telefoneResidencial", {
                getValueFromEvent: (e) => maskPhone(e.target.value),
                initialValue: dados.telefoneResidencial,
              })(<Input maxLength={14} disabled={isDisabled()} />)}
            </Col>
            <Col span={4}>
              <span className="color-gray">(opcional)</span>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Celular" {...halfWidth}>
          {getFieldDecorator("telefoneCelular", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            getValueFromEvent: (e) => maskPhone(e.target.value),
            initialValue: dados.telefoneCelular,
          })(<Input maxLength={14} disabled={isDisabled()} />)}
        </Form.Item>

        <Form.Item label="CEP" {...thirdWidth}>
          {getFieldDecorator("endereco.cep", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.cep,
            getValueFromEvent: handleGetCEP,
          })(
            <Input
              disabled={isDisabled()}
              suffix={cepLoading ? <Icon type="loading" /> : ""}
            />
          )}
        </Form.Item>

        <Form.Item label="Estado" {...thirdWidth}>
          {getFieldDecorator("endereco.uf", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.uf,
            getValueFromEvent: (e) =>
              e.target.value.replace(/\d/gi, "").toUpperCase(),
          })(<Input maxLength={4} disabled={isDisabled() || cepLoading} />)}
        </Form.Item>

        <Form.Item label="Município">
          {getFieldDecorator("endereco.cidade", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.cidade,
          })(<Input maxLength={60} disabled={isDisabled() || cepLoading} />)}
        </Form.Item>

        <Form.Item label="Rua, avenida, etc.">
          {getFieldDecorator("endereco.logradouro", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.logradouro,
          })(<Input maxLength={100} disabled={isDisabled() || cepLoading} />)}
        </Form.Item>

        <Form.Item label="Número" {...halfWidth}>
          {getFieldDecorator("endereco.numero", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.numero,
            getValueFromEvent: (e) => maskNumber(e.target.value),
          })(<Input maxLength={10} disabled={isDisabled() || cepLoading} />)}
        </Form.Item>

        <Form.Item label="Complemento">
          <Row gutter={8}>
            <Col span={20}>
              {getFieldDecorator("endereco.complemento", {
                initialValue: endereco.complemento,
              })(
                <Input maxLength={30} disabled={isDisabled() || cepLoading} />
              )}
            </Col>
            <Col span={4}>
              <span className="color-gray">(opcional)</span>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Bairro">
          {getFieldDecorator("endereco.bairro", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: endereco.bairro,
          })(<Input maxLength={72} disabled={isDisabled() || cepLoading} />)}
        </Form.Item>

        <Form.Item label="Função/Cargo" {...thirdWidth}>
          {getFieldDecorator("cargo", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: dados.cargo,
          })(<Input maxLength={100} disabled={isDisabled()} />)}
        </Form.Item>

        <Form.Item label="Data de admissão" {...halfWidth}>
          {getFieldDecorator("dataAdmissao", {
            rules: [
              {
                required: isRequired,
                message: "Campo obrigatório.",
              },
              {
                pattern: DATE_FORMAT,
                validator: isValidDate,
                message: "Insira uma data válida.",
              },
            ],
            initialValue: dados.dataAdmissao,
            getValueFromEvent: (e) => maskDate(e.target.value),
          })(<Input maxLength={10} disabled={isDisabled()} />)}
        </Form.Item>

        <Form.Item label="Perfil" {...thirdWidth}>
          {getFieldDecorator("perfil", {
            rules: [
              {
                required: isRequired,
                message: "Campo obrigatório.",
              },
            ],
            initialValue: dados.perfil?.id,
          })(
            <Select loading={loadingPerfis} disabled={isDisabled()}>
              {listaPerfil.map((perfil) => (
                <Option key={perfil.id} value={perfil.id}>
                  {perfil.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {showUBS() && (
          <Form.Item label="UBS" {...thirdWidth}>
            {getFieldDecorator("ubs", {
              rules: [{ required: isRequired, message: "Campo obrigatório." }],
              initialValue: dados?.ubs?.ubs,
            })(
              <Select allowClear loading={loadingUbs} disabled={isDisabled()}>
                {opcoesUbs.map((opt) => (
                  <Option key={opt.ubs} value={opt.ubs}>
                    {opt.nome}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}
        {modalType === "view" && (
          <>
            <Form.Item label="Situação" {...thirdWidth}>
              <Select value={dados.situacao?.nome} disabled={true}></Select>
            </Form.Item>

            {dados?.afastamentos && dados.situacao?.codigo === "T" && (
              <>
                <Form.Item label="Motivo" {...halfWidth}>
                  <Input
                    value={dados?.afastamentos[0]?.motivo?.nome}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item label="Início afastamento" {...halfWidth}>
                  <Input
                    value={dados?.afastamentos[0]?.dataInicio}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item label="Fim afastamento" {...halfWidth}>
                  <Input
                    value={dados?.afastamentos[0]?.dataFim}
                    disabled={true}
                  />
                </Form.Item>
              </>
            )}

            {dados?.desligamento && dados.situacao?.codigo === "I" && (
              <>
                <Form.Item label="Motivo" {...halfWidth}>
                  <Input
                    value={dados?.desligamento?.motivo?.nome}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item label="Data desligamento" {...halfWidth}>
                  <Input
                    value={dados?.desligamento?.dataDesligamento}
                    disabled={true}
                  />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form>
    </BasicModal>
  );
};

const mapStateToProps = (store) => ({
  modalType: store.atendentes.modal,
  listaPerfil: store.atendentes.perfis,
  loadingPerfis: store.atendentes.loadingPerfis,
  cepLoading: store.atendentes.cepLoading,
  endereco: store.atendentes.endereco,
  dados: store.atendentes.dados,
  filtro: store.atendentes.filtro,
  loadingSave: store.atendentes.loadingModalSave,
  situacoes: store.atendentes.situacoes,
  opcoesUbs: store.atendentes.opcoesUbs,
  loadingUbs: store.atendentes.loadingUbs,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseAtendentesModal: () => dispatch(actions.closeAtendentesModal()),
  buscarOpcoesPerfil: () => dispatch(actions.buscarOpcoesPerfil()),
  buscarEndereco: (cep) => dispatch(actions.buscarEndereco(cep)),
  inserirAtendente: (body, filtro) =>
    dispatch(actions.inserirAtendente(body, filtro)),
  atualizarAtendente: (body, filtro) =>
    dispatch(actions.atualizarAtendente(body, filtro)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "modal_add_atendentes",
  })(AtendentesAddModal)
);
