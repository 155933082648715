import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";
import { history } from "App";

export const buscarDadosDesvioPadrao = (tarefaID) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCH_DESVIO_PADRAO_INIT });

    const response = await services.buscarDadosDesvioPadrao(tarefaID);

    await dispatch({
      type: types.FETCH_DESVIO_PADRAO_COMPLETE,
      payload: response,
    });
  } catch (error) {
    sendErrorMsg(error);
    history.push("/painel");
    dispatch({ type: types.FETCH_DESVIO_PADRAO_ERROR });
    console.error("Falha na requisicao de [buscarDadosDesvioPadrao]", error);
  }
};