import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Typography, Form, Divider, Button } from "antd";
import { connect } from "react-redux";
import InformationTable from "Components/InformationTable";
import * as actions from "./action";
import { withRouter, useParams } from "react-router-dom";

const { Text } = Typography;

const PlanoCuidado = (props) => {
  const { history, rotina } = props;
  const { tarefa } = useParams();

  const finalizaPlano = async () => {
    const res = await props.finalizarPlanoCuidados(props.paciente, tarefa);
    if (res) {
      history.push("/painel");
    }
  };

  useEffect(() => {
    if (props.paciente) props.getPlanoCuidados(props.paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);
  return (
    <>
      {props.informacoes.map((info, i) => (
        <InformationTable
          title={info.title}
          rowData={info.data}
          badge={info.badge}
          simplified={info.simple}
          key={`information_table_${i}`}
        />
      ))}

      <Text className={"cadastro-section-title"} style={{ marginTop: "3vh" }}>
        Outras informações
      </Text>
      <Text className="cadastro-section-description">{props.outras}</Text>
      {!rotina && (
        <>
          <Divider style={{ background: "rgba(56, 193, 229, 0.4)" }} />

          <div style={{ display: "flex", width: "40%" }}>
            <Button
              className="buttonFooterNext"
              onClick={() => finalizaPlano()}
              type="primary"
            >
              Finalizar Atendimento
            </Button>
            <Button
              className="buttonFooterBack"
              onClick={() => props.backStep(props.step)}
              type="primary"
              style={
                props.step > 1
                  ? { opacity: 1 }
                  : { opacity: 0, pointerEvents: "none" }
              }
            >
              Alterar
            </Button>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  step: store.adesao.step,
  informacoes: store.planocuidado.informacoes,
  outras: store.planocuidado.outras,
});

const mapDispatchToProps = (dispatch) => ({
  getPlanoCuidados: (pacienteID) =>
    dispatch(actions.getPlanoCuidados(pacienteID)),
  finalizarPlanoCuidados: (pacienteID, tarefaID) =>
    dispatch(actions.finalizarPlanoCuidados(pacienteID, tarefaID)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "planoCuidado",
  })(withRouter(PlanoCuidado))
);
