export const FETCH_INIT = "rotina.iniciar_fetch";
export const FETCH_COMPLETE = "rotina.finalizar_fetch";
export const MODIFY_FIELDS = "rotina.modificar_campos";
export const RESET_FIELDS = "rotina.reset_fields";
export const FETCH_ROTINA_GERAL = "rotina.fetch_rotina_geral";
export const FETCH__ROTINA_GERAL_ERROR = "rotina.FETCH__ROTINA_GERAL_ERROR";
export const FETCH_FOLLOW_UP = "rotina.fetch_grupos_follow_up";
export const SAVE_FOLLOW_UP_START = "rotina.salvar_grupos_follow_up_start";
export const SAVE_FOLLOW_UP_END = "rotina.salvar_grupos_follow_up_end";
export const FETCH_OBS = "rotina.buscar_historico_observacoes";
export const FETCH_OBS_INIT = "rotina.start_buscar_historico_observacoes";
export const FETCH_CHART_INIT = "rotina.start_buscar_historico_graficos";
export const FETCH_CHART = "rotina.buscar_historico_graficos";
export const FETCH_ERROR = "rotina.error_ao_buscar";
export const SEND_REPORT_INIT = "rotina.start_enviar_relatorio";
export const SEND_REPORT_FINISH = "rotina.finish_enviar_relatorio";
export const FETCH_REPORT_HISTORY_INIT =
  "rotina.start_buscar_historico_relatorio";
export const FETCH_REPORT_HISTORY_SUCCESS =
  "rotina.sucesso_buscar_historico_relatorio";
export const FETCH_REPORT_HISTORY_ERROR =
  "rotina.erro_buscar_historico_relatorio";
export const SET_ACTIVE_TAB =
  "rotina.set_active_tab";
export const SET_RELATED_QUESTION =
  "rotina.set_related_question";
