import api from '../../api'

export const getFotoPaciente = async (pacienteID) => {
    let foto_paciente;
    try {
      await api
        .post("/backoffice/buscarFotoPaciente", {
          token: "gecro",
          paciente: pacienteID,
        })
        .then((response) => {
          const type = response.data.filename.split(".")[1];
          foto_paciente = `data:image/${type};base64,${response.data.file}`;
        });
  
      return foto_paciente;
    } catch (error) {
      return null;
    }
  };