import React from "react";
import { Badge, Typography } from "antd";

import "./style.scss";

const { Text } = Typography;

const InformationTable = ({ title, badge, rowData, simplified }) => {
  return (
    <div className="information-component">
      <div className="information">
        <Text className={"cadastro-section-title"}>{title}</Text>
        <div className="information-table">
          {simplified
            ? rowData.map((row, i) => {
                return <RowSimple key={`simple_row_${i}`} data={row} />;
              })
            : rowData.map((row, i) => {
                return <Row key={`row_${i}`} data={row} badge={badge} />;
              })}
        </div>
      </div>
    </div>
  );
};

const Row = ({ data: { title, items }, badge }) => (
  <div className="row">
    <div className="title">
      <div>{title}</div>
    </div>
    <div className="subitem">
      {!!items &&
        items.map((item, i) => {
          return (
            <div key={`row_item_${i}`} className="item">
              <div className="item-title">
                {badge ? (
                  <Badge
                    color={item.flag ? "red" : "#fafafa"}
                    text={item.title}
                  />
                ) : (
                  <span>{item.title}</span>
                )}
              </div>
              <div className="item-text">{item.title === "Estado Nutricional (IMC)" ? item.text.replace(".", ",") : (item.text ? item.text : "-") }</div>
            </div>
          );
        })}
    </div>
  </div>
);

const RowSimple = ({ data: { title, text } }) => (
  <div className="row">
    <div className="title">
      <div>{title}</div>
    </div>
    <div className="subitem">
      <div className="item">
        <div>{text ? text : "-"}</div>
      </div>
    </div>
  </div>
);

export default InformationTable;
