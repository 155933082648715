import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";

export const getInfoSaude = (pacienteID) => async (dispatch) => {
  try {
    dispatch({ type: types.FETCHING_INFO });

    const response = await services.getInfoSaude(pacienteID);
    dispatch({
      type: types.FETCHING_INFO_COMPLETE,
      payload: response,
    });

    const morbSelected = response.morbidades
      // eslint-disable-next-line array-callback-return
      .filter((item) => {
        if (!!item.resposta) return item;
      })
      .map((item) => item.id);

    dispatch({
      type: types.FILTER_MORB_SELECTED,
      payload: morbSelected,
    });

    // const profissionais = [
    //   ...new Set(response.profissionais.map(pro => pro.profissao))
    // ];

    // // eslint-disable-next-line array-callback-return
    // const especialidadeSelected = response.especialidades.filter(esp => {
    //   if (profissionais.includes(esp.nome)) return esp;
    // });

    // const pros = [];

    // mock.forEach((element) => {
    //   if (element.profissionais.length > 0) {
    //     pros.push(element.nome);
    //   }
    //   if (element.especialidades?.length > 0) {
    //     element.especialidades.forEach((esp) => {
    //       // console.log(element.nome, "esp", esp);
    //       // pros.push(element.nome);
    //     });
    //   }
    // });

    dispatch({
      type: types.FILTER_ESPECIALIDADE_SELECTED,
      payload: [],
    });
  } catch (error) {
    sendErrorMsg(error);
    dispatch({ type: types.FETCHING_INFO_ERROR });
  }
};

export const selectEspecialidade = (especialidadeSelected) => (dispatch) => {
  dispatch({
    type: types.FILTER_ESPECIALIDADE_SELECTED,
    payload: especialidadeSelected,
  });
};

export const changeProfissional = (profissionalArray) => (dispatch) => {
  dispatch({
    type: types.CHANGE_PROFISSIONAL,
    payload: profissionalArray,
  });
};

export const changeFamilyDoctor = (doctor) => (dispatch) => {
  dispatch({
    type: types.CHANGE_DOCTOR,
    payload: doctor,
  });
};
