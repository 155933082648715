import api from "api";

export const getPatient = async (body) => {
  const res = await api.post(
    "/backoffice/buscarPacientes",
    body
  );

  return res.data;
};
