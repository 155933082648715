import * as types from "./actionType";
import * as typesHeader from "../../Components/Header/actionType";
import * as typesBarra from "../../Components/BarraInformacao/actionType";

import * as services from "./service";
import { buscarEndereco } from "../Atendentes/service";
import { sendErrorMsg, sendMsg, sendSuccessMsg } from "Misc/utils";
import { history } from "App";

export const buscarCadastros = (values) => async (dispatch) => {
  try {
    const body = {};
    const nameAttribute = `nome${values.personNome}`;
    const cpfAttribute = `cpf${values.personCPF}`;

    body[nameAttribute] = values.nome;
    body[cpfAttribute] = values.cpf;
    dispatch({ type: types.BUSCAR_PACIENTES_INIT });

    const response = await services.buscarCadastros(body);
    dispatch({
      type: types.BUSCAR_PACIENTES_SUCCESS,
      payload: response.pacientes,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_PACIENTES_FAIL });
    sendErrorMsg(error);
  }
};

export const refreshCadastros = (values) => async (dispatch) => {
  try {
    const body = {};
    const nameAttribute = `nome${values.personNome}`;
    const cpfAttribute = `cpf${values.personCPF}`;

    body[nameAttribute] = values.nome;
    body[cpfAttribute] = values.cpf;

    const response = await services.buscarCadastros(body);
    dispatch({
      type: types.BUSCAR_PACIENTES_SUCCESS,
      payload: response.pacientes,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_PACIENTES_FAIL });
    sendErrorMsg(error);
  }
};

export const getInfoCabecalhoByPaciente = (enfermeiroID, paciente) => async (
  dispatch
) => {
  try {
    const response = await services.getInfoCabecalhoByPaciente(
      enfermeiroID,
      paciente
    );

    const foto_perfil = await services.getFotoPaciente(response.paciente);

    await dispatch({
      type: typesHeader.EDIT_HEADER_COMPLETE,
      payload: {
        nome: `${response.nome}, ${response.idade} `,
        foto_perfil: foto_perfil,
      },
    });

    await dispatch({
      type: typesBarra.EDIT_BARRA_COMPLETE,
      payload: {
        ubs: response.ubs,
        nome: response.nome,
        email: response.email,
        telefone: response.telefone,
        peso: response.peso,
        imc: response.imc,
        doencas: response.morbidadesResumo,
        sexo: response.sexo,
        urgencia: response.urgencia,
        wearable: response.wearable,
      },
    });
  } catch (error) {
    history.push("/pacientes");
    sendErrorMsg(error);
  }
};

export const resetCadastros = () => (dispatch) => {
  dispatch({ type: types.RESET_PACIENTES });
};

export const saveFiltro = (filtro) => (dispatch) => {
  dispatch({ type: types.SAVE_FILTRO_PACIENTES, payload: filtro });
};

export const resetFiltro = () => (dispatch) => {
  dispatch({ type: types.RESET_FILTRO_PACIENTES });
};

export const buscarPaciente = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_INIT });

    const response = await services.buscarPaciente(id);

    const paciente = {
      telefonePrincipal: response.telefonePrincipal,
      outroTelefone: response.outroTelefone,
      paciente: response.paciente,
    };

    dispatch({
      type: types.BUSCAR_SUCCESS,
      payload: paciente,
    });

    dispatch({
      type: types.BUSCAR_CEP_PACIENTE,
      payload: response.endereco,
    });

    dispatch({
      type: types.OPEN_MODAL_PACIENTE,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_FAIL });
    console.error(error);
  }
};

export const closeModal = () => (dispatch) => {
  dispatch({ type: types.CLOSE_MODAL });
};

export const initSalvar = () => (dispatch) => {
  dispatch({ type: types.INIT_SALVAR });
};

export const finishSalvar = () => (dispatch) => {
  dispatch({ type: types.FINISH_SALVAR });
};

export const buscarCep = (cep) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_CEP_PACIENTE_INIT });

    const response = await buscarEndereco(cep);
    dispatch({
      type: types.BUSCAR_CEP_PACIENTE,
      payload: response.endereco,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_CEP_PACIENTE_FAIL });
    sendErrorMsg(error);
  }
};

export const atualizarPaciente = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    const response = await services.atualizarPaciente(body);
    sendMsg(response);
    await dispatch(refreshCadastros(filtro));
    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};

export const buscarResponsavel = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_INIT });

    const response = await services.buscarResponsavel(id);

    const responsavel = {
      nome: response.nome,
      cpf: response.cpf,
      telefone: response.telefone,
      email: response.email,
      paciente: response.paciente,
    };

    dispatch({
      type: types.BUSCAR_SUCCESS,
      payload: responsavel,
    });

    dispatch({
      type: types.BUSCAR_CEP_PACIENTE,
      payload: response.endereco,
    });

    dispatch({
      type: types.OPEN_MODAL_RESPONSAVEL,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_FAIL });
    console.error(error);
  }
};

export const atualizarResponsavel = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    const response = await services.atualizarResponsavel(body);
    sendMsg(response);
    await dispatch(refreshCadastros(filtro));
    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};

export const abrirTrocarResponsavelModal = (id) => async (dispatch) => {
  try {
    dispatch({ type: types.BUSCAR_INIT });

    const responsavel = {
      paciente: id,
    };

    dispatch({
      type: types.OPEN_NEW_MODAL_RESPONSAVEL,
      payload: responsavel,
    });
  } catch (error) {
    dispatch({ type: types.BUSCAR_FAIL });
    console.error(error);
  }
};

export const trocarResponsavel = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    const response = await services.trocarResponsavel(body);
    sendMsg(response);
    await dispatch(refreshCadastros(filtro));
    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};

export const cancelarMonitoramento = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    await services.cancelarMonitoramento(body);
    await dispatch(refreshCadastros(filtro));

    sendSuccessMsg("Monitoramento cancelado com sucesso.")

    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};

export const suspenderMonitoramento = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    await services.suspenderMonitoramento(body);
    await dispatch(refreshCadastros(filtro));

    sendSuccessMsg("Monitoramento suspendido com sucesso.")

    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};

export const abrirModalDesistencia = (id, type) => (dispatch) => {
  dispatch({
    type: types.OPEN_MODAL_DESISTENCIA,
    payload: { id: id, type: type },
  });
};

export const abrirModalPlano = (id, type) => async (dispatch) => {
  try {
    const response = await services.buscarPlanos();
    const responsePaciente = await services.buscarPlanoPaciente(id);

    dispatch({
      type: types.OPEN_MODAL_PLANO,
      payload: { id: id, type: type },
      planos: response.planos,
      planoPaciente: responsePaciente.planos[0],
    });
  } catch (error) {
    console.error(error);
  }
};

export const mudarPlano = (plano) => (dispatch) => {
  dispatch({
    type: types.CHANGE_PLANO,
    payload: plano,
  });
};

export const reativarMonitoramento = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    await services.reativarMonitoramento(body);
    await dispatch(refreshCadastros(filtro));

    sendSuccessMsg("Monitoramento reativado com sucesso.")

    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};

export const atualizarPlano = (body, filtro) => async (dispatch) => {
  try {
    dispatch(initSalvar());

    const response = await services.atualizarPlano(body);
    sendMsg(response, 12);

    await dispatch(refreshCadastros(filtro));
    dispatch(finishSalvar());
    dispatch(closeModal());
  } catch (error) {
    dispatch(finishSalvar());
    sendErrorMsg(error);
  }
};
