import api from "../../api";

export const getPlanoCuidados = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/planoCuidados",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );

  return res.data;
};

export const finalizarPlanoCuidados = async (pacienteID, tarefaID) => {
  const res = await api.post(
    "/backoffice/finalizarBoasVindas",
    {
      token: "gecro",
      paciente: pacienteID,
      tarefa: tarefaID,
    }
  );

  return res.data;
};
