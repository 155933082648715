import * as types from "./actionType";
import * as services from "./service";
import { sendErrorMsg, sendSuccessMsg } from "Misc/utils";


export const getFollowUpProcessosInflamatorios =
  (pacienteID, tarefa) => async (dispatch) => {
    try {
      dispatch({ type: types.FETCH_FOLLOWUP_PROCESSOS_START });
      const response = await services.getFollowUpProcessosInflamatorios(pacienteID, tarefa);
      if (response.respostas) {
        const respostas = response.respostas
        const mappedQuestions = response.perguntas.map((pergunta) => {
          const checkResponse = respostas.find((resposta) => +resposta.numero === +pergunta.numero);
          console.log('checkResponse', checkResponse)
          if (+pergunta.numero === 419) {
            return {
              ...pergunta,
              resposta: undefined,
            };
          } else {
            return {
              ...pergunta,
              resposta: checkResponse && checkResponse.valor,
            };
          }
        });

        await dispatch({ type: types.FETCH_FOLLOWUP_PROCESSOS, payload: {
          perguntas: mappedQuestions,
          acompanhamento: true
        } });
        dispatch({ type: types.FETCH_FOLLOWUP_PROCESSOS_END });
        return;
      } else {
        await dispatch({ type: types.FETCH_FOLLOWUP_PROCESSOS, payload: {
          perguntas: response.perguntas,
          acompanhamento: false
        } });
        dispatch({ type: types.FETCH_FOLLOWUP_PROCESSOS_END });
        return;
      }
    } catch (error) {
      dispatch({ type: types.FETCH_FOLLOWUP_PROCESSOS_END });
      sendErrorMsg(error);
      console.error("Falha na requisicao de [getFollowUpProcessosInflamatorios]", error);
    }
  };
  
  export const saveFollowUpProcessosInflamatorios = (body, pacienteID, tarefa) => async (dispatch) => {
    console.log('body, pacienteID, tarefa', body, pacienteID, tarefa)
    try {
      dispatch({ type: types.SAVE_FOLLOWUP_PROCESSOS_START });
      await services.saveFollowUpProcessosInflamatorios(body);
      await services.finalizarTarefa(pacienteID, tarefa);
      await dispatch({ type: types.SAVE_FOLLOWUP_PROCESSOS_END });
      return true;
    } catch (error) {
      dispatch({ type: types.SAVE_FOLLOWUP_PROCESSOS_END });
      sendErrorMsg(error);
      console.error("Falha na requisicao de [saveFollowUpProcessos]", error);
    }
  };