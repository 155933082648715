import React, { useState } from "react";
import { Button, Icon, Typography, Tag, Tooltip, Badge } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { CRITICIDADE, TIPO_TAREFA } from "../../../Misc/constants";
import { isEnfermeiro } from "Misc/utils";
import "./style.scss";
const { Text } = Typography;

const Card = ({
  isPendente,
  isAndamento,
  tipo,
  criticidade,
  panico,
  horario,
  nome,
  admin,
  initTask,
  delegateTask,
  id,
  loadingModal,
  status,
  user,
  detalhamento = false,
  atendSolicitado = false,
  tarefaConcluida = false,
  loadingTransfer,
}) => {
  const history = useHistory();
  const [opacity, setOpacity] = useState(false);

  const handleClick = async () => {
    const response = await initTask(user.id, id);
    if (response) {
      switch (tipo) {
        case 2:
          history.push(`/adesao/${tipo}/${id}`);
          break;
        case 1:
          history.push(`/boasvindas/${tipo}/${id}`);
          break;
        case 3:
          history.push(`/rotina/${tipo}/${id}`);
          break;
        case 4:
          history.push(`/urgencia/${tipo}/${id}`);
          break;
        case 5:
          history.push(`/ausencia/${tipo}/${id}`);
          break;
        case 7:
          history.push(`/atendimento/${tipo}/${id}`);
          break;
        case 8:
          history.push(`/reativacao/${tipo}/${id}`);
          break;
        case 9:
          history.push(`/medicoes-incomuns/${tipo}/${id}`);
          break;
        case 10:
          history.push(`/sintomas-inflamatorios/${tipo}/${id}`);
          break;
        case 11:
          history.push(`/desvio-padrao/${tipo}/${id}`);
          break;
        default:
          console.info("[Iniciar] Default não mapeado tipo", tipo);
      }
    }
  };

  const handleClickContinue = async () => {
    switch (tipo) {
      case 2:
        history.push(`/adesao/${tipo}/${id}`);
        break;
      case 1:
        history.push(`/boasvindas/${tipo}/${id}`);
        break;
      case 3:
        history.push(`/rotina/${tipo}/${id}`);
        break;
      case 4:
        history.push(`/urgencia/${tipo}/${id}`);
        break;
      case 5:
        history.push(`/ausencia/${tipo}/${id}`);
        break;
      case 7:
        history.push(`/atendimento/${tipo}/${id}`);
        break;
      case 8:
        history.push(`/reativacao/${tipo}/${id}`);
        break;
      case 9:
        history.push(`/medicoes-incomuns/${tipo}/${id}`);
        break;
      case 10:
        history.push(`/sintomas-inflamatorios/${tipo}/${id}`);
        break;
      case 11:
        history.push(`/desvio-padrao/${tipo}/${id}`);
        break;
      default:
        console.info("[Continuar] Default não mapeado tipo:", tipo);
    }
  };

  const handleDelegate = async () => {
    setOpacity(true);
    await delegateTask(id);
    setOpacity(false);
  };

  return (
    <>
      <div className="layout-card">
        <div className="card-header">
          <Tag
            style={{
              color: CRITICIDADE[criticidade].color,
              fontWeight: "normal",
            }}
            color={CRITICIDADE[criticidade].background}
          >
            {CRITICIDADE[criticidade].descricao}
          </Tag>

          <Tag
            style={{
              fontWeight: "normal",
              fontSize: "0.8rem",
            }}
            color={TIPO_TAREFA[tipo].background}
          >
            {TIPO_TAREFA[tipo].descricao}
          </Tag>
          {panico && (
            <Badge count="SOS" />
          )}
          {status === 4 && (
            <div className="buttons-group">
              <Text className="text">Cancelada</Text>
            </div>
          )}
          <div className="buttons-group">
            {isEnfermeiro(user.perfil) && (
              <>
                {isAndamento && (
                  <Button
                    disabled={loadingModal}
                    onClick={handleClickContinue}
                    type="primary"
                    className="button"
                  >
                    Continuar
                  </Button>
                )}

                {isPendente && (
                  <Button
                    onClick={handleClick}
                    type="primary"
                    className="button"
                    disabled={loadingModal}
                  >
                    Iniciar
                  </Button>
                )}
              </>
            )}

            {admin && isPendente && (
              <Button
                style={opacity ? { opacity: 1 } : {}}
                loading={opacity}
                disabled={loadingModal && !opacity}
                type="primary"
                className="button"
                onClick={handleDelegate}
              >
                Delegar
              </Button>
            )}
            {admin && detalhamento && !atendSolicitado && !tarefaConcluida && (
              <Button
                style={opacity ? { opacity: 1 } : {}}
                loading={loadingTransfer}
                disabled={loadingTransfer && !opacity}
                type="primary"
                className="button"
                onClick={handleDelegate}
              >
                Transferir
              </Button>
            )}
          </div>
        </div>
        <div className="card-info">
          <Icon type={"clock-circle"} />
          <Text style={{ fontSize: "0.9rem" }}>{horario}</Text>
        </div>
        <div className="card-info">
          <Icon type={"smile"} />
          <Text
            style={{
              fontSize: "0.9rem",
            }}
            ellipsis
          >
            <Tooltip mouseEnterDelay={0.5} placement="topLeft" title={nome}>
              {nome}
            </Tooltip>
          </Text>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (store) => ({
  loadingModal: store.painelatividades.loadingModal,
  loadingTransfer: store.painelatividades.loadingTransfer,
  user: store.login.user,
});

export default connect(mapStateToProps, null)(Card);
