import React from 'react'
import { Icon, Tooltip } from 'antd';

const InfoIcon = ({title}) => {
    return (
        <Tooltip title={title}>
            <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
        </Tooltip>
    )
}

export default InfoIcon;