import React from "react";
import "./style.scss";
import { Modal, Input, Form } from "antd";
import { connect } from "react-redux";
import FormItem from "antd/lib/form/FormItem";
import * as actions from "../action";
import { useParams } from "react-router-dom";

const { TextArea } = Input;

const CancelarModal = props => {
  const { onOk, user, ...rest } = props;
  const { getFieldDecorator, validateFields } = props.form;
  const { tarefa } = useParams();
  const validateInput = e => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        const res = props.cancelaAdesao(user.id, tarefa, values.motivo);
        if (res) onOk();
      } else {
        console.error("Erro:", err);
      }
    });
  };

  return (
    <Modal
      wrapClassName="modal-delegate"
      title="Cancelar adesão"
      okText="Confirmar"
      cancelText="Fechar"
      onOk={e => validateInput(e)}
      okButtonProps={{ loading: props.cancelaLoading }}
      {...rest}
    >
      <p>Motivo do cancelamento</p>
      <Form>
        <FormItem key="text-area-cancelar">
          {getFieldDecorator("motivo", {
            rules: [{ required: true, message: "Insira um motivo!" }],
            initialValue: ""
          })(<TextArea rows={6} />)}
        </FormItem>
      </Form>
    </Modal>
  );
};

const mapStateToProps = store => ({
  cancelaLoading: store.barrainformacao.cancelaLoading,
  user: store.login.user
});
const mapDispatchToProps = dispatch => ({
  cancelaAdesao: (enfermeiroID, tarefa, motivo) =>
    dispatch(actions.cancelaAdesao(enfermeiroID, tarefa, motivo))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    nome: "contrato_fields"
  })(CancelarModal)
);
