import React from "react";
import {Steps, Tag, Typography} from "antd";
import InformacaoPessoal from "../../../../Components/InformacaoPessoal";
import QuestionarioCadastro from "../../../../Components/Questionario";
import Saude from "../../../../Components/Saude";
import Acompanhamento from "../../../../Components/Acompanhamento";
import RedeApoio from "../../../../Components/RedeApoio";
import PlanoCuidado from "../../../../Components/PlanoCuidado";
import ContratoResponsavel from "../../../../Components/ContratoResponsavel";
import IndiceIvcfCard from '../../../../Components/IndiceIvcfCard'
import {connect} from "react-redux";
import * as actions from "../../../Adesao/action";
import {withRouter} from "react-router-dom";
import "./style.scss";

const {Step} = Steps;
const {Text} = Typography;

const FluxoBoasVindas = props => {
  const getSteps = () => {
    const {step} = props;


    if (step === 1) {
      return (
        <>
          <InformacaoPessoal
            nextStep={props.nextStep}
            backStep={props.backStep}
          />
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <RedeApoio nextStep={props.nextStep} backStep={props.backStep}/>
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <ContratoResponsavel
            nextStep={props.nextStep}
            backStep={props.backStep}
          />
        </>
      );
    } else if (step === 4) {
      return (
        <>
          <QuestionarioCadastro
            nextStep={props.nextStep}
            backStep={props.backStep}
          />
        </>
      );
    } else if (step === 5) {
      return (
        <>
          <Saude nextStep={props.nextStep} backStep={props.backStep}/>
        </>
      );
    } else if (step === 6) {
      return (
        <>
          <Acompanhamento nextStep={props.nextStep} backStep={props.backStep}/>
        </>
      );
    } else if (step === 7) {
      return (
        <>
          <div style={{display: "flex", flexWrap: "wrap", width: "100%"}}>
            <div style={{flex: 1}}>
              <Text
                className={"cadastro-section-title"}
                style={{fontSize: "24px", color: "#0F79B2", width: "100%", display: "block"}}
              >
                Plano de cuidados
              </Text>
              <Text className="cadastro-section-description color-gray">
                Diagnóstico funcional e conduta, medicamentos, parâmetros de saúde e
                ligações periódicas.
              </Text>
            </div>
            <IndiceIvcfCard paciente={props.paciente} activeTabKey={"1"}/>
          </div>
          <PlanoCuidado nextStep={props.nextStep} backStep={props.backStep}/>
        </>
      );
    }
  };

  return (
    <div className="cadastro-overflow">
      <div className="cadastro-layout">
        {props.step < 7 && (
          <Steps
            style={{marginBottom: "5vh", alignSelf: "center"}}
            current={props.step - 1}
            progressDot
          >
            <Step title="Informações Pessoais"/>
            <Step title="Rede de Apoio"/>
            <Step title="Responsável pelo contrato"/>
            <Step title="Questionário"/>
            <Step title="Saúde"/>
            <Step
              title="Acompanhamento"
              description={<Tag color="orange">NOVO</Tag>}
            />
          </Steps>
        )}
        {getSteps()}
      </div>
    </div>
  );
};

const mapStateToProps = store => ({
  step: store.adesao.step,
  paciente: store.adesao.paciente
});

const mapDispatchToProps = dispatch => ({
  nextStep: step => dispatch(actions.nextStep(step)),
  backStep: step => dispatch(actions.backStep(step))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FluxoBoasVindas));
