import { Button, Form, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { salvarObservacoes } from "Pages/Urgencia/action";
import { buscarDadosDesvioPadrao } from "../action";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";
import { SCROLL_CONFIG } from "Misc/constants";
import { showConfirmationTab } from "Misc/utils";

const { Text } = Typography;

const TabelaDesvio = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll },
    finishLoading,
    buscarDadosDesvioPadrao,
    desvio_padrao,
    lista_cuidadores,
    contato_emergencial,
    salvarObservacoes,
    unsaved,
    paciente
  } = props;

  const { tarefa } = useParams();
  const history = useHistory();

  const formatValue = (value) => {
    if (desvio_padrao.tipoDesvioPadrao === "PASSOS") {
      return Math.floor(+value);
    } else {
      return `${value} = ${Math.floor(+value)}h${Math.round(
        (+value - Math.floor(+value)) * 60
      )}min`;
    }
  };
  
  const handleValidate = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const { observacao } = values;

        const req = {
          token: "gecro",
          tarefa: tarefa,
          observacao: observacao,
        };

        showConfirm(req);
      } else {
        console.error("On ERROR", err);
      }
    });
  };

  const showConfirm = (answers) => {
    const auxArray = [];
    Object.keys(unsaved).forEach((item) => {
      if (unsaved[item]) {
        auxArray.push(item);
      }
    });

    if (auxArray.length > 0) {
      return showConfirmationTab(auxArray, () => handleSaveFollowUp(answers));
    } else {
      handleSaveFollowUp(answers);
    }
  };

  const handleSaveFollowUp = async (answers) => {
    const res = await salvarObservacoes(
      JSON.stringify(answers),
      paciente,
      tarefa
    );
    if (res) history.push("/painel");
  };

  useEffect(() => {
    buscarDadosDesvioPadrao(tarefa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="followup-body">
        <div style={{ padding: "16px 16px 0 16px" }}>
          <Text
            className={"cadastro-section-title"}
            style={{ color: "#0F79B2" }}
          >
            Instruções
          </Text>
          {desvio_padrao && (
            <div
              style={{
                marginTop: "8px",
                display: "flex",
                flexDirection: "column",
                lineHeight: "22px",
              }}
            >
              <span>
                <strong>1.</strong> Verificar os seguintes desvios
                significativos no parâmetro de saúde de{" "}
                <strong>{desvio_padrao.tipoDesvioPadrao}</strong>:
              </span>
              <div className="tabela-desvio" style={{ paddingBottom: "1rem" }}>
                {desvio_padrao.tipoDesvioPadrao === "SONO" ? (
                  <strong>
                    Média do total de horas de sono dos últimos 30 dias:{" "}
                  </strong>
                ) : (
                  <strong>Média de passos dos últimos 30 dias: </strong>
                )}
                <span>{formatValue(desvio_padrao.media)}</span>
              </div>
              <div className="tabela-desvio">
                <strong>
                  Desvio padrão de 50% ou mais da <br />
                  média dos últimos 30 dias
                </strong>
                <Row type="flex" style={{ width: "100%", marginTop: "1rem" }}>
                  {desvio_padrao.desvio50mais &&
                    desvio_padrao.desvio50mais.length > 0 &&
                    desvio_padrao.desvio50mais.map((dado) => (
                      <div className="cell-desvio">
                        <span>{dado.data}</span>
                        <span>{formatValue(dado.valor)}</span>
                      </div>
                    ))}
                </Row>
              </div>
              <div className="tabela-desvio">
                <strong>
                  Desvio padrão de 50% ou mais da <br />
                  média dos últimos 3 dias seguidos
                </strong>
                <Row type="flex" style={{ width: "100%", marginTop: "1rem" }}>
                  {desvio_padrao.desvio50maisSeguidos &&
                    desvio_padrao.desvio50maisSeguidos.length > 0 &&
                    desvio_padrao.desvio50maisSeguidos.map((dado) => (
                      <div className="cell-desvio">
                        <span>{dado.data}</span>
                        <span>{formatValue(dado.valor)}</span>
                      </div>
                    ))}
                </Row>
              </div>
              <div className="tabela-desvio">
                <strong>
                  Desvio padrão de 80% ou mais da <br />
                  média da última medição
                </strong>
                <Row type="flex" style={{ width: "100%", marginTop: "1rem" }}>
                  <div className="cell-desvio" style={{ flex: "0 0 100%" }}>
                    <span>
                      {desvio_padrao &&
                        desvio_padrao.desvio80mais &&
                        desvio_padrao.desvio80mais[0] &&
                        desvio_padrao.desvio80mais[0].data}
                    </span>
                    <span>
                      {desvio_padrao &&
                        desvio_padrao.desvio80mais &&
                        desvio_padrao.desvio80mais[0] &&
                        formatValue(desvio_padrao.desvio80mais[0].valor)}
                    </span>
                  </div>
                </Row>
              </div>
              <span>
                <strong>2. Ligar para o paciente.</strong>
              </span>
              <span>
                <strong>3.</strong> Caso o paciente não atenda, ligar para
                cuidador, seguindo a ordem de prioridade abaixo.
              </span>
              <span>
                <strong>4.</strong> Questionar o paciente se está tudo bem com
                ele, especialmente no tocante à sua mobilidade: se ele tem
                caminhado normalmente, se não ocorreu nenhuma queda, etc.
              </span>
            </div>
          )}
        </div>
        <div style={{ padding: "16px 16px 0 16px" }}>
          <Text
            className={"cadastro-section-title"}
            style={{ color: "#0F79B2" }}
          >
            Cuidadores
          </Text>
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "column",
              lineHeight: "22px",
            }}
          >
            {lista_cuidadores.map((item, i) => (
              <Contato key={i} item={item} />
            ))}
            {lista_cuidadores.length === 0 && (
              <span>Nenhum cuidador foi informado</span>
            )}
          </div>

          <Text
            className={"cadastro-section-title"}
            style={{ color: "#0F79B2", marginTop: "1rem", display: "block" }}
          >
            Contato para emergências
          </Text>
          <div
            style={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "column",
              lineHeight: "22px",
            }}
          >
            {!_.isEmpty(contato_emergencial) ? (
              <Contato item={contato_emergencial} />
            ) : (
              <span>Nenhum Contato Emergêncial foi informado</span>
            )}
          </div>
        </div>
      </Row>
      <Row className={"followup-footer"}>
        <Form>
          <Form.Item label="Observações">
            {getFieldDecorator("observacao", { initialValue: null })(
              <TextArea rows={2} />
            )}
          </Form.Item>
        </Form>
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            onClick={handleValidate}
            className="buttonComplete"
            type="primary"
            loading={finishLoading}
          >
            Finalizar atendimento
          </Button>
        </div>
      </Row>
    </>
  );
};

const Contato = ({ item }) => (
  <div
    style={{
      display: "flex",
      marginBottom: "8px",
      flexDirection: "column",
    }}
  >
    <span>
      {item.nome}{" "}
      {item.principal && (
        <span>
          - <b>principal</b>
        </span>
      )}
    </span>
    <strong>{item.telefone}</strong>
  </div>
);

const mapStateToProps = (store) => ({
  unsaved: store.rotina.unsaved,
  paciente: store.adesao.paciente,
  desvio_padrao: store.desvio.desvio_padrao,
  lista_cuidadores: store.urgencia.lista_cuidadores,
  contato_emergencial: store.urgencia.contato_emergencial,
});

const mapDispatchToProps = (dispatch) => ({
  buscarDadosDesvioPadrao: (tarefa) =>
    dispatch(buscarDadosDesvioPadrao(tarefa)),
  salvarObservacoes: (body, pacienteID, tarefa) =>
    dispatch(salvarObservacoes(body, pacienteID, tarefa)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(TabelaDesvio));
