/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Select, Row, Col, Tooltip, Icon } from "antd";
import BasicModal from "Components/BasicModal";
import * as actions from "../action";
import { maskDate, isValidDateUnrequired } from "Misc/utils";
import { DATE_FORMAT, SCROLL_CONFIG } from "Misc/constants";
import moment from "moment";
import _ from "lodash";
import { useParams } from "react-router-dom";

const { Option } = Select;

export const FilterModal = (props) => {
  const {
    filterModal,
    fecharModal,
    atendentes,
    prioridades,
    status,
    assuntos,
    // filtro,
    buscarDashboard,
    buscarTMEeTMA,
    buscarOpcoes,
    opcoesLoading,
    buscarLoading,
    ubsOption,
    getEnfermeiros,
    bairros,
    morbidades,
    getPacientePorParteNome,
    pacientes,
  } = props;

  const { getFieldDecorator, validateFieldsAndScroll, getFieldValue } =
    props.form;

  const [notFoundPaciente, setNotFoundPaciente] = useState(
    "Pesquise pelo nome de um paciente"
  );
  const [notFoundAtendente, setNotFoundAtendente] = useState(
    "Pesquise pelo nome de um atendente"
  );

  const { tipo, filtro: tipoFiltro } = useParams();

  useEffect(() => {
    buscarOpcoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        if (tipoFiltro === "dia") {
          buscarTMEeTMA(values);
        } else if (tipoFiltro === "hora") {
          buscarTMEeTMA({
            ...values,
            dataInicio: values.dataInicio || new Date().toLocaleDateString("pt-BR"),
            dataFim: values.dataInicio || new Date().toLocaleDateString("pt-BR"),
          });
        } else {
          buscarDashboard(values);
        }
      }
    });
  };

  const formLayout = {
    labelCol: {
      xs: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 14 },
    },
  };

  const dateLayout = {
    labelCol: {
      xs: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 11 },
    },
  };

  const dateAteLayout = {
    labelCol: {
      xs: { span: 3 },
    },
    wrapperCol: {
      xs: { span: 11 },
    },
  };

  const compareDates = (rule, value, callback) => {
    const inicio = moment(getFieldValue("dataInicio"), "DD/MM/YYYY");
    const fim = moment(value, "DD/MM/YYYY");

    if (fim.isBefore(inicio) && value.length >= 10) {
      callback(true);
    } else if (
      fim.isSameOrBefore(moment("1900", "YYYY"), "year") &&
      value.length >= 10
    ) {
      callback(true);
    } else {
      callback();
    }
  };

  const isRequired = false;

  const requirement = (field) => {
    return Boolean(getFieldValue(field));
  };

  useEffect(() => {
    props.form.validateFields(["dataInicio"]);
  }, [Boolean(getFieldValue("dataFim"))]);

  useEffect(() => {
    if (Boolean(getFieldValue("dataInicio")) === false)
      props.form.validateFields(["dataFim"]);
  }, [Boolean(getFieldValue("dataInicio"))]);

  return (
    <BasicModal
      title="Filtrar"
      visible={filterModal}
      onClose={fecharModal}
      width="60vw"
      destroyOnClose
      bodyStyle={{}}
      style={{ minWidth: "400px" }}
      footer={[
        <Button
          className="secondary-btn"
          key="back"
          onClick={fecharModal}
          disabled={opcoesLoading || buscarLoading}
        >
          Cancelar
        </Button>,
        <Button
          className="primary-btn"
          key="submit"
          type="primary"
          onClick={handleSubmit}
          disabled={opcoesLoading}
          loading={buscarLoading}
        >
          Buscar
        </Button>,
      ]}
    >
      <Form layout="horizontal" hideRequiredMark {...formLayout}>
        <Form.Item label="UBS" style={{ marginBottom: ".5rem" }}>
          {getFieldDecorator("ubs", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Selecione"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {ubsOption.map((ubs) => (
                <Option key={ubs.ubs} value={ubs.ubs}>
                  {ubs.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label={
            <span>
              Atendente
              {tipo && (
                <>
                  &nbsp;
                  <Tooltip
                    title="Para o TME (Tempo Médio de Espera), aplicar este filtro talvez não indique
                      que este atendente tenha demorado para iniciar o atendimento. 
                      Embora alguns atendimentos podem ser delegados para o atendente por 
                      um administrador antes de serem iniciados, a maioria dos atendimentos 
                      só tem um atendente quando o mesmo inicia o atendimento de fato."
                    overlayStyle={{ maxWidth: "500px" }}
                  >
                    <Icon type="info-circle" theme="filled" />
                  </Tooltip>
                </>
              )}
            </span>
          }
          style={{ marginBottom: ".5rem" }}
        >
          {getFieldDecorator("atendente", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              placeholder="Selecione"
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
              showSearch
              notFoundContent={ notFoundAtendente }
              onSearch={_.debounce(
                (text) =>
                text.length >= 3
                    ? getEnfermeiros(text)
                    : (setNotFoundAtendente(
                        "Digite pelo menos 3 caracteres para buscar um atendente"
                      )),
                300
              )}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {atendentes?.map((atendente) => (
                <Option key={atendente.id} value={atendente.id}>
                  {atendente.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Bairro" style={{ marginBottom: ".5rem" }}>
          {getFieldDecorator("bairro", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              placeholder="Selecione"
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {bairros?.map((bairro) => (
                <Option key={bairro} value={bairro}>
                  {bairro}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Row>
          <Col span={tipoFiltro !== "hora" ? 12 : 24}>
            <Form.Item
              label={tipoFiltro === "hora" ? "Data" : "De"}
              {...(tipoFiltro !== "hora" && { ...dateLayout })}
              style={{ marginBottom: ".5rem" }}
            >
              {getFieldDecorator("dataInicio", {
                rules: [
                  {
                    required: requirement("dataFim"),
                    message: "Campo obrigatório.",
                  },
                  {
                    pattern: DATE_FORMAT,
                    validator: isValidDateUnrequired,
                    message: "Insira uma data válida.",
                  },
                ],
                initialValue: tipoFiltro === 'hora' ? new Date().toLocaleDateString("pt-BR") : undefined,
                getValueFromEvent: (e) => maskDate(e.target.value),
              })(
                <Input
                  maxLength={10}
                  allowClear
                  disabled={false}
                  placeholder="dd/mm/aaaa"
                />
              )}
            </Form.Item>
          </Col>
          {tipoFiltro !== "hora" && (
            <Col span={12}>
              <Form.Item
                label="Até"
                {...dateAteLayout}
                style={{ marginBottom: ".5rem" }}
              >
                {getFieldDecorator("dataFim", {
                  rules: [
                    {
                      required: requirement("dataInicio"),
                      message: "Campo obrigatório.",
                    },
                    {
                      pattern: DATE_FORMAT,
                      message: "Insira uma data válida.",
                    },
                    {
                      validator: compareDates,
                      message: "Insira uma data válida.",
                    },
                  ],
                  initialValue: undefined,
                  getValueFromEvent: (e) => maskDate(e.target.value),
                })(
                  <Input
                    maxLength={10}
                    allowClear
                    disabled={false}
                    placeholder="dd/mm/aaaa"
                  />
                )}
              </Form.Item>
            </Col>
          )}
        </Row>

        {!tipo && (
          <Form.Item label="Status" style={{ marginBottom: ".5rem" }}>
            {getFieldDecorator("status", {
              rules: [{ required: isRequired, message: "Campo obrigatório." }],
              initialValue: undefined,
            })(
              <Select
                placeholder="Selecione"
                loading={opcoesLoading}
                disabled={false}
                style={{ minWidth: "200px" }}
                allowClear
                mode="multiple"
              >
                {status.map((status) => (
                  <Option key={status.id} value={status.id}>
                    {status.nome}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        )}

        <Form.Item label="Prioridade" style={{ marginBottom: ".5rem" }}>
          {getFieldDecorator("prioridade", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              placeholder="Selecione"
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
            >
              {prioridades.map((prioridade) => (
                <Option key={prioridade.id} value={prioridade.id}>
                  {prioridade.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Tipo da tarefa" style={{ marginBottom: ".5rem" }}>
          {getFieldDecorator("assunto", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              placeholder="Selecione"
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
              mode="multiple"
            >
              {assuntos.map((assunto) => (
                <Option key={assunto.id} value={assunto.id}>
                  {assunto.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Paciente" style={{ marginBottom: ".5rem" }}>
          {getFieldDecorator("paciente", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              placeholder="Selecione"
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
              showSearch
              notFoundContent={notFoundPaciente}
              onSearch={_.debounce(
                (text) =>
                  text.length >= 3
                    ? getPacientePorParteNome(text)
                    : (setNotFoundPaciente(
                        "Digite pelo menos 3 caracteres para buscar um paciente"
                      ),
                      getPacientePorParteNome()),
                300
              )}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {pacientes?.map((paciente) => (
                <Option key={paciente.id} value={paciente.id}>
                  {paciente.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="Comorbidades" style={{ marginBottom: ".5rem" }}>
          {getFieldDecorator("morbidades", {
            rules: [{ required: isRequired, message: "Campo obrigatório." }],
            initialValue: undefined,
          })(
            <Select
              mode="multiple"
              placeholder="Selecione"
              loading={opcoesLoading}
              disabled={false}
              style={{ minWidth: "200px" }}
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {morbidades?.map((morbidade) => (
                <Option key={morbidade.id} value={morbidade.id}>
                  {morbidade.nome}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </BasicModal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fecharModal: () => dispatch(actions.fecharModal()),
  salvarFiltro: (filtro) => dispatch(actions.salvarFiltro(filtro)),
  buscarDashboard: (body) => dispatch(actions.buscarDashboard(body)),
  buscarTMEeTMA: (body) => dispatch(actions.buscarTMEeTMA(body)),
  buscarOpcoes: (ubs) => dispatch(actions.buscarOpcoes(ubs)),
  getEnfermeiros: (body) => dispatch(actions.getEnfermeiros(body)),
  getPacientePorParteNome: (body) =>
    dispatch(actions.getPacientePorParteNome(body)),
});

const mapStateToProps = (store) => ({
  filterModal: store.dashboard.filterModal,
  assuntos: store.dashboard.assuntos,
  status: store.dashboard.status,
  prioridades: store.dashboard.prioridades,
  atendentes: store.dashboard.atendentes,
  filtro: store.dashboard.filtro,
  opcoesLoading: store.dashboard.opcoesLoading,
  buscarLoading: store.dashboard.buscarLoading,
  ubsOption: store.dashboard.ubsOption,
  bairros: store.dashboard.bairros,
  morbidades: store.dashboard.morbidades,
  pacientes: store.dashboard.pacientes,
});

const handleChangeForm = (props, changed, allValues) => {
  props.salvarFiltro(allValues);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "filtro_dashboard",
    onValuesChange: _.debounce(handleChangeForm, 300),
  })(FilterModal)
);
