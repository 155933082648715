export const BUSCAR_SITUACOES_INIT = "atendentes.inico_buscar_situacoes";
export const BUSCAR_SITUACOES_SUCCESS = "atendentes.sucesso_buscar_situacoes";
export const BUSCAR_SITUACOES_FAIL = "atendentes.falha_buscar_situacoes";

export const BUSCAR_PERFIS_INIT = "atendentes.inico_perfis_situacoes";
export const BUSCAR_PERFIS_SUCCESS = "atendentes.sucesso_perfis_situacoes";
export const BUSCAR_PERFIS_FAIL = "atendentes.falha_perfis_situacoes";

export const BUSCAR_ATENDENTES_INIT = "atendentes.inico_buscar_atendentes";
export const BUSCAR_ATENDENTES_SUCCESS = "atendentes.sucesso_buscar_atendentes";
export const BUSCAR_ATENDENTES_FAIL = "atendentes.falha_buscar_atendentes";
export const RESET_ATENDENTES = "atendentes.reiniciar_lista_atendentes";
export const SAVE_FILTRO_ATENDENTES = "atendentes.salvar_filtro_atendentes";
export const RESET_FILTRO_ATENDENTES = "atendentes.resetar_filtro_atendentes";

export const CLOSE_ATENDENDENTES_MODAL = "atendentes.fechar_modal_atendente";
export const OPEN_ATENDENDENTES_MODAL = "atendentes.abrir_modal_atendente";
export const ATENDENDENTE_DATA = "atendentes.preparar_dados_atendente";

export const BUSCAR_CEP_INIT = "atendentes.inicio_buscar_cep_atendentes";
export const BUSCAR_CEP_SUCCESS = "atendentes.sucesso_buscar_cep_atendentes";
export const BUSCAR_CEP_FAIL = "atendentes.falha_buscar_cep_atendentes";
export const RESET_ENDERECO = "atendentes.resetar_endereco_atendentes";

export const LOADING_MODAL_INIT = "atendentes.inicio_loading_botao_modal";
export const LOADING_MODAL_FINISH = "atendentes.fim_loading_botao_modal";

export const CLOSE_ACTION_MODAL = "atendentes.fechar_modal_acao";
export const OPEN_ACTION_MODAL = "atendentes.abrir_modal_acao";
export const BUSCAR_MOTIVOS_INIT = "atendentes.inico_buscar_motivos";
export const BUSCAR_MOTIVOS_SUCCESS = "atendentes.sucesso_buscar_motivos";
export const BUSCAR_MOTIVOS_FAIL = "atendentes.falha_buscar_motivos";

export const ATUALIZAR_AFASTAMENTOS_MODAL =
  "atendentes.atualizar_afastamentos_modal";

export const CHECK_ANOTHER_REGISTRY = "atendentes.checkbox_registrar_outro";

export const BUSCAR_UBS_INIT = "atendentes.BUSCAR_UBS_INIT";
export const BUSCAR_UBS_SUCCESS = "atendentes.BUSCAR_UBS_SUCCESS";
export const BUSCAR_UBS_FAIL = "atendentes.BUSCAR_UBS_FAIL";
