import * as types from "./actionType";
import * as taskTypes from "Pages/PainelAtividades/actionType";
import * as services from "./service";
import { returnMessage, parseJWT } from "Misc/utils";
import api from "api";
import { history } from "App";

export const logIn = (username, password) => async (dispatch) => {
  try {
    await dispatch({
      type: taskTypes.RESET_TASK,
    });

    await dispatch({
      type: types.LOG_IN_INIT,
    });
    const response = await services.logar(username, password);

    const token = response.token;

    localStorage.setItem("user", token);
    const user = parseJWT(token);

    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    const mapUser = {
      id: user.user,
      nome: user.sub,
      perfil: user.aud,
      admin: Boolean(user.admin),
    };
    history.push("/");

    await dispatch({
      type: types.LOG_IN,
      payload: mapUser,
    });

    await dispatch({
      type: types.LOG_IN_END,
    });

    history.push("/");
  } catch (error) {
    console.error("Falha na requisicao de [logIn]", returnMessage(error));

    dispatch({
      type: types.FAIL_LOGIN,
      payload: { msg: returnMessage(error), type: "error" },
    });
  }
};

export const saveUser = (token) => async (dispatch) => {
  const user = parseJWT(token);
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const mapUser = {
    id: user.user,
    nome: user.sub,
    perfil: user.aud,
    admin: Boolean(user.admin),
  };

  await dispatch({
    type: types.LOG_IN,
    payload: mapUser,
  });
  await dispatch({
    type: types.LOG_IN_END,
  });
};

export const logOut = () => async (dispatch) => {
  await localStorage.removeItem("user");
  delete api.defaults.headers.common["Authorization"];
  await dispatch({
    type: types.LOG_OUT,
  });
  history.push("/");
  await dispatch({
    type: taskTypes.RESET_TASK,
  });
};

export const logInExpires = () => async (dispatch) => {
  await localStorage.removeItem("user");
  delete api.defaults.headers.common["Authorization"];
  dispatch({
    type: types.FAIL_LOGIN,
    payload: {
      msg: "Você precisa se autenticar novamente para entrar no sistema.",
      type: "info",
    },
  });
  await dispatch({
    type: taskTypes.RESET_TASK,
  });
};
