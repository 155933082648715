import * as types from "./actionType";

const initialState = {
  lista_cuidadores: [],
  contato_emergencial: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CUIDADORES:
      return {
        ...state,
        lista_cuidadores: action.payload.lista_cuidadores,
        contato_emergencial: action.payload.contato_emergencial
      };
    default:
      return state;
  }
};

export default reducer;
