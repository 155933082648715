import * as types from "./actionType";

const initialState = {
  desvio_padrao: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DESVIO_PADRAO_INIT:
      return {
        ...state,
        desvio_padrao: {},
      };
    case types.FETCH_DESVIO_PADRAO_COMPLETE:
      return {
        ...state,
        desvio_padrao: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
