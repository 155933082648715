import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Input, InputNumber, Modal, Select } from "antd";
import { connect } from "react-redux";
import * as actions from "../action";
import {
  DATE_FORMAT,
  DIAS_SEMANA,
  SCROLL_CONFIG,
  TIME_FORMAT,
} from "Misc/constants";
import { maskDate, maskTime, sendSuccessMsg } from "Misc/utils";
import moment from "moment";

import "./style.scss";

const { Option } = Select;
let fieldId = 0;

const TarefasNaoFarmacologicasModal = (props) => {
  const { onOk, title, tarefaID, onCancel, confirmLoading, ...rest } = props;
  const {
    getFieldDecorator,
    validateFieldsAndScroll,
    setFieldsValue,
    getFieldValue,
  } = props.form;
  const [tarefaField, setTarefaField] = useState(false);

  // console.log('propsModal', props)

  const removeHorarioField = (k) => {
    const horarios = getFieldValue(`horariosTarefasNF`);
    if (horarios.length === 1) return;

    setFieldsValue({
      horariosTarefasNF: horarios.filter((key) => key !== k),
    });
  };

  const addHorarioField = () => {
    const horarios = getFieldValue("horariosTarefasNF");
    const nexthorarios = horarios.concat(fieldId++);

    setFieldsValue({
      horariosTarefasNF: nexthorarios,
    });
  };

  const validateInput = (e) => {
    e.preventDefault();

    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const newValue = { ...values };
        const { fim } = values;
        const newFinal = fim ? fim : null;
        newValue.fim = newFinal;
        newValue.idPlanoTarefa = tarefaID;
        newValue.horarios = Object.values(values.horarios);
        props.adicionaTarefaNF(props.paciente, newValue).then((res) => {
          onOk();
          sendSuccessMsg(newValue.idPlanoTarefa ? "Tarefa atualizada com sucesso." : "Tarefa salva com sucesso.")
          props.form.resetFields();
        });
        setTarefaField(false);
      }
    });
  };

  const handleCancelar = async () => {
    onCancel();
    setTarefaField(false);
    props.form.resetFields();
    props.resetTarefaNFDetalhe();
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  };

  const onChangeTipoTarefa = (value) => {
    console.log('value', value)
    if (value === 9) {
      setTarefaField(true);
    } else {
      setTarefaField(false);
    }
  };

  useEffect(() => {
    if (props.tarefaNFDetalhes.id === 9) {
      setTarefaField(true)
    } else {
      setFieldsValue(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tarefaNFDetalhes.id])

  const handleSelectChangeFrequencia = (value) => {
    props.form.setFieldsValue({
      frequencia: value,
    });
  };

  const verificaFrequencia = () => {
    switch (props.form.getFieldValue("frequencia")) {
      case 2:
        return (
          <Form.Item key="input-tarefaNF-qtd-dias" label="Quantidade de dias">
            {getFieldDecorator("xDias", {
              rules: [{ required: true, message: "Insira a quantidade!" }],
              initialValue:
                props.tarefaNFDetalhes &&
                props.tarefaNFDetalhes.frequencia &&
                props.tarefaNFDetalhes.frequencia.xDias,
            })(<InputNumber style={{ width: 150 }} />)}
          </Form.Item>
        );
      case 3:
        return (
          <>
            <Form.Item key="input-tarefaNF-dias-semanas" label="Dias da semana">
              {getFieldDecorator("diaSemana", {
                rules: [{ required: true, message: "Insira os dias!" }],
                initialValue:
                  props.tarefaNFDetalhes &&
                  props.tarefaNFDetalhes.frequencia &&
                  props.tarefaNFDetalhes.frequencia.diaSemana,
              })(
                <Select mode="tags">
                  {DIAS_SEMANA.map((dia) => {
                    return <Option key={dia.valor}>{dia.descricao}</Option>;
                  })}
                </Select>
              )}
            </Form.Item>
          </>
        );

      default:
        break;
    }
  };

  getFieldDecorator(`horariosTarefasNF`, {
    initialValue:
      props.tarefaNFDetalhes && props.tarefaNFDetalhes.horarios
        ? props.tarefaNFDetalhes.horarios
        : [""],
  });
  const horarios = getFieldValue(`horariosTarefasNF`);

  return (
    <Modal
      wrapClassName="modal-delegate"
      title={title}
      okText="Confirmar"
      cancelText="Cancelar"
      onOk={(e) => validateInput(e)}
      width={"700px"}
      style={{ minWidth: "700px" }}
      centered={true}
      className="modalStyle"
      bodyStyle={{
        overflowY: "auto",
      }}
      onCancel={handleCancelar}
      confirmLoading={confirmLoading}
      {...rest}
    >
      <Form
        {...formItemLayout}
        className="modal-tarefas-nao-farmacologica"
        hideRequiredMark
      >
        <Form.Item
          key="input-tarefa-nao-farmacologica-tipo"
          label="Tipo de tarefa"
          style={{ marginBottom: ".5rem" }}
        >
          {getFieldDecorator("tipoTarefaID", {
            rules: [{ required: true, message: "Insira uma tarefa!" }],
            initialValue: props.tarefaNFDetalhes && props.tarefaNFDetalhes.id,
          })(
            <Select
              showSearch
              style={{ width: 300, textTransform: "capitalize" }}
              placeholder="Selecione"
              onChange={onChangeTipoTarefa}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.tarefasNF.map((item) => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.id}
                  >
                    {item.nome}
                  </Option>
                );
              })}
            </Select>
          )}
          {tarefaField && (
            <>
              {getFieldDecorator("outrosTipoTarefa", {
                rules: [{ required: true, message: "Insira uma tarefa!" }],
                initialValue:
                  props.tarefaNFDetalhes && 
                  props.tarefaNFDetalhes.outrosTipoTarefa &&
                  `${props.tarefaNFDetalhes.outrosTipoTarefa[0].toUpperCase()}${props.tarefaNFDetalhes.outrosTipoTarefa.slice(1)}`,
              })(<Input placeholder="Outro tipo de tarefa" />)}
            </>
          )}
        </Form.Item>
        <Form.Item
            key="input-tarefa-nao-farmacologica-recomendacao"
            label="Recomendação"
            style={{ marginBottom: ".5rem" }}
        >
          {getFieldDecorator("recomendacao", {
            initialValue: props?.tarefaNFDetalhes?.recomendacao
          })(
            <Input
              style={{ width: 300 }}
              placeholder="Digite uma recomendaçào"
              allowClear
              disabled={props.readOnly}
            />
          )}
        </Form.Item>
        <Form.Item
          key="input-tarefa-nao-farmacologica-frequencia"
          label="Frequencia"
          style={{ marginBottom: ".5rem" }}
        >
          {getFieldDecorator("frequencia", {
            rules: [{ required: true, message: "Insira uma frequência!" }],
            initialValue:
              props.tarefaNFDetalhes &&
              props.tarefaNFDetalhes.frequencia &&
              props.tarefaNFDetalhes.frequencia.id,
          })(
            <Select
              showSearch
              style={{ width: 300, textTransform: "capitalize" }}
              placeholder="Selecione"
              onChange={(value) => handleSelectChangeFrequencia(value)}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.frequencias.map((item) => {
                return (
                  <Option
                    style={{ textTransform: "capitalize" }}
                    value={item.id}
                  >
                    {item.nome}
                  </Option>
                );
              })}
            </Select>
          )}
        </Form.Item>
        {verificaFrequencia()}
        <Form.Item
          key="input-tarefa-nao-farmacologica-horario"
          label="Horário"
          style={{ marginBottom: ".5rem" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            {horarios.map((campo, index) => (
              <Form.Item
                key={`input-tarefa-nao-farmacologica-horario-${index}`}
                style={{ marginBottom: ".5rem" }}
              >
                {getFieldDecorator(`horarios[${campo}]`, {
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    {
                      required: true,
                      message: "Insira um horário!",
                      pattern: TIME_FORMAT,
                    },
                  ],
                  getValueFromEvent: (e) => maskTime(e.target.value),
                  initialValue: typeof campo === "string" ? campo : null,
                })(
                  <Input
                    style={{ width: 130 }}
                    placeholder="HH:mm"
                    maxLength={5}
                    allowClear
                    disabled={props.readOnly}
                  />
                )}
                {horarios.length > 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    style={{ alignSelf: "center", padding: "0 .5vw" }}
                    onClick={() => removeHorarioField(campo)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Button
              className="buttonFooterBack"
              style={{ marginTop: "1vh", width: "130px" }}
              onClick={addHorarioField}
            >
              + horário
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          label="Período de uso"
          style={{ marginBottom: ".5rem", marginTop: "12px" }}
        >
          <Form.Item style={{ display: "inline-block" }}>
            {getFieldDecorator("inicio", {
              rules: [
                { message: "Insira uma data válida.", pattern: DATE_FORMAT },
              ],
              initialValue:
                (props.tarefaNFDetalhes?.inicio &&
                  props.tarefaNFDetalhes?.inicio) ||
                moment().format("DD/MM/YYYY"),
              getValueFromEvent: (e) => maskDate(e.target.value),
            })(
              <Input
                style={{ width: 140 }}
                placeholder="Início"
                maxLength={10}
                allowClear
              />
            )}
          </Form.Item>
          <Form.Item style={{ display: "inline-block", marginLeft: "16px" }}>
            {getFieldDecorator("fim", {
              rules: [
                { message: "Insira uma data válida.", pattern: DATE_FORMAT },
              ],
              initialValue:
                props.tarefaNFDetalhes?.fim && props.tarefaNFDetalhes?.fim,
              getValueFromEvent: (e) => maskDate(e.target.value),
            })(
              <Input
                style={{ width: 140 }}
                placeholder="Fim (opcional)"
                maxLength={10}
                allowClear
              />
            )}
          </Form.Item>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  tarefaNFDetalhes: store.acompanhamento.tarefaNFDetalhes,
  frequencias: store.acompanhamento.frequencias,
  tarefasNF: store.acompanhamento.tarefasNF,
  confirmLoading: store.acompanhamento.modalTarefaNFLoading,
});
const mapDispatchToProps = (dispatch) => ({
  getTarefasNF: () => dispatch(actions.getTarefasNF()),
  adicionaTarefaNF: (pacienteID, info) =>
    dispatch(actions.adicionaTarefaNF(pacienteID, info)),
  resetTarefaNFDetalhe: () => dispatch(actions.resetTarefaNFDetalhe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    name: "tarefas_nao_farmacologicas_fields",
  })(TarefasNaoFarmacologicasModal)
);
