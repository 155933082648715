/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionsHeader from "Components/Header/action";
import * as actions from "./action";
import { Button, Collapse } from "antd";

import AtendentesFiltro from "./Components/AtendentesFiltro";
import AtendentesTabela from "./Components/AtendentesTabela";
import AtendentesAddModal from "./Components/AtendentesAddModal";
import AtendentesActionModal from "./Components/AtendentesActionModal";

import "./style.scss";

const Atendentes = ({
  changeCabecalhoInfo,
  openAtendentesModal,
  listaAtendentes,
}) => {
  const [activeCollapse, setActiveCollapse] = useState(false);
  useEffect(() => {
    changeCabecalhoInfo({
      nome: "Equipe de Saúde",
    });
  }, []);

  return (
    <div className="atendentes-body">
      <AtendentesAddModal />
      <AtendentesActionModal />
      <section className="atendentes-buttons">
        <Button
          type="primary"
          className="primary-btn"
          onClick={() => openAtendentesModal("add")}
        >
          Cadastrar
        </Button>
      </section>
      <section className="atendentes-section atendentes-filtro">
        <Collapse onChange={() => setActiveCollapse((state) => !state)} defaultActiveKey={['1']} >
          <Collapse.Panel
            header="Filtros de busca"
            style={{ width: "700px", textAlign: "center" }}
            showArrow={false}
            key="1"
          >
            <AtendentesFiltro />
          </Collapse.Panel>
        </Collapse>
      </section>
      {listaAtendentes !== null && (
        <section className="atendentes-section atendentes-table">
          <AtendentesTabela activeCollapse={activeCollapse} />
        </section>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeCabecalhoInfo: (info) =>
    dispatch(actionsHeader.changeCabecalhoInfo(info)),
  openAtendentesModal: (modal) => dispatch(actions.openAtendentesModal(modal)),
});

const mapStateToProps = (store) => ({
  listaAtendentes: store.atendentes.listaAtendentes,
});

export default connect(mapStateToProps, mapDispatchToProps)(Atendentes);
