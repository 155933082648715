import * as types from "./actionType";

const initialState = {
  admin: true,
  tarefas: {
    tarefasPendentes: [],
    tarefasAndamento: [],
    tarefasConcluidas: [],
  },
  loading: false,
  success: undefined,
  paciente: null,
  enfermeiros: [],
  loadingModal: false,
  loadingTransfer: false,
  confirmLoadingDelegate: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INIT:
      return {
        ...state,
        enfermeiros: [],
        loadingModal: true,
        loadingTransfer: true,
      };
    case types.RESET_TASK:
      return {
        ...state,
        tarefas: {
          tarefasPendentes: [],
          tarefasAndamento: [],
          tarefasConcluidas: [],
        },
      };
    case types.FETCHING_TASK_COMPLETE:
      return {
        ...state,
        admin: action.payload.admin,
        tarefas: action.payload.tarefas,
        loading: false,
      };
    case types.FETCHING_TASK:
      return {
        ...state,
        loading: true,
      };
    case types.POLLING_TASK:
      return {
        ...state,
      };
    case types.FETCHING_TASK_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.EXECUTE_TASK_COMPLETE:
      return {
        ...state,
        success: action.payload.success,
        paciente: action.payload.paciente,
      };
    case types.EXECUTE_TASK_ERROR:
      return {
        ...state,
        success: action.payload.success,
        paciente: null,
      };
    case types.NURSES_FETCH_COMPLETE:
      return {
        ...state,
        enfermeiros: action.payload.enfermeiros,
        loadingModal: false,
        loadingTransfer:false,
      };
    case types.FETCH_ERROR:
      return {
        ...state,
        enfermeiros: [],
        loadingModal: false,
        loadingTransfer: false,
      };

    case types.SEND_INFO:
      return {
        ...state,
        confirmLoadingDelegate: true,
      };
    case types.SEND_INFO_COMPLETE:
      return {
        ...state,
        confirmLoadingDelegate: false,
      };
    default:
      return state;
  }
};

export default reducer;
