import * as types from "./actionsType";

const initialState = {
  grupos: [],
  questions: [{}],
  respostas: [],
  refs: [],
  activeRef: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_QUESTIONARIO_COMPLETE:
      return {
        ...state,
        grupos: action.payload.grupos
      };
    case types.FETCHING_QUESTIONARIO:
      return {
        ...state,
        grupos: [],
        questions: [{}],
        respostas: []
      };

    case types.ATT_QUESTIONS_COMPLETE:
      return {
        ...state,
        questions: action.payload.questions,
        respostas: action.payload.req
      };
    case types.SET_REFS:
      return {
        ...state,
        refs: [...state.refs, action.payload],
      };
    case types.SET_ACTIVE_REF:
      return {
        ...state,
        activeRef: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
