import React from 'react'
import '../style.scss'

const SemaforoRiscoAlto = () => {
  return (
    <>
      <div className="default riscoAlto" />
      <div className="default riscoAlto" />
      <div className="default riscoAlto" />
    </>
  )
}

export default SemaforoRiscoAlto
