import React, { useState } from "react";
import { Typography, Icon, Tag, Form, Input } from "antd";
import "./style.scss";
import { unsavedFields } from "Pages/Rotina/action";
import { connect } from "react-redux";

const { CheckableTag } = Tag;

const { Text } = Typography;
const { TextArea } = Input;

const CondicoesSaude = (props) => {
  const {
    form: { getFieldDecorator },
    morb,
    morbSelected,
    rotina,
    unsavedFields,
    outrasMorbidades,
    readOnly,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState([]);

  const handleClick = (index) => {
    var indexToRemove = morbSelected.indexOf(index);
    if (indexToRemove > -1) {
      setChecked(morbSelected.splice(indexToRemove, 1));
    } else {
      setChecked(morbSelected.push(index));
    }
    unsavedFields({ doenca: true });
  };
  return (
    <>
      <div className="layoutEndereco" style={{ paddingBottom: 60 }}>
        {!rotina && (
          <>
            <div className="cadastro-object-title">
              <Icon style={{ marginRight: "10px" }} type="medicine-box" />
              <Text className="cadastro-section-title">Condições de saúde</Text>
            </div>
            <Text className="cadastro-section-description">
              Quais doenças ou transtornos o idoso apresenta?{" "}
              <span className="color-gray">(opcional)</span>
            </Text>
          </>
        )}
        <div className="bodyCadasterMorbContent">
          {morb.map((questionItem, i) => {
            return (
              <CheckableTag
                key={questionItem.id}
                className="checkableTagMorb"
                style={
                  morbSelected.includes(questionItem.id)
                    ? {
                        backgroundColor: "#138183",
                        color: "#FFFFFF",
                      }
                    : {
                        backgroundColor: "rgba(56, 193, 229, 0.1)",
                        color: "#333333",
                      }
                }
                onChange={() => {
                  !readOnly && handleClick(questionItem.id);
                }}
              >
                {questionItem.nome}
              </CheckableTag>
            );
          })}
        </div>
        {morbSelected.includes(31) && (
          <Form.Item>
            {getFieldDecorator("outrasMorbidades", {
              rules: [
                {
                  required: true,
                  message: "Insira ao menos uma morbidade",
                },
              ],
              initialValue: outrasMorbidades,
            })(<TextArea rows={2} cols={100} disabled={readOnly} />)}
          </Form.Item>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
});

const mapStateToProps = (store) => ({
  morbSelected: store.informacaosaude.morbSelected,
  outrasMorbidades: store.informacaosaude.outrasMorbidades,
  morb: store.informacaosaude.morbidades,
});

export default connect(mapStateToProps, mapDispatchToProps)(CondicoesSaude);
