import React from "react";
import { Typography } from "antd";

const { Paragraph } = Typography;

const InformationFollowUp = ({ info }) => (
  <>
    <Paragraph strong style={{ color: "#333" }}>
      {info.nome}
    </Paragraph>
    <Paragraph style={{ fontStyle: "italic", color: "#333" }}>
      {info.descricao}
    </Paragraph>
  </>
);

export default InformationFollowUp;
