import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "./style.scss";
import { Typography, Divider, Form, Input, Icon, Button } from "antd";
import Cuidador from "./Cuidador";
import { InputPhone } from "Components/FormInputs";
import CardComponent from "Components/CardComponent";
import { connect } from "react-redux";
import { attInfo } from "./service";
import * as actions from "./action";
import { unsavedFields } from "Pages/Rotina/action";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { confirmReenvioEmail, sendErrorMsg, sendSuccessMsg } from "Misc/utils";
import { SCROLL_CONFIG } from "Misc/constants";

const { Text } = Typography;
let id = 1;

const RedeApoio = (props) => {
  const {
    form: { getFieldDecorator, validateFieldsAndScroll, getFieldValue },
    rotina,
    badge_redeApoio,
    readOnly,
  } = props;

  const { tipo } = useParams();

  const validateInput = (e) => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        let emergenciaValor = null;
        if (values.emergencia.nome && values.emergencia.telefone) {
          emergenciaValor = values.emergencia;
        }

        let cuidadorValor = null;
        if (values.cuidador.nome && values.cuidador.telefone) {
          cuidadorValor = values.cuidador;
        }

        let cuidadorSecundario = null;
        if (!!values.secundario) {
          let auxSecundario = _.compact([...values.secundario]).filter(
            (item) => {
              if (!!item.nome && !!item.telefone) return item;
              else return null;
            }
          );
          if (auxSecundario.length > 0) {
            cuidadorSecundario = auxSecundario;
          }
        }

        const newJSON = {
          token: "gecro",
          paciente: props.paciente,
          cuidador: cuidadorValor,
          secundarios: cuidadorSecundario,
          emergencia: emergenciaValor,
        };
        attInfo(newJSON)
          .then((res) => {
            props.nextStep(props.step);
          })
          .catch((e) => {
            sendErrorMsg(e);
          });
      } else {
        console.error("Erro");
      }
    });
  };

  const saveTab = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        let emergenciaValor = null;
        if (values.emergencia.nome && values.emergencia.telefone) {
          emergenciaValor = values.emergencia;
        }

        let cuidadorValor = null;
        if (values.cuidador.nome && values.cuidador.telefone) {
          cuidadorValor = values.cuidador;
        }

        let cuidadorSecundario = null;

        if (!!values.secundario) {
          let auxSecundario = _.compact([...values.secundario]).filter(
            (item) => {
              if (!!item.nome && !!item.telefone) return item;
              else return null;
            }
          );
          if (auxSecundario.length > 0) {
            cuidadorSecundario = auxSecundario;
          }
        }

        const newJSON = {
          token: "gecro",
          paciente: props.paciente,
          cuidador: cuidadorValor,
          secundarios: cuidadorSecundario,
          emergencia: emergenciaValor,
        };
        props.atualizarRedeApoio(JSON.stringify(newJSON)).then(() => sendSuccessMsg("Dados atualizados com sucesso."));
      } else {
        console.error("Erro");
      }
    });
  };

  useEffect(() => {
    if (props.paciente) props.getInfoRedeApoio(props.paciente);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);

  const remove = (k) => {
    const keys = props.form.getFieldValue("keys");
    if (keys.length === 0) {
      return;
    }
    props.form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  };

  const add = () => {
    const { form } = props;
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(id++);
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  getFieldDecorator("keys", {
    initialValue:
      Object.keys(props.secundarios).length > 0
        ? Object.keys(props.secundarios)
        : [0],
  });

  const keys = getFieldValue("keys");

  const formItems = keys.map((k, index) => {
    return (
      <>
        <React.Fragment key={k}>
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Form.Item
              style={{
                marginRight: "8px",
              }}
              label="Nome"
              colon={false}
            >
              {getFieldDecorator(`secundario[${k}].nome`, {
                rules: [
                  {
                    required: Boolean(
                      getFieldValue(`secundario[${k}].telefone`)
                    ),
                    whitespace: true,
                    message: "Insira o nome.",
                  },
                ],
                initialValue: props.secundarios[k] && props.secundarios[k].nome,
              })(
                <Input
                  size={"large"}
                  placeholder="Nome"
                  style={{ marginRight: 8 }}
                  maxLength={60}
                  disabled={readOnly}
                />
              )}
            </Form.Item>
            <div
              style={{
                marginRight: "8px",
              }}
            >
              <InputPhone
                isRequired={Boolean(getFieldValue(`secundario[${k}].nome`))}
                getFieldDecorator={getFieldDecorator}
                initialValue={
                  props.secundarios[k] && props.secundarios[k].telefone
                }
                decorator={`secundario[${k}].telefone`}
                label="Telefone"
                placeholder="Ex.:99 9 9999-9999"
                colon={false}
                disabled={readOnly}
              />
            </div>
            <Form.Item
              label={
                <span>
                  Email <span className="color-gray">(opcional)</span>
                </span>
              }
              colon={false}
            >
              {getFieldDecorator(`secundario[${k}].email`, {
                rules: [
                  {
                    required: false,
                    whitespace: true,
                    message:
                      "Please input passenger's nome or delete this field.",
                  },
                  {
                    type: "email",
                    message: "Digite um email válido.",
                  },
                ],
                initialValue:
                  props.secundarios[k] && props.secundarios[k].email,
              })(
                <Input
                  size={"large"}
                  placeholder="Email"
                  maxLength={50}
                  disabled={readOnly}
                />
              )}
              <Button
                className="buttonFooterBack"
                onClick={() => confirmReenvioEmail(getFieldValue(`secundario[${k}].email`), props.paciente)}
                style={{ display: "table", width: "100%", marginTop: ".5rem" }}
                disabled={!getFieldValue(`secundario[${k}].email`) || props.badge_redeApoio}
              >
                Reenviar e-mail de Boas Vindas
              </Button>
            </Form.Item>
            {keys.length > -1 && !readOnly ? (
              <Icon
                className="dynamic-delete-button"
                type="minus-circle-o"
                style={{ alignSelf: "center", padding: "1vw" }}
                onClick={() => remove(k)}
              />
            ) : null}
          </div>
        </React.Fragment>
      </>
    );
  });

  return (
    <div style={!!rotina ? { marginBottom: "75px" } : {}}>
      {!rotina && (
        <>
          {tipo !== "2" && (
            <>
              <Text>Confirme as informações</Text>
              <Divider style={{ background: "rgba(56, 193, 229, 0.4)" }} />
            </>
          )}
        </>
      )}
      <CardComponent title="Cuidadores" classText="" rotina={rotina}>
        <Cuidador
          handleSubmit={validateInput}
          add={add}
          formItems={formItems}
          getFieldDecorator={getFieldDecorator}
          form={props.form}
          rotina={rotina}
          readOnly={readOnly}
        />
      </CardComponent>
      {!rotina && (
        <>
          <Divider style={{ background: "rgba(56, 193, 229, 0.4)" }} />
          <div className="cadastro-object-title">
            <Icon style={{ marginRight: "10px" }} type="notification" />
            <Text className="cadastro-section-title">
              Contato para emergências{" "}
              <Text className="cadastro-section-description color-gray">
                (opcional)
              </Text>
            </Text>
          </div>
          <Text className="cadastro-section-description color-gray">
            Não provê cuidados para o idoso, mas pode ser acionado numa situação
            de emergência (exemplo: porteiro, vizinho, etc).
          </Text>
        </>
      )}
      <CardComponent
        title={
          <span>
            Contato para emergências{" "}
            <span style={{ fontWeight: "normal", color: "#aaa" }}>
              (opcional)
            </span>
          </span>
        }
        classText=""
        rotina={rotina}
        divStyle={{ width: "100%" }}
      >
        <Form
          style={
            rotina ? { width: "100%", display: "flex" } : { width: "100%" }
          }
          hideRequiredMark={true}
        >
          <Form.Item key={"emergencia"}>
            <div
              style={{
                display: "flex",
                flexDirection: rotina ? "row" : "column",
                width: "100%",
              }}
            >
              <Form.Item
                style={{
                  marginRight: "8px",
                }}
                label="Nome"
                colon={false}
              >
                {getFieldDecorator(`emergencia[nome]`, {
                  rules: [
                    {
                      required: Boolean(getFieldValue("emergencia[telefone]")),
                      whitespace: true,
                      message: "Insira um nome.",
                    },
                  ],
                  initialValue: props.emergencia && props.emergencia.nome,
                })(
                  <Input
                    size={"large"}
                    placeholder="Nome"
                    style={{ marginRight: 8, maxWidth: "600px" }}
                    allowClear
                    disabled={readOnly}
                  />
                )}
              </Form.Item>
              <InputPhone
                isRequired={Boolean(getFieldValue("emergencia[nome]"))}
                getFieldDecorator={getFieldDecorator}
                initialValue={props.emergencia && props.emergencia.telefone}
                decorator={`emergencia[telefone]`}
                label="Telefone"
                placeholder="Ex.:99 9 9999-9999"
                colon={false}
                style={{ maxWidth: "215px" }}
                disabled={readOnly}
              />
            </div>
          </Form.Item>
        </Form>
      </CardComponent>
      {!rotina ? (
        <>
          <Divider style={{ background: "rgba(56, 193, 229, 0.4)" }} />
          <div style={{ display: "flex", width: "40%" }}>
            <Button
              className="buttonFooterNext"
              onClick={(e) => validateInput(e)}
              type="primary"
            >
              Próximo
            </Button>
            <Button
              className="buttonFooterBack"
              onClick={() => props.backStep(props.step)}
              type="primary"
              style={
                props.step > 1
                  ? { opacity: 1 }
                  : { opacity: 0, pointerEvents: "none" }
              }
            >
              Voltar
            </Button>
          </div>
        </>
      ) : (
        !readOnly && (
          <div className="footer-save-button">
            <Button
              className="buttonFooterNext"
              onClick={saveTab}
              disabled={!badge_redeApoio}
              type="primary"
            >
              Salvar alterações
            </Button>
            <span className="affix-title">{props.title}</span>
          </div>
        )
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  step: store.adesao.step,
  emergencia: store.redeapoio.emergencia,
  secundarios: store.redeapoio.secundarios,
  badge_redeApoio: store.rotina.unsaved.redeApoio,
});

const mapDispatchToProps = (dispatch) => ({
  getInfoRedeApoio: (pacienteID) =>
    dispatch(actions.getInfoRedeApoio(pacienteID)),
  editInfo: (redeApoioInfo) => dispatch(actions.editInfo(redeApoioInfo)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
  atualizarRedeApoio: (redeApoioInfo) =>
    dispatch(actions.atualizarRedeApoio(redeApoioInfo)),
});

const handleChange = (props) => {
  props.unsavedFields({ redeApoio: true });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    nome: "apoio",
    onValuesChange: _.debounce(handleChange, 300),
  })(RedeApoio)
);
