import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getInfoCabecalho } from "../Adesao/action";
import { Row, Col } from "antd";
import { useParams, Redirect } from "react-router-dom";
import BarraInformacao from "../../Components/BarraInformacao";
import BarLoader from "react-spinners/BarLoader";
import "./style.scss";
import "./OpcoesRotina";
import OpcoesRotina from "./OpcoesRotina";
import Followup from "../../Components/Followup";

const Rotina = props => {
  const { tarefa, tipo } = useParams();
  useEffect(() => {
    props.getInfoCabecalho(props.user.id, tarefa, tipo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarefa]);
  return (
    <>
      {!tarefa && <Redirect to="/" />}
      <BarraInformacao />
      {!props.loading ? (
        <Row className={"layout-rotina"}>
          <Col className={"coluna-folloup"}>
            <Followup />
          </Col>
          <Col className={"coluna-body"}>
            <OpcoesRotina />
          </Col>
        </Row>
      ) : (
          <BarLoader color={"#0F79B2"} width={"100vw"} />
        )}
    </>
  );
};

const mapStateToProps = store => ({
  paciente: store.adesao.paciente,
  loading: store.barrainformacao.loading,
  user: store.login.user
});

const mapDispatchToProps = dispatch => ({
  getInfoCabecalho: (enfermeiroID, tarefaID, tipo) =>
    dispatch(getInfoCabecalho(enfermeiroID, tarefaID, tipo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Rotina);
