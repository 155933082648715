import React, { useEffect, useState } from "react";
import { Button, Checkbox, Divider, message } from "antd";
import BasicModal from "Components/BasicModal";
import { connect } from "react-redux";
import * as actions from "../action";
import _ from "lodash";

export const TransferirPacientesModal = (props) => {
  var [checked, setChecked] = useState(new Map());

  const {
    transferModal,
    fecharModal,
    filtro,
    buscarTotalOpcoesPacientesAtendentes,
    opcoesLoading,
    transferLoading,
    atendTotalPacientes,
    pacientesAtendente,
    map,
    nomeAtend,
    transferirPacientes,
    buscarDetalhamentoDashboard,
    selectedUbs,
  } = props;

  const onChange = (e, index, key) => {
    const item = e.target.value;
    const isChecked = e.target.checked;

    setChecked(new Map(checked.set(item, isChecked)));
    map.get(key)[index].checked = isChecked;
  };

  const onCheckAllChange = (e) => {
    const isChecked = e.target.checked;
    pacientesAtendente.forEach((pacientes, index) => {
      setChecked(
        new Map(checked.set(`${e.target.value}#${pacientes.id}`, isChecked))
      );
      map.get(e.target.value)[index].checked = isChecked;
    });
  };

  const handleClick = async () => {
    var transfAtend = { novoAtendente: [] };

    map.forEach((value, keys) => {
      const toAtend = { idAtendente: 0, idPaciente: [] };
      var selectedPaciente = [];
      value.forEach((paciente) => {
        if (paciente.checked) {
          selectedPaciente.push(paciente.id);
        }
      });
      toAtend.idAtendente = keys;
      toAtend.idPaciente = selectedPaciente;
      if (!_.isEmpty(selectedPaciente)) {
        transfAtend.novoAtendente.push(toAtend);
      }
    });

    if (!_.isEmpty(transfAtend.novoAtendente)) {
      await transferirPacientes(transfAtend);
      //Atualiza dados da Tela
      await buscarTotalOpcoesPacientesAtendentes(filtro, selectedUbs);
      await buscarDetalhamentoDashboard(filtro);
    } else {
      message.warn("Selecione ao menos um paciente para transferir.");
    }
  };

  useEffect(() => {
    buscarTotalOpcoesPacientesAtendentes(filtro, selectedUbs);
  }, [buscarTotalOpcoesPacientesAtendentes, filtro, selectedUbs]);

  return (
    <BasicModal
      title="Transferir Pacientes"
      visible={transferModal}
      onClose={fecharModal}
      width="60vw"
      destroyOnClose
      bodyStyle={{}}
      style={{ minWidth: "400px", marginTop: "1%" }}
      footer={[
        <Button
          className="secondary-btn"
          key="back"
          onClick={fecharModal}
          disabled={opcoesLoading || transferLoading}
        >
          Cancelar
        </Button>,
        <Button
          type="primary"
          style={{ fontWeight: "bold", width: 110, marginLeft: "1%" }}
          onClick={handleClick}
          loading={transferLoading}
        >
          Transferir
        </Button>,
      ]}
    >
      <div>
        <span>
          <b>Transferindo do atendente: </b>
          {nomeAtend}
        </span>
      </div>
      <Divider />
      <div className="header-list">
        <h4>Atendente</h4>
        <h4>Paciente</h4>
        <h4> </h4>
        <h4>Transferir para o atendente</h4>
      </div>
      <div style={{ marginRight: "0.8%", marginBottom: "2%" }}>
        <Divider style={{ margin: "5px", background: "#C0C0C0" }} />
      </div>
      {atendTotalPacientes.map((tarefa) => {
        return (
          <>
            {tarefa.id !== filtro.atendente && (
              <>
                <div className="components-list">
                  <div className="clerk-col">
                    {tarefa.atendente} <br />
                    {`Total de pacientes (${tarefa.pacientes})`}
                  </div>
                  <div className="patients-col">
                    <span style={{ marginLeft: "5%" }}>
                      Todos ({pacientesAtendente.length})
                    </span>
                    <Checkbox
                      style={{
                        position: "absolute",
                        right: "15%",
                      }}
                      value={tarefa.id}
                      onChange={(e) => onCheckAllChange(e)}
                    />
                    <Divider
                      style={{
                        margin: "2%",
                        background: "#C0C0C0",
                        marginBottom: "2%",
                      }}
                    />

                    {pacientesAtendente.map((pacientes, i) => {
                      return (
                        <>
                          <span style={{ marginLeft: "5%" }}>
                            {pacientes.nome}
                          </span>
                          <Checkbox
                            style={{
                              position: "absolute",
                              right: "15%",
                            }}
                            value={`${tarefa.id}#${pacientes.id}`}
                            checked={checked.get(
                              `${tarefa.id}#${pacientes.id}`
                            )}
                            onChange={(e) => onChange(e, i, tarefa.id)}
                          />
                          <Divider style={{ margin: "2%" }} />
                        </>
                      );
                    })}
                  </div>
                </div>
                <div style={{ marginRight: "2%" }}>
                  <Divider style={{ margin: "2%", background: "#C0C0C0" }} />
                </div>
              </>
            )}
          </>
        );
      })}
    </BasicModal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fecharModal: () => dispatch(actions.fecharModalTransferPatients()),
  buscarTotalOpcoesPacientesAtendentes: (filter, selectedUbs) =>
    dispatch(actions.buscarTotalOpcoesPacientesAtendentes(filter, selectedUbs)),
  transferirPacientes: (info) => dispatch(actions.transferirPacientes(info)),
  buscarDetalhamentoDashboard: (body) =>
    dispatch(actions.buscarDetalhamentoDashboard(body)),
});

const mapStateToProps = (store) => ({
  transferModal: store.dashboard.transferPatientsModal,
  filtro: store.dashboard.filtro,
  user: store.login.user,
  atendTotalPacientes: store.dashboard.atendTotalPacientes,
  pacientesAtendente: store.dashboard.pacientesAtendente,
  map: store.dashboard.map,
  nomeAtend: store.dashboard.nomeAtend,
  transferLoading: store.dashboard.transferLoading,
  selectedUbs: store.dashboard.selectedUbs,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferirPacientesModal);
