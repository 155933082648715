import api from "api";

export const getFollowUpProcessosInflamatorios = async (pacienteID, tarefa) => {
    const res = await api.post(
      "/backoffice/buscarQuestionarioSintomasInflamatorios",
      {
        token: "gecro",
        paciente: pacienteID,
        tarefa: tarefa,
      }
    );
    return res.data;
  };
  
  export const saveFollowUpProcessosInflamatorios = async (body) => {
    const res = await api.post(
      "/backoffice/salvarQuestionarioSintomasInflamatorios",
      body
    );
    return res.data;
  };

  export const finalizarTarefa = async (pacienteID, tarefa) => {
    const res = await api.post(
      "/backoffice/finalizarTarefa",
      {
        token: "gecro",
        paciente: pacienteID,
        tarefa: tarefa,
      }
    );
    return res.data;
  };
  