import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import InfoPessoalResponsavel from "./InfoPessoalResponsavel";
import { Button, Typography, Divider, Icon, Form, Input } from "antd";
import Endereco from "../Endereco";
import * as actions from "./action";
import { connect } from "react-redux";
import { attInfo } from "./services";
import { InputCPF, InputPhone } from "Components/FormInputs";
import CardComponent from "Components/CardComponent";
import { unsavedFields } from "Pages/Rotina/action";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { confirmReenvioEmail, sendErrorMsg, sendSuccessMsg } from "Misc/utils";
import { SCROLL_CONFIG } from "Misc/constants";

const { Text } = Typography;

const ContratoResponsavel = (props) => {
  useEffect(() => {
    if (!!props.paciente) {
      props.getContratoResponsavelInfo(props.paciente);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paciente]);

  const {
    form: { getFieldDecorator, validateFieldsAndScroll, validateFields },
    rotina,
    responsavel,
    badge_responsavel,
    readOnly,
  } = props;

  console.log(props.paciente)

  const { tipo } = useParams();

  const validateInput = (e) => {
    e.preventDefault();

    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const req = { ...props.responsavel, ...values.responsavel };
        delete req.isResponsavel;

        const newJSON = {
          token: "gecro",
          paciente: props.paciente,
          responsavel: req,
        };
        attInfo(JSON.stringify(newJSON))
          .then((res) => {
            props.nextStep(props.step);
          })
          .catch((err) => {
            sendErrorMsg(err);
          });
      } else {
        console.error("Erro:", err);
      }
    });
  };

  const saveTab = () => {
    validateFieldsAndScroll(SCROLL_CONFIG, (err, values) => {
      if (!err) {
        const req = { ...props.responsavel, ...values.responsavel };
        delete req.isResponsavel;

        const newJSON = {
          token: "gecro",
          paciente: props.paciente,
          responsavel: req,
        };
        props.atualizarResponsavel(JSON.stringify(newJSON)).then(() => sendSuccessMsg("Dados atualizados com sucesso."));
      } else {
        console.error("Erro:", err);
      }
    });
  };

  const [showFields, setShowFields] = useState(false);
  const [showFieldEmail, setShowFieldEmail] = useState(false);
  const isRequired = true;
  const completarInformacao = () => {
    return (
      <>
        <InfoResponsavel
          responsavel={props.responsavel}
          form={props.form}
          isRequired={isRequired}
          readOnly={readOnly}
          paciente={props.paciente}
          {...props}
        />
      </>
    );
  };
  const mostrarCampoEmail = () => {
    return (
      <Form.Item label="Email">
        {getFieldDecorator("responsavel[email]", {
          rules: [
            {
              required: isRequired,
              type: "email",
              message: "Insira um email válido.",
            },
          ],
          initialValue: props.responsavel.email,
        })(<Input size="large" disabled={readOnly} />)}
      </Form.Item>
    );
  };

  if (!rotina) {

    return (
      <>
        {tipo !== "2" && (
          <>
            <Text>Confirme as informações</Text>
            <Divider className={"cadastro-divider"} />
          </>
        )}
        <InfoPessoalResponsavel
          setShowFields={setShowFields}
          setShowFieldEmail={setShowFieldEmail}
          setCuidadorSelected={props.cuidadorSelecionado}
          form={props.form}
        />
        <Divider className={"cadastro-divider"} />
        {showFields && completarInformacao()}
        {showFieldEmail && mostrarCampoEmail()}
        <div style={{ display: "flex", width: "40%" }}>
          <Button
            className="buttonFooterNext"
            onClick={(e) => validateInput(e)}
            type="primary"
          >
            Próximo
          </Button>
          <Button
            onClick={() => props.backStep(props.step)}
            className="buttonFooterBack"
            type="primary"
            style={
              props.step > 1
                ? { opacity: 1 }
                : { opacity: 0, pointerEvents: "none" }
            }
          >
            Voltar
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <div style={{ marginBottom: "75px" }}>
        <InfoResponsavel
          opcoesResponsaveis={props.opcoesResponsaveis}
          form={props.form}
          isRequired={isRequired}
          responsavel={responsavel}
          rotina={rotina}
          readOnly={readOnly}
          {...props}
        />
        {!readOnly && (
          <div className="footer-save-button">
            <Button
              className="buttonFooterNext"
              onClick={saveTab}
              disabled={!badge_responsavel}
              type="primary"
            >
              Salvar alterações
            </Button>
            <span className="affix-title">{props.title}</span>
          </div>
        )}
      </div>
    );
  }
};

const InfoResponsavel = (props) => {
  const {
    form: { getFieldDecorator, resetFields, getFieldValue },
    isRequired,
    rotina,
    readOnly,
  } = props;

  const formItemLayout = !!rotina
    ? {
        labelCol: { span: 9, lg: 6, xl: 5, xxl: 4 },
        wrapperCol: { span: 14 },
      }
    : {};

  return (
    <>
      {!rotina && (
        <>
          <div className="cadastro-object-title">
            <Icon style={{ marginRight: "10px" }} type="user" />
            <Text className="cadastro-section-title">
              Informações pessoais do responsável
            </Text>
          </div>

          <Text className="cadastro-section-description color-gray">
            As informações serão utlizadas para gerar o contrato do serviço e,
            se necessário, entrar em contato.
          </Text>
        </>
      )}
      <CardComponent title="Dados pessoais" classText="" rotina={rotina}>
        <Form hideRequiredMark={true} colon={!!rotina} {...formItemLayout}>
          <Form.Item label="Nome completo">
            {getFieldDecorator("responsavel[nome]", {
              rules: [{ required: isRequired, message: "Insira um nome." }],
              initialValue: props.responsavel && props.responsavel.nome,
            })(
              <Input
                size="large"
                style={{ minWidth: "200px" }}
                disabled={readOnly}
              />
            )}
          </Form.Item>
          <InputCPF
            isRequired={isRequired}
            getFieldDecorator={getFieldDecorator}
            initialValue={props.responsavel && props.responsavel.cpf}
            decorator="responsavel[cpf]"
            label="CPF"
            style={{ minWidth: "170px", width: "50%" }}
            disabled={!!rotina || readOnly}
          />

          <InputPhone
            isRequired={isRequired}
            getFieldDecorator={getFieldDecorator}
            initialValue={props.responsavel && props.responsavel.telefone}
            decorator="responsavel[telefone]"
            label="Telefone"
            style={{ minWidth: "170px", width: "50%" }}
            disabled={readOnly}
          />
          <Form.Item label="Email">
            {getFieldDecorator("responsavel[email]", {
              rules: [
                {
                  required: isRequired,
                  type: "email",
                  message: "Insira um email válido.",
                },
              ],
              initialValue: props.responsavel && props.responsavel.email,
            })(
              <Input
                size="large"
                style={{ minWidth: "200px" }}
                disabled={readOnly}
              />
            )}
            <Button
              className="buttonFooterBack"
              onClick={() => confirmReenvioEmail(getFieldValue(`responsavel[email]`), props.paciente)}
              style={{ display: "table", width: "100%", marginTop: ".5rem" }}
              disabled={!getFieldValue(`responsavel[email]`) || props.badge_responsavel}
            >
              Reenviar e-mail de Boas Vindas
            </Button>
          </Form.Item>
        </Form>
      </CardComponent>

      {!rotina && <Divider className={"cadastro-divider"} />}
      <Endereco
        getFieldDecorator={getFieldDecorator}
        resetFields={resetFields}
        decoratorCep="responsavel.endereco[cep]"
        decoratorRua="responsavel.endereco.[logradouro]"
        decoratorComplemento="responsavel.endereco[complemento]"
        decoratorNumero="responsavel.endereco[numero]"
        decoratorBairro="responsavel.endereco[bairro]"
        decoratorEstado="responsavel.endereco[uf]"
        decoratorCidade="responsavel.endereco[cidade]"
        titulo="Endereço do Responsável"
        subtitulo="O endereço é necessário para a cobrança do serviço."
        isRequired={isRequired}
        rotina={rotina}
        formItemLayout={formItemLayout}
        readOnly={readOnly}
        {...props.responsavel.endereco}
      />
      {!rotina && <Divider className={"cadastro-divider"} />}
    </>
  );
};

const mapStateToProps = (store) => ({
  paciente: store.adesao.paciente,
  step: store.adesao.step,
  opcoesResponsaveis: store.responsavelContrato.opcoesResponsaveis,
  responsavel: store.responsavelContrato.responsavel,
  badge_responsavel: store.rotina.unsaved.responsavel,
});

const mapDispatchToProps = (dispatch) => ({
  getContratoResponsavelInfo: (pacienteID) =>
    dispatch(actions.getContratoResponsavelInfo(pacienteID)),
  editInfo: (responsavel, value) =>
    dispatch(actions.editInfo(responsavel, value)),
  unsavedFields: (unsaved) => dispatch(unsavedFields(unsaved)),
  atualizarResponsavel: (responsavel) =>
    dispatch(actions.atualizarResponsavel(responsavel)),
});

const handleChange = (props) => {
  props.unsavedFields({ responsavel: true });
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    nome: "contrato_fields",
    onValuesChange: _.debounce(handleChange, 500),
  })(ContratoResponsavel)
);
