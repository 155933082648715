import React, { useEffect, useState } from "react";
import Card from "./Card";

const CardGrid = ({ infos }) => {
  const [ivcf, setIvcf] = useState();
  const [reporte, setReporte] = useState();
  const [rotina, setRotina] = useState();
  const [agudizacao, setAgudizacao] = useState();

  useEffect(() => {
    if (infos) {
      setIvcf({
        ivcfAtual: infos.ivcfAtual,
        riscoIvcfAtual: infos.riscoIvcfAtual,
        ivcfInicioPeriodo: infos.ivcfInicioPeriodo,
        riscoIvcfInicioPeriodo: infos.riscoIvcfInicioPeriodo,
        graficos: [
          {
            valor: infos.ivcfAtual,
            total: 40,
            legenda: [
              infos.riscoIvcfAtual.charAt(0).toUpperCase() +
                infos.riscoIvcfAtual.slice(1),
            ],
            color:
              infos.riscoIvcfAtual === "alto"
                ? "rgba(221, 0, 0, 1)"
                : infos.riscoIvcfAtual === "moderado"
                ? "rgba(255, 214, 0, 1)"
                : "rgba(46, 125, 50, 1)",
          },
        ],
        descricao:
          "A variação considera a diferença entre o valor do IVCF anterior ao período e o valor mais recente do IVCF dentro do período (que é o valor atual do paciente).",

        variation:
          infos.ivcfAtual > infos.ivcfInicioPeriodo
            ? "negative"
            : infos.ivcfAtual < infos.ivcfInicioPeriodo
            ? "positive"
            : "equal",
      });
      setReporte({
        tarefasNaoRealizadas: infos.tarefasNaoRealizadas,
        totalTarefasEsperadas: infos.totalTarefasEsperadas,
        diasComAusencias: infos.diasComAusencias,
        graficos: [
          {
            valor: infos.tarefasNaoRealizadas,
            total: infos.totalTarefasEsperadas,
            legenda: ["Atividades"],
            color: infos.tarefasNaoRealizadas > (infos.totalTarefasEsperadas / 2) ? "rgba(221, 0, 0, 1)" : "rgba(46, 125, 50, 1)"
          },
          {
            valor: infos.diasComAusencias,
            total: 15,
            legenda: ["Dias"],
            color: infos.diasComAusencias >= 8 ? "rgba(221, 0, 0, 1)" : "rgba(46, 125, 50, 1)"
          },
        ],
      });
      setRotina({
        totalPerguntasUltimaRotina: infos.totalPerguntasUltimaRotina,
        respostasDesfavoraveisUltimaRotina: infos.respostasDesfavoraveisUltimaRotina,
        totalPerguntasRotinaAnterior: infos.totalPerguntasRotinaAnterior,
        respostasDesfavoraveisRotinaAnterior: infos.respostasDesfavoraveisRotinaAnterior,
        graficos: [
          {
            valor: infos.respostasDesfavoraveisUltimaRotina,
            total: infos.totalPerguntasUltimaRotina,
            legenda: ["Respostas ”desfavoráveis”"],
            color:  infos.respostasDesfavoraveisUltimaRotina > (infos.totalPerguntasUltimaRotina / 2) ? "rgba(221, 0, 0, 1)" : "rgba(46, 125, 50, 1)"
          }
        ],
        descricao: "A variação considera a diferença entre a quantidade de respostas desfavoráveis da entrevista de rotina mais recente comparada à quantidade de respostas desfavoráveis da entrevista de rotina anterior, independentemente do período.",
        variation: infos.respostasDesfavoraveisUltimaRotina >
        infos.respostasDesfavoraveisRotinaAnterior
          ? "negative"
          : infos.respostasDesfavoraveisUltimaRotina <
            infos.respostasDesfavoraveisRotinaAnterior
          ? "positive"
          : "equal",
      });
      setAgudizacao({
        values: infos.totalAgudizacoes,
        agudizacoes: infos.agudizacoes.filter((agudizacao) => (agudizacao.quantidade > 0)),
        variation: infos.totalAgudizacoes > infos.totalAgudizacoesPeriodoAnterior 
        ? "negative" 
        : infos.totalAgudizacoes < infos.totalAgudizacoesPeriodoAnterior
        ? "positive"
        : "equal",
        color: infos.totalAgudizacoes > infos.totalAgudizacoesPeriodoAnterior 
        ? "#F5222D" 
        : infos.totalAgudizacoes === infos.totalAgudizacoesPeriodoAnterior
        ? "#0F79B2"
        : "#558B2F",
        descricao:
            "A variação compara a quantidade de agudizações que o paciente teve na mesma quantidade de tempo (15 dias neste caso) do período anterior ao atual.",
      });
    }
  }, [infos]);

  return (
    <div className="card-grid">
      <Card
        data={ivcf}
        title="Pontuação do IVCF-20"
        parameter="ivcf"
      />
      <Card data={reporte} title="Ausências de reporte" parameter="reporte" />
      <Card
        data={rotina}
        title="Entrevistas de rotina"
        parameter="rotina"
      />
      <Card data={agudizacao} title="Agudizações" parameter="agudizacao" />
    </div>
  );
};

export default CardGrid;
