import api from "../../api";

export const getContratoResponsavelInfo = async (pacienteID) => {
  const res = await api.post(
    "/backoffice/buscarResponsavel",
    {
      token: "gecro",
      paciente: pacienteID,
    }
  );

  return res.data;
};

export const attInfo = async (responsavel) => {
  const res = await api.post(
    "/backoffice/atualizarResponsavel",

    responsavel
  );

  return res.data;
};
