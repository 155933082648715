import * as types from "./actionType";
import * as rotinaTypes from "../Rotina/actionType";
import * as services from "./service";
import { sendErrorMsg } from "Misc/utils";

export const salvarObservacoes = (
  body,
  pacienteID,
  tarefa
) => async dispatch => {
  try {
    dispatch({ type: rotinaTypes.SAVE_FOLLOW_UP_START });
    await services.salvarObservacoes(body);
    await services.finalizarTarefa(pacienteID, tarefa);
    await dispatch({ type: rotinaTypes.SAVE_FOLLOW_UP_END });
    return true;
  } catch (error) {
    dispatch({ type: rotinaTypes.SAVE_FOLLOW_UP_END });
    sendErrorMsg(error);
    console.error("Falha na requisicao de [salvarObservacoes]", error);
  }
};

export const buscarCuidadores = paciente => async dispatch => {
  try {
    const req = {
      token: "gecro",
      paciente: paciente
    };

    const res = await services.buscarCuidadores(req);
    const principal = { ...res.cuidador, principal: true };
    const cuidadores = [principal, ...res.secundarios]
      .map(item => ({
        nome: item.nome,
        telefone: item.telefone,
        principal: Boolean(item.principal)
      }))
      .filter(i => i.nome);

    await dispatch({ type: types.FETCH_CUIDADORES, payload: {lista_cuidadores: cuidadores, contato_emergencial: {...res.emergencia}} });
  } catch (error) {
    sendErrorMsg(error);
    console.error("Falha na requisicao de [buscarCuidadores]", error);
  }
};
